class AgoraVideoCallProps {
  maxCallTime: number;

  usedCallTime: number;

  ownerName: string;

  videoCallToken: string;

  videocallInterviewerId: number | undefined;

  videocallRespondentId: number | undefined;

  videocallInterviewerIsGuest: boolean;

  videocallRespondentIsGuest: boolean;

  constructor(params?: AgoraVideoCallPropsType | null) {
    this.maxCallTime = params?.maxCallTime || 0;
    this.usedCallTime = params?.usedCallTime || 0;
    this.ownerName = params?.ownerName || '';
    this.videoCallToken = params?.videoCallToken || '';
    this.videocallInterviewerId = params?.videocallInterviewerId || undefined;
    this.videocallRespondentId = params?.videocallRespondentId || undefined;
    this.videocallInterviewerIsGuest =
      params?.videocallInterviewerIsGuest || false;
    this.videocallRespondentIsGuest =
      params?.videocallRespondentIsGuest || false;
  }
}

export { AgoraVideoCallProps };
