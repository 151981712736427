import React, {
  useState,
  ChangeEvent,
  Dispatch,
  useEffect,
  useContext,
} from 'react';
import { Input } from 'common/input';
import {
  checkEmailAndGetGenericError,
  classnames,
  isEmailValid,
} from 'helpers/utils';
import { Line } from 'common/line';
import { Notification } from 'common/notification';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { InfoIcon } from 'assets/icons';
import { DiscountCode } from 'common/discount_code';
import { cloneDeep } from 'lodash';
import { DiscountCodeItem } from 'common/discount-code-item';
import { maxNumberDiscountCodes } from 'config/constants';
import { HelperText } from 'common/helper-text';
import { DiscountCodeDetailsModal } from 'common/discount-code-details-modal';
import { AppContext, appActions } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import { captureEvent } from 'posthog';
import CypressIds from 'cypressIds';
import { SignUpFooter } from '../sign-up-footer';
import { Action } from '../sign-up-reducer';
import styles from './sign-up-step.module.scss';

type SignUpStep2Props = {
  dispatch: Dispatch<Action>;
  user: UserSignUpType;
  couponCode?: string;
  sectionAlreadyVisited: boolean;
  subscriptionDealList: SubscriptionDealResponseType[];
  step: number;
  subscriptionDealsApplied: CouponCodeID[];
  isCompany: boolean;
};

type MandatoryType = {
  backupName: string;
  backupEmail: string;
  validDiscountCode: boolean;
};

const mandatoryFieldsErrors: MandatoryType = {
  backupName: '',
  backupEmail: '',
  validDiscountCode: true,
};

const SignUpStep2: React.FC<SignUpStep2Props> = ({
  step,
  user,
  couponCode,
  subscriptionDealList,
  sectionAlreadyVisited,
  dispatch,
  subscriptionDealsApplied,
  isCompany,
}) => {
  const { dispatch: dispachContext } = useContext(AppContext);

  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState<MandatoryType>(mandatoryFieldsErrors);
  const [showDiscountDetailModal, setShowDiscountDetailModal] =
    useState<boolean>(false);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    objectToChange: string,
  ) => {
    dispatch({
      type: 'CHANGE_INPUT',
      object: objectToChange,
      field: e.target.name,
      value: e.target.value,
    });
  };

  const validateField = (field: string) => {
    if (field === 'backupEmail') {
      let emailMessage = '';

      if (user[field].trim() !== '') {
        emailMessage = !isEmailValid(user[field]) ? 'invalid email' : '';
      }

      setErrors((prevState) => ({
        ...prevState,
        [field]: emailMessage.toUpperCase(),
      }));
    }
  };

  const checkAllFieldsAndGetErrors = () => {
    const errs = {
      backupName: '',
      backupEmail: '',
      validDiscountCode: errors.validDiscountCode,
    };

    if (user.backupEmail !== '' && user.backupName === '') {
      errs.backupName = 'Name should be completed if there’s an email';
    }

    errs.backupEmail = checkEmailAndGetGenericError(user.backupEmail);

    if (errs.backupEmail === '') {
      if (user.backupName !== '' && user.backupEmail === '') {
        errs.backupEmail = 'Email should be completed if there’s a name';
      }
    }

    return errs;
  };

  const checkAndSetNewErrors = () => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });
  };

  const hasErrorsForm = (newErrorsList: MandatoryType) =>
    newErrorsList.backupName ||
    newErrorsList.backupEmail ||
    !newErrorsList.validDiscountCode;

  const validateFieldsFront = async () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    setErrors(newErrorsList);

    if (hasErrorsForm(newErrorsList)) {
      return false;
    }

    return true;
  };

  const getCouponCodesFlatArray = () =>
    subscriptionDealsApplied?.map((sub: CouponCodeID) => sub.code);

  const registerUser = async () => {
    try {
      const requestData: UserSignUpType = {
        ...user,
        couponCodes: getCouponCodesFlatArray(),
      };

      const data = await UserController.registerUser(requestData);
      dispachContext({ type: appActions.USER_LOGGED, data });

      return true;
    } catch (e) {
      setShowError(true);
      return false;
    }
  };

  const discountDetailsText = () => (
    <Button
      buttonSize={ButtonSize.Small}
      buttonStyle={ButtonStyle.PrimaryGhostLink}
      onClick={() => {
        setShowDiscountDetailModal(true);
      }}
    >
      Discount details
    </Button>
  );

  const deleteCouponCode = (deal: SubscriptionDealResponseType) => {
    const subDealsCopy = cloneDeep(subscriptionDealList);
    const appliedCouponCode = cloneDeep(subscriptionDealsApplied);

    const subDealsCopyUpdated = subDealsCopy.filter(
      (sub: SubscriptionDealResponseType) => sub.id !== deal.id,
    );
    const appliedCouponCodeUpdated = appliedCouponCode?.filter(
      (sub: CouponCodeID) => sub.id !== deal.id,
    );

    dispatch({
      type: 'UPDATE_SUBSCRIPTION_DEALS',
      subscriptionDeals: subDealsCopyUpdated,
    });
    dispatch({
      type: 'COUPON_CODE_APPLIED',
      couponCodeId: appliedCouponCodeUpdated,
    });
  };

  const showDiscountList = () => (
    <div className={styles.discountCodesList}>
      {subscriptionDealList.map((deal: SubscriptionDealResponseType) => (
        <DiscountCodeItem
          key={deal.id}
          deal={deal}
          deleteCouponCode={deleteCouponCode}
        />
      ))}
    </div>
  );

  const showDiscountDetailsButton = () => (
    <HelperText
      content={discountDetailsText()}
      icon={
        <InfoIcon className={classnames(styles.icon, styles.iconDetails)} />
      }
      className={styles.helperText}
    />
  );

  const showDiscountsListAndDetails = () => (
    <div className={styles.discountListAndDetails}>
      {showDiscountList()}
      {showDiscountDetailsButton()}
    </div>
  );

  const handleChangeCoupon = (newValue: string) => {
    dispatch({ type: 'COUPON_INPUT', couponCode: newValue });
    captureEvent('typeDiscountCode', {
      discountCode: newValue,
      step: 'signUp',
    });
  };

  const handleSuccess = (newDiscountCode: SubscriptionDealResponseType) => {
    const subDealsUpdated = cloneDeep(subscriptionDealList);
    const couponCodeAppliedUpdated = cloneDeep(subscriptionDealsApplied);
    subDealsUpdated.push(newDiscountCode);

    couponCodeAppliedUpdated?.push({
      id: newDiscountCode.id,
      code: couponCode!,
    });

    dispatch({
      type: 'UPDATE_SUBSCRIPTION_DEALS',
      subscriptionDeals: subDealsUpdated,
    });
    dispatch({
      type: 'COUPON_CODE_APPLIED',
      couponCodeId: couponCodeAppliedUpdated,
    });
    dispatch({ type: 'COUPON_INPUT', couponCode: '' });
  };

  const setDiscountCodeError = (isValid: boolean) => {
    setErrors((prevState) => ({ ...prevState, validDiscountCode: isValid }));
  };

  const handleApplyCode = async () => {
    captureEvent('applyDiscountCode', {
      discountCode: couponCode!,
      step: 'signUp',
    });
    return SubscriptionController.getSubscriptionDealByCode(couponCode!);
  };

  const showDiscountCodeSection = () => (
    <div className={styles.discountCodeSection}>
      <div className="text__body__regular__medium__textNeutral30">
        {' '}
        Discount code (Optional){' '}
      </div>
      <p className="text__body__regular__tiny__textNeutral30">
        You will have access to a free trial album when you create your account.
        Discount codes can be applied to paid albums you wish to create.
      </p>

      <DiscountCode
        couponCode={couponCode}
        canAddMore={subscriptionDealList.length < maxNumberDiscountCodes}
        notifyApplyNewCode={handleApplyCode}
        notifyChangeCoupon={handleChangeCoupon}
        notifyCodeInputIsValid={(isValid: boolean) =>
          setDiscountCodeError(isValid)
        }
        onSuccess={(data) => {
          handleSuccess(data as SubscriptionDealResponseType);
        }}
      />
      {subscriptionDealList.length ? showDiscountsListAndDetails() : null}
    </div>
  );

  const showDiscountDetailModalComponent = (
    discountList: SubscriptionDealResponseType[],
  ) => (
    <DiscountCodeDetailsModal
      discountCodesList={discountList}
      notifyCloseModal={() => setShowDiscountDetailModal(false)}
    />
  );

  useEffect(() => {
    if (sectionAlreadyVisited) {
      checkAndSetNewErrors();
    } else {
      dispatch({ type: 'SECTION_VISITED', section: 'step3', value: true });
    }
  }, []);

  const { backupName, backupEmail } = user;

  const showContent = () => (
    <>
      <div
        className={classnames('text__title1__textNeutral40', styles.titleView)}
      >
        {' '}
        Sign up
      </div>
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral30',
          styles.subtitle,
        )}
      >
        Legacy contact (Optional)
      </div>

      <div
        className={classnames(
          'text__body__regular__tiny__textNeutral30',
          'my-14',
        )}
      >
        We take our promise to preserve life histories seriously. Should
        something happen to the owner of this account, a legacy contact provides
        us with an alternative contact to determine if and by whom the account
        will be maintained.
      </div>

      <Input
        id="backupName"
        name="backupName"
        label="Contact name"
        placeholder="First & last name"
        value={backupName}
        errorMessage={errors.backupName}
        type="text"
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
        dataCy={CypressIds.nameBackup}
      />

      <Input
        id="backupEmail"
        name="backupEmail"
        label="Contact email"
        placeholder="email@example.com"
        value={backupEmail}
        errorMessage={errors.backupEmail}
        type="email"
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
        onBlur={() => validateField('backupEmail')}
        dataCy={CypressIds.emailBackup}
      />
      {!isCompany && (
        <>
          <Line />

          {showDiscountCodeSection()}
        </>
      )}
      <Line />

      <SignUpFooter
        step={step}
        showBack
        showRegister
        token={user.token}
        backFn={() => dispatch({ type: 'GO_BACK' })}
        sendUserDataToRegister={() => validateFieldsFront()}
        registerFn={() => registerUser()}
        isCompany={isCompany}
      />
    </>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showDiscountDetailModal &&
        showDiscountDetailModalComponent(subscriptionDealList)}
      {showContent()}
    </>
  );
};

export { SignUpStep2 };
