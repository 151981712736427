import { AddPaymentMethodModal } from 'common/add-payment-method-modal';
import { ModalCardDeclined } from 'common/modal-card-declined';
import { ModalOhNo } from 'common/modal-oh-no';
import { ModalSuccessBuying } from 'common/modal-success-buying';
import { clipsPackSize, errorsCodeInPayments } from 'config/constants';
import { AppContext } from 'context';
import { getClipPriceByAlbum } from 'helpers/utils';
import { AlbumsController } from 'networking/controllers/albums-controller';
import React, { useContext, useState } from 'react';
import { ModalProcessingPayment } from 'common/modal-processing-payment/modal-processing-payment';
import { PaymentStatus } from 'common/enums';
import { ModalBuyClips } from './componentes/modal-buy-clips';

type BuyClipsProcessType = {
  album: AlbumType;
  onFinishProcess: () => void;
  refetchAlbum?: () => void;
};

const getOptions = (maxPurchase: number) => {
  const options = [];
  for (let i = 1; i < maxPurchase; i += 1) {
    options.push({
      id: i.toString(),
      value: (i * clipsPackSize).toString(),
    });
  }
  return options;
};

const BuyClipsProcess: React.FC<BuyClipsProcessType> = ({
  album,
  onFinishProcess,
  refetchAlbum,
}) => {
  const {
    state: { subscriptionsScheme },
  } = useContext(AppContext);
  const [clipsSelected, setClipsSelected] = useState<SelectType>(
    getOptions(5)[0],
  );
  const [error, setError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardDeclined, setCardDeclined] = useState(false);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [showSelectClipModal, setShowSelectClipModal] = useState(true);
  const [payment, setPayment] = useState<PaymentInfoSerialized | undefined>(
    undefined,
  );
  const clipPrice = subscriptionsScheme
    ? getClipPriceByAlbum(album, subscriptionsScheme)
    : 0;

  const buyClips = (albumId: number, amount: number) => async () => {
    try {
      setIsLoading(true);
      const body: BuyClipsRaw = {
        album: {
          amount,
        },
      };
      const response = await AlbumsController.buyClips(albumId, body);
      setPayment(response);
      setShowSelectClipModal(false);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      const isPaymentError = err?.errors?.some(
        (e: any) => e.errorCode === errorsCodeInPayments.cardDeclined,
      );
      if (isPaymentError) {
        setCardDeclined(true);
      } else {
        setError(true);
      }
    }
  };
  const verifyPaymentStatus = async () => {
    try {
      if (payment) {
        const response = await AlbumsController.getPayment(payment.id);
        if (response.status === PaymentStatus.paid) {
          setPayment(undefined);
          setPaymentSuccess(true);
        }
        if (response.status === PaymentStatus.failed) {
          setPayment(undefined);
          setError(true);
        }
      }
    } catch (err: any) {
      setError(true);
    }
  };
  const closeSuccess = () => {
    onFinishProcess();
    if (refetchAlbum) {
      refetchAlbum();
    }
  };

  return (
    <>
      {payment && (
        <ModalProcessingPayment
          title=""
          pollingRequest={verifyPaymentStatus}
          disableManualClosing
        />
      )}
      {paymentSuccess && (
        <ModalSuccessBuying
          onClose={closeSuccess}
          information={`Your album clip limit is now ${
            album.maxClips + Number(clipsSelected.value)
          } clips.`}
        />
      )}
      {error && (
        <ModalOhNo
          onClose={onFinishProcess}
          retryFunction={buyClips(album.id, Number(clipsSelected.value))}
        />
      )}
      {cardDeclined && (
        <ModalCardDeclined
          onClose={() => setCardDeclined(false)}
          openPaymentMethodModal={() => setOpenPaymentMethodModal(true)}
        />
      )}
      {openPaymentMethodModal && (
        <AddPaymentMethodModal
          notifyClose={() => {
            setOpenPaymentMethodModal(false);
          }}
          notifySuccess={() => {
            setCardDeclined(false);
            setShowSelectClipModal(true);
            setOpenPaymentMethodModal(false);
          }}
        />
      )}
      {showSelectClipModal && (
        <ModalBuyClips
          options={getOptions(5)}
          optionSelected={clipsSelected}
          onSelectOption={(option) => setClipsSelected(option)}
          clipPrice={clipPrice}
          isLoading={isLoading}
          buyClipsFn={buyClips(album.id, Number(clipsSelected.value))}
          onClose={onFinishProcess}
        />
      )}
    </>
  );
};

export { BuyClipsProcess };
