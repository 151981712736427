/* eslint-disable max-len */
import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { ContributorsPagination } from 'models/contributors-pagination';
import { ContributorSerializer } from 'networking/serializers/contributor-serializer';

class ContributorController {
  static async getContributorsList(
    queryParams: ContributorsQueryParamsSerialized,
  ) {
    const params =
      ContributorSerializer.serializeContributorsQueryParams(queryParams);

    const response = await ApiService.get<ContributorsPaginationRaw>(
      API_ROUTES.GET_CONTRIBUTORS,
      params,
    );

    const deSerializedContributors =
      ContributorSerializer.deSerializeContributorsPagination(response.data);

    return new ContributorsPagination(deSerializedContributors);
  }
}

export { ContributorController };
