import SparkMD5 from 'spark-md5';

const calculateMD5Hash = (file: File, bufferSize: number): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const fileSlicer = File.prototype.slice;
    const hashAlgorithm = new SparkMD5();
    const totalParts = Math.ceil(file.size / bufferSize);
    let currentPart = 0;

    const processNextPart = () => {
      const start = currentPart * bufferSize;
      const end = Math.min(start + bufferSize, file.size);
      fileReader.readAsBinaryString(fileSlicer.call(file, start, end));
    };

    fileReader.onload = (e) => {
      currentPart += 1;

      const buffer = e.target!.result!.toString();
      hashAlgorithm.appendBinary(buffer);

      if (currentPart < totalParts) {
        processNextPart();
        return;
      }

      resolve(hashAlgorithm.end());
    };

    fileReader.onerror = (e) => {
      reject(e);
    };

    processNextPart();
  });

export const calculateMd5File = async (file: File): Promise<string> => {
  const bufferSize = 1024 ** 2 * 10; // 10MB

  return calculateMD5Hash(file, bufferSize);
};
