import { Dispatch, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Modal } from 'common/modal';
import { DiscountCodeItem } from 'common/discount-code-item';
import { InfoIcon } from 'assets/icons';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { maxNumberDiscountCodes } from 'config/constants';
import { AccountAction, AccountStateType } from 'pages/account/account-reducer';
import { DiscountCode } from 'common/discount_code';
import { classnames } from 'helpers/utils';
import { InitialStateType } from 'context/app-context/context-reducer';
import { DiscountCodeDetailsModal } from 'common/discount-code-details-modal';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { appActions } from 'context';
import styles from './discount-codes-cards.module.scss';

type DiscountCodesCardsProps = {
  generalDispatch: Dispatch<any>;
  accountDispatch: Dispatch<AccountAction>;
  generalState: InitialStateType;
  accountState: AccountStateType;
  className?: string;
};

const DiscountCodesCards: React.FC<DiscountCodesCardsProps> = ({
  generalDispatch,
  accountDispatch,
  generalState,
  accountState,
  className = '',
}) => {
  const [showAddDiscountCode, setShowAddDiscountCode] = useState(false);
  const [showDiscountDetailsModal, setShowDiscountDetailsModal] =
    useState(false);

  const { subscriptionDeals } = generalState.data.user;
  const { couponCode } = accountState;

  const showTitle = () => (
    <p className="text__title2__textNeutral40"> Discounts </p>
  );

  const showSubTitle = () => (
    <p className="text__body__regular__small__textNeutral30">
      Your discount code will apply to all elegible albums.
    </p>
  );

  const handleApplyCode = async () => {
    const response =
      await SubscriptionController.applySubscriptionDealByCode(couponCode);
    generalDispatch({
      type: appActions.USER_LOGGED,
      data: { user: response.user },
    });

    return response;
  };

  const handleChangeCouponCode = (newValue: string) => {
    accountDispatch({
      type: 'COUPON_INPUT',
      couponCode: newValue,
    });
  };

  const deleteCouponCode = async (deal: SubscriptionDealResponseType) => {
    try {
      await SubscriptionController.removeSubscriptionDealCode(deal.id);

      const newShallowSubDealsListCopy = subscriptionDeals.filter(
        (coupon) => coupon.id !== deal.id,
      );

      const newUserData = {
        ...generalState.data.user,
        subscriptionDeals: newShallowSubDealsListCopy,
      };

      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: newUserData },
      });
    } catch (err) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Discount code',
          message: "Discount code couldn't be deleted",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const showDiscountList = (codesList: SubscriptionDealResponseType[]) => (
    <div className={styles.discountCodesList}>
      {codesList.map((deal: SubscriptionDealResponseType) => (
        <DiscountCodeItem
          key={deal.id}
          deal={deal}
          deleteCouponCode={deleteCouponCode}
        />
      ))}
    </div>
  );

  const showAddDiscountCodeComponent = () => (
    <Modal onClose={() => setShowAddDiscountCode(false)} title="Discount code">
      <DiscountCode
        label="Do you have a discount code?"
        couponCode={couponCode}
        canAddMore={subscriptionDeals.length < maxNumberDiscountCodes}
        notifyChangeCoupon={handleChangeCouponCode}
        notifyApplyNewCode={handleApplyCode}
        onSuccess={() => {
          handleChangeCouponCode('');
          setShowAddDiscountCode(false);
        }}
      />
    </Modal>
  );

  const showDiscountDetailsButton = () => (
    <div className={styles.discountDetailsButtonContent}>
      <InfoIcon className={classnames(styles.infoIcon)} />
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        onClick={() => setShowDiscountDetailsModal(true)}
      >
        Discount details
      </Button>
    </div>
  );

  const showDiscountDetailsModalComponent = (
    discountList: SubscriptionDealResponseType[],
  ) => (
    <DiscountCodeDetailsModal
      discountCodesList={discountList}
      notifyCloseModal={() => setShowDiscountDetailsModal(false)}
    />
  );

  const showAddDiscountCodeButton = () => (
    <Button
      className={styles.addDiscountCodeButton}
      disabled={subscriptionDeals.length >= maxNumberDiscountCodes}
      buttonStyle={ButtonStyle.PrimaryStroke}
      onClick={() => {
        setShowAddDiscountCode(true);
      }}
    >
      Add discount code
    </Button>
  );

  return (
    <>
      {showAddDiscountCode && showAddDiscountCodeComponent()}
      {showDiscountDetailsModal &&
        showDiscountDetailsModalComponent(subscriptionDeals)}

      <div className={className}>
        {showTitle()}
        {showSubTitle()}

        {subscriptionDeals.length ? (
          <>
            {showDiscountList(subscriptionDeals)}
            {showDiscountDetailsButton()}
          </>
        ) : null}

        {showAddDiscountCodeButton()}
      </div>
    </>
  );
};

export { DiscountCodesCards };
