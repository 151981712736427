/* eslint-disable max-len */
import React, { FormEvent, useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardNumberElement,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';
import { CrossIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { Spinner } from 'common/spinner';
import styles from './card-form.module.scss';

type StripeFieldsType = {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
};

type CardFormProps = {
  showCancelButton?: boolean;
  submitButtonText: string;
  notifyCancel?: () => void;
  notifySubmit: (
    stripe: Stripe,
    cardNumberElement: StripeCardNumberElement,
  ) => Promise<boolean>;
  showError?: boolean;
};

const CardForm: React.FC<CardFormProps> = ({
  showCancelButton = false,
  submitButtonText,
  notifyCancel,
  notifySubmit,
  showError = false,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [fields, setFields] = useState<StripeFieldsType>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const [error, setError] = useState<boolean>(showError);

  const [fieldsError, setFieldError] = useState<StripeFieldsType>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const cardElementOptions = {
    style: {
      invalid: {
        color: '#E17E37',
      },
    },
  };

  const checkForm = () => {
    let isValid = true;

    Object.keys(fields).forEach((field) => {
      if (fields[field as keyof StripeFieldsType] === false) {
        isValid = false;
      }
    });

    return isValid;
  };

  useEffect(() => {
    if (showError) {
      setFieldError({
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
      });
    }
  }, [showError]);

  useEffect(() => {
    const someError = Object.values(fieldsError).some((field) => field);
    setError(someError);
  }, [fieldsError]);

  const onChangeField = (event: StripeElementChangeEvent) => {
    setFields((prevState) => ({
      ...prevState,
      [event.elementType]: event.complete,
    }));

    setFieldError((prevState) => ({
      ...prevState,
      [event.elementType]: event.error,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidForm = checkForm();

    if (!isValidForm) {
      setError(true);
      return;
    }

    const cardNumberElement = elements!.getElement(CardNumberElement);

    if (!stripe || !elements) {
      return;
    }
    setIsInProgress(true);

    const submitSuccess = await notifySubmit(stripe, cardNumberElement!);

    if (!submitSuccess) {
      setError(true);
    }

    setIsInProgress(false);
  };

  const submitButton = () => (
    <div className={styles.submitButton}>
      {!isInProgress ? (
        <Button
          disabled={!stripe || !elements}
          buttonSize={ButtonSize.Small}
          buttonType={ButtonType.Submit}
        >
          {submitButtonText}
        </Button>
      ) : (
        <Spinner className={styles.spinner} />
      )}
    </div>
  );

  const cancelButton = () => (
    <div className={styles.cancelButton}>
      <Button
        onClick={() => notifyCancel!()}
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.GreyGhost}
        buttonType={ButtonType.Submit}
      >
        {' '}
        Cancel{' '}
      </Button>
    </div>
  );

  const showContent = () => (
    <form className={styles.stripeForm} onSubmit={handleSubmit}>
      <div className={styles.cardNumberBox}>
        <p
          className={classnames(
            'text__body__semi__bold__medium__textNeutral40',
          )}
        >
          {' '}
          Card number{' '}
        </p>
        <CardNumberElement
          className={classnames(
            styles.inputStyle,
            fieldsError.cardNumber ? styles.borderError : '',
          )}
          options={cardElementOptions}
          onChange={(e) => onChangeField(e)}
        />
      </div>

      <div className={styles.expiryAndCvcBox}>
        <div className={styles.expiry}>
          <p
            className={classnames(
              'text__body__semi__bold__medium__textNeutral40',
            )}
          >
            {' '}
            Expiration{' '}
          </p>
          <CardExpiryElement
            options={cardElementOptions}
            className={classnames(
              styles.inputStyle,
              fieldsError.cardExpiry ? styles.borderError : '',
            )}
            onChange={(e) => onChangeField(e)}
          />
        </div>

        <div className={styles.cvc}>
          <p
            className={classnames(
              'text__body__semi__bold__medium__textNeutral40',
            )}
          >
            {' '}
            CVC{' '}
          </p>
          <CardCvcElement
            options={cardElementOptions}
            className={classnames(
              styles.inputStyle,
              fieldsError.cardCvc ? styles.borderError : '',
            )}
            onChange={(e) => onChangeField(e)}
          />
        </div>
      </div>

      <div
        className={classnames(
          styles.helperBox,
          error ? styles.showFieldError : styles.hideFieldError,
        )}
      >
        <span className={classnames(styles.iconCross)}>
          <CrossIcon />
        </span>
        <p className={classnames('text__body__regular__tiny__warning30')}>
          Please check your information or try a different card.
        </p>
      </div>

      <div className={classnames(styles.footer)}>
        <div className={styles.buttons}>
          {showCancelButton && cancelButton()}
          {submitButton()}
        </div>
      </div>
    </form>
  );

  return showContent();
};

export { CardForm };
