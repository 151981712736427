import React, { useContext } from 'react';
import { dayjs } from 'helpers/dayjs';
import { AppContext } from 'context/app-context';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  classnames,
  calculateHoursAndMinutes,
  isRestrictedAlbum,
  isLockedAlbum,
  isOwner,
} from 'helpers/utils';
import { KeyIcon, QrTinyIcon } from 'assets/icons';
import { isEmpty } from 'lodash';
import { Button, ButtonStyle } from 'common/button';
import styles from './album-card-row.module.scss';

type AlbumCardRowProps = {
  album: AlbumType;
  showAlbumStatusLabel: Function;
  notifyClickCard: (id: number, keyEvent?: boolean) => () => void;
  notifyClickQrIcon: (id: number) => void;
};

const AlbumCardRow: React.FC<AlbumCardRowProps> = ({
  album,
  showAlbumStatusLabel,
  notifyClickCard,
  notifyClickQrIcon,
}) => {
  const { state } = useContext(AppContext);
  const { user } = state.data;

  const { hours, minutes } = calculateHoursAndMinutes(album.totalTime);

  const showDefaultPicture = () => (
    <div className={styles.defaultpicture}>
      <Logo className={styles.logo} />
    </div>
  );

  const showCardRow = () => (
    <button
      type="button"
      className={styles.cardRow}
      disabled={isLockedAlbum(album)}
      onClick={notifyClickCard(album.id)}
      onKeyDown={notifyClickCard(album.id, true)}
    >
      <div className={styles.pictureAndName}>
        <div className={styles.pictureRounded}>
          {album.coverThumbnailUrl ? (
            <img
              src={album.coverThumbnailUrl}
              className={classnames(styles.picture, {
                [styles.greyImage]:
                  isRestrictedAlbum(album) || isLockedAlbum(album),
              })}
              alt="logo"
            />
          ) : (
            showDefaultPicture()
          )}
        </div>
        <div className="text__body__semi__bold__medium__textNeutral40">
          {album.title}
        </div>
        {!isEmpty(album.qrCodes) && (
          <Button
            className={styles.qrIconButton}
            buttonStyle={ButtonStyle.PrimaryGhost}
            onClick={(event) => {
              event.stopPropagation();
              notifyClickQrIcon(album.id);
            }}
          >
            <QrTinyIcon className={styles.qrIcon} />
          </Button>
        )}
      </div>

      <div
        className={classnames(
          styles.lastUpdate,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        {dayjs(album.lastUpdate).fromNow()}
      </div>
      <div
        className={classnames(
          styles.clipRecorded,
          'text__body__regular__medium__textNeutral30',
        )}
      >{`${album.clipAmount} Clips`}</div>

      <div className={styles.recordingLength}>
        <div className={styles.hours}>
          <span className="text__body__regular__medium__textNeutral30">
            {hours}
          </span>
          <span className="text__body__regular__small__textNeutral30">
            {hours > 1 ? ' hours' : ' hour'}
          </span>
        </div>
        <div className="text__body__regular__small__textNeutral30">
          &nbsp;-&nbsp;
        </div>
        <div className={styles.minutes}>
          <span className="text__body__regular__medium__textNeutral30">
            {minutes}
          </span>
          <span className="text__body__regular__small__textNeutral30">
            {' '}
            minutes{' '}
          </span>
        </div>
      </div>

      <div
        className={classnames(
          'text__body__regular__medium__textNeutral30',
          styles.albumOwner,
        )}
      >
        {isOwner(user, album) ? (
          <>
            <KeyIcon className={styles.ownerIcon} />
            <span> You </span>
          </>
        ) : (
          album.owner.name
        )}
      </div>

      <div
        className={classnames(
          styles.status,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        {showAlbumStatusLabel(album, styles.statusLabelPosition)}
      </div>
    </button>
  );

  return showCardRow();
};

export { AlbumCardRow };
