import React, { useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { useParams } from 'react-router-dom';
import { Notification } from 'common/notification';
import { SignHeader } from '../../common/sign-header';
import styles from './reset-password.module.scss';
import { ResetPasswordStep1, ResetPasswordStep2 } from './sections';

type ParamType = {
  token: string;
};

const ResetPassword: React.FC = () => {
  const { token } = useParams<ParamType>();

  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);

  const showForgotPasswordStep1View = () => (
    <ResetPasswordStep1 tokenUser={token} notifSuccess={() => setStep(1)} />
  );

  const showForgotPasswordStep2View = () => <ResetPasswordStep2 />;

  const showContentView = () => (
    <div className={globalStyles.signBoxContent}>
      <div className={styles.elements}>
        <SignHeader />
        {step === 0 && showForgotPasswordStep1View()}
        {step === 1 && showForgotPasswordStep2View()}
      </div>
    </div>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <div className={globalStyles.signMainContainer}>
      {showError && showErrorNotification()}
      {showContentView()}
    </div>
  );
};

export { ResetPassword };
