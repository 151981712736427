/* eslint-disable max-len */
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { Modal } from 'common/modal';
import { NewWindowIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { QuestionsManagmentViewType } from 'common/enums';
import { WarningBadge } from 'common/warning-badge';
import { classnames, isEditingMyTemplates } from 'helpers/utils';
import { PreBuiltItem } from '../pre-built-item';
import styles from './pre-built-questions-modal.module.scss';

type PreBuiltQuestionsModalProps = {
  title: string;
  subTitle: string;
  showModifyPreBuiltElements: boolean;
  questionsManagmentView: QuestionsManagmentViewType;
  preBuiltQuestonsList?: PreBuiltQuestionsType[];
  maxQuestionsAllowed: number;
  remainingQuestionsCount: number;
  onAddQuestionsFromPreBuiltElementList: (
    preBuiltList: PreBuiltQuestionsType[],
  ) => void;
  onClose: () => void;
  notifyShowModalRedirectToTemplatesView?: (
    preBuiltList: PreBuiltQuestionsType[],
  ) => void;
};

const PreBuiltQuestionsModal: React.FC<PreBuiltQuestionsModalProps> = ({
  title,
  subTitle,
  showModifyPreBuiltElements = false,
  questionsManagmentView,
  preBuiltQuestonsList = [],
  maxQuestionsAllowed,
  remainingQuestionsCount,
  onAddQuestionsFromPreBuiltElementList,
  onClose,
  notifyShowModalRedirectToTemplatesView,
}) => {
  const [preBuiltList, setPreBuiltList] =
    useState<PreBuiltQuestionsType[]>(preBuiltQuestonsList);
  const [currentRemaining, setCurrentRemaining] = useState(
    remainingQuestionsCount,
  );

  const selectAllQuestionsFromPreBuiltElement = (questions: QuestionType[]) =>
    questions.map((question: QuestionType) => ({
      ...question,
      isSelected: true,
    }));

  const deSelectAllQuestionsFromPreBuiltElement = (questions: QuestionType[]) =>
    questions.map((question: QuestionType) => ({
      ...question,
      isSelected: false,
    }));

  const getAllSelectedQuestionsCount = (questions: QuestionType[]) => {
    let counter = 0;

    questions.forEach((question: QuestionType) => {
      if (question.isSelected) {
        counter += 1;
      }
    });

    return counter;
  };

  const handleSelectFullPreBuiltElement = (elementId: string) => {
    const preBuiltListCopy = cloneDeep(preBuiltList);

    const preBuiltElement = preBuiltListCopy.find(
      (elem: PreBuiltQuestionsType) => elem.id === elementId,
    )!;

    if (preBuiltElement.checkValue === false) {
      preBuiltElement.checkValue = true;
      preBuiltElement.questions = selectAllQuestionsFromPreBuiltElement(
        preBuiltElement.questions,
      );

      setCurrentRemaining(
        (prevState) =>
          prevState - getAllSelectedQuestionsCount(preBuiltElement.questions),
      );
    } else if (preBuiltElement.checkValue === true) {
      preBuiltElement.checkValue = false;
      preBuiltElement.questions = deSelectAllQuestionsFromPreBuiltElement(
        preBuiltElement.questions,
      );

      setCurrentRemaining(
        (prevState) => prevState + preBuiltElement.questions.length,
      );
    } else if (preBuiltElement.checkValue === null) {
      preBuiltElement.checkValue = true;

      const alreadySelectedQuestionsCount = getAllSelectedQuestionsCount(
        preBuiltElement.questions,
      );

      preBuiltElement.questions = selectAllQuestionsFromPreBuiltElement(
        preBuiltElement.questions,
      );

      setCurrentRemaining(
        (prevState) =>
          prevState -
          preBuiltElement.questions.length +
          alreadySelectedQuestionsCount,
      );
    }

    setPreBuiltList(preBuiltListCopy);
  };

  const allQuestionsSelected = (element: PreBuiltQuestionsType) =>
    element.questions.every(
      (question: QuestionType) => question.isSelected === true,
    );

  const noQuestionsSelected = (element: PreBuiltQuestionsType) =>
    element.questions.every(
      (question: QuestionType) => question.isSelected === false,
    );

  const handleSelectQuestion = (elementId: string, questionName: string) => {
    const preBuiltListCopy = cloneDeep(preBuiltList);

    const preBuiltElement = preBuiltListCopy!.find(
      (elem: PreBuiltQuestionsType) => elem.id === elementId,
    )!;

    const question = preBuiltElement.questions.find(
      (q: QuestionType) => q.name === questionName,
    )!;
    question.isSelected = !question.isSelected;

    setCurrentRemaining((prevState) =>
      question.isSelected ? prevState - 1 : prevState + 1,
    );

    if (allQuestionsSelected(preBuiltElement)) {
      preBuiltElement.checkValue = true;
    } else if (noQuestionsSelected(preBuiltElement)) {
      preBuiltElement.checkValue = false;
    } else {
      preBuiltElement.checkValue = null;
    }

    setPreBuiltList(preBuiltListCopy);
  };

  const handleAddQuestions = () => {
    onAddQuestionsFromPreBuiltElementList(preBuiltList);
    onClose();
  };

  const footerButtons = () => (
    <div className={styles.actions}>
      <Button
        buttonStyle={ButtonStyle.GreyGhost}
        buttonSize={ButtonSize.Medium}
        onClick={() => onClose()}
      >
        Cancel
      </Button>
      <Button
        buttonStyle={ButtonStyle.PrimaryFilled}
        buttonSize={ButtonSize.Medium}
        onClick={() => handleAddQuestions()}
      >
        Add
      </Button>
    </div>
  );

  const showModifyPreBuiltElementButton = () => (
    <Button
      className={styles.modifyPreBuiltElement}
      buttonSize={ButtonSize.Small}
      buttonStyle={ButtonStyle.PrimaryGhost}
      onClick={() => {
        notifyShowModalRedirectToTemplatesView!(preBuiltList);
        onClose();
      }}
    >
      <span> Modify templates </span>
      <NewWindowIcon />
    </Button>
  );

  const showQuestionsCounter = () => (
    <p
      className={classnames('text__body__semi__bold__small__textNeutral30', {
        [styles.warningCounter]:
          maxQuestionsAllowed - currentRemaining === maxQuestionsAllowed,
      })}
    >
      {`Questions: ${
        maxQuestionsAllowed - currentRemaining
      }/${maxQuestionsAllowed}`}
    </p>
  );

  return (
    <Modal title={title} onClose={() => onClose()}>
      <div className={styles.modal}>
        <div className={styles.title}>
          <p className="text__heading6__textNeutral40">{subTitle}</p>
          {isEditingMyTemplates(questionsManagmentView) &&
            showQuestionsCounter()}

          {showModifyPreBuiltElements && showModifyPreBuiltElementButton()}
        </div>
        {isEditingMyTemplates(questionsManagmentView) &&
          currentRemaining === 0 && (
            <WarningBadge
              className={styles.warningMessage}
              message="You’ve reached the limit of questions per album"
            />
          )}
        <div className={styles.content}>
          {preBuiltList?.length &&
            preBuiltList.map((t) => (
              <PreBuiltItem
                key={t.id}
                item={t}
                questionsManagmentView={questionsManagmentView}
                remainingQuestionsCount={currentRemaining}
                selectPreBuiltItem={handleSelectFullPreBuiltElement}
                selectQuestion={handleSelectQuestion}
              />
            ))}
        </div>
        {footerButtons()}
      </div>
    </Modal>
  );
};
export { PreBuiltQuestionsModal };
