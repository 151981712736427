import { ChevronIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { classnames } from 'helpers/utils';
import styles from './link-button.module.scss';

type LinkButtonProps = {
  text: string | JSX.Element;
  onClick?: () => void;
  className?: string;
};

const LinkButton = ({ text, onClick, className = '' }: LinkButtonProps) => (
  <Button
    className={classnames(styles.linkButton, className)}
    buttonStyle={ButtonStyle.PrimaryGhost}
    buttonSize={ButtonSize.Small}
    onClick={onClick}
  >
    {text}
    <ChevronIcon className={styles.chevronIcon} />
  </Button>
);

export { LinkButton };
