import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { classnames, isOwner } from 'helpers/utils';
import { VideoDisabledIcon } from 'assets/icons';
import { SensorSelectionContext } from 'context';
import { useContext } from 'react';
import styles from './no-cam-enabled.module.scss';

type NoCamEnabledProps = {
  user: UserType;
  album: AlbumType;
  myEmptyView: boolean;
  small?: boolean;
  className?: string;
  otherUserName?: string;
  otherUserIsGuest: boolean;
};

const NoCamEnabled: React.FC<NoCamEnabledProps> = ({
  user,
  album,
  myEmptyView,
  small = false,
  className = '',
  otherUserName,
  otherUserIsGuest,
}) => {
  const { sensorSelection } = useContext(SensorSelectionContext);
  const cameraDeviceAvailable = myEmptyView
    ? !sensorSelection.camera.error
    : true;

  const getInitials = (name: string) => {
    const wordsArray = name.split(' ');
    // If name does not include space, return first letter of name
    if (wordsArray.length === 1) {
      return Array.from(wordsArray[0])[0];
    }
    // If name includes space, return first letter of first and last word
    return `${Array.from(wordsArray[0])[0]} ${
      Array.from(wordsArray[wordsArray.length - 1])[0]
    }`;
  };

  const showDefaultPicture = () => (
    <div className={styles.defaultpicture}>
      <Logo className={styles.logo} />
    </div>
  );

  const showGuestContentDisable = () => (
    <div className={classnames('text__heading1__textNeutral20', styles.guest)}>
      {album.coverUrl ? (
        <img src={album.coverUrl} className={styles.picture} alt="logo" />
      ) : (
        showDefaultPicture()
      )}
    </div>
  );

  const showMyEmptyAvatar = () =>
    user.id ? (
      <div className={styles.initialsText}>{getInitials(user.name)}</div>
    ) : (
      showGuestContentDisable()
    );

  const showOtherUserEmptyAvatar = () =>
    otherUserName && !otherUserIsGuest ? (
      <div className={styles.initialsText}>{getInitials(otherUserName)}</div>
    ) : (
      showGuestContentDisable()
    );

  const showMyEmptyText = () => (
    <div className={classnames(styles.infoText)}>
      {isOwner(user, album) && !cameraDeviceAvailable
        ? 'No camera device detected'
        : 'Your camera is off'}
    </div>
  );

  const showNoCameraIcon = () => (
    <div className={styles.noCamCircle}>
      <VideoDisabledIcon className={styles.videoEnabled} />
    </div>
  );

  const showCameraOffText = (name: string) => {
    const lastLetter = name.slice(-1);
    const properName = lastLetter === 's' ? `${name}’` : `${name}’s`;

    return `${properName} camera is off`;
  };

  const showOtherUserEmptyText = () => (
    <div className={styles.infoText}>
      {showCameraOffText(otherUserName ?? '')}
    </div>
  );

  return (
    <div className={classnames(className, styles.noCamEnabled)}>
      <div className={classnames(styles.avatar, { [styles.smallView]: small })}>
        {myEmptyView ? showMyEmptyAvatar() : showOtherUserEmptyAvatar()}
        {showNoCameraIcon()}
      </div>
      <div className={styles.text}>
        {myEmptyView ? showMyEmptyText() : showOtherUserEmptyText()}
      </div>
    </div>
  );
};

export { NoCamEnabled };
