import React from 'react';

import { goToPage, RouteName } from 'routes';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { classnames } from 'helpers/utils';

import styles from './left-section.module.scss';

type LeftSectionProps = {
  user: UserType;
};

const LeftSection: React.FC<LeftSectionProps> = ({ user }) => {
  const showCompanyName = (company: string) => (
    <div className={styles.company}>
      <span className={styles.powered}> Managed by</span>
      &nbsp;
      <span className={styles.companyName}>{company}</span>
    </div>
  );

  const getCompanyName = () =>
    user.companyName ? showCompanyName(user.companyName) : null;

  return (
    <button
      type="button"
      onClick={() => goToPage(RouteName.Albums)}
      className={styles.logoAndTexts}
    >
      <Logo className={styles.logo} />
      <div
        className={classnames(styles.allText, {
          [styles.gsBiggerSize]: !user.companyName,
        })}
      >
        <div className={styles.gs}>
          <div className={styles.generational}>Generational</div>
          <div className={styles.story}>Story</div>
        </div>
        {getCompanyName()}
      </div>
    </button>
  );
};

export { LeftSection };
