import React from 'react';
import { Line } from 'common/line';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { classnames } from 'helpers/utils';
import styles from './forgot-password-step.module.scss';

type ForgotPasswordStep2Props = {
  notifGoBack: () => void;
  email: string;
};

const ForgotPasswordStep2: React.FC<ForgotPasswordStep2Props> = ({
  notifGoBack,
  email,
}) => {
  const showContentView = () => (
    <>
      <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
        {' '}
        Check your email!
      </div>
      <div className={classnames('text__body__regular__medium__textNeutral30')}>
        {/* eslint-disable-next-line max-len */}
        If a verified account is associated with <b>{email}</b>, you should
        receive an email with a link to reset your password shortly.
      </div>

      <Button
        className={classnames(styles.backButton)}
        onClick={() => {
          goToPage(RouteName.SignIn);
        }}
        buttonSize={ButtonSize.Medium}
        buttonType={ButtonType.Submit}
      >
        Back to log in
      </Button>
      <Line />
      <div className={classnames(styles.footerCheck)}>
        <p className="text__body__regular__small__textNeutral30">
          Didn’t receive the email? Check your spam folder or
        </p>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          onClick={notifGoBack}
        >
          try a different email address
        </Button>
      </div>
    </>
  );

  return <>{showContentView()}</>;
};

export { ForgotPasswordStep2 };
