import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureContext } from '@sentry/types';
import { logger } from 'helpers/logger';
import { sentryDSN, sentryEnvironment, sentryRelease } from 'config/constants';

const initializeSentry = () => {
  if (sentryDSN) {
    Sentry.init({
      release: sentryRelease,
      dsn: sentryDSN,
      integrations: [new BrowserTracing()],
      environment: sentryEnvironment,

      tracesSampleRate: 1.0,
    });
  }
};

const captureException = (
  error: any,
  captureContext?: CaptureContext | undefined,
) => {
  logger.error(error);
  if (sentryDSN) {
    Sentry.captureException(error, captureContext);
  }
};

export { initializeSentry, captureException };
