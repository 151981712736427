import { Modal } from 'common/modal/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button/button';
import styles from './modal-oh-no.module.scss';

type ModalOhNoType = {
  onClose: () => void;
  retryFunction: () => void;
};

const ModalOhNo = ({ onClose, retryFunction }: ModalOhNoType) => (
  <Modal onClose={onClose} contentClassName={styles.modal}>
    <div className={styles.modalInformation}>
      <span className="text__title2__textNeutral40">
        Oh no. Something went wrong.
      </span>
      <p className="text__body__regular__small__textNeutral30">
        The purchase process failed. Please try again in a few minutes.
      </p>
      <div className={styles.actionsButtons}>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          onClick={retryFunction}
        >
          Retry
        </Button>
      </div>
    </div>
  </Modal>
);

export { ModalOhNo };
