import { Modal } from 'common/modal';
import styles from './qr-codes-explanation-modal.module.scss';

type QrCodesExplanationModalProps = {
  onClose: () => void;
};

const QrCodesExplanationModal: React.FC<QrCodesExplanationModalProps> = ({
  onClose,
}) => (
  <Modal contentClassName={styles.container} title="QR Codes" onClose={onClose}>
    <div className="text__body__regular__medium__textNeutral40">
      A QR code can be associated with an album or clip for easy access to your
      recordings into the future.
      <br />
      <div className={styles.bulletPoints}>
        1. Legacy Albums include 10 QR codes. Additional codes may be purchased
        for any album through the Generational Story store.
        <br />
        2. To register your QR code, use the Generational Story mobile app to
        open the clip or album you would like to connect the QR code to.
        <br />
        3. Navigate to the ‘edit’ tab of the album to connect to the album.
        <br />
        4. Alternatively, if you would like a QR to open to a particular clip
        within the album, navigate to clip details.*
        <br />
        5. Scan your code.
        <br />
        6. Finished! Loved ones scanning in your code will be directed to your
        album.
      </div>
    </div>
    <div className="text__body__regular__medium__textNeutral40">
      *Connecting to a particular clip will open the album to the selected clip
      while still providing access to the entire album. This is a great option
      for an heirloom or picture where you want the QR code to highlight a
      description of the particular item it is attached to.
    </div>
    <div className="text__body__regular__medium__textNeutral40">
      Note: Scanning a QR code will open your album providing access to your
      clips excluding recordings marked ‘Private.’
    </div>
  </Modal>
);

export { QrCodesExplanationModal };
