import React, { ChangeEvent, useState, useEffect, useRef } from 'react';

import { Modal } from 'common/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Album } from 'models/album';
import { Spinner } from 'common/spinner';
import {
  mbAlbumCoverMaxSize,
  albumCoverSupportedExtensions,
} from 'config/constants';
import { Input } from 'common/input';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Line } from 'common/line';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { AlbumEditModal } from 'models/albumEditModal';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { classnames, noEmptyFields } from 'helpers/utils';
import { FileUploader } from 'common/file-uploader';
import { ImagePreview } from 'common/image-preview';
import { useHandleApiError } from 'hooks/use-handle-api-error';

import styles from './modal-edit-album.module.scss';

const mandatoryFields: MandatoryFieldsType = {
  title: '',
};

type ModalEditAlbumProps = {
  album: Album;
  onClose: () => void;
  onSucess?: () => void;
};

const ModalEditAlbum: React.FC<ModalEditAlbumProps> = ({
  album,
  onClose,
  onSucess,
}) => {
  const [formState, setFormState] = useState<AlbumEditModal>(
    new AlbumEditModal(album),
  );
  const [errors, setErrors] = useState<MandatoryFieldsType>(mandatoryFields);
  const [backError, setBackError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  const coverId = useRef<string | null | undefined>(undefined);
  const setApiError = useHandleApiError(() => {
    setBackError(true);
    setFetching(false);
  });

  useEffect(
    () => () => {
      setFetching(false);
    },
    [],
  );

  const handleImageUpload = ({ url, signedId: idImage }: FileResponseType) => {
    coverId.current = idImage;

    setIsLoadingImage(false);

    setFormState((prevState) => ({
      ...prevState,
      imageUrl: url,
    }));
  };

  const handleRemovePreview = () => {
    coverId.current = null;

    setFormState((prevState) => ({
      ...prevState,
      imageUrl: '',
    }));
  };

  const validateTitleField = () => {
    const emptyFieldMessage =
      formState.title.trim() === '' ? 'name shouldn’t be empty' : '';
    setErrors((prevState) => ({
      ...prevState,
      title: emptyFieldMessage.toUpperCase(),
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevUserState) => ({ ...prevUserState, [name]: value }));
  };

  const submitModal = async () => {
    setFetching(true);
    const { id, title } = formState;

    const requestData: AlbumEditRequestType = { album: { id, title } };
    requestData.album.coverSignedId = coverId.current;

    try {
      setFetching(true);
      await AlbumsController.editAlbumModal(album.id, requestData);
      onSucess!();
    } catch (err: any) {
      setApiError(err);
    }
  };

  const showModalContentView = () => {
    const { title, imageUrl } = formState;

    return (
      <Modal title="Edit album name & cover photo" onClose={onClose}>
        <div className={classnames(styles.modalContent)}>
          <div className={classnames(styles.body)}>
            <div className={styles.cover}>
              {!imageUrl ? (
                <FileUploader
                  className={styles.fileUploader}
                  supportedExtensions={albumCoverSupportedExtensions}
                  maxFileSizeInMb={mbAlbumCoverMaxSize}
                  uploadingMessage="Uploading cover..."
                  onSuccess={(file: FileResponseType) =>
                    handleImageUpload(file)
                  }
                  onLoading={() => setIsLoadingImage(true)}
                  onError={() => setIsLoadingImage(false)}
                  onCancel={() => setIsLoadingImage(false)}
                />
              ) : (
                <ImagePreview
                  imageLocation={imageUrl}
                  removePreview={() => handleRemovePreview()}
                />
              )}
            </div>

            <Line className={styles.line} />

            <div>
              <Input
                id="title"
                name="title"
                errorMessage={errors.title}
                label="Album name"
                placeholder="Name"
                value={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                onBlur={() => {
                  validateTitleField();
                }}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <div
              className={classnames(
                styles.error,
                backError ? styles.showBackendError : '',
              )}
            >
              <p className="text__body__semi__bold__medium__warning30">
                Something went wrong.
              </p>
              <p className="text__body__regular__tiny__textNeutral30">
                We couldn’t save your changes. Please try again.
              </p>
            </div>
            <div className={styles.buttonsfooter}>
              <Button
                className={styles.cancelButton}
                onClick={onClose}
                buttonStyle={ButtonStyle.GreyGhost}
                buttonSize={ButtonSize.Small}
              >
                Cancel
              </Button>
              {!fetching ? (
                <Button
                  buttonSize={ButtonSize.Small}
                  disabled={!noEmptyFields({ title }) || isLoadingImage}
                  className={styles.successButton}
                  onClick={() => submitModal()}
                >
                  {isMobileOrTablet ? 'Save' : 'Save changes'}
                </Button>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner className={globalStyles.smallSpinner} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return showModalContentView();
};

export { ModalEditAlbum };
