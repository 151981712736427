export class AccountBackupErrors {
  backupEmail: string;

  backupName: string;

  constructor(params?: AccountBackupErrorsType) {
    this.backupName = params?.backupName || '';
    this.backupEmail = params?.backupEmail || '';
  }
}
