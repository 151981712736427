import { classnames } from 'helpers/utils';
import styles from './unanswered-questions.module.scss';

type UnansweredQuestionsProps = {
  unansweredQuestions: ClipType[];
  answeredQuestionsAmount: number;
  questionSelected: ClipType;
  disableSelection: boolean;
  albumQuestionsPermissionLimit: number;
  clipLimitReached: boolean;
  notifyQuestionsSelected: (question: ClipType) => void;
};

const UnansweredQuestions: React.FC<UnansweredQuestionsProps> = ({
  unansweredQuestions,
  questionSelected,
  disableSelection,
  answeredQuestionsAmount,
  albumQuestionsPermissionLimit,
  clipLimitReached,
  notifyQuestionsSelected,
}) => {
  const limitQuestionsReached = () =>
    // -1 Because Custom Question row
    answeredQuestionsAmount + (unansweredQuestions.length - 1) ===
    albumQuestionsPermissionLimit;

  const isCustomAndNoMoreRoomForRecording = (index: number) =>
    index === 0 && limitQuestionsReached();

  const showUnansweredQuestions = () =>
    unansweredQuestions.map((question: ClipType, index) => (
      <div key={question.id} className={classnames(styles.unansweredContainer)}>
        <button
          type="button"
          disabled={
            disableSelection ||
            clipLimitReached ||
            isCustomAndNoMoreRoomForRecording(index)
          }
          onClick={() => notifyQuestionsSelected(question)}
          className={classnames(
            styles.unanswerQuestion,
            'text__body__regular__medium__textNeutral20',
            {
              [styles.questionSelected]:
                question.id === questionSelected.id && !clipLimitReached,
            },
          )}
        >
          {question.name}
        </button>
      </div>
    ));

  return <>{showUnansweredQuestions()}</>;
};

export { UnansweredQuestions };
