import {
  AgoraVideoPlayer,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-react';
import { InfoIcon } from 'assets/icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SensorSelectionContext } from 'context';
import { classnames } from 'helpers/utils';
import { VideoCallRole } from 'hooks/use-videocall-websocket';
import { NoCamEnabled } from '../no-cam-enabled';
import styles from './video.module.scss';

type VideoProps = {
  user: UserType;
  album: AlbumType;
  myTracks?: [IMicrophoneAudioTrack, ICameraVideoTrack?];
  isRecording: boolean;
  otherUserTracks: IAgoraRTCRemoteUser | null;
  otherUserInCall: boolean;
  otherUserTrackState: TrackStateType;
  userRole?: VideoCallRole;
  otherUserName?: string;
  otherUserIsGuest: boolean;
};

const Video: React.FC<VideoProps> = ({
  user,
  album,
  myTracks,
  isRecording,
  otherUserInCall,
  otherUserTrackState,
  otherUserTracks,
  userRole,
  otherUserName,
  otherUserIsGuest,
}) => {
  // listen to window resize to determine if the viewport is landscape or portrait
  const [viewportIsPortrait, setViewportIsPortrait] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      setViewportIsPortrait(innerWidth < innerHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const otherUserVideoStreamTrack = useMemo(
    () => otherUserTracks?.videoTrack?.getMediaStreamTrack(),
    [otherUserTracks?.videoTrack],
  );
  const otherUserVideoIsPortrait =
    otherUserVideoStreamTrack?.getSettings().aspectRatio! < 1;

  const showOtherUserUncropped =
    viewportIsPortrait !== otherUserVideoIsPortrait;

  const { sensorSelection } = useContext(SensorSelectionContext);
  const showOtherUserEmptyFullView = () => (
    <NoCamEnabled
      album={album}
      user={user}
      myEmptyView={false}
      className={styles.otherUserEmptyFullView}
      otherUserName={otherUserName}
      otherUserIsGuest={otherUserIsGuest}
    />
  );

  const showOtherUserFullView = () => (
    <div className={styles.individualVideoContainer}>
      <AgoraVideoPlayer
        videoTrack={otherUserTracks!.videoTrack!}
        className={classnames(styles.individualVideo, {
          [styles.individualVideoUncropped]: showOtherUserUncropped,
        })}
      />
    </div>
  );

  const showMyViewSmall = () => (
    <div className={styles.individualVideoContainer}>
      <AgoraVideoPlayer
        className={styles.individualVideo}
        videoTrack={myTracks![1]!}
      />
    </div>
  );

  const showMyEmptyViewSmall = () => (
    <NoCamEnabled
      album={album}
      user={user}
      myEmptyView
      small
      otherUserName={otherUserName}
      otherUserIsGuest={otherUserIsGuest}
    />
  );

  const otherUserInCallWithNoCamara = () =>
    otherUserInCall &&
    !(otherUserTracks?.videoTrack && otherUserTrackState.video);

  const showNoVideoWarning = () => (
    <div className={classnames(styles.warningNoVideo)}>
      <span>
        <InfoIcon className={styles.infoIcon} />
      </span>
      <span
        className={classnames('text__body__regular__medium__textNeutral10')}
      >
        If you wish to record video make sure the respondent turns on their
        camera before you start recording.
      </span>
    </div>
  );

  const showBothUsersViews = () => (
    <>
      {userRole === VideoCallRole.INTERVIEWER &&
        !isRecording &&
        otherUserInCallWithNoCamara() &&
        showNoVideoWarning()}

      <div
        className={classnames(styles.mytrackSmall, {
          [styles.myTrackSmallPortrait]: viewportIsPortrait,
        })}
      >
        {sensorSelection.camera.enabled
          ? showMyViewSmall()
          : showMyEmptyViewSmall()}
      </div>

      <div className={styles.otherUserFullView}>
        {otherUserTracks?.videoTrack
          ? showOtherUserFullView()
          : showOtherUserEmptyFullView()}
      </div>
    </>
  );

  const showOnlyMyFullView = () =>
    sensorSelection.camera.enabled ? (
      <div className={styles.individualVideoContainer}>
        <AgoraVideoPlayer
          videoTrack={myTracks![1]!}
          className={styles.individualVideo}
        />
      </div>
    ) : (
      <NoCamEnabled
        album={album}
        user={user}
        myEmptyView
        otherUserName={otherUserName}
        otherUserIsGuest={otherUserIsGuest}
      />
    );

  return (
    <div className={styles.videoElement}>
      {otherUserInCall ? showBothUsersViews() : showOnlyMyFullView()}
    </div>
  );
};

export { Video };
