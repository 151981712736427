import { classnames } from 'helpers/utils';
import { AlertTriangleIcon } from 'assets/icons';
import { dayjs } from 'helpers/dayjs';
import { sAnnualAlertTimeVideoCall } from 'config/constants';
import styles from './warning-reaching-time-limit.module.scss';

type WarningReachingTimeLimitProps = {
  stimeLeft: number;
};

const WarningReachingTimeLimit: React.FC<WarningReachingTimeLimitProps> = ({
  stimeLeft,
}) => {
  const getTimeLeft = (time: number) =>
    dayjs.duration(time, 'seconds').format('HH:mm:ss');

  const runningOutTime = () => stimeLeft < sAnnualAlertTimeVideoCall;

  return (
    <div className={classnames(styles.warningTimeLimitContainer)}>
      <div className={styles.leftSection}>
        <div
          className={classnames(styles.warningIcon, {
            [styles.dangerIcon]: runningOutTime(),
          })}
        >
          <AlertTriangleIcon />
        </div>
        <div className="text__body__regular__small__textNeutral00">
          {runningOutTime()
            ? 'Video call time has run out, your call will end in...'
            : 'Annual video call time is running out'}
        </div>
      </div>
      <div
        className={classnames(
          styles.rightSection,
          'text__body__regular__small__textNeutral00',
          { [styles.dangerTime]: runningOutTime() },
        )}
      >
        {!runningOutTime() && <span> Time left: </span>}
        <span>{getTimeLeft(stimeLeft)}</span>
      </div>
    </div>
  );
};

export { WarningReachingTimeLimit };
