import React, { useState } from 'react';
import { FileUploader } from 'common/file-uploader';
import {
  albumCoverSupportedExtensions,
  mbAlbumCoverMaxSize,
} from 'config/constants';
import { ImagePreview } from 'common/image-preview';
import styles from './album-cover.module.scss';

type AlbumCoverProps = {
  setIsLoading: Function;
  setImage: Function;
};

const AlbumCover: React.FC<AlbumCoverProps> = ({ setIsLoading, setImage }) => {
  const [imagePreview, setPreview] = useState<string | null>(null);

  const handleFileUpload = ({ url, signedId }: FileResponseType) => {
    setIsLoading(false);
    setPreview(url);
    setImage(signedId);
  };

  const handleRemovePreview = () => {
    setPreview(null);
    setImage(undefined);
  };

  return (
    <div className={styles.titleAndCover}>
      <p className="text__body__semi__bold__medium__textNeutral40">
        Album cover photo
        <span className="text__body__regular__medium__textNeutral20">
          {' '}
          (Optional)
        </span>
      </p>

      <div className={styles.cover}>
        {!imagePreview ? (
          <FileUploader
            className={styles.fileUploader}
            supportedExtensions={albumCoverSupportedExtensions}
            maxFileSizeInMb={mbAlbumCoverMaxSize}
            uploadingMessage="Uploading cover..."
            onSuccess={handleFileUpload}
            onLoading={() => setIsLoading(true)}
            onError={() => setIsLoading(false)}
            onCancel={() => setIsLoading(false)}
          />
        ) : (
          <ImagePreview
            imageLocation={imagePreview}
            removePreview={() => handleRemovePreview()}
          />
        )}
      </div>
    </div>
  );
};

export { AlbumCover };
