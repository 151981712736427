import React from 'react';

import { CrossIcon } from 'assets/icons';
import { errorFormUrl } from 'config/constants';
import { classnames } from 'helpers/utils';

import styles from './beta-message.module.scss';

type BetaMessageProps = {
  onClose: () => void;
};

const BetaMessage: React.FC<BetaMessageProps> = ({ onClose }) => (
  <div className={styles.container}>
    <div>
      <span className="text__body__bold__small__primary50">
        Pre-release software.&nbsp;
      </span>
      <span className="text__body__regular__small__textNeutral30">
        This is a beta version for testing. If you encounter any bugs please
        report them&nbsp;
      </span>
      <a
        className={classnames('text__body__link__medium__primary50')}
        href={errorFormUrl}
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      <span className="text__body__regular__small__textNeutral30">.</span>
    </div>
    <button type="button" onClick={onClose} aria-label="close">
      <CrossIcon className={styles.icon} />
    </button>
  </div>
);

export { BetaMessage };
