import React, { FunctionComponent, HTMLProps } from 'react';
import { classnames } from 'helpers/utils';
import { CrossIcon } from 'assets/icons';
import styles from './text-area.module.scss';

type TextAreaProps = {
  id: string;
  rows: number;
  label?: string;
  disabled?: boolean;
  counter?: boolean;
  maxLength?: number;
  className?: string;
  resizeHorizontal?: boolean;
  withValidation?: boolean;
  errorMessage?: string;
  showErrorBorder?: boolean;
  ErrorIconComp?: FunctionComponent;
};

const TextArea: React.FC<TextAreaProps & HTMLProps<HTMLTextAreaElement>> = ({
  id,
  rows,
  label = '',
  disabled,
  counter = false,
  maxLength,
  className = '',
  resizeHorizontal = false,
  withValidation = true,
  errorMessage,
  showErrorBorder = false,
  ErrorIconComp = CrossIcon,
  value,
  ...props
}) => {
  const showLabel = () => (
    <div className={classnames({ invisible: !label }, styles.label)}>
      <label
        className={classnames(
          disabled
            ? 'text__body__semi__bold__medium__textNeutral20'
            : 'text__body__semi__bold__medium__textNeutral40',
        )}
        htmlFor={id}
      >
        <span>{label}</span>
      </label>
    </div>
  );

  const showWarningAndCounter = () => {
    const valueLength = value!.toString().length;

    const showValidation = () => {
      const showErrorMessage = () => (
        <div
          className={classnames(
            'mt-5',
            errorMessage ? styles.validationError : 'invisible',
          )}
        >
          <span
            className={classnames(
              styles.iconCross,
              errorMessage && errorMessage.length > 1 ? '' : 'invisible',
            )}
          >
            <ErrorIconComp className={styles.iconError} />
          </span>
          <span className="text__body__regular__overline__warning30">
            {errorMessage}
          </span>
        </div>
      );

      return showErrorMessage();
    };

    const showCounter = () => (
      <div
        className={classnames({ invisible: !counter }, styles.counter, {
          [styles.maximumReached]: valueLength === maxLength,
        })}
      >
        <span className="text__body__regular__overline__textNeutral30">
          {valueLength} / {maxLength!}
        </span>
      </div>
    );

    return (
      <div className={styles.counterAndWarning}>
        {withValidation && showValidation()}
        {counter && showCounter()}
      </div>
    );
  };

  const showTextArea = () => (
    <div
      className={classnames(styles.textArea, styles.noResize, {
        [styles.resizeHorizontalEnable]: resizeHorizontal,
      })}
    >
      <textarea
        id={id}
        type="text"
        rows={rows}
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        className={classnames(
          styles.body,
          errorMessage || showErrorBorder ? styles.errorBorder : '',
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );

  return (
    <div className={classnames(styles.fullTextAreaContent, className)}>
      {label && showLabel()}
      {showTextArea()}
      {(withValidation || counter) && showWarningAndCounter()}
    </div>
  );
};

export { TextArea };
