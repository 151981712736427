import React, { useContext } from 'react';
import { dayjs } from 'helpers/dayjs';
import { KeyIcon, QrTinyIcon } from 'assets/icons';
import { AppContext } from 'context/app-context';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  classnames,
  isOwner,
  isRestrictedAlbum,
  showTextEllipsis,
} from 'helpers/utils';
import { isEmpty } from 'lodash';
import { Button, ButtonStyle } from 'common/button';
import styles from './album-card-mobile.module.scss';

type AlbumCardMobileProps = {
  album: AlbumType;
  showAlbumStatusLabel: Function;
  notifyClickCard: (id: number, keyEvent?: boolean) => () => void;
  notifyClickQrIcon: (id: number) => void;
};

const AlbumCardMobile: React.FC<AlbumCardMobileProps> = ({
  album,
  showAlbumStatusLabel,
  notifyClickCard,
  notifyClickQrIcon,
}) => {
  const { state } = useContext(AppContext);
  const { user } = state.data;

  const showCard = () => (
    <div
      role="button"
      tabIndex={0}
      className={styles.cardMobile}
      onClick={notifyClickCard(album.id)}
      onKeyDown={notifyClickCard(album.id, true)}
    >
      <div
        className={classnames(styles.pictureBox, {
          [styles.greyImage]: isRestrictedAlbum(album),
        })}
      >
        {showAlbumStatusLabel(album, styles.statusLabelPosition)}

        {album.coverThumbnailUrl ? (
          <img
            src={album.coverThumbnailUrl}
            className={styles.pictureBox}
            alt="logo"
          />
        ) : (
          <Logo className={styles.logo} />
        )}
      </div>

      <div className={styles.info}>
        <div className={styles.nameAndOwner}>
          <div className="text__body__semi__bold__small__textNeutral40">
            {showTextEllipsis(album.title, 69)}
          </div>
          <div>
            {!isEmpty(album.qrCodes) && (
              <Button
                className={styles.qrIconButton}
                buttonStyle={ButtonStyle.PrimaryGhost}
                onClick={(event) => {
                  event.stopPropagation();
                  notifyClickQrIcon(album.id);
                }}
              >
                <QrTinyIcon className={styles.qrIcon} />
              </Button>
            )}
            {isOwner(user, album) && <KeyIcon className={styles.ownerIcon} />}
          </div>
        </div>
        <div className={styles.horizontalLine} />
        <div
          className={classnames(
            styles.lastUpdate,
            'text__body__regular__small__textNeutral30',
          )}
        >
          {`Last updated: ${dayjs(album.lastUpdate).fromNow()}`}
        </div>
        {album.owner.companyName && !isOwner(user, album) && (
          <div
            className={classnames('text__body__regular__small__textNeutral20')}
          >
            {`Powered by: ${showTextEllipsis(album.owner.companyName, 30)}`}
          </div>
        )}
      </div>
    </div>
  );

  return showCard();
};

export { AlbumCardMobile };
