import { ButtonStyle } from 'common/button';
import { ModalWarning } from 'common/modal-warning';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { useState } from 'react';

type RemoveContributorModalProps = {
  contributor: ContributorType;
  albumId: number;
  notifyDeleteContributor: (contributor: ContributorType) => void;
  onClose: () => void;
};

const RemoveContributorModal = ({
  contributor,
  albumId,
  notifyDeleteContributor,
  onClose,
}: RemoveContributorModalProps) => {
  const [loading, setLoading] = useState(false);
  const removeContributor = async () => {
    setLoading(true);
    try {
      await AlbumsController.removeContributor({
        albumId,
        invitationId: contributor.invitationId!,
      });

      notifyDeleteContributor(contributor);
    } finally {
      setLoading(false);
    }
    onClose();
  };

  return (
    <ModalWarning
      title="Remove Contributor"
      content="Are you sure you want to remove this contributor? They will lose access to the album."
      successButtonText="Remove contributor"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={onClose}
      loading={loading}
      successFn={removeContributor}
    />
  );
};

export { RemoveContributorModal };
