import React, { ChangeEvent, useEffect, useState } from 'react';

import { Modal } from 'common/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { descriptionClipMaxLength, questionsMaxLength } from 'config/constants';
import { VideoClip } from 'common/video-clip';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { ClipEditModal } from 'models/clip-edit-modal';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { TextArea } from 'common/text-area';
import { classnames, noEmptyFields } from 'helpers/utils';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { AudioClip } from 'common/audio-clip';
import styles from './modal-edit-clip.module.scss';

type Mandatory = {
  name: string;
  description: string;
};

const mandatoryFieldsErrors: Mandatory = {
  name: '',
  description: '',
};

type ModalEditAlbumProps = {
  clip: ClipType;
  onClose: () => void;
  onSuccess: (clip: ClipType) => void;
};

const ModalEditClip: React.FC<ModalEditAlbumProps> = ({
  clip,
  onClose,
  onSuccess,
}) => {
  const [formState, setFormState] = useState<ClipEditModal>(
    new ClipEditModal(clip),
  );
  const [errors, setErrors] = useState<Mandatory>(mandatoryFieldsErrors);
  const [fetching, setFetching] = useState(false);
  const [backError, setBackError] = useState(false);
  const setApiError = useHandleApiError(() => {
    setBackError(true);
  });

  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  const validateField = <T extends keyof Mandatory>(field: T) => {
    if (field === 'name') {
      let emptyFieldMessage = '';

      emptyFieldMessage =
        formState[field].trim() === ''
          ? `${field.toUpperCase()} shouldn't be empty`
          : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: emptyFieldMessage.toUpperCase(),
      }));

      if (emptyFieldMessage) return;

      if (formState[field].length >= questionsMaxLength) {
        setErrors((prevState) => ({
          ...prevState,
          [field]:
            `${field} can't have more than ${questionsMaxLength} characters`.toUpperCase(),
        }));
      }
    }

    if (field === 'description') {
      if (
        formState[field].length &&
        formState[field].length >= descriptionClipMaxLength
      ) {
        setErrors((prevState) => ({
          ...prevState,
          [field]:
            `${field} can't have more than ${descriptionClipMaxLength} characters`.toUpperCase(),
        }));
      } else {
        setErrors((prevState) => ({ ...prevState, [field]: '' }));
      }
    }
  };

  useEffect(() => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });
  }, [formState]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormState((prevUserState) => ({ ...prevUserState, [name]: value }));
  };

  const submitModal = async () => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });

    if (formState.name === '') {
      return;
    }

    setFetching(true);
    const { name, description } = formState;
    const requestData: ClipEditRequestType = {
      name,
      description,
      isPrivate: clip.isPrivate,
    };

    try {
      setFetching(true);

      const clipEdited = await AlbumsController.editClip(clip.id, requestData);

      onSuccess(clipEdited);
    } catch (err: any) {
      setFetching(false);
      setApiError(err);
    }
  };

  const showModalContentView = () => {
    const { name, description } = formState;

    return (
      <Modal title="Editing clip" onClose={onClose}>
        <div className={classnames(styles.modalContent)}>
          <div className={classnames(styles.body)}>
            {clip.isAudioOnly ? (
              <AudioClip
                videoUrl={clip.videoUrl}
                alt=""
                image={clip.customThumbnail}
                className={styles.audioImage}
              />
            ) : (
              <VideoClip clipSelected={clip} />
            )}
            <div>
              <TextArea
                id="name"
                name="name"
                label="Question"
                placeholder="Question"
                value={name}
                rows={isMobileOrTablet ? 4 : 3}
                counter
                maxLength={questionsMaxLength}
                errorMessage={errors.name}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange(e);
                }}
              />
            </div>

            <div>
              <TextArea
                id="description"
                name="description"
                label="Clip description"
                placeholder="Description"
                value={description}
                rows={isMobileOrTablet ? 6 : 4}
                counter
                maxLength={descriptionClipMaxLength}
                errorMessage={errors.description}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <div
              className={classnames(styles.error, {
                [styles.showBackendError]: backError,
              })}
            >
              <p className="text__body__semi__bold__medium__warning30">
                Something went wrong.
              </p>
              <p className="text__body__regular__tiny__textNeutral30">
                We couldn’t save your changes. Please try again.
              </p>
            </div>
            <div className={styles.buttonsfooter}>
              <Button
                className={styles.cancelButton}
                onClick={onClose}
                buttonStyle={ButtonStyle.GreyGhost}
                buttonSize={ButtonSize.Small}
              >
                Cancel
              </Button>
              {!fetching ? (
                <Button
                  buttonSize={ButtonSize.Small}
                  disabled={!noEmptyFields({ name })}
                  className={styles.successButton}
                  onClick={() => submitModal()}
                >
                  {isMobileOrTablet ? 'Save' : 'Save changes'}
                </Button>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner className={globalStyles.smallSpinner} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return showModalContentView();
};

export { ModalEditClip };
