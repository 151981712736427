import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './helper-text.module.scss';

type HelperTextProps = {
  content: React.ReactNode;
  icon?: any;
  className?: string;
};

const HelperText: React.FC<HelperTextProps> = ({
  content,
  icon,
  className = '',
}) => {
  const classes = [className, styles.helperText];
  return (
    <div className={classnames(...classes)}>
      <span>{icon}</span>
      <span>{content}</span>
    </div>
  );
};

export { HelperText };
