import { useEffect, useState } from 'react';
import { logger } from 'helpers/logger';
import { Consumer, Mixin, Subscription } from '@rails/actioncable';
import { useActionCableConsumer } from './use-action-cable-consumer';

const VIDEOCALL_CHANNEL = 'VideocallChannel';

enum MessageType {
  ROLE_UPDATE = 'role_update',
  CALL_INFORMATION = 'call_information',
  RECORDING_STARTED = 'recording_started',
  RECORDING_ENDED = 'recording_ended',
}

export enum VideoCallRole {
  INTERVIEWER = 'interviewer',
  RESPONDENT = 'respondent',
}

interface VideocallMessage {
  type: MessageType;
  // NOTE: This type is a bit restrictive, change it if needed.
  [key: string]: any;
}

const deserializeCallInformation = (
  info: VideocallMessage,
): VideoCallInformationType => ({
  videocallInterviewerIsGuest: info.data
    .videocall_interviewer_is_guest as boolean,
  videocallRespondentIsGuest: info.data
    .videocall_respondent_is_guest as boolean,
  videocallInterviewerId:
    (info.data.videocall_interviewer_id as number) ?? undefined,
  videocallRespondentId:
    (info.data.videocall_respondent_id as number) ?? undefined,
  videocallInterviewerName:
    (info.data.videocall_interviewer_name as string) ?? undefined,
  videocallRespondentName:
    (info.data.videocall_respondent_name as string) ?? undefined,
});

export const useVideocallWebsocket = (
  albumId: number | null,
  videoCallToken: string | null,
) => {
  const { consumer } = useActionCableConsumer();

  const [online, setOnline] = useState<boolean>(false);
  const [socketDisconnected, setSocketDisconnected] = useState<boolean>(false);
  const [channel, setChannel] = useState<
    (Subscription<Consumer> & Mixin) | null
  >(null);
  const [callInformation, setCallInformation] =
    useState<VideoCallInformationType | null>(null);

  const [recordingInProgress, setRecordingInProgress] = useState(false);

  const processMessage = (data: VideocallMessage) => {
    const { type } = data;
    if (!type) {
      logger.warn('[Videocall Cable] Received a message with no type');
      return;
    }
    if (!Object.values(MessageType).includes(type)) {
      logger.warn(`[Videocall Cable] Unkown message type received: "${type}"`);
      return;
    }
    switch (type) {
      case MessageType.RECORDING_STARTED:
        setRecordingInProgress(true);
        break;
      case MessageType.RECORDING_ENDED:
        setRecordingInProgress(false);
        break;
      case MessageType.CALL_INFORMATION:
      case MessageType.ROLE_UPDATE: {
        const info = deserializeCallInformation(data);
        setCallInformation(info);
        break;
      }
      default:
    }
  };

  const sendMessage = (data: VideocallMessage) => {
    if (!channel) {
      return;
    }
    channel.send(data);
  };

  useEffect(() => {
    if (!consumer || !albumId || channel) {
      return () => {};
    }
    const ch = consumer.subscriptions.create(
      { channel: VIDEOCALL_CHANNEL, room: albumId, token: videoCallToken },
      {
        initialized() {
          logger.log('[Videocall Cable] Initialized');
        },

        received(data) {
          processMessage(data as VideocallMessage);
        },

        connected() {
          logger.log('[Videocall Cable] Connected');
          setOnline(true);
          if (socketDisconnected) {
            setSocketDisconnected(false);
          }
        },

        disconnected() {
          logger.log('[Videocall Cable] Disconnected');
          setOnline(false);
          setSocketDisconnected(true);
        },

        rejected() {
          logger.warn('[Videocall Cable] Connection Rejected');
          setOnline(false);
          setSocketDisconnected(true);
        },
      },
    );
    setChannel(ch);
    return () => {
      logger.log('[Videocall Cable] Disconnected');
      ch.unsubscribe();
      setChannel(null);
      setOnline(false);
      setSocketDisconnected(false);
    };
  }, [albumId, consumer]);

  const sendRecordingStarted = () => {
    sendMessage({
      type: MessageType.RECORDING_STARTED,
    });
  };

  const sendRecordingEnded = () => {
    sendMessage({
      type: MessageType.RECORDING_ENDED,
    });
  };

  const getCallInformation = () => {
    sendMessage({
      type: MessageType.CALL_INFORMATION,
    });
  };

  const updateRole = (role: VideoCallRole) => {
    sendMessage({
      type: MessageType.ROLE_UPDATE,
      role,
    });
  };

  return {
    online,
    recordingInProgress,
    sendRecordingStarted,
    sendRecordingEnded,
    callInformation,
    getCallInformation,
    updateRole,
    socketDisconnected,
  };
};
