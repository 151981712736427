import Bowser from 'bowser';

export const calculateMissingSensorsText = (
  microphonePermissionError?: DOMException,
  cameraPermissionError?: DOMException,
): string => {
  let missingSensorsText = 'camera or mic';

  if (microphonePermissionError && cameraPermissionError) {
    missingSensorsText = 'camera and mic';
  } else if (microphonePermissionError) {
    missingSensorsText = 'mic';
  } else if (cameraPermissionError) {
    missingSensorsText = 'camera';
  }

  return missingSensorsText;
};

export const calculateBrowserSettingsLink = () => {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent);

  if (browser.name === 'Chrome' && os.name === 'Android') {
    return 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid&oco=0#:~:text=Change%20a%20site%27s%20camera%20%26%20microphone%20permissions';
  }
  if (browser.name === 'Chrome' && os.name === 'iOS') {
    return 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DiOS&oco=0';
  }
  if (browser.name === 'Chrome') {
    return 'https://support.google.com/chrome/answer/2693767#:~:text=Change%20a%20site%27s%20camera%20%26%20microphone%20permissions';
  }
  if (browser.name === 'Firefox') {
    return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
  }
  if (browser.name === 'Safari' && platform.type === 'desktop') {
    return 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac';
  }
  if (browser.name === 'Safari') {
    return 'https://www.macrumors.com/how-to/access-website-settings-in-safari/#:~:text=Tap%20the%20%22aA,Website%20Settings.';
  }
  if (browser.name === 'Edge' && platform.type === 'desktop') {
    return 'edge://settings/content';
  }
  if (browser.name === 'Edge') {
    return 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.';
  }
  if (browser.name === 'Internet Explorer') {
    return 'https://support.microsoft.com/en-us/help/17479/windows-internet-explorer-11-change-security-privacy-settings';
  }
  return '';
};
