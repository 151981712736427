/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLProps, useState } from 'react';
import { ChevronIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import styles from './accordion.module.scss';

type AccordionProps = {
  title: React.ReactNode;
  content: React.ReactElement;
  className?: string;
  classNameIcon?: string;
  classNametitle?: string;
};

const Accordion: React.FC<AccordionProps> & HTMLProps<HTMLInputElement> = ({
  title,
  content,
  className = '',
  classNameIcon = '',
  classNametitle = '',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerClasses = [className, styles.accordion];
  return (
    <div className={classnames(...containerClasses)}>
      <div className={classnames(styles.title, classNametitle)}>
        {title}
        <button
          aria-label="Close"
          className={
            isExpanded
              ? classnames(
                  ...[styles.headerIcon, styles.collapsed, classNameIcon],
                )
              : classnames(styles.headerIcon, classNameIcon)
          }
          type="button"
          onClick={() => setIsExpanded((prevState: boolean) => !prevState)}
        >
          <ChevronIcon />
        </button>
      </div>
      {isExpanded && (
        <div
          className={
            isExpanded
              ? classnames(...[styles.content, styles.visible])
              : styles.content
          }
        >
          {content}
        </div>
      )}
    </div>
  );
};
export { Accordion };
