import { Dispatch } from 'react';
import { dayjs } from 'helpers/dayjs';
import {
  TemplateStateType,
  TemplatesAction,
} from 'pages/templates/templates-reducer';
import { classnames } from 'helpers/utils';
import styles from './template-card.module.scss';

type TemplateCardProps = {
  templatesState: TemplateStateType;
  template: PreBuiltQuestionsType;
  templatesDispatch: Dispatch<TemplatesAction>;
};

const TemplateCard: React.FC<TemplateCardProps> = ({
  templatesState,
  template,
  templatesDispatch,
}) => {
  const { selectedTemplate } = templatesState;

  const isSelected = () => selectedTemplate!.id === template.id;

  const handleSelectTemplate = () => {
    templatesDispatch({ type: 'SELECT_TEMPLATE', selectedTemplate: template });
    templatesDispatch({ type: 'CURRENT_NAME', templateName: template.name });
  };

  return (
    <button
      type="button"
      className={classnames(styles.cardItem, {
        [styles.selectedCard]: isSelected(),
      })}
      onClick={handleSelectTemplate}
    >
      <div
        className={classnames(
          'text__body__semi__bold__large__textNeutral40',
          styles.name,
          { [styles.selected]: isSelected() },
        )}
      >
        <span>{template.name}</span>
      </div>
      <div className={styles.questionsCountAndDate}>
        <span className="text__body__regular__small__textNeutral40">
          {`${template.questions.length} questions`}
        </span>
        <span className="text__body__regular__small__textNeutral30">
          {`Updated: ${dayjs(template.updatedAt).format('MM/DD/YYYY')}`}
        </span>
      </div>
    </button>
  );
};

export { TemplateCard };
