import React from 'react';
import { classnames } from 'helpers/utils';
import { AlertTriangleIcon } from 'assets/icons';
import styles from './warning-badge.module.scss';

type WarningBadgeProps = {
  icon?: React.ReactNode;
  message: string;
  className?: string;
};

const WarningBadge: React.FC<WarningBadgeProps> = ({
  icon = <AlertTriangleIcon className={styles.icon} />,
  message,
  className = '',
}) => {
  const classes = classnames(...[className, styles.warning]);
  return (
    <div className={classes}>
      {icon}{' '}
      <p className="text__body__regular__small__textNeutral30">{message}</p>
    </div>
  );
};

export { WarningBadge };
