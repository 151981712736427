/* eslint-disable max-len */
import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { UploadFileSerializer } from 'networking/serializers/upload-file-serializer';
import { PresignUrl } from 'models/presigned.url';

class UploadFileController {
  static async presignedUrl(file: File, md5: string) {
    const serializedPresignedUrl = UploadFileSerializer.serializePresignedUrl(
      file,
      md5,
    );

    const response = await ApiService.post<PresignedUrlResponseRaw>(
      API_ROUTES.PRESIGNED_URL,
      serializedPresignedUrl,
    );

    const deSerializePresignedUrl =
      UploadFileSerializer.deSerializePresignedUrl(response.data);

    return new PresignUrl(deSerializePresignedUrl);
  }
}

export { UploadFileController };
