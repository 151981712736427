import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './steps-progress.module.scss';

type StepsProgressProps = {
  step: number;
  maximum: number;
};

const StepsProgress: React.FC<StepsProgressProps> = ({ step, maximum }) => (
  <>
    <div className={classnames(styles.outsideBox)}>
      <div className={classnames(styles.insideBox, `step${step}`)} />
    </div>
    <div className="mt-5">
      <span className="text__body__regular__small__primary50">
        Step &nbsp;
        {step}
        &nbsp;
      </span>
      <span className="text__body__regular__small__textNeutral30">
        out of &nbsp;
        {maximum}
      </span>
    </div>
  </>
);

export { StepsProgress };
