import { SubscriptionSchemeId } from 'common/enums';

export function getDiscountedPriceIfBetter(
  sub: SubscriptionTypeType,
  discountedPrices: SubscriptionDiscountedPriceType[],
) {
  const existingPrice = sub.price;

  // trial is always 0. There is no way to have a better price.
  if (sub.id === SubscriptionSchemeId.trial) return null;

  const discountedPrice = discountedPrices.find(
    (discountedPriceItem: SubscriptionDiscountedPriceType) =>
      discountedPriceItem.id === sub.id,
  )?.price!;

  // 0.5 is the minimum amount accepted by Stripe
  if (discountedPrice <= 0.5) return 0;

  return discountedPrice < existingPrice ? discountedPrice : null;
}
