import { paginationDefault } from 'config/constants';

class ContributorsQueryParams {
  search?: string;

  pageSize: number;

  pageNum: number;

  sortBy: SelectType;

  accepted: boolean;

  pending: boolean;

  rejected: boolean;

  active: boolean;

  restricted: boolean;

  locked: boolean;

  constructor(params?: ContributorsQueryParamsType | null) {
    this.search = params?.search;
    this.pageNum = params?.pageNum || paginationDefault.pageNum;
    this.pageSize = params?.pageSize || paginationDefault.pageSize;
    this.sortBy = params?.sortBy || paginationDefault.lastUpdate;
    this.accepted = params?.accepted || true;
    this.pending = params?.pending || true;
    this.rejected = params?.rejected || true;
    this.active = params?.active || true;
    this.restricted = params?.restricted || true;
    this.locked = params?.locked || true;
  }
}

export { ContributorsQueryParams };
