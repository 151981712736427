import React, { useState } from 'react';

import { Input } from 'common/input';
import { isEmailValid } from 'helpers/utils';
import { ModalWarning } from 'common/modal-warning';
import { ButtonStyle } from 'common/button';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { RecordingGuest } from 'models/recording-guest';
import styles from './invite-guest-modal.module.scss';

type InviteGuestModalProps = {
  onClose: () => void;
  albumId: number;
  notifyGuestInvited: (guest: RecordingGuest) => void;
};

const InviteGuestModal: React.FC<InviteGuestModalProps> = ({
  onClose,
  albumId,
  notifyGuestInvited,
}) => {
  const [guest, setGuest] = useState({
    name: '',
    email: '',
  });
  const { name, email } = guest;

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [loading, setLoading] = useState(false);

  const validateField = (field: keyof typeof guest) => {
    let emailError = '';
    if (guest[field].trim() === '') {
      setErrors((prevState) => ({
        ...prevState,
        [field]: `${field} cannot be empty`,
      }));
    } else if (field === 'email') {
      if (!isEmailValid(guest[field])) emailError = 'invalid email';

      setErrors((prevState) => ({
        ...prevState,
        [field]: emailError.toUpperCase(),
      }));
    }
  };

  const updateGuest = (field: keyof typeof guest, value: string) => {
    setGuest((prevState) => ({ ...prevState, [field]: value }));
    setErrors((prevState) => ({ ...prevState, [field]: '' }));
  };

  const hasErrorsForm = ({
    name: guestName,
    email: guestEmail,
  }: {
    name: string;
    email: string;
  }) => guestName || guestEmail;

  const checkAllFieldsAndGetErrors = () => {
    const errs = { name: '', email: '' };

    if (name === '') {
      errs.name = 'Name cannot be empty';
    }
    if (email === '') {
      errs.email = 'Email cannot be empty';
    }
    if (!isEmailValid(email)) {
      errs.email = 'Invalid email';
    }

    return errs;
  };

  const inviteGuest = async () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    setErrors(newErrorsList);

    if (hasErrorsForm(newErrorsList)) {
      return;
    }

    setLoading(true);
    try {
      const invitedGuest = await AlbumsController.inviteGuestToRecord(albumId, {
        name,
        email,
      });
      notifyGuestInvited(invitedGuest);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWarning
      className={styles.modal}
      title="Invite guest to record"
      closeFn={onClose}
      successStyleButton={ButtonStyle.PrimaryFilled}
      successButtonText="Invite"
      disableSuccessButton={!!errors.email || !!errors.name || !name || !email}
      successFn={inviteGuest}
      loading={loading}
      content={
        <div className={styles.modalContent}>
          <p className="text__body__regular__medium__textNeutral30">
            To invite a guest to record please enter their name and email so we
            can keep track of their session.
          </p>
          <div className={styles.guestInputs}>
            <Input
              id="name"
              name="name"
              label="Name"
              placeholder="Name"
              value={name}
              type="text"
              errorMessage={errors.name}
              onChange={(e: any) => updateGuest('name', e.target.value)}
              onBlur={() => validateField('name')}
              className={styles.input}
            />
            <Input
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              value={email}
              type="email"
              errorMessage={errors.email}
              onChange={(e: any) => updateGuest('email', e.target.value)}
              onBlur={() => validateField('email')}
              className={styles.input}
            />
          </div>
        </div>
      }
    />
  );
};

export { InviteGuestModal };
