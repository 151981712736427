import { classnames } from 'helpers/utils';
import { contributorAlbumsTitles } from 'config/constants';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { RouteName, goToPage } from 'routes';
import styles from './contributor-table-row.module.scss';

type ContributorAlbumsRowsProps = {
  albums: AlbumType[];
};

const ContributorAlbumsRows: React.FC<ContributorAlbumsRowsProps> = ({
  albums,
}) => (
  <div className={styles.albumsRows}>
    <div className={styles.titles}>
      {Object.keys(contributorAlbumsTitles).map((title) => (
        <div
          key={title}
          className={classnames(
            styles[title],
            'text__body__semi__bold__medium__textNeutral40',
          )}
        >
          {contributorAlbumsTitles[title]}
        </div>
      ))}
    </div>
    <div
      className={classnames(
        styles.body,
        'text__body__regular__medium__textNeutral30',
      )}
    >
      {albums.map((album: AlbumType) => (
        <div key={album.id} className={styles.albumRow}>
          <div className={styles.albumName}>{album.title}</div>

          <div className={classnames(styles.column, styles.owner)}>
            {album.owner?.name}
          </div>

          <div className={classnames(styles.column, styles.companyName)}>
            {album.owner?.companyName}
          </div>

          <div className={classnames(styles.column, styles.actions)}>
            <Button
              className={styles.detailsButton}
              buttonStyle={ButtonStyle.PrimaryGhost}
              buttonSize={ButtonSize.Small}
              onClick={() => {
                goToPage(RouteName.AlbumDetail, { id: album.id });
              }}
            >
              <span className={styles.noWrap}>Album details</span>
              <span className={styles.chevronIcon}>
                <ChevronIcon />
              </span>
            </Button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export { ContributorAlbumsRows };
