import React, { useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Notification } from 'common/notification';
import { SignHeader } from '../../common/sign-header';
import styles from './forgot-password.module.scss';
import { ForgotPasswordStep1, ForgotPasswordStep2 } from './sections';

const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState('');

  const showForgotPasswordStep1View = () => (
    <ForgotPasswordStep1
      notifSuccess={() => setStep(1)}
      email={email}
      setEmail={setEmail}
    />
  );

  const showForgotPasswordStep2View = () => (
    <ForgotPasswordStep2 notifGoBack={() => setStep(0)} email={email} />
  );

  const showContentView = () => (
    <div className={globalStyles.signBoxContent}>
      <div className={styles.elements}>
        <SignHeader />
        {step === 0 && showForgotPasswordStep1View()}
        {step === 1 && showForgotPasswordStep2View()}
      </div>
    </div>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <div className={globalStyles.signMainContainer}>
      {showError && showErrorNotification()}
      {showContentView()}
    </div>
  );
};

export { ForgotPassword };
