import { Modal } from 'common/modal/modal';
import { Button, ButtonStyle } from 'common/button';
import styles from './modal-connection-failed.module.scss';

type ModalConnectionFailedType = {
  handleLeaveCall: () => void;
  handleRefreshPage: () => void;
};

const ModalConnectionFailed: React.FC<ModalConnectionFailedType> = ({
  handleLeaveCall,
  handleRefreshPage,
}) => (
  <Modal disableManualClosing contentClassName={styles.modal} title="Error">
    <div className={styles.modalContent}>
      <p className="text__body__regular__medium__textNeutral40">
        {`We're experimenting some issues with your call. Please refresh the page.
        If the issue persists try again in a few minutes`}
      </p>
      <div className={styles.buttons}>
        <Button
          buttonStyle={ButtonStyle.PrimaryGhost}
          className={styles.leaveCallButton}
          onClick={handleLeaveCall}
        >
          Leave call
        </Button>
        <Button onClick={handleRefreshPage}>Refresh page</Button>
      </div>
    </div>
  </Modal>
);

export { ModalConnectionFailed };
