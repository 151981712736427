import { Modal } from 'common/modal/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Spinner } from 'common/spinner';
import { extraVideoCallPackage } from 'config/constants';
import styles from './modal-buy-call-time.module.scss';

type ModalBuyTimeType = {
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: (option: boolean) => void;
  handleBuyTime: () => void;
  hourPrice: number;
};

const ModalBuyTime = ({
  onClose,
  isLoading,
  setIsLoading,
  handleBuyTime,
  hourPrice,
}: ModalBuyTimeType) => (
  <Modal onClose={onClose} contentClassName={styles.modal}>
    <div className={styles.modalContent}>
      <div className={styles.modalInformation}>
        <span className="text__title2__textNeutral40">
          Buying video call time
        </span>
        <p className="text__body__regular__small__textNeutral30">
          Increase your video call time by{' '}
          <span className="text__body__semi__bold__small__textNeutral30">
            two extra hours for ${hourPrice * extraVideoCallPackage}
          </span>
          . Remaining time rolls over to the next year.
        </p>
      </div>
      <div className={styles.actionsButtons}>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          className={isLoading ? styles.loading : ''}
          onClick={() => {
            setIsLoading(!isLoading);
            handleBuyTime();
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner color="white" className="spinner-inside-button" />
          ) : (
            `Buy ${extraVideoCallPackage} hours for $${
              hourPrice * extraVideoCallPackage
            }`
          )}
        </Button>
      </div>
    </div>
  </Modal>
);

export { ModalBuyTime };
