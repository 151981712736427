class Pagination {
  currentPage: number;

  lastPage: number;

  pageSize: number;

  count: number;

  constructor(params?: Pagination | null) {
    this.currentPage = params?.currentPage || -1;
    this.lastPage = params?.lastPage || -1;
    this.pageSize = params?.pageSize || -1;
    this.count = params?.count || -1;
  }
}

export { Pagination };
