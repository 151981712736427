class OwnerSerializer {
  static deSerializeOwnerInfo(data: OwnerInfoRaw): OwnerInfoSerializSerialized {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      companyName: data.company_name,
    };
  }
}

export { OwnerSerializer };
