class Owner {
  id: number;

  name: string;

  email: string;

  companyName: string;

  constructor(params?: OwnerInfoType | null) {
    this.id = params?.id || -1;
    this.name = params?.name || '';
    this.email = params?.email || '';
    this.companyName = params?.companyName || '';
  }
}

export { Owner };
