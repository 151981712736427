/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useContext, useState } from 'react';
import { DragIcon, EditIcon, DeleteIcon, CrossIcon } from 'assets/icons';
import {
  classnames,
  isOwner,
  isReadOnlySubOrRestrictedAlbum,
  isRestrictedAlbum,
} from 'helpers/utils';
import { Input } from 'common/input';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { questionsMaxLength } from 'config/constants';
import { RecordingStatus } from 'common/enums';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AppContext } from 'context/app-context';
import styles from './questions-list.module.scss';

type QuestionItemProps = {
  index?: number;
  album?: AlbumType;
  isEditingAlbum: boolean;
  question: QuestionType;
  deleteQuestion: Function;
  editQuestion: Function;
};

const QuestionItem: React.FC<QuestionItemProps> = ({
  index = 0,
  isEditingAlbum,
  album,
  question,
  deleteQuestion,
  editQuestion,
}) => {
  const { state: userState } = useContext(AppContext);

  const [editedQuestion, setEditedQuestion] = useState(question.name);
  const [error, setError] = useState('');

  const [isEditingRow, setIsEditingRow] = useState(false);
  const [isDeletingRow, setIsDeletingRow] = useState(false);
  const [isHoveringRow, setIsHoveringRow] = useState(false);

  const getPosition = (i: number) => i + 1;

  const validateQuestion = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length >= questionsMaxLength) {
      setError(`max. ${questionsMaxLength} characters`.toUpperCase());
    } else {
      setError('');
    }
  };

  const handleEdit = () => {
    setIsHoveringRow(false);
    setIsEditingRow(true);
  };

  const handleCancel = () => {
    setError('');
    setIsDeletingRow(false);
    setEditedQuestion(question.name);
    setIsEditingRow(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateQuestion(e);
    const { value } = e.target;
    setEditedQuestion(value);
  };

  const handleDelete = (q: QuestionType) => {
    deleteQuestion(q);
    setIsDeletingRow(false);
  };

  const handleSaveEdit = () => {
    editQuestion({ ...question, name: editedQuestion });
    setIsEditingRow(false);
  };

  const showActions = () => (
    <div className={styles.actions}>
      <Button
        disabled={isEditingAlbum && isReadOnlySubOrRestrictedAlbum(album!)}
        buttonStyle={ButtonStyle.PrimaryGhost}
        onClick={() => handleEdit()}
      >
        <EditIcon />
      </Button>
      <div className={styles.divider} />
      <Button
        disabled={isEditingAlbum && isRestrictedAlbum(album!)}
        buttonStyle={ButtonStyle.PrimaryGhost}
        onClick={() => setIsDeletingRow(true)}
      >
        <DeleteIcon />
      </Button>
    </div>
  );

  const showDeleteConfirmation = (q: QuestionType) => (
    <div className={styles.deleteWarning}>
      <Button
        buttonSize={ButtonSize.Tiny}
        buttonStyle={ButtonStyle.GreyGhost}
        onClick={() => handleCancel()}
      >
        Cancel
      </Button>
      <Button
        buttonSize={ButtonSize.Tiny}
        buttonStyle={ButtonStyle.RedGhost}
        onClick={() => handleDelete(q)}
      >
        Delete
      </Button>
    </div>
  );

  const canEditQuestion = () => {
    if (question.isNew) {
      return true;
    }

    if (
      album &&
      !isOwner(userState.data.user, album) &&
      question.createdByOwner
    )
      return false;

    return true;
  };

  const userCanEditRow = () => {
    if (canEditQuestion()) {
      if (isHoveringRow && !isDeletingRow) {
        return true;
      }
    }

    return false;
  };

  const useEditMode = () => (
    <div className={styles.editQuestion}>
      <div className={styles.inputContainer}>
        <Input
          id={`edit-question-${question.auxId}`}
          value={editedQuestion}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
          showErrorBorder={!!error}
          withValidation={false}
          className={styles.inputEditing}
        />
      </div>
      <div className={styles.information}>
        <div className={styles.errorMessage}>
          {!!error && (
            <>
              <span className={styles.crossIcon}>
                <CrossIcon />
              </span>
              <p className="text__body__regular__tiny__warning30">
                max. 140 characters
              </p>
            </>
          )}
        </div>
        <div className={styles.editActions}>
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.GreyGhost}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.PrimaryGhost}
            disabled={!!error}
            onClick={() => handleSaveEdit()}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Draggable
      draggableId={question.auxId!}
      index={index}
      isDragDisabled={
        isEditingRow ||
        (isEditingAlbum && isReadOnlySubOrRestrictedAlbum(album!))
      }
    >
      {(provided, snapshot) => (
        <div
          role="listbox"
          tabIndex={0}
          className={classnames(styles.questionContainer, {
            [styles.draggingRow]: snapshot.isDragging,
          })}
          onMouseEnter={() => !isEditingRow && setIsHoveringRow(true)}
          onMouseLeave={() => !isEditingRow && setIsHoveringRow(false)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className={styles.position}>{getPosition(index)}</div>
          <div className={styles.question}>
            <div className={styles.details}>
              <span
                className={classnames(styles.dragIcon, {
                  [styles.draggingIcon]: snapshot.isDragging,
                })}
              >
                <DragIcon />
              </span>
              {isEditingRow ? (
                useEditMode()
              ) : (
                <div className="text__body__regular__medium__textNeutral40">
                  {question.name}
                </div>
              )}
            </div>
            {userCanEditRow() && showActions()}

            {isDeletingRow && showDeleteConfirmation(question)}
          </div>
        </div>
      )}
    </Draggable>
  );
};

type QuestionsListProps = {
  album?: AlbumType;
  questions: QuestionType[];
  deleteQuestion: Function;
  isEditingAlbum: boolean;
  editQuestion: Function;
  handleDragEnd: Function;
};

const QuestionsList: React.FC<QuestionsListProps> = ({
  album,
  questions,
  deleteQuestion,
  isEditingAlbum,
  editQuestion,
  handleDragEnd,
}) => {
  const getNoVideoQuestions = (questionsList: QuestionType[]) =>
    questionsList.filter(
      (q: QuestionType) =>
        q.recordingStatus === RecordingStatus.noVideo || !q.recordingStatus,
    );

  const getProcessingQuestions = (questionsList: QuestionType[]) =>
    questionsList.filter(
      (q: QuestionType) => q.recordingStatus === RecordingStatus.processing,
    );

  const processingQuestions = getProcessingQuestions(questions);
  const noVideoQuestions = getNoVideoQuestions(questions);

  return (
    <>
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral20',
          styles.processingQuetionsContainer,
        )}
      >
        {processingQuestions.map((q) => (
          <div key={q.id} className={styles.processingQuestionRow}>
            <div className={styles.position}>-</div>
            <span
              className={classnames('invisible', styles.processingDragIcon)}
            >
              <DragIcon />
            </span>
            <div>{q.name}</div>
            <div
              className={classnames(
                'text__body__semi__bold__small__primary50',
                styles.processingLabel,
              )}
            >
              {' '}
              Processing...
            </div>
          </div>
        ))}
      </div>
      <DragDropContext onDragEnd={(result: any) => handleDragEnd(result)}>
        <Droppable droppableId="question-list" type="QUESTION">
          {(provided) => (
            <div className={styles.listContainer} ref={provided.innerRef}>
              {noVideoQuestions.map((q, i) => {
                if (
                  q.recordingStatus === RecordingStatus.noVideo ||
                  !q.recordingStatus
                ) {
                  return (
                    <QuestionItem
                      key={q.auxId}
                      album={album}
                      isEditingAlbum={isEditingAlbum}
                      question={q}
                      index={i}
                      deleteQuestion={deleteQuestion}
                      editQuestion={editQuestion}
                    />
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
export { QuestionsList };
