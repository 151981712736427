import { Countries } from 'common/enums';
import { PaymentSerializer } from './payment-serializer';
import { SubscriptionDealSerializer } from './subscription-deal-serializer';

class UserSerializer {
  static deSerializeAllDataUser(data: AllDataUserRaw): AllDataUserSerialized {
    return {
      csrfToken: data.csrf_token,
      user: {
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        companyName: data.user.company_name,
        recordingTips: data.user.recording_tips,
        stripeClientSecret: data.user.stripe_client_secret,
        backupEmail: data.user.backup_email,
        backupName: data.user.backup_name,
        country: data.user.state ? Countries.UnitedStates : Countries.Other,
        state: data.user.state,
        verified: data.user.verified,
        firstAlbumPaymentProcessed:
          data.user.first_album_payment_processed || false,
        paymentMethods:
          data.user.payment_methods.map(
            PaymentSerializer.deSerializePaymentMethod,
          ) || [],
        subscriptionDeals: data.user.subscription_deals
          ? data.user.subscription_deals.map(
              SubscriptionDealSerializer.deSerializeSubscriptionDealResponse,
            )
          : [],
      },
    };
  }

  static deSerializeUtmProperty(
    utmProperty: UtmProperties,
  ): UtmPropertiesDeSerialized | null {
    switch (utmProperty) {
      case 'utm_source':
        return 'utmSource';
      case 'utm_medium':
        return 'utmMedium';
      case 'utm_campaign':
        return 'utmCampaign';
      case 'utm_term':
        return 'utmTerm';
      case 'utm_content':
        return 'utmContent';
      default:
        return null;
    }
  }

  static serializeSignUp(user: UserSignUpSerialized): SignUpRaw {
    return {
      user: {
        name: user.name,
        email: user.email.trim(),
        company_name: user.companyName,
        password: user.password,
        backup_name: user.backupName,
        backup_email: user.backupEmail.trim(),
        state: user.state.value,
        token: user.token,
        coupon_codes: user.couponCodes,
      },
      utm: {
        source: user.utmProperties.utmSource,
        medium: user.utmProperties.utmMedium,
        campaign: user.utmProperties.utmCampaign,
        term: user.utmProperties.utmTerm,
        content: user.utmProperties.utmContent,
      },
    };
  }

  static serializeSignIn(signIn: UserSignInSerialized): UserSignInRaw {
    return {
      email: signIn.email.trim(),
      password: signIn.password,
    };
  }

  static serializeResetPassword(
    data: ResetPasswordSerialized,
  ): ResetPasswordRaw {
    return {
      new_password: data.newPassword,
      token: data.token,
    };
  }

  static serializeUser(data: UserType): UserUpdateRaw {
    return {
      user: {
        name: data.name,
        email: data.email.trim(),
        company_name: data.companyName,
        recording_tips: data.recordingTips,
        state: data.state,
        backup_name: data.backupName,
        backup_email: data.backupEmail?.trim(),
      },
    };
  }

  static deSerializeUser(data: UserRaw): UserType {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      companyName: data.company_name,
      recordingTips: data.recording_tips,
      stripeClientSecret: data.stripe_client_secret,
      backupEmail: data.backup_email,
      backupName: data.backup_name,
      country: data.state ? Countries.UnitedStates : Countries.Other,
      state: data.state,
      verified: data.verified,
      firstAlbumPaymentProcessed: data.first_album_payment_processed,
      paymentMethods:
        data.payment_methods.map(PaymentSerializer.deSerializePaymentMethod) ||
        [],
      subscriptionDeals: data.subscription_deals
        ? data.subscription_deals.map(
            SubscriptionDealSerializer.deSerializeSubscriptionDealResponse,
          )
        : [],
    };
  }

  static serializeVerificationEmail(
    data: VerifyEmailSerialized,
  ): VerifyEmailRaw {
    return {
      email: data.email.trim(),
      email_verification_token: data.emailVerificationToken,
    };
  }

  static serializeChangePassword(
    data: ChangePasswordRequestSerialized,
  ): ChangePasswordRequestRaw {
    return {
      password: data.password,
      new_password: data.newPassword,
    };
  }

  static serializeAddPaymentMethod(
    data: AddPaymentMethodRequestSerialized,
  ): AddPaymentMethodRequestRaw {
    return {
      payment_method_id: data.PaymentMethodId,
    };
  }
}

export { UserSerializer };
