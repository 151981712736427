import { useRef, useState } from 'react';
import { SensorMenu } from 'common/sensor-menu';
import styles from './waiting-room-sensor-selection.module.scss';
import { WaitingRoomSensorSelectionButton } from './waiting-room-sensor-selection-button';

const WaitingRoomSensorSelection = () => {
  const [microphoneMenuOpen, setMicrophoneMenuOpen] = useState(false);
  const [cameraMenuOpen, setCameraMenuOpen] = useState(false);

  const microphoneMenuRef = useRef(null);
  const cameraMenuRef = useRef(null);

  return (
    <div className={styles.waitingRoomSensorSelection}>
      <div ref={microphoneMenuRef} className={styles.menuButtonContainer}>
        <WaitingRoomSensorSelectionButton
          sensorType="microphone"
          menuOpen={microphoneMenuOpen}
          toggleMenuOpen={() => setMicrophoneMenuOpen(!microphoneMenuOpen)}
        />
        <SensorMenu
          containerRef={microphoneMenuRef}
          sensorType="microphone"
          menuOpen={microphoneMenuOpen}
          setMenuOpen={setMicrophoneMenuOpen}
        />
      </div>
      <div ref={cameraMenuRef} className={styles.menuButtonContainer}>
        <WaitingRoomSensorSelectionButton
          sensorType="camera"
          menuOpen={cameraMenuOpen}
          toggleMenuOpen={() => setCameraMenuOpen(!cameraMenuOpen)}
        />
        <SensorMenu
          containerRef={cameraMenuRef}
          sensorType="camera"
          menuOpen={cameraMenuOpen}
          setMenuOpen={setCameraMenuOpen}
        />
      </div>
    </div>
  );
};

export { WaitingRoomSensorSelection };
