import { classnames } from 'helpers/utils';
import { BoxIcon } from 'assets/icons';
import { Button, ButtonStyle } from 'common/button';
import { RouteName, goToPage } from 'routes';
import styles from './no-contributors-found.module.scss';

const NoContributorsFound: React.FC = () => (
  <div className={styles.emptyPage}>
    <BoxIcon className={styles.boxIcon} />
    <div className={styles.emptyPageTexts}>
      <p
        className={classnames(
          'text__heading4__textNeutral30',
          styles.headingEmpty,
        )}
      >
        No contributors found
      </p>

      <p className="text__body__regular__medium__textNeutral40">
        <span>You can add contributors from&nbsp;</span>
        <Button
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          onClick={() => goToPage(RouteName.Albums)}
        >
          your album
        </Button>
        <span>
          &nbsp; to give other people the ability to participate in the
          interview process.
        </span>
      </p>
    </div>
  </div>
);

export { NoContributorsFound };
