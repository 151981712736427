import React, { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { classnames } from 'helpers/utils';
import { CrossIcon, InfoIcon } from 'assets/icons';
import { NotificationType } from 'common/enums';
import styles from './notification.module.scss';

type NotificationProps = {
  type?: NotificationType;
  title?: string;
  noMessage?: boolean;
  message?: string;
  limitTimeMs?: number;
  classes?: string;
  handleClose: () => void;
  footer?: ReactNode;
};

const Notification: React.FC<NotificationProps> = ({
  type = NotificationType.Error,
  noMessage = false,
  title = 'Something went wrong',
  message = 'There was an error. Please try again later.',
  limitTimeMs = 5000,
  handleClose,
  classes = '',
  footer,
}) => {
  const [opening, setOpening] = useState(true);
  const [display, setDisplay] = useState(true);

  const hide = async () => {
    setOpening(false);

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((_) => setTimeout(_, 900));

    setDisplay(false);
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      hide();
    }, limitTimeMs);
    return () => {
      setOpening(true);
      setDisplay(true);
    };
  }, []);

  function handleTypeStyle(typeNotif: NotificationType) {
    const classesList = [];

    switch (typeNotif) {
      case NotificationType.Error:
        classesList.push(styles.iconInfoError);
        break;
      case NotificationType.Success:
        classesList.push(styles.iconInfoSuccess);
        break;
      case NotificationType.Info:
        classesList.push(styles.iconInfo);
        break;
      default:
        break;
    }

    return classnames(...classesList);
  }

  const showNotification = () => (
    <div
      className={classnames(
        classes,
        styles.box,
        `${opening ? styles.openAnimation : styles.closeAnimation}`,
      )}
    >
      <div className={classnames(styles.title)}>
        <span className={handleTypeStyle(type)}>
          <InfoIcon />
        </span>
        <span className="text__body__regular__large__textNeutral50">
          {title}
        </span>
        <button
          type="button"
          onClick={hide}
          className={classnames(styles.iconCross)}
        >
          <CrossIcon />
        </button>
      </div>
      {!noMessage && (
        <div className="mt-10 text__body__regular__small__textNeutral40">
          {message}
        </div>
      )}
      {footer}
    </div>
  );

  return ReactDOM.createPortal(
    display ? showNotification() : '',
    document.getElementById('notification') as HTMLElement,
  );
};

export { Notification };
