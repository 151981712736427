import { classnames } from 'helpers/utils';
import styles from './answered-questions.module.scss';

type AnsweredQuestionsProps = {
  previouslyAnsweredQuestions?: ClipType[];
  newAnsweredQuestions: ClipType[];
};

const AnsweredQuestions: React.FC<AnsweredQuestionsProps> = ({
  previouslyAnsweredQuestions = [],
  newAnsweredQuestions,
}) => {
  const showEmptyNewMessage = () => (
    <div
      className={classnames(
        'text__body__regular__medium__textNeutral30',
        styles.noNewAnsweredQuestion,
      )}
    >
      {' '}
      You have not recorded any clips during this call
    </div>
  );

  const showNewAnsweredList = (newAnswered: ClipType[]) => (
    <>
      {newAnswered.map((question: ClipType) => (
        <div key={question.id} className={classnames(styles.newAnsweredItem)}>
          <div
            className={classnames('text__body__regular__small__textNeutral10')}
          >
            {question.name}
          </div>
        </div>
      ))}
    </>
  );

  const showEmptyPreviousMessage = () => (
    <div
      className={classnames(
        styles.noPreviousClips,
        'text__body__regular__tiny__textNeutral30',
      )}
    >
      {' '}
      Your album does not have any previously answered questions.{' '}
    </div>
  );

  const showPreviouslyAnsweredList = (previouslyAnswered: ClipType[]) => (
    <>
      {previouslyAnswered.map((question: ClipType) => (
        <div key={question.id} className={classnames(styles.prevAnsweredList)}>
          <div
            className={classnames(
              'text__body__regular__tiny__textNeutral20',
              styles.prevAnsweredQuestion,
            )}
          >
            {question.name}
          </div>
        </div>
      ))}
    </>
  );

  const showNewRecordedClips = (newAnswered: ClipType[]) => (
    <div className={styles.newAnsweredContainer}>
      {newAnswered.length
        ? showNewAnsweredList(newAnswered)
        : showEmptyNewMessage()}
    </div>
  );

  const showPreviouslyAnsweredQuestions = (previouslyAnswered: ClipType[]) => (
    <div className={styles.previouslyAnsweredContainer}>
      <div
        className={classnames(
          'text__body__regular__overline__textNeutral20',
          styles.titlePreviously,
        )}
      >
        {' '}
        previously answered questions{' '}
      </div>
      {previouslyAnswered.length
        ? showPreviouslyAnsweredList(previouslyAnswered)
        : showEmptyPreviousMessage()}
    </div>
  );

  return (
    <>
      {showNewRecordedClips(newAnsweredQuestions)}
      {showPreviouslyAnsweredQuestions(previouslyAnsweredQuestions)}
    </>
  );
};

export { AnsweredQuestions };
