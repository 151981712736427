export const allOptionsSelected = (checkBoxGroupChanged: CheckboxGroupType) =>
  checkBoxGroupChanged.options.every(
    (opt: CheckboxOptionSimpleType) => opt.isSelected === true,
  );

export const noOptionsSelected = (checkBoxGroupChanged: CheckboxGroupType) =>
  checkBoxGroupChanged.options.every(
    (opt: CheckboxOptionSimpleType) => opt.isSelected === false,
  );

export const selectAllOptionsFromCheckBoxGroup = (
  options: CheckboxOptionSimpleType[],
) =>
  options.map((option: CheckboxOptionSimpleType) => ({
    ...option,
    isSelected: true,
  }));

export const deSelectAllOptionsFromCheckBoxGroup = (
  options: CheckboxOptionSimpleType[],
) =>
  options.map((option: CheckboxOptionSimpleType) => ({
    ...option,
    isSelected: false,
  }));
