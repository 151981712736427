import React, { useContext } from 'react';

import { Navbar } from 'common/navbar';
import { Footer } from 'common/footer';
import { AppContext } from 'context';
import { classnames } from 'helpers/utils';

import styles from './layout.module.scss';

enum LayoutType {
  // Add more layout types here
  Home = 'Home',
  WithNavAndFooter = 'WithNavAndFooter',
  WithNavOnly = 'WithNavOnly',
  Empty = 'Empty',
}

type LayoutProps = {
  children: React.ReactNode;
  layoutType: LayoutType;
};

const Layout = ({ layoutType, children }: LayoutProps) => {
  const { state } = useContext(AppContext);
  const { showBetaMsg } = state;
  const globalClass = classnames(styles.globalContent, {
    [styles.withBetaMsg]: showBetaMsg,
  });

  if (layoutType === LayoutType.WithNavAndFooter) {
    return (
      <div className={globalClass}>
        <Navbar />
        {children}
        <Footer />
      </div>
    );
  }

  if (layoutType === LayoutType.WithNavOnly) {
    return (
      <div className={globalClass}>
        <Navbar />
        {children}
      </div>
    );
  }

  if (layoutType === LayoutType.Empty) {
    return <div className={globalClass}>{children}</div>;
  }

  return null;
};

export { Layout, LayoutType };
