import { CreateAlbumSteps } from 'common/enums';
import { AlbumsNew } from 'models/album-new';
import { SubscriptionType } from 'models/subscription-type';

const mandatoryFieldsErrors: NewAlbumErrorsType = {
  title: '',
  contributors: [],
};

export type AlbumNewStateType = {
  isLoading: boolean;
  step: number;
  subscriptionSelected: SubscriptionTypeType;
  album: AlbumNewRequestType;
  couponCode: string;
  discountedPrices: SubscriptionDiscountedPriceType[];
  errors: NewAlbumErrorsType;
};

const AlbumNewinitialState: AlbumNewStateType = {
  isLoading: false,
  step: CreateAlbumSteps.subscriptionType,
  subscriptionSelected: new SubscriptionType(),
  album: new AlbumsNew(),
  couponCode: '',
  discountedPrices: [],
  errors: mandatoryFieldsErrors,
};

type NewAlbumAction =
  | { type: 'IS_LOADING'; isLoading: boolean }
  | { type: 'MOVE_STEP'; direction: 'next' | 'back' }
  | { type: 'SUBSCRIPTION_SELECTED'; subSelected: SubscriptionTypeType }
  | { type: 'ALBUM_INPUT'; field: string; value: string }
  | { type: 'COUPON_INPUT'; couponCode: string }
  | { type: 'UPLOAD_IMAGE'; coverSignedId: string | undefined }
  | { type: 'UPDATE_ERRORS'; errors: NewAlbumErrorsType }
  | {
      type: 'UPDATE_SUBSCRIPTION_NEW_PRICES';
      discountedPrices: SubscriptionDiscountedPriceType[];
    }
  | { type: 'UPDATE_CONTRIBUTORS'; contributors: ContributorType[] };

const AlbumNewReducer = (state: AlbumNewStateType, action: NewAlbumAction) => {
  switch (action.type) {
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case 'MOVE_STEP':
      return {
        ...state,
        step: action.direction === 'next' ? state.step + 1 : state.step - 1,
      };

    case 'UPDATE_CONTRIBUTORS':
      return {
        ...state,
        album: {
          ...state.album,
          contributors: action.contributors,
        },
      };

    case 'UPLOAD_IMAGE':
      return {
        ...state,
        album: {
          ...state.album,
          coverSignedId: action.coverSignedId,
        },
      };

    case 'UPDATE_ERRORS':
      return {
        ...state,
        errors: action.errors,
      };

    case 'UPDATE_SUBSCRIPTION_NEW_PRICES':
      return {
        ...state,
        discountedPrices: action.discountedPrices,
      };

    case 'COUPON_INPUT':
      return {
        ...state,
        couponCode: action.couponCode,
      };

    case 'ALBUM_INPUT':
      return {
        ...state,
        album: {
          ...state.album,
          [action.field]: action.value,
        },
      };

    case 'SUBSCRIPTION_SELECTED':
      return {
        ...state,
        subscriptionSelected: action.subSelected,
      };

    default:
      return state;
  }
};

export { AlbumNewReducer, AlbumNewinitialState };
export type { NewAlbumAction };
