/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-children-prop */
import React, { useContext } from 'react';
import { AppContext } from 'context';
import { AppRedirect, RouteName } from 'routes';
import { hasNotUserValidatedEmail } from 'helpers/utils';

const Home: React.FC = () => {
  const { state } = useContext(AppContext);
  const {
    data: { user },
  } = state;

  const getRoutePropertiesByUserStatus = (): RoutePropertiesType => {
    if (hasNotUserValidatedEmail(user)) {
      return { routeName: RouteName.EmailWaitingConfirm };
    }

    return { routeName: RouteName.Albums };
  };

  return <AppRedirect {...getRoutePropertiesByUserStatus()} children={<></>} />;
};

export { Home };
