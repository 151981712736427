import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './payment-method.module.scss';

type PaymentMethodProps = {
  paymentMethod: PaymentMethodType;
};

const PaymentMethod: React.FC<PaymentMethodProps> = ({ paymentMethod }) => {
  const { lastFourDigits, expirationMonth, expirationYear, brand } =
    paymentMethod;

  return (
    <div className={styles.paymentMethodInfo}>
      <div className={styles.numberAndDate}>
        <span className="text__body__semi__bold__medium__textNeutral40">
          {`**** ${lastFourDigits}`}
        </span>
        <span className="text__body__regular__medium__textNeutral30">
          {`${expirationMonth}/${expirationYear}`}
        </span>
      </div>
      <div
        className={classnames(
          'text__body__semi__bold__tiny__textNeutral40',
          styles.brand,
        )}
      >
        {brand}
      </div>
    </div>
  );
};

export { PaymentMethod };
