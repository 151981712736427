import React, { useContext } from 'react';
import { classnames } from 'helpers/utils';
import { StoreAction, StoreStateType } from 'pages/store/store-reducer';
import { Button } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { PaymentMethod } from 'common/payment-method';
import { AppContext } from 'context/app-context';
import { OrderList } from './OrderList';
import styles from './checkout.module.scss';

type PaymentSummaryProps = {
  state: StoreStateType;
  dispach: React.Dispatch<StoreAction>;
};
export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  state,
  dispach,
}) => {
  const { state: generalState } = useContext(AppContext);
  const showPaymentMethod = () => (
    <div className={classnames(styles.paymentBox)}>
      <div
        className={classnames(
          styles.title,
          'text__body__regular__overline__textNeutral40',
        )}
      >
        PAYMENT METHOD
      </div>
      <PaymentMethod paymentMethod={generalState.data.user.paymentMethods[0]} />
    </div>
  );
  return (
    <div className={styles.orderDetailsContent}>
      <div className={styles.leftSection}>
        <div className={classnames(styles.paymentSummaryContent)}>
          <div
            className={classnames(
              'text__body__regular__overline__textNeutral40',
            )}
          >
            ORDER CONFIRMATION
          </div>
          <div className={classnames(styles.paymentSummaryInformation)}>
            <div
              className={classnames(
                'text__body__regular__medium__textNeutral30',
              )}
            >
              Your order number is{' '}
              <span className="text__body__semi__bold__medium__textNeutral30">
                #{state.orderId}
              </span>
              . We’ve sent you an email to{' '}
              <span className="text__body__semi__bold__medium__textNeutral30">
                {state.shippingDetails.email}
              </span>{' '}
              with your order information.
            </div>
            <div
              className={classnames(
                'text__body__regular__medium__textNeutral30',
              )}
            >
              If your order requires shipping we will notify you once it has
              been shipped and provide a tracking number. Digital products, such
              as extra clips and video call time,have already been added to
              their respective albums.
            </div>
            <div
              className={classnames(
                'text__body__regular__medium__textNeutral30',
              )}
            >
              Thank you for shopping with us!
            </div>
            <Button
              onClick={() => {
                dispach({ type: 'GO_INITIAL_STATE' });
                goToPage(RouteName.Store);
              }}
              className={classnames(styles.fullWidth)}
            >
              Back to store
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.allItems}>
          <OrderList state={state} />
          {showPaymentMethod()}
        </div>
      </div>
    </div>
  );
};
