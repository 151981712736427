/* eslint-disable max-len */
import {
  AlbumsIcon,
  FileTextIcon,
  ShoppingCartIcon,
  UsersIcon,
} from 'assets/icons';
import { NavSections } from 'common/enums';
import { classnames, isAnyLoggedUser } from 'helpers/utils';
import { RouteName, goToPage } from 'routes';
import { ReactNode } from 'react';
import styles from './central-section.module.scss';

type OptionNavbarProps = {
  tab: NavSections;
  handleTabSelected: (queryParams: ParamsType) => void;
  actualSection: string;
  icon?: ReactNode;
};

const OptionNavbar: React.FC<OptionNavbarProps> = ({
  tab,
  actualSection,
  icon,
  handleTabSelected,
}) => (
  <button
    type="button"
    onClick={() => handleTabSelected({ tab })}
    className={classnames(styles.option, {
      [styles.optionSelected]: actualSection === tab,
    })}
  >
    {icon}
    <span
      className={classnames(
        styles.title,
        'text__body__semi__bold__medium__textNeutral30',
      )}
    >
      {tab}
    </span>
  </button>
);

type CentralSectionProps = {
  user: UserType;
  actualSection?: string;
  guestToken?: string;
};

const CentralSection: React.FC<CentralSectionProps> = ({
  user,
  actualSection = '',
  guestToken,
}) => {
  const showLoggedContent = () => (
    <div className={styles.centralOptions}>
      <OptionNavbar
        tab={NavSections.albums}
        icon={<AlbumsIcon />}
        actualSection={actualSection}
        handleTabSelected={() => goToPage(RouteName.Albums)}
      />
      <OptionNavbar
        tab={NavSections.contributors}
        icon={<UsersIcon />}
        actualSection={actualSection}
        handleTabSelected={() => goToPage(RouteName.Contributors)}
      />
      <OptionNavbar
        tab={NavSections.templates}
        icon={<FileTextIcon />}
        actualSection={actualSection}
        handleTabSelected={() => goToPage(RouteName.Templates)}
      />
      <OptionNavbar
        tab={NavSections.store}
        icon={<ShoppingCartIcon />}
        actualSection={actualSection}
        handleTabSelected={() => goToPage(RouteName.Store)}
      />
    </div>
  );

  const showNoLoggedContent = () =>
    guestToken ? (
      <div className={styles.centralOptions}>
        <OptionNavbar
          tab={NavSections.albums}
          icon={<AlbumsIcon />}
          actualSection={actualSection}
          handleTabSelected={() => goToPage(RouteName.Albums)}
        />
      </div>
    ) : null;

  if (isAnyLoggedUser(user)) {
    return showLoggedContent();
  }

  return showNoLoggedContent();
};

export { CentralSection };
