import { useEffect, useState } from 'react';

import { useHandleApiError } from 'hooks/use-handle-api-error';

type UseFetchTypes = {
  action: Function;
  param?: object | string | number;
  exec?: boolean;
  onError?: ((e: any) => void) | null;
  onSuccess?: (data: any) => void;
};

const useFetch = ({
  action,
  param = {},
  exec = true,
  onError,
  onSuccess = () => {},
}: UseFetchTypes) => {
  const [loading, setLoading] = useState(exec);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [execution, setExecution] = useState(exec);
  const setApiError = useHandleApiError((apiError: any) => {
    setError(apiError);
  });

  useEffect(() => {
    if (execution) {
      (async () => {
        setLoading(true);
        try {
          const response = await action(param);
          setData(response);
          onSuccess(response);
        } catch (e: any) {
          if (onError) {
            onError(e);
          } else {
            setApiError(e);
          }
        }
        setLoading(false);
        setExecution(false);
      })();
    }
  }, [execution]);
  return [loading, data, error, setExecution] as const;
};

export { useFetch };
