import { classnames } from 'helpers/utils';
import { ChevronIcon } from 'assets/icons';
import { VideoCallMenuQuestionsTabs } from 'common/enums';
import styles from './header.module.scss';

type HeaderProps = {
  isMenuExpanded: boolean;
  tabSelected: VideoCallMenuQuestionsTabs;
  notifyTabSelected: (tab: VideoCallMenuQuestionsTabs) => void;
  notifyMenuChange: () => void;
};

const Header: React.FC<HeaderProps> = ({
  tabSelected,
  isMenuExpanded,
  notifyTabSelected,
  notifyMenuChange,
}) => {
  const showHeader = () => (
    <div className={styles.headerContainer}>
      <div className={classnames('text__body__regular__large__textNeutral00')}>
        Questions
      </div>
      <div className={styles.tabsAndChevronContainer}>
        <div
          className={classnames(
            styles.alltabs,
            'text__body__regular__medium__textNeutral10',
          )}
        >
          <div className={styles.tab}>
            <button
              type="button"
              className={classnames(
                styles.tabButton,
                tabSelected === VideoCallMenuQuestionsTabs.pending
                  ? styles.tabSelected
                  : '',
              )}
              onClick={() =>
                notifyTabSelected(VideoCallMenuQuestionsTabs.pending)
              }
            >
              Pending
            </button>
          </div>
          <div className={styles.tab}>
            <button
              type="button"
              className={classnames(
                styles.tabButton,
                tabSelected === VideoCallMenuQuestionsTabs.recorded
                  ? styles.tabSelected
                  : '',
              )}
              onClick={() =>
                notifyTabSelected(VideoCallMenuQuestionsTabs.recorded)
              }
            >
              Recorded
            </button>
          </div>
        </div>
        <div className={styles.chevronIcon}>
          <button
            aria-label="Close"
            type="button"
            className={
              isMenuExpanded ? classnames(styles.expanded) : styles.collapsed
            }
            onClick={() => notifyMenuChange()}
          >
            <ChevronIcon />
          </button>
        </div>
      </div>
    </div>
  );

  return <>{showHeader()}</>;
};

export { Header };
