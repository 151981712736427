import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Breakpoints, FileExtension, NotificationType } from 'common/enums';
import { classnames } from 'helpers/utils';
import { sFileMaxDuration } from 'config/constants';
import { formatFileExtensions, isValidFile } from 'helpers/file-utils';
import { FileUploaderErrorMessage } from 'common/file-uploader-error-message';
import { useMediaQuery } from 'hooks/use-media-query';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import styles from './file-uploader-video.module.scss';

type FileUploaderVideoProps = {
  maxQuestionsLimit: number;
  clipsAmount: number;
  className?: string;
  supportedExtensions?: Array<FileExtension>;
  maxFileSizeInMb?: number;
  notifyNewFile: (file: File) => void;
  isGuest?: boolean;
  canUploadMultipleFiles: boolean;
};

const FileUploaderVideo: React.FC<FileUploaderVideoProps> = ({
  clipsAmount,
  className = '',
  supportedExtensions = [],
  maxFileSizeInMb = 0,
  notifyNewFile,
  maxQuestionsLimit,
  isGuest,
  canUploadMultipleFiles,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(
    '',
  );
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { dispatch } = useContext(AppContext);

  const uploadErrors = {
    generic: 'Oh no, something went wrong.',
    size: `File is too big. Maximum file size accepted is ${maxFileSizeInMb}MB.`,
    format: `File format not supported. Please upload only ${formatFileExtensions(
      supportedExtensions,
    ).toUpperCase()} files.`,
    duration: `File is too long. Maximum file length is ${
      sFileMaxDuration / 60
    } minutes.`,
  };

  const limitQuestionReached = () => {
    if (clipsAmount >= maxQuestionsLimit) {
      setErrorMessage(
        <span>
          {`You’ve reached the limit of ${maxQuestionsLimit} clips per album. ${
            isGuest ? '' : 'Delete clips to upload videos'
          } `}
        </span>,
      );
    }
  };

  const verifyMaxQuestionLimit = (clipPosition: number) => {
    if (clipsAmount + clipPosition >= maxQuestionsLimit) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Your album is full',
          message: `You’ve reached the limit of ${maxQuestionsLimit} clips per album. ${
            isGuest ? '' : 'Delete clips to upload videos'
          } `,
          type: NotificationType.Error,
        }),
      });
      return false;
    }
    return true;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const verifyAndNotifyFiles = async (files: File[]) => {
    const firstFile = files[0];
    if (canUploadMultipleFiles) {
      files.forEach(async (file, index) => {
        const maxQuestionsLimitReached = verifyMaxQuestionLimit(index);
        if (!maxQuestionsLimitReached) return;
        isValidFile(
          file!,
          uploadErrors,
          supportedExtensions,
          maxFileSizeInMb,
          sFileMaxDuration,
        ).then((error) => {
          if (error) {
            dispatch({
              type: appActions.NOTIFICATION,
              notification: new NotificationObject({
                show: true,
                title: `${file.name}`,
                message: `${error.toString()}`,
                type: NotificationType.Error,
              }),
            });
          } else {
            setErrorMessage('');
            notifyNewFile(file);
          }
        });
      });
    } else {
      const error = await isValidFile(
        firstFile!,
        uploadErrors,
        supportedExtensions,
        maxFileSizeInMb,
        sFileMaxDuration,
      );

      if (!error) {
        notifyNewFile(firstFile);
      } else {
        setErrorMessage(error);
      }
    }
  };

  const handleOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const arrayFiles = Array.from(e.dataTransfer.files);
    await verifyAndNotifyFiles(arrayFiles);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    const arrayFiles = Array.from(files);
    await verifyAndNotifyFiles(arrayFiles);
  };

  useEffect(() => {
    limitQuestionReached();
  }, [clipsAmount]);

  const showBrowseFiles = () => (
    <>
      <Button
        key={uploadErrors.format}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => fileRef.current?.click()}
        disabled={clipsAmount >= maxQuestionsLimit}
      >
        Browse files
      </Button>
      <input
        type="file"
        ref={fileRef}
        accept={formatFileExtensions(supportedExtensions)}
        onChange={handleInputChange}
        hidden
        multiple={canUploadMultipleFiles}
      />
    </>
  );

  const showUploader = () => {
    if (errorMessage) {
      return (
        <FileUploaderErrorMessage
          errorMessage={errorMessage}
          actions={showBrowseFiles()}
        />
      );
    }

    return (
      <>
        <span
          className={classnames(
            styles.uploaderText,
            'text__body__regular__small__textNeutral30',
          )}
        >
          Drag and drop your videos{' '}
        </span>
        <Button
          buttonStyle={ButtonStyle.PrimaryStroke}
          onClick={() => fileRef.current?.click()}
          disabled={clipsAmount >= maxQuestionsLimit}
        >
          Browse files
        </Button>
        <input
          type="file"
          ref={fileRef}
          accept={formatFileExtensions(supportedExtensions)}
          onChange={handleInputChange}
          hidden
          multiple={canUploadMultipleFiles}
        />
      </>
    );
  };

  if (isMobile)
    return (
      <>
        <Button
          buttonStyle={ButtonStyle.PrimaryFilled}
          buttonSize={ButtonSize.Medium}
          onClick={() => fileRef.current?.click()}
        >
          Browse files
        </Button>
        <input
          type="file"
          ref={fileRef}
          accept={formatFileExtensions(supportedExtensions)}
          onChange={handleInputChange}
          hidden
          multiple={canUploadMultipleFiles}
        />
      </>
    );

  return (
    <div
      className={classnames(className, styles.uploader)}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
    >
      {showUploader()}
    </div>
  );
};

export { FileUploaderVideo };
