import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { CreateAlbumSteps } from 'common/enums';
import { AlbumNewStateType } from 'pages/album-new/album-new-reducer';
import CypressIds from 'cypressIds';
import styles from './action-buttons.module.scss';

type ActionButtonsProps = {
  isMobileSize: boolean;
  newAlbumState: AlbumNewStateType;
  notifiyPreviousAction: () => void;
  notifiyNextAction: () => void;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isMobileSize,
  newAlbumState,
  notifiyPreviousAction,
  notifiyNextAction,
}) => {
  const shouldBeDisabledNextButton = (newAlbState: AlbumNewStateType) => {
    const { step, subscriptionSelected } = newAlbState;

    if (step === CreateAlbumSteps.subscriptionType && !subscriptionSelected.id)
      return true;

    return false;
  };

  const showPreviousButton = (actualStep: number) => (
    <Button
      buttonStyle={ButtonStyle.GreyGhost}
      buttonSize={isMobileSize ? ButtonSize.Small : ButtonSize.Medium}
      buttonType={ButtonType.Button}
      onClick={() => notifiyPreviousAction()}
    >
      {actualStep === CreateAlbumSteps.subscriptionType ? 'Cancel' : 'Back'}
    </Button>
  );

  const showNextButton = (actualStep: number) => (
    <Button
      buttonSize={isMobileSize ? ButtonSize.Small : ButtonSize.Medium}
      buttonType={ButtonType.Submit}
      onClick={() => notifiyNextAction()}
      disabled={shouldBeDisabledNextButton(newAlbumState)}
      dataCy={CypressIds.createAlbumButton}
    >
      {actualStep === CreateAlbumSteps.subscriptionType
        ? 'Next'
        : 'Create New Album'}
    </Button>
  );

  return (
    <div className={styles.buttonsBox}>
      {showPreviousButton(newAlbumState.step)}
      {showNextButton(newAlbumState.step)}
    </div>
  );
};

export { ActionButtons };
