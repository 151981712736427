class UploadFileSerializer {
  static serializeFileProps(
    file: File,
    md5: string,
  ): PresignedUrlRequestPropsRaw {
    return {
      byte_size: file.size,
      checksum: md5,
      content_type: file.type,
      filename: file.name,
    };
  }

  static serializePresignedUrl(
    file: File,
    md5: string,
  ): PresignedUrlRequestRaw {
    return {
      blob: UploadFileSerializer.serializeFileProps(file, md5),
    };
  }

  static deSerializeDirectUpload(
    data: DirectUploadPropsRaw,
  ): DirectUploadPropsSerialized {
    return {
      headers: data.headers,
      url: data.url,
    };
  }

  static deSerializePresignedUrl(
    response: PresignedUrlResponseRaw,
  ): PresignedUrlResponseSerialized {
    return {
      id: response.id,
      key: response.key,
      filename: response.filename,
      contentType: response.content_type,
      metaData: response.metaData,
      serviceName: response.service_name,
      byteSize: response.byte_size,
      checksum: response.checksum,
      createdAt: response.created_at,
      signedId: response.signed_id,
      attachableSgid: response.attachable_sgid,
      directUpload: UploadFileSerializer.deSerializeDirectUpload(
        response.direct_upload,
      ),
    };
  }
}

export { UploadFileSerializer };
