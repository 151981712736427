import { classnames } from 'helpers/utils';
import React, { useState } from 'react';
import { Button, ButtonSize, ButtonType } from 'common/button';
import { PlusIcon } from 'assets/icons';
import { Spinner } from 'common/spinner';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import styles from './store-components.module.scss';
import { StoreStateType } from '../store-reducer';

type CardItemProps = {
  image: string;
  name: string;
  description: string;
  price: number;
  id: number;
  state: StoreStateType;
  storeDispatch: any;
  handleSetImageToShow: () => void;
};

const CardItem: React.FC<CardItemProps> = ({
  image,
  name,
  description,
  price,
  id,
  state,
  storeDispatch,
  handleSetImageToShow,
}) => {
  const [showLabelAdded, setShowLabelAdded] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const handleShowLabelAdded = () => {
    setShowLabelAdded(true);
    setTimeout(() => {
      setShowLabelAdded(false);
    }, 1000);
  };

  const handleAddToCart = () => {
    storeDispatch({ type: 'IS_LOADING', isLoading: true });
    storeDispatch({
      type: 'ADDING_ITEM_CART',
      id,
      image,
      name,
      priceOneItem: price,
    });
    storeDispatch({ type: 'IS_LOADING', isLoading: false });
    handleShowLabelAdded();
  };
  return (
    <div className={classnames(styles.card)}>
      <button
        onClick={() => {
          handleSetImageToShow();
        }}
        type="button"
      >
        <img
          src={image}
          alt="Item Store"
          className={classnames(styles.cardImage)}
        />
      </button>
      <div className={classnames(styles.cardContent)}>
        <div className={classnames(styles.cardTitleAndSubtitle)}>
          <div
            className={
              isMobile
                ? 'text__body__semi__bold__large__textNeutral40'
                : classnames('text__title1__textNeutral40')
            }
          >
            {name} - ${price.toFixed(2)}
          </div>
          <div
            className={
              isMobile
                ? 'text__body__regular__small__textNeutral30'
                : 'text__body__regular__medium__textNeutral30'
            }
          >
            <p>{description}</p>
          </div>
        </div>
        <div className={styles.buttonAndLabel}>
          <Button
            buttonSize={ButtonSize.Small}
            onClick={() => handleAddToCart()}
            buttonType={ButtonType.Button}
          >
            <div className={styles.createButtonInside}>
              {state.isLoading ? (
                <Spinner color="white" className="spinner-inside-button" />
              ) : (
                <>
                  <PlusIcon />
                  <span>Add to cart</span>
                </>
              )}
            </div>
          </Button>
          {showLabelAdded && (
            <div className="text__body__regular__medium__primary50">Added!</div>
          )}
        </div>
      </div>
    </div>
  );
};
export { CardItem };
