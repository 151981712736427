type Item = {
  id: number;
  name: string;
  image: string;
  priceOneItem: number;
  mountOfItems: number;
};

type ShippingDetails = {
  fullName: string;
  email: string;
  addressFirstField: string;
  addressSecondField: string;
  zipCode: string;
  city: string;
  state: string;
};

export type ShippingDetailsErrorsType = {
  fullName: string;
  email: string;
  addressFirstField: string;
  zipCode: string;
  city: string;
  state: string;
};

export enum StorePages {
  selectItems = 'selectItems',
  checkout = 'checkout',
}

export enum CheckoutSteps {
  confirmOrder,
  orderDetails,
  paymentSummary,
}

type StoreStateType = {
  isLoading: boolean;
  itemsInCart: Item[] | undefined;
  actualPage: StorePages;
  checkoutStep: CheckoutSteps;
  shippingDetails: ShippingDetails;
  shippingCosts: ShippingCost[];
  orderId: number;
  shippingDetailsErrors: ShippingDetailsErrorsType;
};

type StoreAction =
  | { type: 'IS_LOADING'; isLoading: boolean }
  | {
      type: 'ADDING_ITEM_CART';
      id: number;
      name: string;
      priceOneItem: number;
      image: string;
    }
  | { type: 'REMOVE_ITEM_CART'; id: number }
  | { type: 'ONE_ITEM_MORE'; id: number }
  | { type: 'ONE_ITEM_LESS'; id: number }
  | { type: 'CHANGE_PAGE'; page: StorePages }
  | { type: 'CHANGE_CHECKOUT_STEP'; step: CheckoutSteps }
  | { type: 'UPDATE_SHIPPING_DETAILS'; field: string; value: string }
  | { type: 'SET_SHIPPING_COSTS'; shippingCosts: ShippingCost[] }
  | { type: 'GO_INITIAL_STATE' }
  | { type: 'SET_ORDER_NUMBER'; orderId: number }
  | {
      type: 'UPDATE_SHIPPING_ERRORS';
      shippingDetailsErrors: ShippingDetailsErrorsType;
    };

const shippingDetailsErrors: ShippingDetailsErrorsType = {
  fullName: '',
  email: '',
  addressFirstField: '',
  zipCode: '',
  city: '',
  state: '',
};

const StoreInitialState: StoreStateType = {
  isLoading: false,
  itemsInCart: [],
  actualPage: StorePages.selectItems,
  checkoutStep: CheckoutSteps.confirmOrder,
  shippingDetails: {
    fullName: '',
    email: '',
    addressFirstField: '',
    addressSecondField: '',
    zipCode: '',
    city: '',
    state: '',
  },
  shippingCosts: [],
  orderId: 0,
  shippingDetailsErrors,
};

const StoreReducer = (
  state: StoreStateType,
  action: StoreAction,
): StoreStateType => {
  switch (action.type) {
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'ADDING_ITEM_CART':
      if (state.itemsInCart?.find((elem) => elem.id === action.id)) {
        return {
          ...state,
          itemsInCart: state.itemsInCart?.map((item) => {
            if (item.id === action.id) {
              return { ...item, mountOfItems: item.mountOfItems + 1 };
            }
            return item;
          }),
        };
      }
      return {
        ...state,
        itemsInCart: state.itemsInCart?.concat({
          id: action.id,
          name: action.name,
          priceOneItem: action.priceOneItem,
          image: action.image,
          mountOfItems: 1,
        }),
      };

    case 'REMOVE_ITEM_CART':
      return {
        ...state,
        itemsInCart: state.itemsInCart?.filter((item) => item.id !== action.id),
      };
    case 'ONE_ITEM_MORE':
      return {
        ...state,
        itemsInCart: state.itemsInCart?.map((item) => {
          if (item.id === action.id) {
            return { ...item, mountOfItems: item.mountOfItems + 1 };
          }
          return item;
        }),
      };
    case 'ONE_ITEM_LESS':
      return {
        ...state,
        itemsInCart: state.itemsInCart?.map((item) => {
          if (item.id === action.id) {
            return { ...item, mountOfItems: item.mountOfItems - 1 };
          }
          return item;
        }),
      };
    case 'CHANGE_PAGE':
      return {
        ...state,
        actualPage: action.page,
      };
    case 'CHANGE_CHECKOUT_STEP':
      return {
        ...state,
        checkoutStep: action.step,
      };
    case 'UPDATE_SHIPPING_DETAILS':
      return {
        ...state,
        shippingDetails: {
          ...state.shippingDetails,
          [action.field]: action.value,
        },
      };
    case 'SET_SHIPPING_COSTS':
      return {
        ...state,
        shippingCosts: action.shippingCosts,
      };
    case 'GO_INITIAL_STATE':
      return {
        ...StoreInitialState,
      };
    case 'SET_ORDER_NUMBER':
      return {
        ...state,
        orderId: action.orderId,
      };
    case 'UPDATE_SHIPPING_ERRORS':
      return {
        ...state,
        shippingDetailsErrors: action.shippingDetailsErrors,
      };
    default:
      return state;
  }
};

export { StoreInitialState, StoreReducer };
export type { StoreAction, StoreStateType, Item };
