import { Button, ButtonStyle } from 'common/button';
import { ImageSupportedExtensions } from 'config/constants';
import {
  bytesToMegabytes,
  formatFileExtensions,
  getFileExtension,
  isSupportedExtension,
  isValidSize,
} from 'helpers/file-utils';
import { useFileUpload } from 'hooks/use-file-upload';
import { ChangeEvent, useState, useRef, useEffect, useContext } from 'react';
import { AlertTriangleIcon, InfoIcon } from 'assets/icons';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import styles from './browse-file.module.scss';

type BrowseFileProps = {
  maxFileSizeInMb: number;
  onSuccessfulUpload: (file: FileResponseType) => void;
  handleFile?: (fileName: File) => void;
  content: React.ReactNode;
  showInfoIcon?: boolean;
  disabled?: boolean;
  showErrorNotification?: boolean;
};

const BrowseFile: React.FC<BrowseFileProps> = ({
  maxFileSizeInMb,
  onSuccessfulUpload,
  content,
  showInfoIcon = true,
  handleFile,
  disabled,
  showErrorNotification = false,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [hasError, setHasError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { uploadS3File, response } = useFileUpload();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    if (response) {
      onSuccessfulUpload(response);
    }
  }, [response]);

  const uploadErrors = {
    generic: 'Oh no, something went wrong.',
    size: `File is too big. Maximum file size accepted is ${maxFileSizeInMb}MB.`,
    format: `File format not supported. Please upload only ${formatFileExtensions(
      ImageSupportedExtensions,
    ).toUpperCase()} files.`,
  };

  const isValidFile = (file: File) => {
    const fileExtension = getFileExtension(file.name);
    if (
      !isSupportedExtension(
        fileExtension.toLocaleLowerCase(),
        ImageSupportedExtensions,
      )
    ) {
      setHasError(true);
      setErrorMessage(uploadErrors.format);
      return false;
    }
    if (!isValidSize(maxFileSizeInMb, bytesToMegabytes(file.size))) {
      setHasError(true);
      setErrorMessage(uploadErrors.size);
      return false;
    }
    return true;
  };

  const handleFileUpload = async (file: File) => {
    if (file && isValidFile(file)) {
      setHasError(false);
      setErrorMessage('');
      if (handleFile) {
        handleFile(file);
      }
      setIsUploading(true);
      uploadS3File(file!);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    handleFileUpload(file!);
  };

  const infoIconMessage = () => {
    if (showInfoIcon) {
      return (
        <>
          <InfoIcon className={styles.infoIcon} />
          <div className="text__body__regular__small__textNeutral30">
            You can attach a related image to your clip to help you tell your
            story.
          </div>
        </>
      );
    }
    return null;
  };

  const errorMessageContent = () => {
    if (showErrorNotification) {
      if (errorMessage === uploadErrors.format) {
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'File format not supported',
            message: `Please upload only ${formatFileExtensions(
              ImageSupportedExtensions,
            ).toUpperCase()} files.`,
            type: NotificationType.Error,
          }),
        });
        setHasError(false);
        return null;
      }
      if (errorMessage === uploadErrors.size) {
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'File is too big',
            message: `Maximum file size accepted is ${maxFileSizeInMb}MB.`,
            type: NotificationType.Error,
          }),
        });
        setHasError(false);
        return null;
      }
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Oh no',
          message: 'Something went wrong.',
          type: NotificationType.Error,
        }),
      });
      setHasError(false);
      return null;
    }
    return (
      <div className={styles.errorRow}>
        <AlertTriangleIcon className={styles.alertIcon} />
        <div className="text__body__regular__small__textNeutral30">
          {errorMessage}
        </div>
      </div>
    );
  };

  return (
    <>
      <Button
        key={uploadErrors.format}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => fileRef.current?.click()}
        disabled={isUploading || disabled}
      >
        {isUploading ? 'Uploading ...' : content}
      </Button>
      <input
        type="file"
        ref={fileRef}
        accept={formatFileExtensions(ImageSupportedExtensions)}
        onChange={(e) => {
          handleInputChange(e);
        }}
        hidden
      />
      <div className={styles.rightSide}>
        {hasError ? errorMessageContent() : infoIconMessage()}
      </div>
    </>
  );
};

export { BrowseFile };
