import React from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Line } from 'common/line';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import styles from './form-footer.module.scss';

type FormFooterProps = {
  readyToSubmit: boolean;
  cancelButton?: string;
  successButton?: string;
  successButtonStyle?: ButtonStyle;
  cancelButtonStyle?: ButtonStyle;
  successButtonSize?: ButtonSize;
  cancelButtonSize?: ButtonSize;
  includeLine?: boolean;
  onClickCancel: () => void;
  onClickSuccess: () => void;
};

const FormFooter: React.FC<FormFooterProps> = ({
  readyToSubmit = false,
  cancelButton = 'Cancel',
  successButton = 'Accept',
  successButtonStyle = ButtonStyle.PrimaryRoundedFilled,
  cancelButtonStyle = ButtonStyle.PrimaryRoundedStroke,
  successButtonSize = ButtonSize.Large,
  cancelButtonSize = ButtonSize.Large,
  includeLine = true,
  onClickCancel,
  onClickSuccess,
}) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.xs}px)`);

  return (
    <div className={styles.footerContainer}>
      {includeLine && <Line className={styles.line} />}
      <div className={styles.buttonContainer}>
        <Button
          buttonSize={mobile ? ButtonSize.Small : cancelButtonSize}
          buttonStyle={cancelButtonStyle}
          className={styles.button}
          onClick={onClickCancel}
        >
          {cancelButton}
        </Button>
        <Button
          buttonSize={mobile ? ButtonSize.Small : successButtonSize}
          buttonStyle={successButtonStyle}
          className={styles.button}
          disabled={!readyToSubmit}
          onClick={onClickSuccess}
        >
          {successButton}
        </Button>
      </div>
    </div>
  );
};

export { FormFooter };
