import { useState } from 'react';
import { LocalStorageApi } from 'helpers/local-storage';
import { UploadFileController } from 'networking/controllers/upload-file.controller';
import { calculateMd5File } from 'helpers/calculate-file-md5';

export const useFileUpload = () => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [query, setQuery] = useState<XMLHttpRequest>();
  const [response, setResponse] = useState<FileResponseType>();
  const [error, setError] = useState(false);
  const [errorWithId, setErrorWithId] = useState<ErrorWithId>();

  // NOTE: this request has to be made using
  // XMLHttpRequest instead of fetch since fetch does not
  // support upload progress.
  // https://usefulangle.com/post/321/javascript-fetch-upload-progress

  const uploadS3File = async (file: File, requestId?: string) => {
    const setS3Headers = (
      request: XMLHttpRequest,
      headersObject: HeadersResponse,
    ) => {
      Object.keys(headersObject).forEach((key: string) => {
        request.setRequestHeader(
          key,
          headersObject[key as keyof typeof headersObject],
        );
      });
    };

    try {
      const md5 = await calculateMd5File(file);

      const presignedUrlResponse = await UploadFileController.presignedUrl(
        file,
        md5,
      );

      const apiUrl = presignedUrlResponse.directUpload.url;

      // We need to get the raw bytes
      const buffer = await file.arrayBuffer();
      const binaryFile = new Uint8Array(buffer);

      const request = new XMLHttpRequest();
      setQuery(request);
      request.open('PUT', apiUrl);

      request.setRequestHeader(
        'X-CSRF-Token',
        LocalStorageApi.get('csrfToken'),
      );
      setS3Headers(request, presignedUrlResponse.directUpload.headers);

      // --- Listeners ---
      request.upload.addEventListener('progress', (e) => {
        setUploadPercentage((e.loaded / e.total) * 100);
      });

      request.addEventListener('load', async () => {
        if (request.status >= 200 && request.status < 300) {
          try {
            const url = URL.createObjectURL(file);
            if (requestId) {
              setResponse({
                requestId,
                id: presignedUrlResponse.id,
                signedId: presignedUrlResponse.signedId,
                url,
              });
            } else {
              setResponse({
                id: presignedUrlResponse.id,
                signedId: presignedUrlResponse.signedId,
                url,
              });
            }
          } catch (err) {
            if (requestId) {
              setErrorWithId({ requestId, error: true });
            } else {
              setError(true);
            }
            setUploadPercentage(0);
          }
        } else {
          if (requestId) {
            setErrorWithId({ requestId, error: true });
          } else {
            setError(true);
          }
          setUploadPercentage(0);
        }
      });

      request.addEventListener('abort', () => {
        setUploadPercentage(0);
        if (requestId) {
          setErrorWithId({ requestId, error: false });
        } else {
          setError(false);
        }
      });

      request.addEventListener('error', () => {
        setUploadPercentage(0);
        if (requestId) {
          setErrorWithId({ requestId, error: true });
        } else {
          setError(true);
        }
      });

      setError(false);
      request.send(binaryFile);
    } catch (err) {
      if (requestId) {
        setErrorWithId({ requestId, error: true });
      } else {
        setError(true);
      }
    }
  };

  const cancelRequest = () => {
    query?.abort();
  };

  return {
    uploadS3File,
    uploadPercentage,
    cancelRequest,
    response,
    error,
    errorWithId,
  };
};
