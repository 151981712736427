import { AlertTriangleIcon } from 'assets/icons';
import styles from './alert-long-recording-run-out.module.scss';

const AlertLongRecordingRunOut: React.FC = () => {
  const showAlertRecordingRunOut = () => (
    <div className={styles.alertLongVideoContainer}>
      <div className={styles.warningIcon}>
        <AlertTriangleIcon />
      </div>
      <div className="text__body__regular__medium__textNeutral00">
        {' '}
        Recording time is running out{' '}
      </div>
    </div>
  );

  return showAlertRecordingRunOut();
};

export { AlertLongRecordingRunOut };
