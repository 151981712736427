class RecordingGuest {
  id: number;

  album_id: number;

  album_title: string;

  email: string;

  name: string;

  expiration_date: string;

  token: string;

  album_url: string;

  constructor(params: RecordingGuestType) {
    this.id = params.id;
    this.name = params.name;
    this.email = params.email;
    this.album_id = params.album_id;
    this.album_title = params.album_title;
    this.expiration_date = params.expiration_date;
    this.token = params.token;
    this.album_url = params.album_url;
  }
}

export { RecordingGuest };
