import { CreateAlbumSteps } from 'common/enums';
import { Line } from 'common/line';
import { classnames } from 'helpers/utils';
import styles from './steps-line.module.scss';

type StepsLinesProps = {
  isMobileSize: boolean;
  step: number;
};

const StepsLine: React.FC<StepsLinesProps> = ({ isMobileSize, step }) => {
  const showStepBox = (
    stepNumber: CreateAlbumSteps,
    stepText: string,
    actualStep: number,
  ) => (
    <div
      className={classnames(styles.labelBox, {
        [styles.labelBoxDisabled]: stepNumber !== actualStep,
      })}
    >
      <div
        className={classnames(
          isMobileSize
            ? 'text__heading6__primary50'
            : 'text__heading5__primary50',
          { [styles.numberDisabled]: step === actualStep },
        )}
      >
        {stepNumber}
      </div>
      <div
        className={classnames(
          isMobileSize
            ? 'text__body__semi__bold__tiny__textNeutral30'
            : 'text__body__semi__bold__small__textNeutral30',
          { [styles.textDisabled]: step === actualStep },
        )}
      >
        {stepText}
      </div>
    </div>
  );

  return (
    <div className={styles.fullBox}>
      <Line className={styles.line} />
      <div className={styles.stepsBox}>
        {showStepBox(
          CreateAlbumSteps.subscriptionType,
          'Subscription type',
          step,
        )}
        {showStepBox(CreateAlbumSteps.information, ' Album information', step)}
      </div>
    </div>
  );
};

export { StepsLine };
