import { createContext, useReducer, ReactNode, Dispatch, useMemo } from 'react';

import {
  SensorSelectionReducer,
  initialState,
  SensorSelection,
  Action,
} from './context-reducer';

type SensorSelectionProviderProps = {
  children: ReactNode;
};

const SensorSelectionContext = createContext<{
  sensorSelection: SensorSelection;
  updateSensorSelection: Dispatch<Action>;
}>({
  sensorSelection: initialState,
  updateSensorSelection: () => null,
});

const SensorSelectionProvider = ({
  children,
}: SensorSelectionProviderProps) => {
  const [sensorSelection, updateSensorSelection] = useReducer(
    SensorSelectionReducer,
    initialState,
  );

  const providerValue = useMemo(
    () => ({ sensorSelection, updateSensorSelection }),
    [sensorSelection, updateSensorSelection],
  );

  return (
    <SensorSelectionContext.Provider value={providerValue}>
      {children}
    </SensorSelectionContext.Provider>
  );
};

export { SensorSelectionContext, SensorSelectionProvider };
