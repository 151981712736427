import { InfoIcon, CrossIcon } from 'assets/icons';
import { VideoCallRole } from 'hooks/use-videocall-websocket';
import styles from './warning-user-left-call.module.scss';

type WarningUserLeftCallProps = {
  notifyClose: () => void;
  role: VideoCallRole;
};

const WarningUserLeftCall: React.FC<WarningUserLeftCallProps> = ({
  notifyClose,
  role,
}) => (
  <div className={styles.warningUserLeftCallContainer}>
    <InfoIcon className={styles.warningIcon} />
    <span className="text__body__regular__medium__textNeutral00">
      {' '}
      The {role} has left the call
    </span>
    <button className={styles.closeIcon} type="button" onClick={notifyClose}>
      <CrossIcon />
    </button>
  </div>
);

export { WarningUserLeftCall };
