import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AllClipsList } from 'common/all-clips-list';
import { NavSections, NotificationType } from 'common/enums';
import { Spinner } from 'common/spinner';
import { VideoClip } from 'common/video-clip';
import { appActions, AppContext } from 'context';
import {
  classnames,
  getIndexClipSelectedInList,
  isChromeVersionWithIssues,
} from 'helpers/utils';
import { Album } from 'models/album';
import { Clip } from 'models/clip';
import { MetaTags } from 'models/meta-tags';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { goToPage, RouteName } from 'routes';

import { NotificationObject } from 'models/notificationObject';
import { AudioClip } from 'common/audio-clip';
import { AudioPlayer } from 'common/audio-player';
import { SmallPicture } from 'common/small-picture';
import { addLinks } from 'helpers/text-helper';
import styles from './album-detail-guest.module.scss';

type ParamType = {
  id: string;
};

const AlbumDetailGuest: React.FC = () => {
  const location = useLocation();
  const { dispatch } = useContext(AppContext);
  const [album, setAlbum] = useState<AlbumType>(new Album());

  const [clipSelected, setClipSelected] = useState<Clip>(new Clip());
  const [autoPlayingVideo, setAutoPlayingVideo] = useState(false);

  const [fetching, setFetching] = useState(true);

  const { id: albumId } = useParams<ParamType>();
  const setApiError = useHandleApiError(() => {
    goToPage(RouteName.NotFoundRedirect);
  }, false);

  const fetchAlbum = async () => {
    try {
      if (albumId) {
        const response = await AlbumsController.getSharedAlbum(+albumId);
        const { coverThumbnailUrl, title } = response;
        const description = `Get to know ${title} ’s life story`;

        dispatch({
          type: appActions.SET_META_TAGS,
          metaTags: new MetaTags({ description, image: coverThumbnailUrl }),
        });

        if (response.clips?.length) {
          const firstClip = response.clips[0];
          const queryParams = new URLSearchParams(location.search);
          const clipSelection =
            response.clips.find(
              (c) => c.id === parseInt(queryParams.get('clipId') || '', 10),
            ) || firstClip;
          setClipSelected(clipSelection);
        }

        setAlbum(response);
      } else {
        goToPage(RouteName.NotFoundRedirect);
      }
    } catch (err: any) {
      setApiError(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.albums,
    });
    fetchAlbum();
  }, []);

  useEffect(() => {
    if (isChromeVersionWithIssues()) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Problems with your browser version',
          message:
            'Your browser might experience issues playing back videos. Consider updating your browser to get the best experience.',
          type: NotificationType.Error,
          limitTimeMs: 15000,
        }),
      });
    }
  }, []);

  const showDefaultPicture = () => (
    <div className={styles.defaultpicture}>
      <Logo className={styles.logo} />
    </div>
  );

  const showClipInfo = () => (
    <div className={styles.clipInfo}>
      <div className={styles.nameAndDescription}>
        <div className={styles.nameRow}>
          <div className="text__heading5__textNeutral40">
            {clipSelected.name}
          </div>
        </div>
        <div
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            styles.descriptionRow,
          )}
        >
          {addLinks(clipSelected.description)}
        </div>
      </div>
      {clipSelected.customThumbnail && !clipSelected.isAudioOnly && (
        <SmallPicture picture={clipSelected.customThumbnail} />
      )}
    </div>
  );

  const handleClipAction = (action: string, clip: ClipType) => {
    switch (action) {
      case 'select':
        setAutoPlayingVideo(true);
        setClipSelected(clip);
        break;
      default:
        throw new Error('Unhandled action type');
    }
  };

  const assignNewSelectedClipAfterFinishing = (
    clipList: ClipType[],
    indexClipSelected: number,
  ) => {
    if (indexClipSelected !== clipList.length - 1) {
      setAutoPlayingVideo(true);

      const newSelectedClip = clipList[indexClipSelected + 1];
      setClipSelected(newSelectedClip);
    } else {
      setAutoPlayingVideo(false);
    }
  };

  const handleOnEndedClip = () => {
    const clipsListCopy = [...album.clips!];

    const clipSelectedIndex = getIndexClipSelectedInList(
      clipsListCopy,
      clipSelected,
    );
    assignNewSelectedClipAfterFinishing(clipsListCopy, clipSelectedIndex!);
  };

  const showHeaderRow = () => (
    <div className={styles.backAndHeaderRow}>
      <div className={styles.headerRow}>
        <div className={styles.leftSide}>
          <div className={styles.pictureRounded}>
            {album.coverThumbnailUrl ? (
              <img
                src={album.coverThumbnailUrl}
                className={styles.picture}
                alt="logo"
              />
            ) : (
              showDefaultPicture()
            )}
          </div>
          <div className={styles.titles}>
            <div className="text__body__regular__overline__textNeutral30">
              {' '}
              ALBUM{' '}
            </div>
            <span
              className={classnames(
                styles.albumName,
                'text__heading4__textNeutral40',
              )}
            >
              {album.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  const showClipAudioOnly = () =>
    clipSelected.customThumbnail ? (
      <AudioClip
        videoUrl={clipSelected.videoUrl}
        image={clipSelected.customThumbnail}
        alt=""
      />
    ) : (
      <AudioPlayer videoUrl={clipSelected.videoUrl} />
    );
  const showClipsBody = () => (
    <div className={styles.fullBody}>
      <div className={styles.clipVideoAndDescription}>
        {clipSelected.isAudioOnly ? (
          showClipAudioOnly()
        ) : (
          <VideoClip
            autoPlaying={autoPlayingVideo}
            clipSelected={clipSelected}
            notifyOnEnded={() => handleOnEndedClip()}
          />
        )}
        {showClipInfo()}
      </div>
      <div className={styles.clipsListAndGsStore}>
        <AllClipsList
          album={album}
          clipSelected={clipSelected}
          publicView
          notifyClipAction={(action: string, clip: ClipType) => {
            handleClipAction(action, clip);
          }}
        />
      </div>
    </div>
  );

  const showContentView = () => (
    <div className={styles.fullAlbumContainer}>
      {showHeaderRow()}
      {showClipsBody()}
    </div>
  );

  return (
    <div className={globalStyles.loggedMainContainer}>
      {!fetching ? showContentView() : <Spinner />}
    </div>
  );
};

export { AlbumDetailGuest };
