import { Pagination } from './pagination';

class ContributorsPagination {
  data: ContributorElementType[];

  pagination: Pagination;

  constructor(params?: ContributorsPaginationType) {
    this.data = params?.data || [];
    this.pagination = params?.pagination || new Pagination();
  }
}

export { ContributorsPagination };
