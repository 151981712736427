import { LayoutType, withLayout } from 'hocs/with-layout';
import { withAuth } from 'hocs/auth/with-auth';
import { Contributors } from './contributors';

const WrappedPage = withAuth(Contributors);
const WrappedContributorsView = withLayout(
  LayoutType.WithNavAndFooter,
  WrappedPage,
);

export { WrappedContributorsView as Contributors };
