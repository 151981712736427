import { SubscriptionType } from 'models/subscription-type';
import React, { useMemo } from 'react';
import { classnames } from 'helpers/utils';
import { TagIcon } from 'assets/icons';
import styles from './subscription-card.module.scss';

const RoundLabel = ({ label }: { label: string }) => (
  <div
    className={classnames(
      'text__body__semi__bold__overline__textNeutral30',
      styles.roundLabel,
    )}
  >
    {label}
  </div>
);

type SubscriptionCardProps = {
  subscription: SubscriptionType;
  cardSelected?: boolean;
  subscriptionActive?: boolean;
  betterPrice: number | null;
  className?: string;
  notifyClickCard?: () => void;
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  cardSelected = false,
  subscriptionActive = false,
  betterPrice,
  className = '',
  notifyClickCard = () => {},
}) => {
  const { name, price } = subscription;

  const getPrice = () => {
    const getNormalPrice = () => (
      <span>
        ${price}
        &nbsp;
      </span>
    );

    const getModifiedPrice = () => (
      <>
        <span className={styles.oldPrice}>${price}</span>
        &nbsp;
        <span className={styles.discountedPrice}>
          ${betterPrice}
          &nbsp;
        </span>
      </>
    );

    if (betterPrice === null) {
      return getNormalPrice();
    }

    return getModifiedPrice();
  };

  const showTagDiscountApplied = () => (
    <div className={styles.cardTagIcon}>
      <TagIcon />
    </div>
  );

  const showDescription = (sub: SubscriptionType) => (
    <div className={styles.description}>
      {sub.features.map((feature: string) => (
        <span
          key={feature}
          className="text__body__regular__small__textNeutral30"
        >
          {feature}
        </span>
      ))}
    </div>
  );

  const showPrice = (sub: SubscriptionType) => (
    <div className={styles.price}>
      <span className="text__title2__textNeutral40">{getPrice()}</span>
      <span className="text__body__regular__small__textNeutral30">
        {sub.paymentDescription}
      </span>
    </div>
  );

  const roundLabelText = useMemo(() => {
    if (subscriptionActive) {
      return 'Active';
    }
    if (subscription.onlyFirstAlbum) {
      return 'First time only';
    }

    return '';
  }, [subscriptionActive, subscription.onlyFirstAlbum]);

  return (
    <div
      className={classnames(
        styles.content,
        {
          [styles.selected]: cardSelected,
        },
        className,
      )}
    >
      <button
        type="button"
        className={styles.cardButton}
        onClick={() => notifyClickCard()}
        data-cy={`subscription-card-${name}`}
      >
        <div className={styles.topContent}>
          <div className={styles.topRow}>
            <div className={styles.leftSide}>
              <div className={styles.nameBox}>
                <div className={styles.radioAndName}>
                  <div
                    className={classnames(
                      styles.name,
                      cardSelected
                        ? 'text__body__semi__bold__large__primary50'
                        : 'text__body__semi__bold__large__textNeutral40',
                    )}
                  >
                    {name}
                  </div>
                </div>
              </div>
              {roundLabelText && <RoundLabel label={roundLabelText} />}
            </div>
            {betterPrice !== null && showTagDiscountApplied()}
          </div>
          {showDescription(subscription)}
        </div>
        {showPrice(subscription)}
      </button>
    </div>
  );
};

export { SubscriptionCard };
