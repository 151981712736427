import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { TextArea } from 'common/text-area';
import {
  ImageSupportedExtensions,
  descriptionClipMaxLength,
  mbAlbumCoverMaxSize,
  questionsMaxLength,
} from 'config/constants';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Toggle } from 'common/toggle';
import { AlbumsIcon, DeleteIcon } from 'assets/icons';
import { z } from 'zod';
import { BrowseFile } from 'common/browse-file-button';
import { AudioClip } from 'common/audio-clip';
import { FileUploader } from 'common/file-uploader';
import { AudioPlayer } from 'common/audio-player';
import { ModalWarning } from 'common/modal-warning';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { classnames } from 'helpers/utils';
import styles from './review-video-card.module.scss';

type ReviewVideosProps = {
  videoUrl: string;
  clipName: string;
  handleSaveClip: (requestId: string) => Promise<boolean>;
  requestId: string;
};

const FormStateSchema = z.object({
  name: z.string(),
  description: z.string(),
});

type FormStateType = z.infer<typeof FormStateSchema>;

const ReviewVideoCard: React.FC<ReviewVideosProps> = ({
  videoUrl,
  clipName,
  requestId,
  handleSaveClip,
}) => {
  const {
    state: { selectedQuestion },
  } = useContext(SelfRecordingContext);

  const [formState, setFormState] = useState<FormStateType>({
    name: clipName,
    description:
      selectedQuestion && selectedQuestion.description
        ? selectedQuestion.description
        : '',
  });
  const { dispatch: selfRecordingDispatch } = useContext(SelfRecordingContext);
  const [errors, setErrors] = useState<FormStateType>({
    name: '',
    description: '',
  });
  const [isAudioOnly, setIsAudioOnly] = useState<boolean>(
    selectedQuestion ? selectedQuestion.isAudioOnly : false,
  );

  const [showDeleteClipModal, setShowDeleteClipModal] =
    useState<boolean>(false);

  const [showDeletePictureModal, setShowDeletePictureModal] =
    useState<boolean>(false);

  const [haveImageAttached, setHaveImageAttached] =
    useState<FileResponseType | null>(null);

  const [disableCard, setDisableCard] = useState<boolean>(false);

  const [startAnimation, setStartAnimation] = useState<boolean>(false);

  const [imageFile, setImageFile] = useState<File | null>(null);

  const animation = useRef<HTMLDivElement>(null);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormState((prevUserState) => ({ ...prevUserState, [name]: value }));
  };

  const validateField = () => {
    setErrors(() => ({
      name: '',
      description: '',
    }));

    if (
      !z
        .string()
        .max(descriptionClipMaxLength - 1)
        .safeParse(formState.description).success
    ) {
      setErrors((prevState) => ({
        ...prevState,
        description:
          `Description can't have more than ${descriptionClipMaxLength} characters`.toUpperCase(),
      }));
    }

    if (!z.string().min(1).safeParse(formState.name.trim()).success) {
      setErrors((prevState) => ({
        ...prevState,
        name: `Name  shouldn't be empty.`.toUpperCase(),
      }));
    }

    if (
      !z
        .string()
        .max(questionsMaxLength - 1)
        .safeParse(formState.name).success
    ) {
      setErrors((prevState) => ({
        ...prevState,
        name: `Name can't have more than ${questionsMaxLength} characters`.toUpperCase(),
      }));
    }
  };

  useEffect(() => {
    selfRecordingDispatch({
      type: selfRecordingActions.updateAudioOnly,
      updateAudioOnly: {
        requestId,
        isAudioOnly,
      },
    });
  }, [isAudioOnly]);

  useEffect(() => {
    validateField();
    selfRecordingDispatch({
      type: selfRecordingActions.updateInformation,
      updateInformation: {
        requestId,
        description: formState.description,
        name: formState.name,
      },
    });
  }, [formState]);

  const handleImageUpload = (file: FileResponseType) => {
    selfRecordingDispatch({
      type: selfRecordingActions.updateInformation,
      updateInformation: {
        requestId: file.requestId ?? requestId,
        imageId: file.signedId,
      },
    });
    setHaveImageAttached(file);
  };

  const modalConfirmationDeletePicture = () => (
    <ModalWarning
      title="Delete picture"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          Are you sure you want to delete this picture?
        </div>
      }
      successButtonText="Delete picture"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowDeletePictureModal(false)}
      successFn={() => {
        setHaveImageAttached(null);
        setShowDeletePictureModal(false);
      }}
    />
  );

  const removeClip = (event: AnimationEvent) => {
    if (event.animationName.includes('reduce-height')) {
      selfRecordingDispatch({
        type: selfRecordingActions.deleteVideo,
        videoToDelete: requestId,
      });
    }
  };

  const handleDeleteClip = () => {
    setStartAnimation(true);
    // TODO: Delete the blob when the endpoint is ready to use
    setShowDeleteClipModal(false);
  };

  const modalDeletionClip = () => (
    <ModalWarning
      title="Delete clip"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          Are you sure you want to delete this clip? Deleted clips cannot be
          recovered.
        </div>
      }
      successButtonText="Delete clip"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowDeleteClipModal(false)}
      successFn={handleDeleteClip}
    />
  );

  useEffect(() => {
    if (startAnimation) {
      animation.current?.addEventListener('animationend', removeClip);
    }
  }, [startAnimation]);

  const showButtonImage = () => {
    if (haveImageAttached) {
      return (
        <div className={styles.imageAttached}>
          <a href={haveImageAttached.url} target="_blank" rel="noreferrer">
            <span className="text__body__link__small__textNeutral30">
              {imageFile?.name || 'Image attached'}
            </span>
          </a>
          <button type="button" onClick={() => setShowDeletePictureModal(true)}>
            <DeleteIcon className={styles.icon} />
          </button>
        </div>
      );
    }
    if (!isAudioOnly) {
      return (
        <BrowseFile
          maxFileSizeInMb={mbAlbumCoverMaxSize}
          onSuccessfulUpload={(file) => handleImageUpload(file)}
          handleFile={(file) => {
            setImageFile(file);
          }}
          content={
            <div className={styles.buttonAndIcon}>
              <AlbumsIcon className={styles.icon} />
              <span> Attach image</span>
            </div>
          }
          showInfoIcon={false}
          disabled={disableCard}
          showErrorNotification
        />
      );
    }
    return null;
  };

  const showAudioOption = () =>
    haveImageAttached ? (
      <div className={styles.imageUploaded}>
        <AudioClip
          videoUrl={videoUrl}
          image={haveImageAttached?.url}
          alt=""
          className={styles.imageAndAudioPlayer}
          classNameIcon={styles.expandIcon}
        />
      </div>
    ) : (
      <div className={styles.fileUploaderContainer}>
        <div className={styles.fileUploader}>
          <FileUploader
            uploadingMessage="Uploading photo"
            supportedExtensions={ImageSupportedExtensions}
            maxFileSizeInMb={mbAlbumCoverMaxSize}
            onLoading={() => {}}
            onSuccess={handleImageUpload}
            onError={() => {}}
            onCancel={() => {}}
            message="Drag and drop a related image to your clip to help tell your story or"
          />
        </div>

        <AudioPlayer videoUrl={videoUrl} />
      </div>
    );

  return (
    <>
      {showDeleteClipModal && modalDeletionClip()}
      {showDeletePictureModal && modalConfirmationDeletePicture()}
      <div
        className={classnames(
          styles.videoCard,
          `${startAnimation ? styles.savingAnimation : ''}`,
        )}
        ref={animation}
      >
        <div
          className={classnames(styles.videoOrAudio, {
            [styles.videoBackground]: !isAudioOnly,
          })}
        >
          {isAudioOnly ? (
            showAudioOption()
          ) : (
            <video
              src={videoUrl}
              controls
              className={classnames(
                styles.video,
                `${disableCard ? styles.opacitySaving : ''}`,
              )}
            >
              <track kind="captions" />
            </video>
          )}
        </div>
        <div className={styles.videoCardContent}>
          <div>
            <TextArea
              id="name"
              name="name"
              label="Clip name"
              placeholder="Clip name"
              value={formState.name}
              rows={2}
              counter
              maxLength={questionsMaxLength}
              errorMessage={errors.name}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(e);
              }}
              disabled={disableCard}
            />
          </div>
          <div>
            <TextArea
              id="description"
              name="description"
              label="Clip description"
              placeholder="Description"
              value={formState.description}
              rows={6}
              counter
              maxLength={descriptionClipMaxLength}
              errorMessage={errors.description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(e);
              }}
              disabled={disableCard}
            />
          </div>
          <div className={styles.toggleAndPhoto}>
            <div className={styles.toggle}>
              <Toggle
                id={`switch-${requestId}`}
                name="AudioOnly"
                label="Audio only"
                checked={isAudioOnly}
                onChangeFn={() => {
                  setIsAudioOnly(!isAudioOnly);
                }}
                disabled={disableCard}
              />
              <span className="text__body__regular__medium__textNeutral30">
                Make clip audio only
              </span>
            </div>
            <div>{showButtonImage()}</div>
          </div>
          <div className={styles.buttons}>
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.RedGhost}
              disabled={disableCard}
              onClick={() => setShowDeleteClipModal(true)}
              className={styles.deleteButton}
            >
              Delete clip
            </Button>
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.PrimaryFilled}
              className={styles.saveButton}
              onClick={async () => {
                setDisableCard(true);
                const isSaved = await handleSaveClip(requestId);
                if (isSaved) {
                  setStartAnimation(true);
                } else {
                  setDisableCard(false);
                }
              }}
              disabled={disableCard}
            >
              <div className={styles.buttonText}>Save clip</div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export { ReviewVideoCard };
