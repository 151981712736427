import { Modal } from 'common/modal/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './modal-success-buying.module.scss';

type ModalSuccessBuyingType = {
  onClose: () => void;
  information: string;
};

const ModalSuccessBuying = ({
  onClose,
  information,
}: ModalSuccessBuyingType) => (
  <Modal onClose={onClose} contentClassName={styles.modal}>
    <div className={styles.modalInformation}>
      <span className="text__title2__textNeutral40">
        Your purchase was successful!
      </span>
      <p className="text__body__regular__small__textNeutral30">{information}</p>
      <div className={styles.actionsButtons}>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          onClick={onClose}
        >
          Ok
        </Button>
      </div>
    </div>
  </Modal>
);

export { ModalSuccessBuying };
