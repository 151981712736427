import { classnames } from 'helpers/utils';
import { Button, ButtonSize } from 'common/button';
import { getMountOfItems, getTotalPrice } from 'helpers/store-helper';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import React from 'react';
import { Accordion } from 'common/accordion';
import { ShoppingCartWidthLineIcon } from 'assets/icons';
import styles from './store-components.module.scss';
import { CardItemInCart } from './itemInCart';
import { Item, StorePages, StoreStateType } from '../store-reducer';

type CartProps = {
  state: StoreStateType;
  dispatch: React.Dispatch<any>;
};

const itemsAndButton = (
  state: StoreStateType,
  handleGoToCheckout: Function,
  isMobile: boolean,
) => (
  <>
    <div className={classnames(styles.leftSide)}>
      <div
        className={
          isMobile
            ? 'text__body__regular__small__textNeutral30'
            : classnames('text__body__regular__large__textNeutral30')
        }
      >
        Items: {getMountOfItems(state)}
      </div>
      <div
        className={
          isMobile
            ? 'text__body__regular__small__textNeutral40'
            : classnames('text__body__regular__large__textNeutral40')
        }
      >
        $ {getTotalPrice(state).toFixed(2)}
      </div>
    </div>
    <div className={classnames(styles.rigthSide)}>
      <Button
        buttonSize={isMobile ? ButtonSize.Small : ButtonSize.Medium}
        disabled={state.itemsInCart?.length === 0}
        onClick={() => handleGoToCheckout()}
      >
        Continue to check out
      </Button>
    </div>
  </>
);

const emptyCart = () => (
  <div className={classnames(styles.emptyContent)}>
    <ShoppingCartWidthLineIcon className={styles.shoppingCart} />
    <div className={classnames('text__heading4__textNeutral20')}>
      Your cart is empty
    </div>
    <div
      className={classnames(
        styles.subtitle,
        'text__body__regular__medium__textNeutral20',
      )}
    >
      Add items from the left side column to proceed to check out.
    </div>
  </div>
);

const itemsInCart = (state: StoreStateType, dispatch: React.Dispatch<any>) => (
  <>
    <div
      className={classnames(
        styles.cartTitleBox,
        'text__heading4__textNeutral40',
      )}
    >
      Cart
    </div>
    <div className={classnames(styles.cartContent)}>
      {state.itemsInCart && state.itemsInCart?.length > 0
        ? state.itemsInCart?.map((item: Item) => (
            <CardItemInCart
              image={item.image}
              name={item.name}
              price={item.priceOneItem}
              key={item.id}
              mountOfItems={item.mountOfItems}
              id={item.id}
              state={state}
              dispatch={dispatch}
            />
          ))
        : emptyCart()}
    </div>
  </>
);

const showFullScreen = (
  state: StoreStateType,
  dispatch: React.Dispatch<any>,
  handleGoToCheckout: Function,
  isMobile: boolean,
) => (
  <div className={classnames(styles.fullHeight)}>
    {itemsInCart(state, dispatch)}
    <div className={classnames(styles.sumUpBox)}>
      {itemsAndButton(state, handleGoToCheckout, isMobile)}
    </div>
  </div>
);

const Cart: React.FC<CartProps> = ({ state, dispatch }) => {
  const handleGoToCheckout = () => {
    dispatch({ type: 'CHANGE_PAGE', page: StorePages.checkout });
  };
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  return isMobile ? (
    <Accordion
      title={
        <div className={styles.accordionTitle}>
          {itemsAndButton(state, handleGoToCheckout, isMobile)}
        </div>
      }
      className={styles.footer}
      content={
        <div className={classnames(styles.fullHeight)}>
          {itemsInCart(state, dispatch)}
        </div>
      }
      classNameIcon={styles.accordionIcon}
      classNametitle={styles.accordionTitle}
    />
  ) : (
    showFullScreen(state, dispatch, handleGoToCheckout, isMobile)
  );
};

export { Cart };
