import { Countries } from 'common/enums';

class User {
  id: number;

  name: string;

  email: string;

  companyName: string;

  recordingTips: boolean;

  stripeClientSecret: string;

  backupEmail: string;

  backupName: string;

  country: string;

  state: string;

  verified: boolean;

  firstAlbumPaymentProcessed: boolean;

  paymentMethods: PaymentMethodType[];

  subscriptionDeals: SubscriptionDealResponseType[];

  constructor(params?: UserType) {
    this.id = params?.id || -1;
    this.name = params?.name || '';
    this.email = params?.email || '';
    this.companyName = params?.companyName || '';
    this.recordingTips = params?.recordingTips || false;
    this.stripeClientSecret = params?.stripeClientSecret || '';
    this.backupEmail = params?.backupEmail || '';
    this.backupName = params?.backupName || '';
    this.country = params?.state ? Countries.UnitedStates : Countries.Other;
    this.state = params?.state || '';
    this.verified = params?.verified || false;
    this.firstAlbumPaymentProcessed =
      params?.firstAlbumPaymentProcessed || false;
    this.paymentMethods = params?.paymentMethods || [];
    this.subscriptionDeals = params?.subscriptionDeals || [];
  }
}

export { User };
