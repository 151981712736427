class Template {
  id: string;

  name: string;

  questions: QuestionType[];

  createdAt?: Date;

  updatedAt?: Date;

  constructor(params?: Template | null) {
    this.id = params?.id || '';
    this.name = params?.name || '';
    this.questions = params?.questions || [];
    this.createdAt = params?.createdAt;
    this.updatedAt = params?.updatedAt;
  }
}

export { Template };
