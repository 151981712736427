class SubscriptionTypesSerializer {
  static deSerializeSubPermissions(
    subPermissions: SubscriptionPermissionsRaw,
  ): SubscriptionPermissionsSerialized {
    return {
      clipNumbers: subPermissions.clip_numbers,
      usersCallTime: subPermissions.users_call_time,
      canSwitchTo: subPermissions.can_switch_to,
      create: subPermissions.create,
      update: subPermissions.update,
      contributors: subPermissions.contributors,
      addOrRecordClips: subPermissions.add_or_record_clips,
      buyExtraClips: subPermissions.buy_extra_clips,
      buyExtraVideoCallTime: subPermissions.buy_extra_videocall_time,
    };
  }

  static deSerializeSubscriptionType(
    sub: SubscriptionTypeRaw,
  ): SubscriptionTypeSerialized {
    return {
      id: sub.id,
      name: sub.name,
      price: sub.price,
      onlyFirstAlbum: sub.only_first_album,
      paymentDescription: sub.payment_description,
      features: sub.features,
      paymentPeriod: sub.payment_period,
      permissions:
        sub.permissions &&
        SubscriptionTypesSerializer.deSerializeSubPermissions(sub.permissions),
      extraClipPrice: sub.extra_clips_price,
      extraVideoCallTimePrice: sub.extra_videocall_price_per_hour,
    };
  }
}

export { SubscriptionTypesSerializer };
