import { Clip } from './clip';

class AgoraStopRecording {
  clip: ClipType;

  constructor(params?: AgoraStopRecording) {
    this.clip = params?.clip || new Clip();
  }
}

export { AgoraStopRecording };
