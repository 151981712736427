import React, { ReactNode } from 'react';
import { classnames } from 'helpers/utils';
import { AlertTriangleIcon } from 'assets/icons';
import styles from './info-label.module.scss';

type InfoLabelProps = {
  content: React.ReactNode;
  icon?: ReactNode;
  withIcon?: boolean;
  className?: string;
};

const InfoLabel: React.FC<InfoLabelProps> = ({
  content,
  icon,
  className = 'text__body__semi__bold__overline__textNeutral30',
  withIcon = true,
}) => {
  const classes = [className, styles.statusLabel];
  return (
    <div className={classnames(...classes)}>
      {withIcon ? !icon && <AlertTriangleIcon className={styles.icon} /> : null}
      {content}
    </div>
  );
};

export { InfoLabel };
