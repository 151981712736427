import { classnames } from 'helpers/utils';
import { accountAlbumsTitles, subscriptionSchemeInfo } from 'config/constants';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { SubscriptionSchemeId } from 'common/enums';
import { RouteName, goToPage } from 'routes';
import styles from './account-albums-rows.module.scss';

type AccountAlbumsRowsProps = {
  albums: AlbumType[];
  getAlbumStatusLabel: Function;
  getRenewalExpiration: Function;
};

const AccountAlbumsRows: React.FC<AccountAlbumsRowsProps> = ({
  albums,
  getAlbumStatusLabel,
  getRenewalExpiration,
}) => {
  const getLastBilling = (album: AlbumType) =>
    album.lastPayment?.amount
      ? `$${album.lastPayment?.amount.toFixed(2)}`
      : '-';

  return (
    <div className={styles.albumsRows}>
      <div className={styles.titles}>
        {Object.keys(accountAlbumsTitles).map((title) => (
          <div
            key={title}
            className={classnames(
              styles[title],
              'text__body__semi__bold__medium__textNeutral40',
            )}
          >
            {accountAlbumsTitles[title]}
          </div>
        ))}
      </div>
      <div
        className={classnames(
          styles.body,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        {albums.map((album: AlbumType) => (
          <div key={album.id} className={styles.albumRow}>
            <div className={styles.albumName}>{album.title}</div>

            <div className={classnames(styles.column, styles.subscription)}>
              {
                subscriptionSchemeInfo[
                  album.subscriptionType as SubscriptionSchemeId
                ].name
              }
            </div>

            <div className={classnames(styles.column, styles.lastBilling)}>
              {getLastBilling(album)}
            </div>

            <div
              className={classnames(styles.column, styles.renewalExpiration)}
            >
              {getRenewalExpiration(album)}
              {getAlbumStatusLabel(album, styles.status)}
            </div>

            <div className={classnames(styles.column, styles.actions)}>
              <Button
                className={styles.detailsButton}
                buttonStyle={ButtonStyle.PrimaryGhost}
                buttonSize={ButtonSize.Small}
                onClick={() => {
                  goToPage(RouteName.AlbumDetail, { id: album.id });
                }}
              >
                <span className={styles.noWrap}>Album details</span>
                <span className={styles.chevronIcon}>
                  <ChevronIcon />
                </span>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { AccountAlbumsRows };
