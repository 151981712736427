import React, { ChangeEvent } from 'react';
import { Input } from 'common/input';
import { CrossIcon } from 'assets/icons';
import styles from './contributor-box.module.scss';

type ContributorProps = {
  contributor: ContributorType;
  contributorErrors: ContributorErrorsType;
  notifyChange: (field: string, newValue: string) => void;
  checkValidation: (field: string, newValue: string) => void;
  notifiyDelete: () => void;
};

const ContributorBox: React.FC<ContributorProps> = ({
  contributor,
  contributorErrors,
  notifyChange,
  notifiyDelete,
  checkValidation,
}) => (
  <div className={styles.box}>
    <Input
      id={contributor.idContributorAux!.toString()}
      name={contributor.name}
      label="Name"
      value={contributor.name}
      placeholder="Name"
      className={styles.inputSize}
      errorMessage={contributorErrors.name}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        notifyChange('name', e.target.value)
      }
      onBlur={(e: ChangeEvent<HTMLInputElement>) =>
        checkValidation('name', e.target.value)
      }
    />
    <Input
      id={contributor.idContributorAux!.toString()}
      name={contributor.email}
      label="Email"
      value={contributor.email}
      placeholder="Email"
      className={styles.inputSize}
      errorMessage={contributorErrors.email}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        notifyChange('email', e.target.value)
      }
      onBlur={(e: ChangeEvent<HTMLInputElement>) =>
        checkValidation('email', e.target.value)
      }
    />
    <button
      aria-label="Close"
      className={styles.deleteIcon}
      type="button"
      onClick={() => notifiyDelete()}
    >
      <CrossIcon />
    </button>
  </div>
);
export { ContributorBox };
