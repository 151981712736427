import { AlbumsSerializer } from './albums-serializer';

/* eslint-disable max-len */
class AgoraSerializer {
  static serializeOwnerInCallStatusRequest(
    data: OwnerInCallStatusRequestSerialized,
  ): OwnerInCallStatusRequestRaw {
    return {
      value: data.value,
    };
  }

  static deSerializeTokenChannel(
    data: TokenChannelRaw,
  ): TokenChannelSerialized {
    return {
      token: data.token,
      channelName: data.channel_name,
      uid: data.uid,
    };
  }

  static serializeStartRecordingRequest(
    data: StartRecordingRequestSerialized,
  ): StartRecordingRequestRaw {
    return {
      token: data.token,
    };
  }

  static deSerializeStartRecordingResponse(
    data: StartRecordingResponseRaw,
  ): StartRecordingResponseSerialized {
    return {
      resourceId: data.resource_id,
      sid: data.sid,
    };
  }

  static serializeStopRecordingRequest(
    data: StopRecordingRequestSerialized,
  ): StopRecordingRequestRaw {
    return {
      sid: data.sid,
      resource_id: data.resourceId,
      clip_id: data.clipId,
      clip_name: data.clipName,
    };
  }

  static deSerializeStopRecordingResponse(
    data: StopRecordingResponseRaw,
  ): StopRecordingResponseSerialized {
    return {
      clip: AlbumsSerializer.deSerializeClip(data.clip),
    };
  }

  static deSerializeCallTimeLeftResponse(
    data: CallTimeLeftResponseRaw,
  ): CallTimeLeftResponseSerialized {
    return {
      usedCallTime: data.used_call_time,
    };
  }
}

export { AgoraSerializer };
