import React, { useState, ChangeEvent, Dispatch, useEffect } from 'react';
import { Input } from 'common/input';
import { Line } from 'common/line';
import {
  classnames,
  isEmailValid,
  isPasswordValid,
  noEmptyFields,
  noErrors,
} from 'helpers/utils';
import { EyeIcon } from 'assets/icons';
import { SignController } from 'networking/controllers/sign-controller';
import { errorsCode } from 'config/constants';
import { usStatesList } from 'config/us-states-list';
import { Notification } from 'common/notification';
import { Select } from 'common/select';
import { SelectElement } from 'models/selectElement';
import CypressIds from 'cypressIds';
import { Countries } from 'common/enums';
import { SignUpFooter } from '../sign-up-footer';
import { Action } from '../sign-up-reducer';
import styles from './sign-up-step.module.scss';

type SignUpStep1Props = {
  dispatch: Dispatch<Action>;
  step: number;
  user: UserSignUpType;
  sectionAlreadyVisited: boolean;
  isCompany: boolean;
};

type Mandatory = {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
};

const mandatoryStringFieldsErrors: Mandatory = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
};

type SelectMandatory = {
  state: string;
};

const mandatorySelectFieldsErrors = {
  state: '',
};

const SignUpStep1: React.FC<SignUpStep1Props> = ({
  step,
  user,
  dispatch,
  sectionAlreadyVisited,
  isCompany,
}) => {
  const [errors, setErrors] = useState<Mandatory>(mandatoryStringFieldsErrors);
  const [selectErrors, setSelectErrors] = useState<SelectMandatory>(
    mandatorySelectFieldsErrors,
  );
  const [inputPassword, setInputPassword] = useState<boolean>(true);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [countrySelected, setCountrySelected] = useState<SelectType>({
    id: '',
    value: Countries.UnitedStates,
  });
  const isUSCountry = countrySelected.value === Countries.UnitedStates;

  const validateField = (field: string, label?: string) => {
    const emptyFieldMessage =
      user[field].trim() === '' ? `${label || field} shouldn't be empty` : '';
    setErrors((prevState) => ({
      ...prevState,
      [field]: emptyFieldMessage.toUpperCase(),
    }));

    if (emptyFieldMessage) return;

    if (field === 'email') {
      const emailMessage = !isEmailValid(user[field]) ? 'invalid email' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: emailMessage.toUpperCase(),
      }));

      if (emailMessage) return;
    }

    if (field === 'password') {
      const passwordMessage = !isPasswordValid(user[field])
        ? 'Invalid password. It must have at least 8 characters, one letter and one number.'
        : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: passwordMessage.toUpperCase(),
      }));

      if (passwordMessage) return;
    }

    if (field === 'repeatPassword') {
      const repeatMessage =
        user.password !== user.repeatPassword ? 'passwords do not match' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: repeatMessage.toUpperCase(),
      }));
    }
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    objectToChange: string,
  ) => {
    dispatch({
      type: 'CHANGE_INPUT',
      object: objectToChange,
      field: e.target.name,
      value: e.target.value,
    });
  };

  const onChangeState = (option: SelectType) => {
    setSelectErrors((prevState) => ({ ...prevState, state: '' }));

    dispatch({
      type: 'STATE_SELECTED',
      state: option,
    });
  };

  const checkFrontendErrors = () => {
    Object.keys(mandatoryStringFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryStringFieldsErrors);
    });

    if (!user.state.id) {
      const stateMessage = 'You must select a state';
      setSelectErrors((prevState) => ({
        ...prevState,
        state: stateMessage.toUpperCase(),
      }));
    }
  };

  const showBackendErrors = (backendErrors: any) => {
    const errorsCopy = { ...errors };

    Object.keys(errorsCopy).forEach((field) => {
      errorsCopy[field as keyof typeof mandatoryStringFieldsErrors] =
        backendErrors[field]
          ? backendErrors[field].toUpperCase()
          : errorsCopy[field as keyof typeof mandatoryStringFieldsErrors];
    });

    setErrors(errorsCopy);
  };

  const generateBackendErrors = (err: any) => {
    const emailInUse = err.errors.find(
      (e: any) => e.errorCode === errorsCode.emailInUse,
    );

    if (emailInUse) {
      const objectErrors: MandatoryFieldsType = {
        email: 'Email already in use',
      };

      showBackendErrors(objectErrors);
    }
  };

  const validateFieldsFrontAndBack = async () => {
    checkFrontendErrors();

    if (!noErrors(errors)) {
      return false;
    }

    if (isUSCountry && !user.state.id) return false;

    try {
      await SignController.validatePersonalInfo(user);
      return true;
    } catch (err: any) {
      if (!err.errors) {
        setShowError(true);
        return false;
      }

      generateBackendErrors(err);
      return false;
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const checkFields = () => {
    checkFrontendErrors();

    if (!noErrors(errors)) {
      return;
    }

    dispatch({ type: 'GO_NEXT' });
  };

  useEffect(() => {
    if (sectionAlreadyVisited) {
      checkFrontendErrors();
    } else {
      dispatch({ type: 'SECTION_VISITED', section: 'step1', value: true });
    }
  }, []);

  const onChangeCountry = (option: SelectType) => {
    setCountrySelected(option);
    if (option.value !== Countries.UnitedStates) {
      dispatch({
        type: 'STATE_SELECTED',
        state: new SelectElement(),
      });
    }
  };
  const { name, email, password, companyName, repeatPassword, state } = user;

  return (
    <>
      {showError && showErrorNotification()}
      <div>
        <div
          className={classnames(
            styles.titleView,
            'text__title1__textNeutral40',
          )}
        >
          {' '}
          Sign up
        </div>
        <div
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            'my-12',
          )}
        >
          Personal information
        </div>

        <Input
          id="name"
          name="name"
          label="Name"
          placeholder="first & last name"
          value={name}
          type="text"
          errorMessage={errors.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => validateField('name')}
          className="mb-6"
          dataCy={CypressIds.nameSignup}
        />

        {isCompany ? (
          <div className={classnames(styles.emailBox, 'mb-6')}>
            <span
              className={classnames(
                'text__body__semi__bold__medium__textNeutral40',
              )}
            >
              Email
            </span>
            <span
              className={classnames(
                styles.email,
                'text__body__regular__medium__textNeutral30',
              )}
            >
              {email}
            </span>
          </div>
        ) : (
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="email@example.com"
            value={email}
            type="email"
            errorMessage={errors.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
            onBlur={() => validateField('email')}
            dataCy={CypressIds.emailSignup}
          />
        )}

        <div className={isUSCountry ? styles.countryAndState : styles.country}>
          <div className="mb-6">
            <Select
              options={Object.values(Countries).map((country: string) => ({
                id: country,
                value: country,
              }))}
              optionSelected={countrySelected}
              onClickOption={(option) => onChangeCountry(option)}
              id="country"
              value={countrySelected.value}
              placeholder="select country"
              title="Country"
              className="mb-6"
            />
          </div>
          {isUSCountry && (
            <div className="mb-6">
              <Select
                options={usStatesList}
                optionSelected={state}
                onClickOption={(option) => onChangeState(option)}
                id="state"
                value={state.value}
                placeholder="select state"
                title="State"
                withValidation
                errorMessage={selectErrors.state}
                dataCy={CypressIds.selectState}
                className="mb-6"
              />
            </div>
          )}
        </div>

        {!isCompany && ( // if isCompany automatically set the name, don't show company name input
          <Input
            id="companyName"
            name="companyName"
            label="Company name"
            secondLabel=" (Optional)"
            placeholder="company name"
            value={companyName}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
            className="mb-6"
            dataCy={CypressIds.companyNameSignup}
          />
        )}

        <Input
          id="password"
          name="password"
          label="Password"
          placeholder="password"
          value={password}
          type={inputPassword ? 'password' : 'text'}
          errorMessage={errors.password}
          withIconEnd={
            <EyeIcon onClick={() => setInputPassword(!inputPassword)} />
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => validateField('password')}
          className="mb-6"
          dataCy={CypressIds.passwordSignup}
        />

        <Input
          id="repeatPassword"
          name="repeatPassword"
          label="Repeat password"
          placeholder="password"
          value={repeatPassword}
          type={inputPasswordRepeat ? 'password' : 'text'}
          errorMessage={errors.repeatPassword}
          withIconEnd={
            <EyeIcon
              onClick={() => setInputPasswordRepeat(!inputPasswordRepeat)}
            />
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => validateField('repeatPassword', 'Repeat Password')}
          className="mb-6"
          dataCy={CypressIds.repeatPasswordSignup}
        />

        <Line />

        <SignUpFooter
          step={step}
          enableNext={noEmptyFields({
            name,
            email,
            password,
            repeatPassword,
          })}
          showNext
          nextFn={() => {
            checkFields();
          }}
          mustValidateBackend
          validateFieldsBackend={() => validateFieldsFrontAndBack()}
        />
      </div>
    </>
  );
};

export { SignUpStep1 };
