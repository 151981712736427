const addLinks = (inputText: string) => {
  const REGEXP =
    /\b(?:https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|http:\/\/[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|www\.[a-zA-Z0-9]+\.[a-zA-Z]{2,}|[a-zA-Z0-9]*\.com)(?:\/[\w-]+)*(?:\?.*)?\S*/gm;

  const linkMatches = inputText ? [...inputText.matchAll(REGEXP)] : null;

  if (!linkMatches) {
    return inputText;
  }

  return inputText
    .split(REGEXP)
    .reduce((newArray: (string | JSX.Element)[], slice: string, i: number) => {
      if (i === 0) {
        return [<span>{slice}</span>];
      }

      return [
        ...newArray,
        <a href={linkMatches[[i - 1][0]].toString()}>
          {linkMatches[[i - 1][0]]}
        </a>,
        slice === linkMatches[[i - 1][0]].toString() ? (
          ''
        ) : (
          <span>{slice}</span>
        ),
      ];
    }, []);
};
export { addLinks };
