import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AppContext, appActions } from 'context';
import { classnames, getPriceInfoBySubscription } from 'helpers/utils';
import {
  checkAllFieldsAndGetErrors,
  hasErrorsInformationSection,
} from 'helpers/new-album-helper';
import {
  Breakpoints,
  CreateAlbumSteps,
  NotificationType,
  PaymentType,
  SubscriptionSchemeId,
} from 'common/enums';
import { goToPage, RouteName } from 'routes';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { ButtonStyle } from 'common/button';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { NotificationObject } from 'models/notificationObject';
import { useMediaQuery } from 'hooks/use-media-query';
import { ModalWarning } from 'common/modal-warning';
import { AllPaymentProcess } from 'common/all-payment-process';
import { captureEvent } from 'posthog';
import { errorsCodeInPayments, subscriptionSchemeInfo } from 'config/constants';
import CypressIds from 'cypressIds';
import { ActionButtons } from './components/action-buttons';
import { StepsLine } from './components/steps-line';
import { AlbumNewinitialState, AlbumNewReducer } from './album-new-reducer';
import { StepInformation } from './components/step-information';
import { StepSubscriptionType } from './components/step-subscription-type';
import styles from './album-new.module.scss';

const AlbumNew: React.FC = () => {
  const [newAlbumState, newAlbumDispatch] = useReducer(
    AlbumNewReducer,
    AlbumNewinitialState,
  );
  const { state: generalState, dispatch: generalDispatch } =
    useContext(AppContext);

  const [startPaymentProcess, setStartPaymentProcess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const newAlbumId = useRef<number | null>(null);

  const { discountedPrices, subscriptionSelected } = newAlbumState;

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.xs}px)`);

  const updatePricesByExistingDiscountCodes = async () => {
    const response = await SubscriptionController.getSubscriptionPrices();

    newAlbumDispatch({
      type: 'UPDATE_SUBSCRIPTION_NEW_PRICES',
      discountedPrices: response.prices,
    });
  };

  useEffect(() => {
    updatePricesByExistingDiscountCodes();
  }, []);

  const handleNextAction = (actualStep: CreateAlbumSteps) => {
    const { album } = newAlbumState;

    if (actualStep === CreateAlbumSteps.subscriptionType) {
      captureEvent('createAlbumNext');
      newAlbumDispatch({ type: 'MOVE_STEP', direction: 'next' });
    }

    if (actualStep === CreateAlbumSteps.information) {
      const newErrorsList = checkAllFieldsAndGetErrors(album);
      newAlbumDispatch({ type: 'UPDATE_ERRORS', errors: newErrorsList });

      if (hasErrorsInformationSection(newErrorsList)) {
        return;
      }

      captureEvent('createNewAlbum');
      setStartPaymentProcess(true);
    }
  };

  const handlePreviousAction = (actualStep: CreateAlbumSteps) => {
    if (actualStep === CreateAlbumSteps.subscriptionType) {
      setShowWarning(true);
    }

    if (actualStep === CreateAlbumSteps.information) {
      newAlbumDispatch({ type: 'MOVE_STEP', direction: 'back' });
    }
  };

  const handleCreateAlbum = async () => {
    try {
      const requestData: AlbumNewRequestType = {
        ...newAlbumState.album,
        subscriptionType: newAlbumState.subscriptionSelected.id,
      };

      const albumResponse = await AlbumsController.createAlbum(requestData);
      newAlbumId.current = albumResponse.id;

      try {
        const subscriptionsScheme =
          await SubscriptionController.getSubscriptionTypes();
        generalDispatch({
          type: appActions.SUBSCRIPTIONS_SCHEME,
          subscriptionsScheme,
        });
      } catch (err) {
        // Ignore error
      }

      return albumResponse;
    } catch (err: any) {
      const isPaymentError = err?.errors?.some(
        (error: any) => error.errorCode === errorsCodeInPayments.cardDeclined,
      );
      if (isPaymentError) {
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Create Album',
            message:
              'There was an error with your payment method. The album was created in the restricted mode.',
            type: NotificationType.Error,
          }),
        });
        goToPage(RouteName.Albums);
      } else {
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Create Album',
            message: "Album couldn't be created",
            type: NotificationType.Error,
          }),
        });
      }
      return null;
    }
  };

  const showStep1Content = () => (
    <StepSubscriptionType
      generalState={generalState}
      newAlbumState={newAlbumState}
      generalDispatch={generalDispatch}
      newAlbumDispatch={newAlbumDispatch}
    />
  );

  const showStep2Content = () => (
    <StepInformation
      generalState={generalState}
      newAlbumState={newAlbumState}
      newAlbumDispatch={newAlbumDispatch}
    />
  );

  const showCancelWarning = () => (
    <ModalWarning
      title="Your progress will be lost"
      content="If you leave this page without saving your album won’t be created and your progress will be lost. Are you sure you want to continue?"
      successButtonText="Confirm"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowWarning(false)}
      successFn={() => goToPage(RouteName.Albums)}
    />
  );

  const isAlbumWithFullDiscount = () =>
    getPriceInfoBySubscription(discountedPrices, subscriptionSelected)!
      .price === 0;

  const albumTotalDiscountText = () => (
    <p>
      {' '}
      You won’t be charged for this album. Click ‘Create album’ to confirm its
      creation.{' '}
    </p>
  );

  const albumWithPrice = () => {
    const { name } = subscriptionSelected;

    const { paymentPeriod } =
      subscriptionSchemeInfo[subscriptionSelected.id as SubscriptionSchemeId];
    const albumName = name.toLocaleLowerCase();
    return (
      <>
        <p>
          {`You will be charged $${
            getPriceInfoBySubscription(discountedPrices, subscriptionSelected)!
              .price
          } (${paymentPeriod}) for this ${albumName} album. `}
        </p>
        <br />
        <p>
          {' '}
          Click <b>Pay</b> to complete your purchase.
        </p>
      </>
    );
  };

  const createAlbumTextBody = () => {
    if (isAlbumWithFullDiscount()) {
      return albumTotalDiscountText();
    }

    return albumWithPrice();
  };

  const createAlbumButtonText = () => {
    if (isAlbumWithFullDiscount()) return 'Create album';

    return `Pay $${
      getPriceInfoBySubscription(discountedPrices, subscriptionSelected)!.price
    }`;
  };

  const showAllPaymentProcessComponent = () => (
    <AllPaymentProcess
      paymentType={PaymentType.CreateNewAlbum}
      title="Create album"
      creatingFreeTrial={
        newAlbumState.subscriptionSelected.id === SubscriptionSchemeId.trial
      }
      payAlbumWithFullDiscout={isAlbumWithFullDiscount()}
      confirmPaymentTextBody={createAlbumTextBody()}
      payButtonText={createAlbumButtonText()}
      successPaymentTextBody="Congratulations! Your album has been created."
      successPaymentButtonText="Open album"
      failedPaymentTextBody="Your album could not be created. Please modify your payment method and try again."
      notifyProcessConfirmation={() => handleCreateAlbum()}
      cancelPaymentProcess={() => setStartPaymentProcess(false)}
      successPaymentProcess={() => {
        setStartPaymentProcess(false);
        goToPage(RouteName.AlbumDetail, { id: newAlbumId.current });
      }}
      notifyError={() => setStartPaymentProcess(false)}
      failedPaymentProcess={() => {
        setStartPaymentProcess(false);
      }}
      dataCy={CypressIds.successCreateAlbum}
    />
  );

  return (
    <>
      {showWarning && showCancelWarning()}
      {startPaymentProcess && showAllPaymentProcessComponent()}

      <div className={globalStyles.loggedMainContainer}>
        <div className={styles.fullAlbumNewContainer}>
          <div className={styles.headerRow}>
            <div
              className={classnames(
                mobile
                  ? 'text__heading6__textNeutral40'
                  : 'text__heading5__textNeutral40',
                styles.title,
              )}
            >
              Create new album
            </div>
            <ActionButtons
              isMobileSize={mobile}
              newAlbumState={newAlbumState}
              notifiyPreviousAction={() => {
                handlePreviousAction(newAlbumState.step);
              }}
              notifiyNextAction={() => {
                handleNextAction(newAlbumState.step);
              }}
            />
          </div>

          <StepsLine isMobileSize={mobile} step={newAlbumState.step} />

          <div
            className={classnames(
              'text__body__semi__bold__overline__textNeutral30',
              styles.step,
            )}
          >
            {`STEP ${newAlbumState.step}`}
          </div>

          {newAlbumState.step === CreateAlbumSteps.subscriptionType &&
            generalState.subscriptionsScheme?.length &&
            showStep1Content()}

          {newAlbumState.step === CreateAlbumSteps.information &&
            showStep2Content()}
        </div>
      </div>
    </>
  );
};

export { AlbumNew };
