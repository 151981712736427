import { classnames } from 'helpers/utils';
import styles from './sensor-menu.module.scss';

type MenuOptionProps = {
  option: MediaDeviceInfo;
  selected?: boolean;
  onClick: () => void;
};

const MenuOption = ({ option, selected, onClick }: MenuOptionProps) => (
  <button type="button" onClick={() => onClick()}>
    <span
      className={classnames(
        'text__body__regular__medium__textNeutral30',
        styles.menuOption,
        { [styles.selected]: !!selected },
      )}
    >
      {option.label}
    </span>
  </button>
);

export { MenuOption };
