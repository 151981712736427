export class AccountPersonalErrors {
  name: string;

  email: string;

  state: string;

  constructor(params?: AccountPersonalErrorsType) {
    this.name = params?.name || '';
    this.email = params?.email || '';
    this.state = params?.state || '';
  }
}
