import { useState } from 'react';
import { ButtonStyle } from 'common/button';
import { ModalWarning } from 'common/modal-warning';
import { AlbumsController } from 'networking/controllers/albums-controller';

type LeaveAlbumModalProps = {
  contributor: ContributorType;
  albumId: number;
  notifyLeaveAlbum: (contributor: ContributorType) => void;
  onClose: () => void;
};

const LeaveAlbumModal = ({
  contributor,
  albumId,
  notifyLeaveAlbum,
  onClose,
}: LeaveAlbumModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleLeaveAlbum = async () => {
    setLoading(true);

    try {
      await AlbumsController.leaveAlbum(albumId);

      notifyLeaveAlbum(contributor);
    } finally {
      setLoading(false);
    }
    onClose();
  };

  return (
    <ModalWarning
      title="Leave album"
      content="Are you sure you want to remove yourself from this album? You won’t be able to make contributions or see private clips anymore."
      successButtonText="Leave album"
      successStyleButton={ButtonStyle.SecondaryFilled}
      closeFn={onClose}
      loading={loading}
      successFn={handleLeaveAlbum}
    />
  );
};

export { LeaveAlbumModal };
