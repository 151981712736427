class PresignUrl {
  id: number;

  key: string;

  filename: string;

  contentType: string;

  metaData: any;

  serviceName: string;

  byteSize: number;

  checksum: string;

  createdAt: Date;

  signedId: string;

  attachableSgid: string;

  directUpload: DirectUploadPropsType;

  constructor(params: PresignUrl) {
    this.id = params.id;
    this.key = params.key;
    this.filename = params.filename;
    this.contentType = params.contentType;
    this.metaData = params.metaData;
    this.serviceName = params.serviceName;
    this.byteSize = params.byteSize;
    this.checksum = params.checksum;
    this.createdAt = params.createdAt;
    this.signedId = params.signedId;
    this.attachableSgid = params.attachableSgid;
    this.directUpload = params.directUpload;
  }
}

export { PresignUrl };
