import { Album } from './album';
import { Pagination } from './pagination';

class AlbumsPagination {
  data: Album[];

  pagination: Pagination;

  constructor(params?: AlbumsPaginationType) {
    this.data = params?.data || [];
    this.pagination = params?.pagination || new Pagination();
  }
}

export { AlbumsPagination };
