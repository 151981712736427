import React, { useState, useEffect, useContext } from 'react';
import { Spinner } from 'common/spinner';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { StepsProgress } from 'common/steps-progress';
import { classnames } from 'helpers/utils';
import { UserController } from 'networking/controllers/user-controller';
import { AppContext, appActions } from 'context';
import CypressIds from 'cypressIds';
import styles from './sign-up-footer.module.scss';

type SignUpFooterProps = {
  token?: string;
  step: number;
  enableNext?: boolean;
  showBack?: boolean;
  showNext?: boolean;
  showRegister?: boolean;
  backFn?: () => void;
  nextFn?: () => void;
  registerFn?: () => Promise<boolean>;
  validateFieldsBackend?: () => Promise<boolean>;
  sendUserDataToRegister?: () => Promise<boolean>;
  mustValidateBackend?: boolean;
  isCompany?: boolean;
};

const SignUpFooter: React.FC<SignUpFooterProps> = ({
  token,
  step,
  enableNext,
  showBack = false,
  showNext = false,
  showRegister = false,
  mustValidateBackend = false,
  backFn,
  nextFn,
  registerFn,
  validateFieldsBackend,
  sendUserDataToRegister,
  isCompany,
}) => {
  const { dispatch } = useContext(AppContext);

  const [fetching, setFetching] = useState(false);
  const registerButtonText = isCompany ? 'Activate' : 'Create';

  useEffect(() => setFetching(false), []);

  const disabledNextButton = () => {
    if (step === 1 || step === 2) {
      return !enableNext;
    }

    return true;
  };

  const validateFields = async () => {
    setFetching(true);

    const validForm = await validateFieldsBackend!();

    if (validForm) {
      nextFn!();
    }

    setFetching(false);
  };

  const registerUser = async () => {
    setFetching(true);

    const isValid = await sendUserDataToRegister!();

    if (!isValid) {
      setFetching(false);
      return;
    }

    const userRegistered = await registerFn!();

    if (!userRegistered) {
      setFetching(false);
      return;
    }

    // contributor token
    if (token) {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });
      goToPage(RouteName.Albums);
    } else {
      goToPage(RouteName.EmailWaitingConfirm);
    }
  };

  const showBackButton = (backCallback: Function) => (
    <Button
      onClick={() => backCallback()}
      buttonSize={ButtonSize.Medium}
      buttonStyle={ButtonStyle.PrimaryGhost}
    >
      Back
    </Button>
  );

  const showNextButton = () => (
    <Button
      className={fetching ? 'noClick staticSizeNext' : ''}
      onClick={() => (mustValidateBackend ? validateFields() : nextFn!())}
      disabled={disabledNextButton()}
      buttonSize={ButtonSize.Medium}
      dataCy={CypressIds.signupButton}
    >
      {fetching ? (
        <Spinner color="white" className="spinner-inside-button" />
      ) : (
        <> Next </>
      )}
    </Button>
  );

  const showRegisterButton = () => (
    <Button
      className={classnames(
        styles.registerButton,
        fetching ? 'noClick staticSizeRegister' : '',
      )}
      onClick={() => registerUser()}
      buttonSize={ButtonSize.Medium}
      buttonType={ButtonType.Submit}
      dataCy={CypressIds.signupButton}
    >
      {fetching ? (
        <Spinner color="white" className="spinner-inside-button" />
      ) : (
        registerButtonText
      )}
    </Button>
  );

  return (
    <div className={classnames(styles.footer)}>
      <div className={classnames(styles.progressbar)}>
        <StepsProgress step={step} maximum={2} />
      </div>

      <div className={classnames(styles.buttons)}>
        {showBack && showBackButton(backFn!)}
        {showNext && showNextButton()}
        {showRegister && showRegisterButton()}
      </div>
    </div>
  );
};

export { SignUpFooter };
