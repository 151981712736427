import { SensorSelectionContext } from 'context';
import { loadSensorSelection } from 'context/sensor-selection-context/init';
import { useContext, useEffect } from 'react';

const useLoadSensorSelection = () => {
  const { updateSensorSelection } = useContext(SensorSelectionContext);
  // Make sure sensor selection is loaded
  useEffect(() => {
    const initializeSensors = async () => {
      const selection = await loadSensorSelection();
      updateSensorSelection({
        type: 'SENSOR_SELECTION_LOADED',
        data: selection,
      });
    };
    initializeSensors();
  }, []);
};

export { useLoadSensorSelection };
