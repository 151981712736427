import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './line-info.module.scss';

type LineInfoPros = {
  label: string;
  info: string;
};
const LineInfo: React.FC<LineInfoPros> = ({ label, info }) => (
  <div className={classnames(styles.container)}>
    <div className="text__body__semi__bold__medium__textNeutral40">{label}</div>
    <div className="text__body__semi__bold__medium__textNeutral40">{info}</div>
  </div>
);

export { LineInfo };
