import React, { ChangeEvent, Dispatch, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Input } from 'common/input';
import { EditIcon } from 'assets/icons';
import { UserController } from 'networking/controllers/user-controller';
import { FormFooter } from 'common/form-footer';
import {
  checkEmailAndGetGenericError,
  checkValueAndGetEmptyError,
  classnames,
} from 'helpers/utils';
import { Countries, NotificationType } from 'common/enums';
import { AccountAction, AccountStateType } from 'pages/account/account-reducer';
import { cloneDeep } from 'lodash';
import { ChangePasswordModal } from 'common/change-password-modal';
import { AccountPersonalErrors } from 'models/account-personal-errors';
import { usStatesList } from 'config/us-states-list';
import { Select } from 'common/select';
import { InitialStateType } from 'context/app-context/context-reducer';
import { appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { deleteAccountLink } from 'config/constants';
import styles from './personal-information.module.scss';

type PersonalInformationProps = {
  generalDispatch: Dispatch<any>;
  accountDispatch: Dispatch<AccountAction>;
  generalState: InitialStateType;
  accountState: AccountStateType;
  className?: string;
};

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  generalDispatch,
  accountDispatch,
  generalState,
  accountState,
  className = '',
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const handleChange = (field: string, value: string) => {
    accountDispatch({ type: 'CHANGE_INPUT', field, value });
  };

  const { user: userOriginal } = generalState.data;
  const { userNewData, personalErrors } = accountState;

  const showChangePasswordModalComponent = () => (
    <ChangePasswordModal
      onClose={() => setShowChangePasswordModal(false)}
      onSuccess={() => {
        setShowChangePasswordModal(false);
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Password updated',
            message: 'Your password has been changed',
            type: NotificationType.Success,
          }),
        });
      }}
    />
  );

  const showTitle = () => (
    <div className={styles.titleBox}>
      <p className="text__title2__textNeutral40"> Personal information </p>
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        className={classnames({ invisible: !readOnly })}
        onClick={() => {
          setReadOnly(false);
        }}
      >
        <EditIcon />
      </Button>
    </div>
  );

  const onChangeState = (newState: SelectType) => {
    accountDispatch({ type: 'CHANGE_STATE', newState });
  };

  const onChangeCountry = (newCountry: SelectType) => {
    accountDispatch({ type: 'CHANGE_COUNTRY', newCountry });
  };

  const handleReturnPersonalInfoToOriginalValues = () => {
    const { name, email, companyName, country, state } = userOriginal;

    accountDispatch({
      type: 'UPDATE_USER_NEW_DATA',
      userNewData: {
        ...userNewData,
        name,
        email,
        companyName,
        country,
        state,
      },
    });
  };

  const updatePersonalInfoValues = async () => {
    const { name, email, companyName, state } = userNewData;

    let newDataCopy = cloneDeep(userOriginal);
    newDataCopy = {
      ...newDataCopy,
      name,
      email,
      companyName,
      state,
    };

    try {
      const userResponse = await UserController.update(newDataCopy);
      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: userResponse },
      });

      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Personal Information',
          message: 'Personal Information has been changed',
          type: NotificationType.Success,
        }),
      });
    } catch (e) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Personal Information',
          message: "Personal Information couldn't be saved",
          type: NotificationType.Error,
        }),
      });
    } finally {
      setReadOnly(true);
    }
  };

  const checkStateAndGetError = (state: string) =>
    userNewData.country === Countries.UnitedStates && state === ''
      ? 'You must select a state'
      : '';

  const hasErrorsPersonalInfo = (newErrorsList: AccountPersonalErrorsType) =>
    newErrorsList.name || newErrorsList.email || newErrorsList.state;

  const checkAllFieldsAndGetErrors = () => {
    const errs: AccountPersonalErrorsType = new AccountPersonalErrors();

    errs.name = checkValueAndGetEmptyError(userNewData.name);

    errs.email = checkValueAndGetEmptyError(userNewData.email);
    if (errs.email === '') {
      errs.email = checkEmailAndGetGenericError(userNewData.email);
    }

    errs.state = checkStateAndGetError(userNewData.state);

    return errs;
  };

  const validateField = (field: string) => {
    const newErrors = { ...personalErrors };

    if (field === 'name') {
      newErrors.name = checkValueAndGetEmptyError(userNewData.name);
    }

    if (field === 'email') {
      newErrors.email = checkValueAndGetEmptyError(userNewData.email);
      if (newErrors.email === '') {
        newErrors.email = checkEmailAndGetGenericError(userNewData.email);
      }
    }

    if (field === 'state') {
      newErrors.state = checkStateAndGetError(userNewData.state);
    }

    accountDispatch({
      type: 'UPDATE_PERSONAL_ERRORS',
      personalErrors: { ...personalErrors, ...newErrors },
    });
  };

  const checkValuesAndSavePersonalInfo = () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    accountDispatch({
      type: 'UPDATE_PERSONAL_ERRORS',
      personalErrors: newErrorsList,
    });

    if (hasErrorsPersonalInfo(newErrorsList)) {
      return;
    }

    updatePersonalInfoValues();
  };

  const cleanValidations = () => {
    accountDispatch({
      type: 'UPDATE_PERSONAL_ERRORS',
      personalErrors: new AccountPersonalErrors(),
    });
  };

  const showPersonalInformarmationFields = () => (
    <div className={styles.personalFieldsContainer}>
      <div className={styles.firstColumn}>
        <div>
          <Input
            id="name"
            name="name"
            label="Name"
            value={userNewData.name}
            disabled={readOnly}
            withValidation
            errorMessage={personalErrors.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange('name', e.target.value)
            }
            onBlur={() => {
              validateField('name');
            }}
          />
          <Input
            type="email"
            id="email"
            name="email"
            label="Email"
            disabled={readOnly}
            value={userNewData.email}
            withValidation
            errorMessage={personalErrors.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange('email', e.target.value)
            }
            onBlur={() => {
              validateField('email');
            }}
          />
          <Input
            id="companyName"
            name="companyName"
            label="Company name"
            value={userNewData.companyName}
            disabled={readOnly}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange('companyName', e.target.value)
            }
          />
        </div>
      </div>

      <div className={styles.secondColumn}>
        <div className="mb-20">
          <Select
            options={Object.values(Countries).map((country: string) => ({
              id: country,
              value: country,
            }))}
            optionSelected={{ id: '', value: userNewData.country }}
            onClickOption={(option) => onChangeCountry(option)}
            id="country"
            disabled={readOnly}
            value={userNewData.country}
            placeholder="select country"
            title="Country"
          />
        </div>

        {userNewData.country !== Countries.Other && (
          <div className="mb-6">
            <Select
              options={usStatesList}
              optionSelected={{ id: '', value: userNewData.state }}
              onClickOption={(option) => onChangeState(option)}
              id="state"
              disabled={readOnly}
              value={userNewData.state}
              placeholder="Select state"
              title="State"
              withValidation
              errorMessage={personalErrors.state}
            />
          </div>
        )}
      </div>
    </div>
  );

  const showChangePasswordButton = () => (
    <Button
      className={styles.changePasswordButton}
      buttonStyle={ButtonStyle.PrimaryGhost}
      onClick={() => setShowChangePasswordModal(true)}
    >
      Change password
    </Button>
  );

  const showDeleteAccountButton = () => (
    <Button
      buttonSize={ButtonSize.Tiny}
      buttonStyle={ButtonStyle.RedGhost}
      onClick={() => window.open(deleteAccountLink, '_blank')}
    >
      <span className={styles.deleteAccountButton}>Delete account</span>
    </Button>
  );

  const showActionButtons = () => (
    <div className={styles.personalActionButtons}>
      <FormFooter
        readyToSubmit
        successButton="Save"
        includeLine={false}
        successButtonSize={ButtonSize.Medium}
        cancelButtonSize={ButtonSize.Medium}
        cancelButtonStyle={ButtonStyle.PrimaryGhost}
        successButtonStyle={ButtonStyle.PrimaryFilled}
        onClickCancel={() => {
          <>
            {setReadOnly(true)}
            {handleReturnPersonalInfoToOriginalValues()}
            {cleanValidations()}
          </>;
        }}
        onClickSuccess={() => {
          checkValuesAndSavePersonalInfo();
        }}
      />
    </div>
  );

  const showFooter = () => (
    <div className={styles.footer}>
      {readOnly && showDeleteAccountButton()}
      {readOnly ? showChangePasswordButton() : showActionButtons()}
    </div>
  );

  return (
    <>
      {showChangePasswordModal && showChangePasswordModalComponent()}

      <div className={className}>
        {showTitle()}
        {showPersonalInformarmationFields()}
        {showFooter()}
      </div>
    </>
  );
};

export { PersonalInformation };
