import { SelfRecordingContext } from 'context/self-recording-context';
import { useContext, useEffect, useRef, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { BackButton } from 'common/back-button';
import { classnames } from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { ReviewVideoCard } from 'common/review-video-card';
import { ModalWarning } from 'common/modal-warning';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { RouteName, goToPage } from 'routes';
import { selfRecordingClipName } from 'helpers/self-recording';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { VideosRecordedType } from 'context/self-recording-context/context-reducer';
import styles from './review-videos.module.scss';

const ReviewVideosPage = () => {
  const { state, dispatch } = useContext(SelfRecordingContext);
  const { dispatch: generalDispatch } = useContext(AppContext);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [oneWasSaved, setOneWasSaved] = useState(false);
  const { albumId } = state;
  const positionInBatchRef = useRef<number | undefined>(undefined);

  const getInformationById = (requestId: string) => {
    const videoInformation = state.videosRecorded.find(
      (video) => video.requestId === requestId,
    );
    return videoInformation;
  };

  const createClip = async (requestId: string) => {
    const clipToSave = getInformationById(requestId);
    if (!clipToSave) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      return false;
    }

    const createClipRequest: CreateClipRequestType = {
      clip: {
        videoSignedId: clipToSave.videoSignedId,
        name: clipToSave.name,
        recordedAt: new Date(Date.now() + parseInt(requestId, 10) * 1000),
        description: clipToSave.description,
        isAudioOnly: clipToSave.isAudioOnly,
        customThumbnailSignedId: clipToSave.imageId,
        batchId: state.batchId,
        positionInBatch: clipToSave.positionInBatchToClip
          ? clipToSave.positionInBatchToClip
          : positionInBatchRef.current,
      },
    };

    try {
      const clipResponse = await AlbumsController.createClip(
        state.albumId,
        createClipRequest,
      );
      if (clipResponse) {
        setOneWasSaved(true);
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Your clip(s) have been saved.',
            message: '',
            type: NotificationType.Success,
          }),
        });
      }
      return true;
    } catch (error) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      return false;
    }
  };

  const handleEditClip = async (requestId: string, clip: ClipType) => {
    const clipToEdit = getInformationById(requestId);
    if (!clipToEdit) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      return false;
    }
    try {
      const requestData: ClipEditRequestType = {
        customThumbnailSignedId: clipToEdit.imageId,
        isAudioOnly: clipToEdit.isAudioOnly,
        name: clipToEdit.name,
        description: clipToEdit.description,
        videoSignedId: clipToEdit.videoSignedId,
        recodedAt: new Date(Date.now() + parseInt(requestId, 10) * 1000),
        batchId: state.batchId,
        positionInBatch: positionInBatchRef.current,
      };
      const responseEditClip = await AlbumsController.editClip(
        clip.id,
        requestData,
      );
      if (responseEditClip) {
        setOneWasSaved(true);
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Your clip(s) have been saved.',
            message: '',
            type: NotificationType.Success,
          }),
        });
      }
      return true;
    } catch (error) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      return false;
    }
  };

  useEffect(() => {
    if (state.videosRecorded.length > 1) {
      dispatch({ type: selfRecordingActions.setBatchId });
      positionInBatchRef.current = 1;
    } else {
      positionInBatchRef.current = undefined;
    }
  }, []);

  const handleSaveClip = async (requestId: string) => {
    if (state.selectedQuestion && !oneWasSaved) {
      const isSaved = await handleEditClip(requestId, state.selectedQuestion);
      if (isSaved && positionInBatchRef.current) {
        positionInBatchRef.current += 1;
      }
      return isSaved;
    }
    const isSaved = await createClip(requestId);
    if (isSaved && positionInBatchRef.current) {
      positionInBatchRef.current += 1;
    }
    return isSaved;
  };

  const leaveModal = () => (
    <ModalWarning
      title="Your clips will be lost"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          If you leave this page without saving your clips will me deleted. Are
          you sure you want to continue?
        </div>
      }
      successButtonText="Leave & delete clips"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowLeaveModal(false)}
      successFn={() => {
        dispatch({ type: selfRecordingActions.deleteAllVideos });
        window.history.back();
        setShowLeaveModal(false);
      }}
    />
  );

  useEffect(() => {
    if (state.videosRecorded.length === 0) {
      dispatch({ type: selfRecordingActions.setReadyForNextStep });
      goToPage(
        RouteName.AlbumDetail,
        { id: albumId },
        { tab: 'Record/Upload' },
      );
    }
  }, [state.videosRecorded]);

  const handleDeleteAllClips = () => {
    dispatch({ type: selfRecordingActions.deleteAllVideos });
    setShowDeleteAllModal(false);
  };

  const deleteAllModal = () => (
    <ModalWarning
      title="Delete clips"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          Are you sure you want to delete these clips? Deleted clips cannot be
          recovered.
        </div>
      }
      successButtonText="Delete clips"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowDeleteAllModal(false)}
      successFn={() => {
        handleDeleteAllClips();
      }}
    />
  );

  const addPositionInBatch = (video: VideosRecordedType) => {
    if (positionInBatchRef.current) {
      positionInBatchRef.current += 1;
      dispatch({
        type: selfRecordingActions.setPositionInBatchToClip,
        positionInBatchToClip: {
          positionInBatch: positionInBatchRef.current,
          requestId: video.requestId,
        },
      });
    }
  };

  const handleSaveAllClips = async () => {
    if (state.selectedQuestion) {
      const firstClip = state.videosRecorded[0];
      const clipToSave = getInformationById(firstClip.requestId);
      if (!clipToSave) {
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Something went wrong',
            message: 'Please try again',
            type: NotificationType.Error,
          }),
        });
        return;
      }
      if (!oneWasSaved) {
        const savedSuccessfully = await handleEditClip(
          firstClip.requestId,
          state.selectedQuestion,
        );
        if (savedSuccessfully) {
          dispatch({
            type: selfRecordingActions.deleteVideo,
            videoToDelete: firstClip.requestId,
          });
          state.videosRecorded.forEach(async (video) => {
            if (video.requestId !== firstClip.requestId) {
              addPositionInBatch(video);
              const isSaved = await createClip(video.requestId);
              if (isSaved) {
                dispatch({
                  type: selfRecordingActions.deleteVideo,
                  videoToDelete: video.requestId,
                });
              }
            }
          });
        }
      } else {
        state.videosRecorded.forEach(async (video) => {
          addPositionInBatch(video);
          const savedSuccessfully = await createClip(video.requestId);
          if (savedSuccessfully) {
            dispatch({
              type: selfRecordingActions.deleteVideo,
              videoToDelete: video.requestId,
            });
          }
        });
      }
    } else {
      state.videosRecorded.forEach(async (video) => {
        addPositionInBatch(video);
        const savedSuccessfully = await createClip(video.requestId);
        if (savedSuccessfully) {
          dispatch({
            type: selfRecordingActions.deleteVideo,
            videoToDelete: video.requestId,
          });
        }
      });
    }
  };

  return (
    <>
      {showLeaveModal && leaveModal()}
      {showDeleteAllModal && deleteAllModal()}
      <div
        className={classnames(globalStyles.loggedMainContainer, styles.allPage)}
      >
        <div className={styles.content}>
          <div className={styles.backAndTitleContainer}>
            <BackButton onClickFn={() => setShowLeaveModal(true)} />
            <div className={styles.titleAndButtonContainer}>
              <span className={styles.title}>Review clip(s)</span>
              {state.videosRecorded.length > 1 && (
                <div className={styles.buttonContainer}>
                  <Button
                    buttonSize={ButtonSize.Small}
                    buttonStyle={ButtonStyle.RedGhost}
                    onClick={() => setShowDeleteAllModal(true)}
                  >
                    Delete all
                  </Button>
                  <Button
                    buttonSize={ButtonSize.Small}
                    buttonStyle={ButtonStyle.PrimaryFilled}
                    className={styles.saveButton}
                    onClick={() => {
                      handleSaveAllClips();
                    }}
                  >
                    <div className={styles.buttonText}>Save all</div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.videosList}>
            {state.videosRecorded.map((video, index) => (
              <ReviewVideoCard
                key={video.videoId}
                videoUrl={video.videoUrl}
                clipName={video.fileName ?? selfRecordingClipName(index)}
                requestId={video.requestId}
                handleSaveClip={handleSaveClip}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export { ReviewVideosPage };
