import { Consumer, createConsumer } from '@rails/actioncable';
import { apiBaseURL } from 'config/constants';
import { useEffect, useState } from 'react';

export const useActionCableConsumer = () => {
  const [consumer, setConsumer] = useState<Consumer | undefined>(undefined);

  useEffect(() => {
    setConsumer(createConsumer(`${apiBaseURL}/api/cable`));

    return () => {
      consumer?.connection.close();
    };
  }, []);

  return {
    consumer,
  };
};
