import { classnames } from 'helpers/utils';
import { CheckoutSteps, Item, StoreStateType } from 'pages/store/store-reducer';
import { StoreItem } from 'common/store-item';
import {
  checkAllFieldsAreFilled,
  getShippingCosts,
  getTotalPrice,
} from 'helpers/store-helper';
import { LineInfo } from 'common/LineInfo';
import styles from './checkout.module.scss';

type OrderListProps = {
  state: StoreStateType;
};

export const OrderList: React.FC<OrderListProps> = ({ state }) => (
  <div className={classnames(styles.orderContainer)}>
    <div className={classnames('text__body__regular__overline__textNeutral40')}>
      YOUR ORDER
    </div>
    <div className={styles.orderItems}>
      {state.itemsInCart?.map((item: Item) => (
        <StoreItem
          key={item.id}
          name={item.name}
          price={item.priceOneItem}
          image={item.image}
          amountOfItems={item.mountOfItems}
        />
      ))}
    </div>
    <div className={styles.orderTotal}>
      <LineInfo
        label="Subtotal:"
        info={`$${getTotalPrice(state).toFixed(2)}`}
      />
      {/*       {state.shippingDetails.state
        ? (
          <LineInfo
            label={`Taxes (${getShippingTax(state) * 100}%):`}
            info={`$${(getShippingTax(state) * getTotalPrice(state)).toFixed(2)}`}
            // FOR NOW WE ARE NOT USING TAXES
          />
        )
        : null} */}
      {state.shippingDetails.state ? (
        <div className={classnames(styles.shippingInfo)}>
          <div className={classnames(styles.shippingPrice)}>
            <div className="text__body__semi__bold__medium__textNeutral40">
              Shipping & Handling:
            </div>
            <div className="text__body__semi__bold__medium__textNeutral40">{`$${getShippingCosts(
              state,
            ).toFixed(2)}`}</div>
          </div>
          <div className="text__body__regular__small__textNeutral30">
            {state.shippingDetails.state}
          </div>
          {checkAllFieldsAreFilled(state) ||
          state.checkoutStep === CheckoutSteps.paymentSummary ? (
            <div className="text__body__regular__small__textNeutral30">
              {`${state.shippingDetails.addressFirstField}, ${state.shippingDetails.addressSecondField} ${state.shippingDetails.zipCode}. ${state.shippingDetails.city}.`}
            </div>
          ) : null}
        </div>
      ) : null}

      {checkAllFieldsAreFilled(state) ||
      state.checkoutStep === CheckoutSteps.paymentSummary ? (
        <LineInfo
          label="Total:"
          info={`$${(
            getShippingCosts(state) +
            /* (getShippingTax(state) * getTotalPrice(state)) */ +getTotalPrice(
              state,
            )
          ).toFixed(2)}`} // FOR NOW WE ARE NOT USING TAXES
        />
      ) : null}
    </div>
  </div>
);
