class AgoraStartRecording {
  resourceId: string;

  sid: string;

  constructor(params?: AgoraStartRecording) {
    this.resourceId = params?.resourceId || '';
    this.sid = params?.sid || '';
  }
}

export { AgoraStartRecording };
