import { classnames } from 'helpers/utils';
import { InfoIcon } from 'assets/icons';
import styles from './delete-confirmation-window.module.scss';

type DeleteConfirmationWindowProps = {
  notifyClose: () => void;
  notifyConfirmDeleteClip: () => void;
  className: string;
};

const DeleteConfirmationWindow: React.FC<DeleteConfirmationWindowProps> = ({
  className,
  notifyClose,
  notifyConfirmDeleteClip,
}) => (
  <div className={classnames(styles.clipRecordedWindowContainer, className)}>
    <div className={styles.title}>
      <div className={styles.infoIcon}>
        <InfoIcon />
      </div>
      <div className="text__body__regular__large__textNeutral10">
        Are you sure?
      </div>
    </div>
    <div className={classnames('text__body__regular__small__textNeutral20')}>
      You won’t be able to recover your recording if you delete it.
    </div>
    <div className={styles.buttons}>
      <button
        type="button"
        className={classnames(
          'text__body__regular__small__success20',
          styles.deleteButton,
        )}
        onClick={notifyConfirmDeleteClip}
      >
        Delete
      </button>

      <button
        type="button"
        className={classnames(
          'text__body__semi__bold__small__textNeutral20',
          styles.cancelButton,
        )}
        onClick={notifyClose}
      >
        Cancel
      </button>
    </div>
  </div>
);

export { DeleteConfirmationWindow };
