import React from 'react';
import { storeLinks } from 'config/constants';
import { Accordion } from 'common/accordion';
import { Breakpoints } from 'common/enums';
import { classnames } from 'helpers/utils';
import { useMediaQuery } from 'hooks/use-media-query';

import styles from './answered-questions.module.scss';

type AnsweredQuestionsProps = {
  questions?: ClipType[];
};

const AnsweredQuestions: React.FC<AnsweredQuestionsProps> = ({
  questions = [],
}) => {
  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  const showList = () => (
    <div className={styles.row}>
      {questions.map((question: ClipType) => (
        <div
          key={question.id}
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            styles.questionElement,
          )}
        >
          {question.name}
        </div>
      ))}
    </div>
  );

  const emptyList = () => (
    <div className={styles.empty}>
      <p className="text__title2__textNeutral30"> Nothing to see here yet.</p>
      <p className="text__body__regular__medium__textNeutral30">
        <span>Download the Generational Story app on your </span>
        <a
          className="text__body__link__medium__primary50"
          href={storeLinks.appleStore}
          target="_blank"
          rel="noopener noreferrer"
        >
          apple
        </a>
        &nbsp;or&nbsp;
        <a
          className="text__body__link__medium__primary50"
          href={storeLinks.androidStore}
          target="_blank"
          rel="noopener noreferrer"
        >
          android&nbsp;
        </a>
        smartphone and begin recording your story.
      </p>
    </div>
  );

  const showQuestionsList = () => (questions.length ? showList() : emptyList());

  return (
    <div className={styles.answeredContainer}>
      {isMobileOrTablet ? (
        <Accordion
          title="Answered questions"
          className="text__title2__textNeutral40"
          content={questions.length ? showList() : emptyList()}
        />
      ) : (
        showQuestionsList()
      )}
    </div>
  );
};

export { AnsweredQuestions };
