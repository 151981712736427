import { useCallback, useState } from 'react';
import { ButtonStyle } from 'common/button';
import { Input } from 'common/input';
import { ModalWarning } from 'common/modal-warning';
import { isEmailValid } from 'helpers/utils';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { Contributor } from 'models/contributor';
import { InvitationStatusId } from 'common/enums';

import styles from './add-contributor-modal.module.scss';

type AddContributorModalProps = {
  albumContributors: ContributorType[];
  albumId: number;
  notifyAddContributor: (contri: ContributorType) => void;
  onClose: () => void;
};

const AddContributorModal = ({
  albumId,
  albumContributors,
  notifyAddContributor,
  onClose,
}: AddContributorModalProps) => {
  const [contributor, setContributor] = useState({
    name: '',
    email: '',
  });
  const { name, email } = contributor;

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [loading, setLoading] = useState(false);

  const isEmailAlreadyAdded = (
    allContributors: ContributorType[],
    currentEmail: string,
  ) =>
    allContributors.find(
      (contri: ContributorType) => contri.email === currentEmail,
    );

  const getContributorEmailError = (
    allContributors: ContributorType[],
    currentEmail: string,
  ) => {
    let emailError = '';

    if (!currentEmail) {
      emailError = 'email cannot be empty';
    } else if (!isEmailValid(currentEmail)) {
      emailError = 'invalid email';
    } else if (isEmailAlreadyAdded(allContributors, currentEmail)) {
      emailError = 'email already added';
    }

    return emailError;
  };

  const updateContributor = (
    field: keyof typeof contributor,
    value: string,
  ) => {
    setContributor((prevState) => ({ ...prevState, [field]: value }));
    setErrors((prevState) => ({ ...prevState, [field]: '' }));
  };

  const validateField = (field: keyof typeof contributor) => {
    if (contributor[field].trim() === '') {
      setErrors((prevState) => ({
        ...prevState,
        [field]: `${field} cannot be empty`,
      }));
    } else if (field === 'email') {
      const emailMessage = getContributorEmailError(
        albumContributors,
        contributor[field],
      );
      setErrors((prevState) => ({
        ...prevState,
        [field]: emailMessage.toUpperCase(),
      }));
    }
  };

  const hasErrorsForm = ({
    name: contriName,
    email: contriEmail,
  }: {
    name: string;
    email: string;
  }) => contriName || contriEmail;

  const checkAllFieldsAndGetErrors = () => {
    const errs = { name: '', email: '' };

    if (name === '') {
      errs.name = 'Name cannot be empty';
    }

    errs.email = getContributorEmailError(albumContributors, email);

    return errs;
  };

  const addContributor = useCallback(async () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    setErrors(newErrorsList);

    if (hasErrorsForm(newErrorsList)) {
      return;
    }

    setLoading(true);
    try {
      const response = await AlbumsController.addContributor({
        albumId,
        name,
        email,
      });

      notifyAddContributor(
        new Contributor({
          name,
          email,
          invitationId: response.invitationId,
          invitationStatus: InvitationStatusId.pending,
        }),
      );
    } finally {
      setLoading(false);
    }

    onClose();
  }, [albumId, albumContributors, name, email]);

  return (
    <ModalWarning
      className={styles.addContributorModal}
      title="Add Contributor"
      content={
        <div className={styles.modalContent}>
          <Input
            id="name"
            name="name"
            label="Name"
            placeholder="John Doe"
            value={name}
            type="text"
            errorMessage={errors.name}
            onChange={(e: any) => updateContributor('name', e.target.value)}
            onBlur={() => validateField('name')}
          />
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="johndoe@example.com"
            value={email}
            type="email"
            errorMessage={errors.email}
            onChange={(e: any) => updateContributor('email', e.target.value)}
            onBlur={() => validateField('email')}
          />
        </div>
      }
      loading={loading}
      successButtonText="Add"
      successStyleButton={ButtonStyle.PrimaryFilled}
      closeFn={onClose}
      successFn={addContributor}
    />
  );
};

export { AddContributorModal };
