import { useContext, useEffect, useState } from 'react';

import { appActions, AppContext } from 'context';
import { NotificationType } from 'common/enums';
import { NotificationObject } from 'models/notificationObject';
import { goToPage, RouteName } from 'routes';

const useHandleApiError = (
  callback: (e: any) => void,
  showNotification: boolean = true,
) => {
  const { dispatch } = useContext(AppContext);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (error) {
      if (showNotification) {
        dispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            type: NotificationType.Error,
          }),
        });
      }
      // 401 means that the user is not authorized
      // because the csrf-token is invalid or the session cookie is incorrect.
      if (error.status === 401) {
        setTimeout(() => {
          dispatch({ type: appActions.SESSION_EXPIRED });
          goToPage(RouteName.SignIn);
        }, 1000);
      } else {
        callback(error);
      }
    }
  }, [error]);

  return setError;
};

export { useHandleApiError };
