import { AlertTriangleIcon } from 'assets/icons';
import { ModalWarning } from 'common/modal-warning';
import { contactLink } from 'config/constants';
import { AlbumsController } from 'networking/controllers/albums-controller';
import styles from './cancel-subscription-modal.module.scss';

type CancelSubscriptionModalProps = {
  albumId: number;
  refetchAlbum: () => Promise<void>;
  onClose: () => void;
};

const CancelSubscriptionModal = ({
  albumId,
  refetchAlbum,
  onClose,
}: CancelSubscriptionModalProps) => (
  <ModalWarning
    title="Cancel subscription"
    content={
      <>
        <div className={styles.warning}>
          <AlertTriangleIcon className={styles.icon} />
          <div className="text__body__regular__large__textNeutral50">
            You will lose access to your album once your current subscription
            runs out
          </div>
        </div>
        <div className="text__body__regular__small__textNeutral40">
          <p>
            {/* eslint-disable-next-line max-len */}
            Are you sure you want to cancel your subscription? If you cancel,
            the contributor will be notified and have an opportunity to assume
            private ownership of the album.
          </p>
          <br />
          <p>
            {/* eslint-disable-next-line max-len */}
            Unclaimed albums will remain active for viewing, editing,
            downloading or ordering physical copies from the GS store until the
            subscription ends and the files are locked.
          </p>
          <br />
          <p>
            {/* eslint-disable-next-line max-len */}
            If you intend to transfer this album to another account, please{' '}
            <a href={contactLink} target="_blank" rel="noreferrer">
              contact support
            </a>{' '}
            instead of cancelling.
          </p>
        </div>
      </>
    }
    className={styles.cancelSubscriptionModal}
    contentClassName={styles.content}
    showCancelButton
    closeButtonText="Cancel"
    successButtonText="Cancel subscription"
    closeFn={onClose}
    successFn={async () => {
      await AlbumsController.cancelSubscription(albumId);
      await refetchAlbum();
      onClose();
    }}
  />
);

export { CancelSubscriptionModal };
