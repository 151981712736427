import {
  contributorsOrderByOptions,
  paginationDefault,
} from 'config/constants';
import { ContributorsQueryParams } from 'models/contributors-query-params';
import { Pagination } from 'models/pagination';

export const CONTRIBUTORS_ROWS_PER_PAGE_DEFAULT = paginationDefault.pageSize;
export const CONTRIBUTORS_ROWS_INITIAL_CURRENT_PAGE = paginationDefault.pageNum;
export const CONTRIBUTORS_SORT_BY_DEEFAULT = contributorsOrderByOptions.find(
  (elem: SelectType) => elem.id === paginationDefault.lastUpdate.id,
);

export type ContributorsStateType = {
  isLoading: boolean;
  isSearching: boolean;
  searchValue: string;
  contributorsList: ContributorElementType[];
  pagination: PaginationType;
  queryParams: ContributorsQueryParamsType;
};

const ContributorsInitialState: ContributorsStateType = {
  isLoading: true,
  isSearching: true,
  searchValue: '',
  contributorsList: [],
  pagination: new Pagination(),
  queryParams: new ContributorsQueryParams(),
};

type ContributorsAction =
  | { type: 'IS_LOADING_PAGE'; isLoading: boolean }
  | { type: 'IS_SEARCHING_CONTRIBUTORS'; isSearching: boolean }
  | { type: 'UPDATE_SEARCH_VALUE'; searchValue: string }
  | { type: 'UPDATE_QUERY_PARAMS'; queryParams: ContributorsQueryParamsType }
  | { type: 'UPDATE_PAGINATION'; pagination: PaginationType }
  | { type: 'UPDATE_CONTRIBUTORS'; contributorsList: ContributorElementType[] };

const ContributorsReducer = (
  state: ContributorsStateType,
  action: ContributorsAction,
) => {
  switch (action.type) {
    case 'IS_LOADING_PAGE':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case 'IS_SEARCHING_CONTRIBUTORS':
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case 'UPDATE_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.searchValue,
      };

    case 'UPDATE_PAGINATION':
      return {
        ...state,
        pagination: action.pagination,
      };

    case 'UPDATE_QUERY_PARAMS':
      return {
        ...state,
        queryParams: action.queryParams,
      };

    case 'UPDATE_CONTRIBUTORS':
      return {
        ...state,
        contributorsList: action.contributorsList,
      };

    default:
      return state;
  }
};

export { ContributorsReducer, ContributorsInitialState };
export type { ContributorsAction };
