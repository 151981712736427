import { useEffect, useState } from 'react';
import { useFlags } from 'flagsmith/react';

const useFeatureFlags = () => {
  const flags = useFlags(['album_download_enabled']);
  const [featureFlags, setFeatureFlags] = useState({
    albumDownloadEnabled: !!flags?.album_download_enabled?.enabled,
  });

  useEffect(() => {
    setFeatureFlags({
      albumDownloadEnabled: !!flags?.album_download_enabled?.enabled,
    });
  }, [flags]);
  return featureFlags;
};

export { useFeatureFlags };
