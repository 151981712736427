import React, { useContext, useEffect, useState } from 'react';
import { AppContext, appActions } from 'context';
import { albumHasPublicClip, hasAlbumAccess, isOwner } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import { VideoCall } from 'common/video-call';
import { goToPage, RouteName } from 'routes';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { VideoCallRole } from 'hooks/use-videocall-websocket';

type ParamType = {
  agoraAlbumId: string;
  videoCallToken: string;
  role: VideoCallRole;
};

const AgoraVideoCall: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const { agoraAlbumId, videoCallToken, role } = useParams<ParamType>();

  const [album, setAlbum] = useState<AlbumType>();

  const { user } = state.data;

  const handleEndCall = async () => {
    try {
      if (album && !hasAlbumAccess(user, album)) {
        const sharedAlbum = await AlbumsController.getSharedAlbum(album.id);
        const hasPublicClips = albumHasPublicClip(sharedAlbum);
        if (hasPublicClips) {
          goToPage(RouteName.AlbumDetailGuest, { id: agoraAlbumId });
        } else {
          goToPage(RouteName.SignIn);
        }
      } else {
        goToPage(RouteName.AlbumDetail, { id: agoraAlbumId });
      }
    } catch (_error) {
      goToPage(RouteName.SignIn);
    }
  };

  const getAlbum = async () => {
    try {
      const albumResponse = await AlbumsController.getVideoCallAlbum(
        +agoraAlbumId,
        videoCallToken,
      );
      setAlbum(albumResponse);
    } catch (err: any) {
      handleEndCall();

      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Video call',
          message: 'Album Information fails',
          type: NotificationType.Error,
        }),
      });
    }
  };

  useEffect(() => {
    getAlbum();
  }, []);

  const showVideoCallComponent = () => (
    <div>
      <VideoCall
        id={agoraAlbumId}
        user={user}
        album={album!}
        subSchemes={state.subscriptionsScheme!}
        notifyAlbumChange={(alb: AlbumType) => setAlbum(alb)}
        notifyEndCall={() => handleEndCall()}
        refetchAlbum={getAlbum}
        initialRole={role}
      />
    </div>
  );

  return album && (!isOwner(user, album) || state.subscriptionsScheme?.length)
    ? showVideoCallComponent()
    : null;
};

export { AgoraVideoCall };
