import { classnames } from 'helpers/utils';
import { BoxIcon, PlusIcon } from 'assets/icons';
import { Button, ButtonSize } from 'common/button';
import CypressIds from 'cypressIds';
import styles from './no-templates-created.module.scss';

type NoTemplatesCreatedProps = {
  notifyAddTemplate: () => void;
};

const NoTemplatesCreated: React.FC<NoTemplatesCreatedProps> = ({
  notifyAddTemplate,
}) => (
  <div className={styles.emptyPage}>
    <BoxIcon className={styles.boxIcon} />
    <div className={styles.emptyPageTexts}>
      <p
        className={classnames(
          'text__heading4__textNeutral30',
          styles.headingEmpty,
        )}
      >
        No templates created
      </p>
      <p className="text__body__regular__medium__textNeutral40">
        Create a new template to make your interview planning process easier!
      </p>
      <Button
        className={styles.addTemplateButton}
        buttonSize={ButtonSize.Small}
        onClick={notifyAddTemplate}
        dataCy={CypressIds.addTemplateButton}
      >
        <PlusIcon />
        New template
      </Button>
    </div>
  </div>
);

export { NoTemplatesCreated };
