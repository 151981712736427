class MetaTags {
  description: string;

  image?: string;

  constructor(params: MetaTagsType | null) {
    this.description = params?.description || '';
    this.image = params?.image || '';
  }
}

export { MetaTags };
