import { Template } from 'models/template';

export type TemplateStateType = {
  isLoading: boolean;
  templatesList: PreBuiltQuestionsType[];
  isEditingName: boolean;
  templateName: string;
  selectedTemplate: PreBuiltQuestionsType;
};

const TemplatesInitialState: TemplateStateType = {
  templatesList: [],
  isLoading: true,
  isEditingName: false,
  templateName: '',
  selectedTemplate: new Template(),
};

type TemplatesAction =
  | { type: 'IS_LOADING'; isLoading: boolean }
  | { type: 'IS_EDITING_NAME'; isEditingName: boolean }
  | { type: 'CURRENT_NAME'; templateName: string }
  | { type: 'UPDATE_TEMPLATES'; templatesList: PreBuiltQuestionsType[] }
  | { type: 'SELECT_TEMPLATE'; selectedTemplate: PreBuiltQuestionsType };

const TemplatesReducer = (
  state: TemplateStateType,
  action: TemplatesAction,
) => {
  switch (action.type) {
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case 'UPDATE_TEMPLATES':
      return {
        ...state,
        templatesList: action.templatesList,
      };

    case 'IS_EDITING_NAME':
      return {
        ...state,
        isEditingName: action.isEditingName,
      };

    case 'CURRENT_NAME':
      return {
        ...state,
        templateName: action.templateName,
      };

    case 'SELECT_TEMPLATE':
      return {
        ...state,
        selectedTemplate: action.selectedTemplate,
      };

    default:
      return state;
  }
};

export { TemplatesReducer, TemplatesInitialState };
export type { TemplatesAction };
