import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { PersonalMandatorySerializer } from 'networking/serializers/personal-mandatory-serializer';

class SignController {
  static async validatePersonalInfo(user: MandatoryFieldsType) {
    const serializedUserMandatoryFields =
      PersonalMandatorySerializer.serialize(user);

    return ApiService.post<void>(
      API_ROUTES.CHECK_SIGNUP_STEP,
      serializedUserMandatoryFields,
    );
  }
}

export { SignController };
