import { ChangeEvent, Dispatch, useState } from 'react';
import { dayjs } from 'helpers/dayjs';
import {
  TemplateStateType,
  TemplatesAction,
} from 'pages/templates/templates-reducer';
import { classnames } from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { DeleteIcon, EditIcon } from 'assets/icons';
import { UserController } from 'networking/controllers/user-controller';
import { ModalWarning } from 'common/modal-warning';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { appActions } from 'context';
import { Input } from 'common/input';
import { templateNameMaxLength } from 'config/constants';
import { Template } from 'models/template';
import styles from './template-name-section.module.scss';

type TemplateNameSectionProps = {
  templatesState: TemplateStateType;
  templatesDispatch: Dispatch<TemplatesAction>;
  generalDispatch: Dispatch<any>;
  notifyUpdateTemplateFromList: (
    updatedTemplate: PreBuiltQuestionsType,
  ) => void;
  notifyUpdateTemplateSelected: (
    updatedTemplate: PreBuiltQuestionsType,
  ) => void;
};

const TemplateNameSection: React.FC<TemplateNameSectionProps> = ({
  templatesState,
  templatesDispatch,
  generalDispatch,
  notifyUpdateTemplateFromList,
  notifyUpdateTemplateSelected,
}) => {
  const [showWarning, setShowWarning] = useState(false);

  const { selectedTemplate, templatesList, isEditingName, templateName } =
    templatesState;

  const assignNewSelectedTemplate = (
    deletedTemplate: PreBuiltQuestionsType,
  ) => {
    let newSelectedtemplate = new Template();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= templatesList.length - 1; i++) {
      if (templatesList[i].id === deletedTemplate.id) {
        if (templatesList.length === 1) {
          templatesDispatch({
            type: 'SELECT_TEMPLATE',
            selectedTemplate: newSelectedtemplate,
          });
          return;
        }

        if (i !== templatesList.length - 1) {
          newSelectedtemplate = templatesList[i + 1];
        } else {
          newSelectedtemplate = templatesList[i - 1];
        }

        templatesDispatch({
          type: 'SELECT_TEMPLATE',
          selectedTemplate: newSelectedtemplate,
        });
      }
    }
  };

  const deleteTemplateFromList = (deletedTemplate: PreBuiltQuestionsType) => {
    const updatedTemplateList = templatesList.filter(
      (template: PreBuiltQuestionsType) => template.id !== deletedTemplate.id,
    );

    templatesDispatch({
      type: 'UPDATE_TEMPLATES',
      templatesList: updatedTemplateList,
    });
  };

  const cancelTemplateEditionAfterDeleting = () => {
    templatesDispatch({ type: 'IS_EDITING_NAME', isEditingName: false });
  };

  const handleDeleteTemplate = async () => {
    try {
      await UserController.deleteCustomTemplate(+selectedTemplate.id);

      assignNewSelectedTemplate(selectedTemplate);
      deleteTemplateFromList(selectedTemplate);

      if (isEditingName) {
        cancelTemplateEditionAfterDeleting();
      }

      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Template deleted',
          type: NotificationType.Success,
        }),
      });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error deleting template ',
        }),
      });
    } finally {
      setShowWarning(false);
    }
  };

  const showCancelWarning = () => (
    <ModalWarning
      title="Delete template"
      content="Are you sure you want to delete this template? This action cannot be undone."
      successButtonText="Delete Template"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowWarning(false)}
      successFn={() => handleDeleteTemplate()}
    />
  );

  const handleEditingName = (isEditing: boolean) => {
    templatesDispatch({ type: 'IS_EDITING_NAME', isEditingName: isEditing });
  };

  const showNameLabel = () => (
    <>
      <span className={classnames('text__title1__textNeutral40', styles.name)}>
        {selectedTemplate.name}
      </span>
      <Button
        className={styles.pencil}
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.GreyGhost}
        onClick={() => handleEditingName(true)}
      >
        <EditIcon />
      </Button>
    </>
  );

  const handleChangeName = async () => {
    try {
      const requestData: EditTemplateRequestType = {
        template: {
          ...selectedTemplate,
          name: templateName,
        },
      };

      const updatedTemplate =
        await UserController.editCustomTemplate(requestData);
      notifyUpdateTemplateFromList(updatedTemplate);
      notifyUpdateTemplateSelected(updatedTemplate);

      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Template updated',
          type: NotificationType.Success,
        }),
      });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error updating template ',
        }),
      });
    } finally {
      setShowWarning(false);
    }

    handleEditingName(false);
  };

  const showEditingName = () => (
    <div className={styles.inputAndActions}>
      <Input
        className={styles.inputName}
        id="templateName"
        name="templateName"
        value={templateName}
        maxLength={templateNameMaxLength}
        withValidation={false}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          templatesDispatch({
            type: 'CURRENT_NAME',
            templateName: e.target.value,
          });
        }}
      />
      <div>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryGhost}
          disabled={templateName === ''}
          onClick={handleChangeName}
        >
          Save
        </Button>
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={() => {
            templatesDispatch({
              type: 'CURRENT_NAME',
              templateName: selectedTemplate.name,
            });
            handleEditingName(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {showWarning && showCancelWarning()}

      <div className={styles.templateNameSection}>
        <div className={styles.nameAndDeleteButton}>
          <div className={styles.name}>
            {isEditingName ? showEditingName() : showNameLabel()}
          </div>
          <div>
            <Button
              className={styles.deleteButton}
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.GreyGhost}
              onClick={() => setShowWarning(true)}
            >
              <span> Delete template</span>
              <DeleteIcon />
            </Button>
          </div>
        </div>

        <div
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            styles.createdDate,
          )}
        >
          {`Created: ${dayjs(selectedTemplate.createdAt).format(
            'MMM DD, YYYY',
          )} `}
        </div>
      </div>
    </>
  );
};

export { TemplateNameSection };
