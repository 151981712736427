import { Breakpoints } from 'common/enums';
import { Button, ButtonSize } from 'common/button';
import { PlusIcon } from 'assets/icons';
import { useMediaQuery } from 'hooks/use-media-query';
import { classnames } from 'helpers/utils';
import { TemplateStateType } from 'pages/templates/templates-reducer';
import { templatesCountMaxLength } from 'config/constants';
import CypressIds from 'cypressIds';
import styles from './templates-header.module.scss';

type TemplatesHeaderProps = {
  templatesState: TemplateStateType;
  className?: string;
  notifyAddTemplate: () => void;
};

const TemplatesHeader: React.FC<TemplatesHeaderProps> = ({
  className = '',
  templatesState,
  notifyAddTemplate,
}) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.lg}px)`);

  return (
    <div className={className}>
      <div className={styles.templatesTitleAndAddButton}>
        <div className={classnames('text__heading4__textNeutral40')}>
          {' '}
          Templates{' '}
        </div>
        <Button
          buttonSize={mobile ? ButtonSize.Tiny : ButtonSize.Small}
          onClick={notifyAddTemplate}
          disabled={
            templatesState.templatesList.length >= templatesCountMaxLength
          }
          dataCy={CypressIds.templatesAddButton}
        >
          <div className={styles.addButton}>
            <PlusIcon />
            <span> New</span>
          </div>
        </Button>
      </div>
      <div className={classnames('text__body__regular__medium__textNeutral30')}>
        Templates are reusable lists of questions you can create to make
        planning your interviews easier.
      </div>
    </div>
  );
};

export { TemplatesHeader };
