import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { Dispatch } from 'react';
import {
  ContributorsAction,
  ContributorsStateType,
} from 'pages/contributors/contributors-reducer';
import { Button, ButtonStyle } from 'common/button';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { ContributorsMobile } from './components/contributors-mobile';
import { ContributorsRows } from './components/contributors-rows';
import styles from './contributors-table.module.scss';

type ContributorsTableProps = {
  contributorsState: ContributorsStateType;
  contributorsDispatch: Dispatch<ContributorsAction>;
  className?: string;
  notifyGetMoreContributors: () => void;
};

const ContributorsTable: React.FC<ContributorsTableProps> = ({
  contributorsState,
  contributorsDispatch,
  className,
  notifyGetMoreContributors,
}) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const { pagination, isSearching } = contributorsState;

  const showMoreButton = () =>
    !isSearching ? (
      <Button
        buttonStyle={ButtonStyle.PrimaryRoundedFilled}
        onClick={() => notifyGetMoreContributors()}
      >
        Show more
      </Button>
    ) : (
      <Spinner className={globalStyles.smallSpinner} />
    );

  const isNotLastPage = () => pagination.currentPage !== pagination?.lastPage;

  return (
    <div className={className}>
      {mobile ? (
        <ContributorsMobile contributorsState={contributorsState} />
      ) : (
        <ContributorsRows
          contributorsState={contributorsState}
          contributorsDispatch={contributorsDispatch}
        />
      )}
      <div className={styles.moreContributorsButton}>
        {contributorsState.contributorsList.length && isNotLastPage()
          ? showMoreButton()
          : ''}
      </div>
    </div>
  );
};

export { ContributorsTable };
