import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './line.module.scss';

type LineProps = {
  className?: string;
};

const Line: React.FC<LineProps> = ({ className = '' }) => {
  const classes = [styles.line, className];
  return <div className={classnames(...classes)} />;
};

export { Line };
