import {
  CAMERA_ENABLED_KEY,
  CAMERA_ID_KEY,
  MICROPHONE_ENABLED_KEY,
  MICROPHONE_ID_KEY,
} from 'config/constants';
import { captureException } from 'sentry';
import AgoraRTC from 'agora-rtc-react';

import {
  requestAudioPermissions,
  requestVideoPermissions,
} from 'mic-check/lib/requestMediaPermissions';
import { SensorSelection } from './context-reducer';

function getSelectedCameraOption(
  options: MediaDeviceInfo[],
): MediaDeviceInfo | undefined {
  const selectedCameraId = localStorage.getItem(CAMERA_ID_KEY);
  return options.find((option) => option.deviceId === selectedCameraId);
}

function getSelectedMicrophoneOption(
  options: MediaDeviceInfo[],
): MediaDeviceInfo | undefined {
  const selectedMicrophoneId = localStorage.getItem(MICROPHONE_ID_KEY);
  return options.find((option) => option.deviceId === selectedMicrophoneId);
}

// Default to enabled if nothing is stored in local storage
function getCameraEnabled(): boolean {
  const cameraEnabled = localStorage.getItem(CAMERA_ENABLED_KEY) || 'true';
  return cameraEnabled === 'true';
}

function getMicrophoneEnabled(): boolean {
  const microphoneEnabled =
    localStorage.getItem(MICROPHONE_ENABLED_KEY) || 'true';
  return microphoneEnabled === 'true';
}

export async function loadSensorSelection(): Promise<SensorSelection> {
  let cameraPermissionError = null;
  let cameraOptions: MediaDeviceInfo[] = [];
  try {
    await requestVideoPermissions();
    cameraOptions = await AgoraRTC.getCameras();
  } catch (err: any) {
    captureException(err, { tags: { video: true } });
    cameraPermissionError = err;
  }

  let microphonePermissionError = null;
  let microphoneOptions: MediaDeviceInfo[] = [];
  try {
    await requestAudioPermissions();
    microphoneOptions = await AgoraRTC.getMicrophones();
  } catch (err: any) {
    captureException(err, { tags: { audio: true } });
    microphonePermissionError = err;
  }

  const defaultCamera = cameraOptions.length ? cameraOptions[0] : undefined;
  const defaultMicrophone = microphoneOptions.length
    ? microphoneOptions[0]
    : undefined;
  return {
    camera: {
      enabled: !cameraPermissionError && getCameraEnabled(),
      error: cameraPermissionError,
      options: cameraOptions,
      selectedOption: getSelectedCameraOption(cameraOptions) || defaultCamera,
    },
    microphone: {
      enabled: !microphonePermissionError && getMicrophoneEnabled(),
      error: microphonePermissionError,
      options: microphoneOptions,
      selectedOption:
        getSelectedMicrophoneOption(microphoneOptions) || defaultMicrophone,
    },
  };
}
