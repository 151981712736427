class SubscriptionDealSerializer {
  static deSerializeSubscriptionDealResponse(
    data: SubscriptionDealResponseRaw,
  ): SubscriptionDealResponseSerialized {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      priceModifier: data.price_modifier,
      expirationDate: data.expiration_date,
      appliesTo: data.applies_to,
    };
  }

  static deSerializeSubscriptionDiscountedPrice(
    data: SubscriptionDiscountedPriceRaw,
  ): SubscriptionDiscountedPriceSerialized {
    return {
      id: data.id,
      price: data.price,
    };
  }

  static deSerializeSubscriptionDiscountedPricesList(
    data: SubscriptionDiscountedPriceRaw[],
  ): SubscriptionDiscountedPricesListSerialized {
    const subPrices = data.map((subPrice: SubscriptionDiscountedPriceRaw) =>
      SubscriptionDealSerializer.deSerializeSubscriptionDiscountedPrice(
        subPrice,
      ),
    );

    return { prices: subPrices };
  }

  static deSerializeSubscriptionSwitch(
    data: SubscriptionSwitchRaw,
  ): SubscriptionSwitchSerialized {
    return {
      id: data.id,
      price: data.price ? parseFloat(data.price.toString()) : 0,
      rollover: parseFloat(data.rollover.toString()),
      purchaseExtraCharge: parseFloat(data.purchases_extra_charge.toString()),
    };
  }

  static deSerializeSubscriptionSwitchPricesList(
    data: SubscriptionSwitchRaw[],
  ): SubscriptionSwitchListSerialized {
    const subPrices = data.map((subPrice: SubscriptionSwitchRaw) =>
      SubscriptionDealSerializer.deSerializeSubscriptionSwitch(subPrice),
    );

    return { prices: subPrices };
  }
}

export { SubscriptionDealSerializer };
