/* eslint-disable max-len */
import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { AgoraSerializer } from 'networking/serializers/agora-serializer';
import { AgoraTokenChannel } from 'models/agora-token-channel';
import { AgoraStartRecording } from 'models/agora-start-recording';
import { AgoraStopRecording } from 'models/agora-stop-recording';
import { AgoraCallTimeLeft } from 'models/agora-call-time-left';
import AgoraRTC from 'agora-rtc-react';
import { agoraOptions } from 'config/constants';

// Suppress unnecessary logs
AgoraRTC.setLogLevel(agoraOptions.logLevel);

class AgoraController {
  static async getAgoraToken(videoCallToken: string) {
    const response = await ApiService.get<TokenChannelRaw>(
      API_ROUTES.AGORA_GET_TOKEN(videoCallToken),
    );

    const deSerializedTokenChannel = AgoraSerializer.deSerializeTokenChannel(
      response.data,
    );

    return new AgoraTokenChannel(deSerializedTokenChannel);
  }

  static async startRecording(
    videoCallToken: string,
    data: StartRecordingRequestType,
  ) {
    const serializedStartRecordingRequest =
      AgoraSerializer.serializeStartRecordingRequest(data);

    const response = await ApiService.post<StartRecordingResponseRaw>(
      API_ROUTES.AGORA_START_RECORDING(videoCallToken),
      serializedStartRecordingRequest,
    );

    const deSerializedStartRecordingResponse =
      AgoraSerializer.deSerializeStartRecordingResponse(response.data);

    return new AgoraStartRecording(deSerializedStartRecordingResponse);
  }

  static async stopRecording(
    videoCallToken: string,
    data: StopRecordingRequestType,
  ) {
    const serializedStopRecordingRequest =
      AgoraSerializer.serializeStopRecordingRequest(data);

    const response = await ApiService.post<StopRecordingResponseRaw>(
      API_ROUTES.AGORA_STOP_RECORDING(videoCallToken),
      serializedStopRecordingRequest,
    );

    const deSerializedStopRecordingResponse =
      AgoraSerializer.deSerializeStopRecordingResponse(response.data);

    return new AgoraStopRecording(deSerializedStopRecordingResponse);
  }

  static async getNewVideoCallUsedTime(albumId: number) {
    const response = await ApiService.put<CallTimeLeftResponseRaw>(
      API_ROUTES.AGORA_GET_VIDEO_CALL_USED_TIME(albumId),
    );

    const deSerializedCallTimeLeftResponse =
      AgoraSerializer.deSerializeCallTimeLeftResponse(response.data);

    return new AgoraCallTimeLeft(deSerializedCallTimeLeftResponse);
  }
}

export { AgoraController };
