import { useContext, useMemo } from 'react';
import {
  VideoIcon,
  VideoDisabledIcon,
  MicIcon,
  MicDisabledIcon,
  ChevronIcon,
} from 'assets/icons';
import { classnames } from 'helpers/utils';
import { SensorType } from 'context/sensor-selection-context/context-reducer';
import { SensorSelectionContext } from 'context';
import { SelfRecordingContext } from 'context/self-recording-context';
import styles from './waiting-room-sensor-selection-button.module.scss';

type WaitingRoomSensorSelectionProps = {
  sensorType: SensorType;
  menuOpen: boolean;
  toggleMenuOpen: () => void;
};

const WaitingRoomSensorSelectionButton = ({
  sensorType,
  menuOpen,
  toggleMenuOpen,
}: WaitingRoomSensorSelectionProps) => {
  const { sensorSelection } = useContext(SensorSelectionContext);
  const { state } = useContext(SelfRecordingContext);

  const sensorIcon = useMemo(() => {
    if (sensorType === 'camera') {
      return sensorSelection[sensorType].enabled ? (
        <VideoIcon
          className={state.isRecording ? styles.disabled : styles.enabled}
        />
      ) : (
        <VideoDisabledIcon />
      );
    }
    return sensorSelection[sensorType].enabled ? (
      <MicIcon
        className={state.isRecording ? styles.disabled : styles.enabled}
      />
    ) : (
      <MicDisabledIcon />
    );
  }, [sensorType, sensorSelection[sensorType].enabled, state.isRecording]);

  return (
    <button
      type="button"
      className={classnames(styles.selectionButton, {
        [styles.enabled]: sensorSelection[sensorType].enabled,
      })}
      onClick={toggleMenuOpen}
      disabled={!!sensorSelection[sensorType].error || state.isRecording}
    >
      <div className={styles.iconContainer}>{sensorIcon}</div>
      <div className={styles.label}>
        {sensorSelection[sensorType].selectedOption?.label || 'Not Available'}
      </div>
      <div className={styles.iconContainer}>
        <ChevronIcon
          className={classnames(styles.icon, {
            [styles.verticallyFlipped]: menuOpen,
          })}
        />
      </div>
    </button>
  );
};

export { WaitingRoomSensorSelectionButton };
