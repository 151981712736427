import React from 'react';
import { classnames } from 'helpers/utils';
import { AlertTriangleIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import styles from './info-badge.module.scss';

type InfoBadgeProps = {
  icon?: React.ReactNode;
  message: string;
  className?: string;
  showAction?: boolean;
  buttonAction?: () => void;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
};

const InfoBadge: React.FC<InfoBadgeProps> = ({
  icon = <AlertTriangleIcon className={styles.icon} />,
  message,
  className = '',
  showAction = false,
  buttonAction,
  buttonText,
  buttonIcon,
}) => (
  <div className={classnames(className, styles.badge)}>
    <div className={styles.messageAndIcon}>
      {icon}{' '}
      <p className="text__body__regular__small__textNeutral30">{message}</p>
    </div>
    {showAction && (
      <div>
        <Button
          buttonType={ButtonType.Button}
          buttonStyle={ButtonStyle.PrimaryGhost}
          onClick={buttonAction}
          buttonSize={ButtonSize.Tiny}
        >
          <div className={styles.buttonContainer}>
            <span className="text__body__regular__tiny">{buttonText}</span>
            {buttonIcon}
          </div>
        </Button>
      </div>
    )}
  </div>
);

export { InfoBadge };
