import { ReactNode, useContext, useEffect, useState } from 'react';
import { appActions, AppContext } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import {
  getQueryParamsObject,
  redictDependingFailingStatus,
} from 'helpers/utils';
import { Spinner } from 'common/spinner';
import { goToPage, RouteName } from 'routes';
import { LocalStorageApi } from 'helpers/local-storage';
import { injectQueryParamsIntoUrlPath } from 'routes/route-helpers';
import styles from './auth.module.scss';

type AuthProps = {
  optional?: boolean;
  children: ReactNode;
};

const Auth = ({ optional, children }: AuthProps) => {
  const [fetching, setFetching] = useState(true);
  const { state, dispatch } = useContext(AppContext);

  useEffect(
    () => () => {
      setFetching(false);
    },
    [],
  );

  const isUserGoingToAlbumDetail = (userUrlPath: string) => {
    const pattern = /^\/albums\/\d+$/;

    return userUrlPath.match(pattern);
  };

  const authenticate = async () => {
    if (state.logged) {
      setFetching(false);
      return;
    }

    try {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });

      // If the route is only optionally authenticated, we don't need to redirect to the login page
      if (!optional) redictDependingFailingStatus(data.user);

      setFetching(false);
    } catch (e) {
      if (optional) {
        setFetching(false);
        return;
      }

      dispatch({ type: appActions.SESSION_EXPIRED });
      const queryParams = getQueryParamsObject();

      const userUrlPath = window.location.pathname;
      const userFullUrl = injectQueryParamsIntoUrlPath(
        userUrlPath,
        queryParams,
      );

      if (!isUserGoingToAlbumDetail(userUrlPath)) {
        LocalStorageApi.set('previous-url', userFullUrl);
        goToPage(RouteName.SignIn);
        return;
      }

      const albumId = userUrlPath.substring(userUrlPath.lastIndexOf('/') + 1);

      if (queryParams.noGuest) {
        LocalStorageApi.set('previous-url', userFullUrl);
        goToPage(RouteName.SignIn);
      } else {
        goToPage(RouteName.AlbumDetailGuest, { id: albumId });
      }
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (state.logged || optional) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    );
  }

  return null;
};

export { Auth };
