class LocalStorageApi {
  static storage = window.localStorage;

  static get = (key: string) => {
    const item = LocalStorageApi.storage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  };

  static set = (key: string, item: any) => {
    if (item === undefined) {
      return;
    }

    LocalStorageApi.storage.setItem(key, JSON.stringify(item));
  };

  static remove = (key: string) => {
    LocalStorageApi.storage.removeItem(key);
  };
}

export { LocalStorageApi };
