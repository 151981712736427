class PaginationSerializer {
  static deSerializePagination(data: PaginationRaw): PaginationSerialized {
    return {
      currentPage: data.current_page,
      lastPage: data.last_page,
      pageSize: data.page_size,
      count: data.count,
    };
  }
}

export { PaginationSerializer };
