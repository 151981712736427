import React from 'react';

import { logger } from 'helpers/logger';
import { UnexpectedError } from 'pages/unexpected-error';
import * as Sentry from '@sentry/react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

/*
  NOTE: remember that error boundaries do not catch
  all kinds of errors: https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
*/
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidMount() {
    // Catch unhandled Promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      // Unhandled rejections do not necessarily indicate a crash
      // of the whole application, so there's no immediate need
      // to show a fallback UI.
      logger.warn(`Unhandled Promise rejection: ${event.reason}`);
      logger.error(event.reason);
    });
  }

  render() {
    return (
      <Sentry.ErrorBoundary
        onError={(error: Error) => logger.log(error)}
        fallback={<UnexpectedError />}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export { ErrorBoundary };
