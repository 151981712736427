import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  PauseIconFilledIcon,
  PlayIconFilledIcon,
  VolumeIcon,
  VolumeMuteIcon,
} from 'assets/icons';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import styles from './audio-player.module.scss';

type AudioPlayerProps = {
  videoUrl?: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ videoUrl }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  useEffect(() => {
    setIsPlaying(false);
  }, [videoUrl]);

  useEffect(() => {
    const updateCurrentTime = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };

    const updateDuration = () => {
      if (audioRef.current) {
        setDuration(audioRef.current?.duration);
      }
    };

    const goToStart = () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
      }
    };

    audioRef.current?.addEventListener('timeupdate', updateCurrentTime);
    audioRef.current?.addEventListener('durationchange', updateDuration);
    audioRef.current?.addEventListener('ended', goToStart);

    return () => {
      audioRef.current?.removeEventListener('timeupdate', updateCurrentTime);
      audioRef.current?.removeEventListener('durationchange', updateDuration);
      audioRef.current?.removeEventListener('ended', goToStart);
    };
  }, [audioRef.current]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handleSeek = (e: ChangeEvent<HTMLInputElement>) => {
    const seekTime = parseFloat(e.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
    }
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}`;
  };

  return (
    <div className={styles.audio}>
      <button
        type="button"
        onClick={handlePlayPause}
        className={styles.playPauseButton}
      >
        {isPlaying ? <PauseIconFilledIcon /> : <PlayIconFilledIcon />}
      </button>

      {!isMobile && (
        <span className="text__body__regular__small__textNeutral30">
          {' '}
          {formatTime(currentTime)}
        </span>
      )}
      <input
        type="range"
        name="seek"
        min="0"
        max={duration}
        step="0.01"
        value={currentTime}
        onChange={handleSeek}
        className={styles.seek}
        style={{
          background: `linear-gradient(to right, #A1C9D3 ${
            (currentTime / duration) * 100
          }%, white ${(currentTime / duration) * 100}%)`,
          height: '4px',
        }}
      />

      {!isMobile && (
        <span className="text__body__regular__small__textNeutral30">
          {formatTime(duration - currentTime)}
        </span>
      )}
      {volume > 0 ? <VolumeIcon /> : <VolumeMuteIcon />}
      {!isMobile && (
        <div>
          <input
            type="range"
            id="volume"
            name="volume"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className={styles.volume}
            style={{
              background: `linear-gradient(to right, #8B8987 ${
                volume * 100
              }%, white ${volume * 100}%)`,
              height: '4px',
            }}
          />
        </div>
      )}

      <audio ref={audioRef} src={videoUrl}>
        <track kind="captions" />
      </audio>
    </div>
  );
};

export { AudioPlayer };
