import { classnames } from 'helpers/utils';
import spinnerPrimary from 'assets/images/spinner-primary.gif';
import spinnerWhite from 'assets/images/spinner-white.gif';
import styles from './spinner.module.scss';

type SpinnerProps = {
  color?: string;
  className?: string;
};

const Spinner = ({ color = 'primary', className = '' }: SpinnerProps) => (
  <img
    alt="Loading content"
    src={color === 'primary' ? spinnerPrimary : spinnerWhite}
    className={classnames(className || styles.globalSpinner)}
  />
);

export { Spinner };
