import { ChevronIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import styles from './back-button.module.scss';

type BackButtonProperties = {
  className?: string;
  onClickFn: () => void;
};

const BackButton: React.FC<BackButtonProperties> = ({
  onClickFn,
  className = '',
}) => (
  <div className={className}>
    <button type="button" className={styles.backButton} onClick={onClickFn}>
      <div className={styles.chevronIcon}>
        <ChevronIcon />
      </div>
      <div
        className={classnames(
          styles.backText,
          'text__body__regular__overline__textNeutral30',
        )}
      >
        BACK
      </div>
    </button>
  </div>
);

export { BackButton };
