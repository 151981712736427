import { AlbumOwnershipFiltersIds, Countries } from 'common/enums';
import { paginationDefault } from 'config/constants';
import { AlbumsQueryParams } from 'models/albumsQueryParams';
import { Pagination } from 'models/pagination';
import { User } from 'models/user';
import { AlbumsQueryParamsType } from 'networking/types/albums-query-params';

const personalErrors: AccountPersonalErrorsType = {
  name: '',
  email: '',
  state: '',
};

const backupErrors: AccountBackupErrorsType = {
  backupName: '',
  backupEmail: '',
};

export type AccountStateType = {
  isLoading: boolean;
  albums: AlbumType[];
  pagination: PaginationType;
  queryParams: AlbumsQueryParamsType;
  userNewData: UserType;
  couponCode: string;
  personalErrors: AccountPersonalErrorsType;
  backupErrors: AccountBackupErrorsType;
  albumsContributor: AlbumType[];
  paginationContributor: PaginationType;
  queryParamsContributor: AlbumsQueryParamsType;
};

export const ACCOUNT_ALBUMS_ROWS_PER_PAGE_DEFAULT = 4;
export const ACCOUNT_ALBUMS_ROWS_INITIAL_CURRENT_PAGE =
  paginationDefault.pageNum;
export const ACCOUNT_ALBUMS_SORT_BY_DEFAULT = paginationDefault.lastUpdate.id;
export const ACCOUNT_ALBUMS_ROLE = AlbumOwnershipFiltersIds.owner;

const AccountInitialState: AccountStateType = {
  isLoading: false,
  albums: [],
  pagination: new Pagination(),
  queryParams: new AlbumsQueryParams({
    pageSize: ACCOUNT_ALBUMS_ROWS_PER_PAGE_DEFAULT,
    pageNum: ACCOUNT_ALBUMS_ROWS_INITIAL_CURRENT_PAGE,
    sortBy: ACCOUNT_ALBUMS_SORT_BY_DEFAULT,
    role: ACCOUNT_ALBUMS_ROLE,
  }),
  userNewData: new User(),
  couponCode: '',
  personalErrors,
  backupErrors,
  albumsContributor: [],
  paginationContributor: new Pagination(),
  queryParamsContributor: new AlbumsQueryParams({
    pageSize: ACCOUNT_ALBUMS_ROWS_PER_PAGE_DEFAULT,
    pageNum: ACCOUNT_ALBUMS_ROWS_INITIAL_CURRENT_PAGE,
    sortBy: ACCOUNT_ALBUMS_SORT_BY_DEFAULT,
    role: AlbumOwnershipFiltersIds.contributor,
  }),
};

type AccountAction =
  | { type: 'IS_LOADING'; isLoading: boolean }
  | { type: 'CHANGE_INPUT'; field: string; value: string }
  | { type: 'CHANGE_COUNTRY'; newCountry: SelectType }
  | { type: 'CHANGE_STATE'; newState: SelectType }
  | { type: 'COUPON_INPUT'; couponCode: string }
  | { type: 'UPDATE_USER_NEW_DATA'; userNewData: UserType }
  | { type: 'UPDATE_ALBUMS'; albums: AlbumType[] }
  | { type: 'UPDATE_PAGINATION'; pagination: PaginationType }
  | { type: 'UPDATE_QUERY_PARAMS'; queryParams: AlbumsQueryParamsType }
  | {
      type: 'UPDATE_PERSONAL_ERRORS';
      personalErrors: AccountPersonalErrorsType;
    }
  | { type: 'UPDATE_BACKUP_ERRORS'; backupErrors: AccountBackupErrorsType }
  | { type: 'UPDATE_ALBUMS_CONTRIBUTOR'; albums: AlbumType[] }
  | { type: 'UPDATE_PAGINATION_CONTRIBUTOR'; pagination: PaginationType }
  | {
      type: 'UPDATE_QUERY_PARAMS_CONTRIBUTOR';
      queryParams: AlbumsQueryParamsType;
    };

const AccountReducer = (state: AccountStateType, action: AccountAction) => {
  switch (action.type) {
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case 'UPDATE_ALBUMS':
      return {
        ...state,
        albums: action.albums,
      };

    case 'UPDATE_PAGINATION':
      return {
        ...state,
        pagination: action.pagination,
      };

    case 'UPDATE_QUERY_PARAMS':
      return {
        ...state,
        queryParams: action.queryParams,
      };

    case 'CHANGE_INPUT':
      return {
        ...state,
        userNewData: {
          ...state.userNewData,
          [action.field]: action.value,
        },
      };

    case 'CHANGE_STATE':
      return {
        ...state,
        userNewData: {
          ...state.userNewData,
          state: action.newState.value,
        },
      };

    case 'COUPON_INPUT':
      return {
        ...state,
        couponCode: action.couponCode,
      };

    case 'CHANGE_COUNTRY':
      return {
        ...state,
        userNewData: {
          ...state.userNewData,
          country: action.newCountry.value,
          state:
            action.newCountry.value === Countries.Other
              ? ''
              : state.userNewData.state,
        },
      };

    case 'UPDATE_PERSONAL_ERRORS':
      return {
        ...state,
        personalErrors: action.personalErrors,
      };

    case 'UPDATE_BACKUP_ERRORS':
      return {
        ...state,
        backupErrors: action.backupErrors,
      };

    case 'UPDATE_USER_NEW_DATA':
      return {
        ...state,
        userNewData: action.userNewData,
      };
    case 'UPDATE_ALBUMS_CONTRIBUTOR':
      return {
        ...state,
        albumsContributor: action.albums,
      };
    case 'UPDATE_PAGINATION_CONTRIBUTOR':
      return {
        ...state,
        paginationContributor: action.pagination,
      };
    case 'UPDATE_QUERY_PARAMS_CONTRIBUTOR':
      return {
        ...state,
        queryParamsContributor: action.queryParams,
      };
    default:
      return state;
  }
};

export { AccountReducer, AccountInitialState };
export type { AccountAction };
