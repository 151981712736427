import { FileExtension } from 'common/enums';

export const bytesToMegabytes = (bytes: number) => bytes / 1024 ** 2;
export const formatFileExtensions = (extensions: Array<FileExtension>) =>
  extensions.join(',');
export const getFileExtension = (fileName: string) => {
  const fileNameParts = fileName.split('.');
  if (fileNameParts.length > 1) {
    return `.${fileNameParts.at(-1)}`;
  }
  return '';
};
export const isValidSize = (maxSize: number, currentSize: number) =>
  currentSize <= maxSize;

export const isSupportedExtension = (
  extension: string,
  supportedExtensions: Array<FileExtension>,
) => supportedExtensions.some((ext) => ext === extension);

export const getVideoDuration = (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.onerror = (error) => reject(error);
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
  });

export const isValidFile = async (
  file: File,
  uploadErrors: {
    generic: string;
    format?: string;
    size?: string;
    duration?: string;
  },
  supportedExt: Array<FileExtension>,
  mbMaxSize: number,
  sMaxDurationAllowed?: number,
): Promise<string> => {
  const fileExtension = getFileExtension(file.name);

  if (!isSupportedExtension(fileExtension.toLocaleLowerCase(), supportedExt)) {
    return uploadErrors.format || uploadErrors.generic;
  }

  if (!isValidSize(mbMaxSize, bytesToMegabytes(file.size))) {
    return uploadErrors.size || uploadErrors.generic;
  }

  if (sMaxDurationAllowed) {
    const duration = await getVideoDuration(file!);

    if (duration > sMaxDurationAllowed) {
      return uploadErrors.duration || uploadErrors.generic;
    }
  }

  return '';
};
