import { AudioIcon } from 'assets/icons';
import styles from './image-audio.module.scss';

type ImageAudioProps = {
  src?: string;
  alt: string;
  className?: string;
};

const ImageAudio: React.FC<ImageAudioProps> = ({ src, alt }) => (
  <div className={styles.image}>
    {src ? <img src={src} alt={alt} /> : <div className={styles.noImage} />}
    <div className={styles.iconContianier}>
      <AudioIcon className={styles.icon} />
    </div>
  </div>
);

export { ImageAudio };
