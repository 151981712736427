import { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { copyToClipboard, classnames } from 'helpers/utils';
import styles from './copy-link.module.scss';

type CopyLinkProps = {
  path: string;
  disabled?: boolean;
  className?: string;
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  children: ReactNode;
};

const CopyLink: React.FC<CopyLinkProps> = ({
  path,
  disabled = false,
  buttonType = ButtonType.Button,
  buttonStyle = ButtonStyle.PrimaryGhost,
  buttonSize = ButtonSize.Medium,
  className = '',
  children,
}) => {
  const [showMsg, setShowMsg] = useState(false);
  const [display, setDisplay] = useState(false);

  const handleCick = () => {
    copyToClipboard(path);
    setDisplay(true);
    setShowMsg(true);
  };
  const hiden = () => {
    setDisplay(false);
    setTimeout(() => setShowMsg(false), 900);
  };

  useEffect(() => {
    if (showMsg) {
      setTimeout(hiden, 3000);
    }
  }, [showMsg]);

  const tooltipClass = classnames(
    styles.tooltip,
    display ? styles.openAnimation : styles.closeAnimation,
  );

  return (
    <Button
      buttonStyle={buttonStyle}
      buttonType={buttonType}
      buttonSize={buttonSize}
      disabled={disabled}
      className={classnames(styles.container, className)}
      onClick={handleCick}
    >
      {showMsg && <span className={tooltipClass}>Copied!</span>}
      {children}
    </Button>
  );
};

export { CopyLink };
