import { AppContext } from 'context/app-context';
import { useContext } from 'react';
import { AlbumTabs } from 'common/enums';
import { ContributorsSection } from './components/contributors-section';
import { OwnerSection } from './components/owner-section';
import { SubscriptionSection } from './components/subscription-section/subscription-section';
import { ReminderSection } from './components/reminder-section';
import styles from './about-album.module.scss';

type AboutAlbumProps = {
  album: AlbumType;
  refetchAlbum: () => Promise<void>;
  handleTabChange: (tab: AlbumTabs) => void;
};

const AboutAlbum = ({
  album,
  refetchAlbum,
  handleTabChange,
}: AboutAlbumProps) => {
  const {
    state: {
      data: { user },
    },
    dispatch,
  } = useContext(AppContext);

  const isOwner = user.id === album.owner.id;
  const isOwnerOrContributor =
    isOwner || album.contributors.some((c) => c.id === user.id);

  return (
    <div className={styles.aboutAlbum}>
      <div className={styles.firstColumn}>
        <SubscriptionSection
          album={album}
          isOwner={isOwner}
          refetchAlbum={refetchAlbum}
          generalDispatch={dispatch}
        />
      </div>

      <div className={styles.secondColumn}>
        <OwnerSection album={album} isOwner={isOwner} />
        {isOwnerOrContributor && (
          <ReminderSection album={album} handleTabChange={handleTabChange} />
        )}
        <ContributorsSection
          generalDispatch={dispatch}
          album={album}
          isOwner={isOwner}
          refetchAlbum={refetchAlbum}
        />
      </div>
    </div>
  );
};

export { AboutAlbum };
