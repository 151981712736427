/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import { stripePublicKey, stripeAPIVersionOverride } from 'config/constants';
import { CreditCardApprovedIcon } from 'assets/icons';
import { Modal } from 'common/modal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { CardForm } from 'common/card-form';
import { ModalWarning } from 'common/modal-warning';
import { UserController } from 'networking/controllers/user-controller';
import { appActions, AppContext } from 'context';
import { ButtonStyle } from 'common/button';
import styles from './add-payment-method-modal.module.scss';

const stripeOptions = !stripeAPIVersionOverride
  ? {}
  : {
      apiVersion: stripeAPIVersionOverride,
    };
const stripePromise = loadStripe(stripePublicKey!, stripeOptions);

type AddPaymentMethodModalProps = {
  showWarningNotPayMethod?: boolean;
  notifyClose: () => void;
  notifySuccess: () => void;
};

const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = ({
  showWarningNotPayMethod = false,
  notifyClose,
  notifySuccess,
}) => {
  const { dispatch } = useContext(AppContext);

  const [showWarningNotPayMethodModal, setShowWarningNotPayMethodModal] =
    useState(false);
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] =
    useState(false);
  const [showPaymentMethodAddedModal, setShowPaymentMethodAddedModal] =
    useState(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (showWarningNotPayMethod) {
      setShowWarningNotPayMethodModal(true);
    } else {
      setShowAddPaymentMethodModal(true);
    }
  }, []);

  const handleSubmit = async (
    stripe: Stripe,
    cardNumberElement: StripeCardNumberElement,
  ) => {
    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardNumberElement!,
    });

    if (error) {
      setShowAddPaymentMethodModal(false);
      return false;
    }

    if (paymentMethod?.id) {
      const requestData: AddPaymentMethodRequestType = {
        PaymentMethodId: paymentMethod?.id,
      };

      try {
        const userUpdated = await UserController.addPaymentMethod(requestData);
        dispatch({ type: appActions.USER_LOGGED, data: { user: userUpdated } });

        setShowAddPaymentMethodModal(false);
        setShowPaymentMethodAddedModal(true);

        return true;
      } catch (err: any) {
        setShowError(true);
        return false;
      }
    }

    return false;
  };

  const showWarningNotPayMethodModalComponent = () => (
    <ModalWarning
      title="Payment method"
      content="We don’t have a payment method associated with your account. Please add one before continuing."
      successStyleButton={ButtonStyle.PrimaryFilled}
      successButtonText="Add payment method"
      closeFn={() => {
        notifyClose();
      }}
      successFn={() => {
        setShowWarningNotPayMethodModal(false);
        setShowAddPaymentMethodModal(true);
      }}
    />
  );

  const paymentMethodAddedTextBody = () => (
    <div className={styles.paymentMethodAddedContent}>
      <CreditCardApprovedIcon className={styles.cardIcon} />
      <p className="text__body__semi__bold__medium__textNeutral40">
        Your payment method has been successfully added!
      </p>
      <p className="text__body__tiny__medium__textNeutral30">
        You can modify it from your account’s settings.
      </p>
    </div>
  );

  const showAddPaymentMethodModalComponent = () => (
    <Modal
      onClose={() => notifyClose()}
      className={styles.processingContent}
      title="Payment method"
    >
      <div className={styles.elements}>
        <div className="text__body__semi__bold__medium__textNeutral30">
          {' '}
          Add payment method{' '}
        </div>
        <p className="text__body__regular__tiny__textNeutral30">
          All credit card information and transactions are managed by Stripe, a
          company specialized in collecting and protecting payment information.
          Generational Story does not store credit card information.
        </p>
        <div>
          <Elements stripe={stripePromise}>
            <CardForm
              showCancelButton
              submitButtonText="Add payment method"
              notifyCancel={() => notifyClose()}
              notifySubmit={(
                stripe: Stripe,
                cardNumberElement: StripeCardNumberElement,
              ) => handleSubmit(stripe, cardNumberElement)}
              showError={showError}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );

  const showPaymentMethodAddedModalComponent = () => (
    <ModalWarning
      title="Payment method"
      showCancelButton={false}
      content={paymentMethodAddedTextBody()}
      successButtonText="Okay"
      closeFn={() => {
        notifySuccess();
      }}
      successStyleButton={ButtonStyle.PrimaryFilled}
      successFn={() => {
        notifySuccess();
      }}
    />
  );

  return (
    <>
      {showWarningNotPayMethodModal && showWarningNotPayMethodModalComponent()}
      {showAddPaymentMethodModal && showAddPaymentMethodModalComponent()}
      {showPaymentMethodAddedModal && showPaymentMethodAddedModalComponent()}
    </>
  );
};

export { AddPaymentMethodModal };
