import { Modal } from 'common/modal/modal';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import styles from './modal-role-changing.module.scss';

type ModalRoleChangingType = {};

const ModalRoleChanging: React.FC<ModalRoleChangingType> = () => (
  <Modal disableManualClosing contentClassName={styles.modal}>
    <div className={styles.modalContent}>
      <div className="text__body__semi__bold__medium__textNeutral40">
        Your role is changing...
      </div>
      <div className={styles.spinnerContent}>
        <Spinner className={globalStyles.smallSpinner} />
      </div>
    </div>
  </Modal>
);

export { ModalRoleChanging };
