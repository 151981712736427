import React from 'react';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import styles from './sign-header.module.scss';

const SignHeader = () => (
  <div className={styles.logoAndText}>
    <Logo className={styles.logo} />
    <div className={styles.text}>
      <div className={styles.generational}>Generational</div>
      <div className={styles.story}>Story</div>
    </div>
  </div>
);

export { SignHeader };
