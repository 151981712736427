import React from 'react';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlertTriangleIcon } from 'assets/icons';
import { Button } from 'common/button';
import { Footer } from 'common/footer';
import { RouteName, findRoute } from 'routes';

import styles from './unexpected-error.module.scss';

const UnexpectedError = () => (
  <div className={globalStyles.globalContent}>
    <div className={globalStyles.loggedMainContainer}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <AlertTriangleIcon className={styles.icon} />
          <div>
            <h1 className="text__heading1__textNeutral40">Oops!</h1>
            <h2 className="text__heading4__textNeutral30">
              Something went wrong.
            </h2>
          </div>
        </div>
        <Button
          className={styles.button}
          // Reload is used to try to avoid the unexpected error again. //
          onClick={() => {
            window.location.href = findRoute(RouteName.Home).path;
          }}
        >
          Go back home
        </Button>
      </div>
    </div>
    <Footer />
  </div>
);

export { UnexpectedError };
