import React, { ChangeEvent, useState } from 'react';
import { Line } from 'common/line';
import { Button, ButtonSize, ButtonType } from 'common/button';
import { AppLink, RouteName } from 'routes';
import { Notification } from 'common/notification';
import { UserController } from 'networking/controllers/user-controller';
import { EyeIcon, ChevronIcon } from 'assets/icons';
import { Input } from 'common/input';
import { Spinner } from 'common/spinner';
import {
  classnames,
  isPasswordValid,
  noEmptyFields,
  noErrors,
} from 'helpers/utils';
import styles from './reset-password-step.module.scss';

type MandatoryResetFields = {
  password: string;
  repeatPassword: string;
};

const mandatoryFieldsErrors: MandatoryResetFields = {
  password: '',
  repeatPassword: '',
};

type ResetPasswordStep1Props = {
  notifSuccess: () => void;
  tokenUser: string;
};

const ResetPasswordStep1: React.FC<ResetPasswordStep1Props> = ({
  tokenUser,
  notifSuccess,
}) => {
  const [state, setState] = useState({ password: '', repeatPassword: '' });
  const [errors, setErrors] = useState<MandatoryResetFields>(
    mandatoryFieldsErrors,
  );

  const [inputPassword, setInputPassword] = useState<boolean>(true);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [fetching, setFetching] = useState(false);

  const validateField = <T extends keyof MandatoryResetFields>(field: T) => {
    let emptyFieldMessage = '';

    emptyFieldMessage =
      state[field].trim() === ''
        ? `${field.toUpperCase()} shouldn't be empty`
        : '';
    setErrors((prevState) => ({
      ...prevState,
      [field]: emptyFieldMessage.toUpperCase(),
    }));

    if (emptyFieldMessage) return;

    if (field === 'password') {
      const passwordMessage = !isPasswordValid(state[field])
        ? 'Invalid password. It must have at least 8 characters, one letter and one number.'
        : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: passwordMessage.toUpperCase(),
      }));

      if (passwordMessage) return;
    }

    if (field === 'repeatPassword') {
      const repeatMessage =
        state.password !== state.repeatPassword ? 'passwords do not match' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: repeatMessage.toUpperCase(),
      }));
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    setState((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });

    if (!noErrors(errors)) {
      return;
    }

    setFetching(true);

    const data: ResetPasswordType = {
      newPassword: state.password,
      token: tokenUser || '',
    };

    try {
      await UserController.resetPassword(data);
      notifSuccess();
    } catch (e) {
      setShowError(true);
    } finally {
      setFetching(false);
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const showContentView = () => {
    const { password, repeatPassword } = state;

    return (
      <>
        <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
          {' '}
          Reset Password{' '}
        </div>
        <div
          className={classnames(
            'text__body__regular__small__textNeutral30',
            'my-7',
          )}
        >
          Enter a new password.
        </div>

        <div>
          <Input
            id="password"
            name="password"
            label="Password"
            placeholder="password"
            value={password}
            type={inputPassword ? 'password' : 'text'}
            errorMessage={errors.password}
            withIconEnd={
              <EyeIcon onClick={() => setInputPassword(!inputPassword)} />
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            onBlur={() => validateField('password')}
          />
        </div>

        <div>
          <Input
            id="repeatPassword"
            name="repeatPassword"
            label="Repeat password"
            placeholder="password"
            value={repeatPassword}
            type={inputPasswordRepeat ? 'password' : 'text'}
            errorMessage={errors.repeatPassword}
            withIconEnd={
              <EyeIcon
                onClick={() => setInputPasswordRepeat(!inputPasswordRepeat)}
              />
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            onBlur={() => validateField('repeatPassword')}
          />
        </div>
        <Button
          className={classnames(
            styles.sendButton,
            fetching ? 'noClick fixSizeFetching' : '',
          )}
          onClick={() => handleSubmit()}
          buttonSize={ButtonSize.Medium}
          buttonType={ButtonType.Submit}
          disabled={!noEmptyFields({ password, repeatPassword })}
        >
          {fetching ? (
            <Spinner color="white" className="spinner-inside-button" />
          ) : (
            <> Reset password </>
          )}
        </Button>
        <Line />
        <div className={classnames(styles.footerForgotReset)}>
          <span className={styles.chevronIcon}>
            <ChevronIcon />
          </span>
          <AppLink className={styles.linkBack} routeName={RouteName.SignIn}>
            <span
              className={classnames(
                styles.linkBack,
                'text__body__semi__bold__medium__primary50',
              )}
            >
              &nbsp; Back to log in
            </span>
          </AppLink>
        </div>
      </>
    );
  };

  return (
    <>
      {showError && showErrorNotification()}
      {showContentView()}
    </>
  );
};

export { ResetPasswordStep1 };
