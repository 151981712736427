import React from 'react';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlertTriangleIcon } from 'assets/icons';
import { Button } from 'common/button';
import { RouteName, goToPage } from 'routes';

import styles from './not-found.module.scss';

const NotFound = () => (
  <div className={globalStyles.loggedMainContainer}>
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <AlertTriangleIcon className={styles.icon} />
        <div>
          <h1 className="text__heading1__textNeutral40">404</h1>
          <h2 className="text__heading4__textNeutral30">Page not found</h2>
        </div>
      </div>
      <p className="mt-25 mb-30 text__body__regular__medium__textNeutral30">
        Sorry, we can’t seem to find the page you’re looking for.
      </p>
      <Button
        className={styles.button}
        onClick={() => goToPage(RouteName.Home)}
      >
        Go back home
      </Button>
    </div>
  </div>
);

export { NotFound };
