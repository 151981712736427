import { classnames } from 'helpers/utils';
import { dayjs } from 'helpers/dayjs';
import styles from './recording-progress-bar.module.scss';

type RecordingProgressBarProps = {
  chuckTimeRecorded: number;
  maxTimeRecording: number;
};

const RecordingProgressBar: React.FC<RecordingProgressBarProps> = ({
  chuckTimeRecorded,
  maxTimeRecording,
}) => {
  const getPercentageTimeRecordingUsed = () =>
    (100 * chuckTimeRecorded!) / maxTimeRecording!;

  return (
    <div className={styles.progressContainer}>
      <div className="text__body__regular__medium__surfaceLight10">
        {dayjs.duration(chuckTimeRecorded!, 'milliseconds').format('mm:ss')}
      </div>
      <div className={styles.bar}>
        <div className={classnames(styles.outsideBox)}>
          <div
            style={{
              width: `calc(${getPercentageTimeRecordingUsed()}% + 2px)`,
            }}
            className={classnames(styles.insideBox)}
          />
        </div>
      </div>
      <div className="text__body__regular__medium__surfaceLight10">
        {dayjs.duration(maxTimeRecording!, 'milliseconds').format('m:ss')}
      </div>
    </div>
  );
};

export { RecordingProgressBar };
