import React from 'react';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlertTriangleIcon } from 'assets/icons';
import styles from './invalid-guest-link.module.scss';

const InvalidGuestLink: React.FC = () => (
  <div className={globalStyles.loggedMainContainer}>
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <AlertTriangleIcon className={styles.icon} />
        <div>
          <p className="text__heading1__textNeutral40">Oops!</p>
          <p className="text__heading4__textNeutral30">
            We couldn’t find your album.
          </p>
        </div>
      </div>
      <p className="text__body__regular__medium__textNeutral30">
        Your link is not valid. The album you’re trying to access might have
        been deleted, or your invitation might have expired.
      </p>
      <p className="text__body__semi__bold__medium__textNeutral40">
        You can request a new invite from the album owner.
      </p>
    </div>
  </div>
);

export { InvalidGuestLink };
