import { SelectElement } from './selectElement';
import { UserUtmProperties } from './user-utm-properties';

class UserSignUp {
  name: string;

  email: string;

  password: string;

  repeatPassword: string;

  companyName: string;

  backupName: string;

  backupEmail: string;

  state: SelectType;

  token?: string;

  couponCodes?: string[];

  utmProperties: UtmPropertiesType;

  constructor(params?: UserSignUpType | null) {
    this.name = params?.name || '';
    this.email = params?.email || '';
    this.password = params?.password || '';
    this.repeatPassword = params?.repeatPassword || '';
    this.companyName = params?.companyName || '';
    this.backupName = params?.backupName || '';
    this.backupEmail = params?.backupEmail || '';
    this.token = params?.token || '';
    this.state = params?.state || new SelectElement();
    this.couponCodes = params?.couponCodes || [];
    this.utmProperties = params?.utmProperties || new UserUtmProperties();
  }
}

export { UserSignUp };
