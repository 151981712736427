import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlbumTabs, NavSections } from 'common/enums';
import { Notification } from 'common/notification';
import { Spinner } from 'common/spinner';
import { appActions, AppContext } from 'context';
import { Album } from 'models/album';
import { MetaTags } from 'models/meta-tags';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { goToPage, RouteName } from 'routes';

import { VideoCallWaitingRoom } from 'pages/album-detail/components/video-call-waiting-room';
import { classnames, hasAlbumAccess } from 'helpers/utils';
import styles from './join-video-call-guest.module.scss';

type ParamType = {
  albumId: string;
  videoCallToken: string;
};

const JoinVideoCallGuest: React.FC = () => {
  const { albumId, videoCallToken } = useParams<ParamType>();
  const {
    state: {
      data: { user },
    },
    dispatch,
  } = useContext(AppContext);
  const [album, setAlbum] = useState<AlbumType>(new Album());

  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);

  const setApiError = useHandleApiError(() => {
    setShowError(true);
    goToPage(RouteName.NotFoundRedirect);
  });

  // if user is contributor or owner of the album, redirect to album detail
  useEffect(() => {
    if (hasAlbumAccess(user, album)) {
      goToPage(
        RouteName.AlbumDetail,
        { id: albumId },
        { tab: AlbumTabs.videoCall },
      );
    }
  }, [album, user]);

  const fetchAlbum = async () => {
    try {
      if (albumId && videoCallToken) {
        const response = await AlbumsController.getVideoCallAlbum(
          +albumId,
          videoCallToken,
        );
        const { coverThumbnailUrl, title } = response;
        const description = `Get to know ${title} ’s life story`;

        dispatch({
          type: appActions.SET_META_TAGS,
          metaTags: new MetaTags({ description, image: coverThumbnailUrl }),
        });

        setAlbum(response);
      } else {
        goToPage(RouteName.NotFoundRedirect);
      }
    } catch (err: any) {
      setApiError(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.albums,
    });
    fetchAlbum();
  }, []);

  const showErrorNotification = useCallback(
    () => <Notification handleClose={() => setShowError(false)} />,
    [showError],
  );

  return (
    <>
      {showError && showErrorNotification()}

      <div
        className={classnames(
          globalStyles.loggedMainContainer,
          styles.joinVideoCallGuest,
        )}
      >
        {!fetching ? (
          <div className={styles.waitingRoomWrapper}>
            <VideoCallWaitingRoom album={album} isGuest />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export { JoinVideoCallGuest };
