import React, { ChangeEvent, Dispatch, useState } from 'react';
import { checkEmailAndGetGenericError, classnames } from 'helpers/utils';
import { Input } from 'common/input';
import { AlertTriangleIcon, EditIcon } from 'assets/icons';
import { AccountAction, AccountStateType } from 'pages/account/account-reducer';
import { FormFooter } from 'common/form-footer';
import { AccountBackupErrors } from 'models/account-backup-errors';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { cloneDeep } from 'lodash';
import { UserController } from 'networking/controllers/user-controller';
import { NotificationType } from 'common/enums';
import { InitialStateType } from 'context/app-context/context-reducer';
import { NotificationObject } from 'models/notificationObject';
import { appActions } from 'context';
import styles from './backup-information.module.scss';

type BackupInformationProps = {
  generalDispatch: Dispatch<any>;
  accountDispatch: Dispatch<AccountAction>;
  generalState: InitialStateType;
  accountState: AccountStateType;
  className?: string;
};

const BackupInformation: React.FC<BackupInformationProps> = ({
  generalDispatch,
  accountDispatch,
  generalState,
  accountState,
  className = '',
}) => {
  const [readOnly, setReadOnly] = useState(true);

  const handleChange = (field: string, value: string) => {
    accountDispatch({ type: 'CHANGE_INPUT', field, value });
  };

  const { user: userOriginal } = generalState.data;
  const { userNewData, backupErrors } = accountState;
  const userHaveBackupInfo = userOriginal.backupEmail !== '';

  const validateField = (field: string) => {
    const newErrors = { ...backupErrors };

    if (field === 'backupEmail') {
      newErrors.backupEmail = checkEmailAndGetGenericError(
        userNewData.backupEmail,
      );
    }

    accountDispatch({
      type: 'UPDATE_BACKUP_ERRORS',
      backupErrors: { ...backupErrors, ...newErrors },
    });
  };

  const handleReturnBackupInfoToOriginalValues = () => {
    const { backupName, backupEmail } = userOriginal;

    accountDispatch({
      type: 'UPDATE_USER_NEW_DATA',
      userNewData: {
        ...userNewData,
        backupName,
        backupEmail,
      },
    });
  };

  const cleanValidations = () => {
    accountDispatch({
      type: 'UPDATE_BACKUP_ERRORS',
      backupErrors: new AccountBackupErrors(),
    });
  };

  const updateBackupInfoValues = async () => {
    const { backupName, backupEmail } = userNewData;

    let newDataCopy = cloneDeep(userOriginal);
    newDataCopy = {
      ...newDataCopy,
      backupName,
      backupEmail,
    };

    try {
      const userResponse = await UserController.update(newDataCopy);
      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: userResponse },
      });

      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Backup Information',
          message: 'Backup Information has been changed',
          type: NotificationType.Success,
        }),
      });
    } catch (e) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Backup Information',
          message: "Backup Information couldn't be saved",
          type: NotificationType.Error,
        }),
      });
    } finally {
      setReadOnly(true);
    }
  };

  const checkAllFieldsAndGetErrors = () => {
    const errs: AccountBackupErrorsType = new AccountBackupErrors();

    const { backupName, backupEmail } = userNewData;

    errs.backupEmail = checkEmailAndGetGenericError(backupEmail);

    if (backupEmail !== '' && backupName === '') {
      errs.backupName = 'Name should be completed if there’s an email';
    }

    errs.backupEmail = checkEmailAndGetGenericError(backupEmail);

    if (errs.backupEmail === '') {
      if (backupName !== '' && backupEmail === '') {
        errs.backupEmail = 'Email should be completed if there’s a name';
      }
    }

    return errs;
  };

  const hasErrorsBackupInfo = (newErrorsList: AccountBackupErrorsType) =>
    newErrorsList.backupName || newErrorsList.backupEmail;

  const checkValuesAndSaveBackupInfo = () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    accountDispatch({
      type: 'UPDATE_BACKUP_ERRORS',
      backupErrors: newErrorsList,
    });

    if (hasErrorsBackupInfo(newErrorsList)) {
      return;
    }

    updateBackupInfoValues();
  };

  const showTitle = () => (
    <div className={styles.titleBox}>
      <p className="text__title2__textNeutral40">
        Legacy contact
        <span
          className={classnames('text__body__regular__medium__textNeutral20')}
        >
          {' '}
          (Optional)
        </span>
      </p>
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        className={classnames(styles.editIcon, { invisible: !readOnly })}
        onClick={() => {
          setReadOnly(false);
        }}
      >
        <EditIcon />
      </Button>
    </div>
  );

  const showEmptyBackupInformation = () => (
    <div className={styles.warning}>
      <AlertTriangleIcon />{' '}
      <p className="text__body__semi__bold__overline__textNeutral30">
        LEGACY CONTACT NOT SET UP
      </p>
    </div>
  );

  const showActionButtons = () => (
    <div className={styles.backupActionButtons}>
      <FormFooter
        readyToSubmit
        successButton="Save"
        includeLine={false}
        successButtonSize={ButtonSize.Medium}
        cancelButtonSize={ButtonSize.Medium}
        cancelButtonStyle={ButtonStyle.PrimaryGhost}
        successButtonStyle={ButtonStyle.PrimaryFilled}
        onClickCancel={() => {
          <>
            {setReadOnly(true)}
            {handleReturnBackupInfoToOriginalValues()}
            {cleanValidations()}
          </>;
        }}
        onClickSuccess={() => {
          checkValuesAndSaveBackupInfo();
        }}
      />
    </div>
  );
  const backupFields = () => (
    <>
      <Input
        id="backupName"
        name="backupName"
        label="Name"
        disabled={readOnly}
        errorMessage={backupErrors.backupName}
        value={userNewData.backupName}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange('backupName', e.target.value)
        }
      />
      <Input
        id="backupEmail"
        name="backupEmail"
        label="Email"
        disabled={readOnly}
        value={userNewData.backupEmail}
        type="email"
        withValidation
        errorMessage={backupErrors.backupEmail}
        onBlur={() => {
          validateField('backupEmail');
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange('backupEmail', e.target.value)
        }
      />
    </>
  );
  const showBackupInformation = () => (
    <div className={styles.backupFieldsContainer}>
      {userHaveBackupInfo || !readOnly
        ? backupFields()
        : showEmptyBackupInformation()}
    </div>
  );

  return (
    <div className={className}>
      {showTitle()}
      {showBackupInformation()}
      <span className="text__body__regular__tiny__textNeutral30">
        We take our promise to preserve life histories seriously. Should
        something happen to the owner of this account, a back-up email provides
        us with an alternative contact to determine if and by whom the account
        will be maintained.
      </span>
      {!readOnly && showActionButtons()}
    </div>
  );
};

export { BackupInformation };
