import React, { useState } from 'react';
import { Button, ButtonStyle } from 'common/button';
import { InitialStateType } from 'context/app-context/context-reducer';
import { AddPaymentMethodModal } from 'common/add-payment-method-modal';
import { PaymentMethod } from 'common/payment-method';
import styles from './payment-method-card.module.scss';

type PaymentMethodCardProps = {
  generalState: InitialStateType;
  className?: string;
  openUpdatePayMethodModalAutomatically?: boolean;
  titleText?: string;
  subtitleText?: string;
};

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  generalState,
  openUpdatePayMethodModalAutomatically = false,
  className = '',
  titleText,
  subtitleText,
}) => {
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(
    openUpdatePayMethodModalAutomatically,
  );

  const showTitle = () => (
    <p className="text__title2__textNeutral40">{titleText}</p>
  );

  const showSubTitle = () => (
    <p className="text__body__regular__small__textNeutral30">{subtitleText}</p>
  );

  const showUpdatePaymentModalComponent = () => (
    <AddPaymentMethodModal
      notifyClose={() => {
        setShowUpdatePaymentModal(false);
      }}
      notifySuccess={() => {
        setShowUpdatePaymentModal(false);
      }}
    />
  );

  const isThereAPaymentMethod = () =>
    generalState.data.user.paymentMethods.length;

  const showChangePaymentMethodButton = () => (
    <Button
      className={styles.changePaymentMethodButton}
      buttonStyle={ButtonStyle.PrimaryStroke}
      onClick={() => setShowUpdatePaymentModal(true)}
    >
      {isThereAPaymentMethod()
        ? 'Update Payment Method'
        : 'Add Payment Method '}
    </Button>
  );

  return (
    <>
      {showUpdatePaymentModal && showUpdatePaymentModalComponent()}
      <div className={className}>
        {titleText && showTitle()}
        {subtitleText && showSubTitle()}
        {isThereAPaymentMethod() ? (
          <PaymentMethod
            paymentMethod={generalState.data.user.paymentMethods[0]}
          />
        ) : null}
        {showChangePaymentMethodButton()}
      </div>
    </>
  );
};

export { PaymentMethodCard };
