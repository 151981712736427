import { RouteName } from 'routes/routes';
import { Home } from 'pages/home';
import { NotFound } from 'pages/not-found';
import { SignIn } from 'pages/sign-in';
import { SignUp } from 'pages/sign-up';
import { ForgotPassword } from 'pages/forgot-password';
import { ResetPassword } from 'pages/reset-password';
import { AlbumNew } from 'pages/album-new';
import { Albums } from 'pages/albums';
import { AlbumDetail } from 'pages/album-detail';
import { AlbumDetailGuest } from 'pages/album-detail-guest';
import { ClipDetailGuest } from 'pages/clip-detail-guest';
import { EmailWaitingConfirm } from 'pages/email-waiting-confirm';
import { EmailVerifying } from 'pages/email-verifying';
import { Account } from 'pages/account';
import { AgoraVideoCall } from 'pages/agora-video-call';
import { JoinVideoCallGuest } from 'pages/join-video-call-guest';
import { Templates } from 'pages/templates';
import { Contributors } from 'pages/contributors';
import { Store } from 'pages/store';
import { UnlinkedQrCode } from 'pages/unlinked-qr-code';
import { SelfRecordingGuest } from 'pages/self-recording-guest';
import { ReviewVideosPage } from 'pages/review-videos';
import { ReviewVideosGuest } from 'pages/review-videos-guest';

const routeConfig = [
  {
    name: RouteName.Home,
    component: Home,
  },
  {
    name: RouteName.Account,
    component: Account,
  },
  {
    name: RouteName.Templates,
    component: Templates,
  },
  {
    name: RouteName.AlbumNew,
    component: AlbumNew,
  },
  {
    name: RouteName.Albums,
    component: Albums,
  },
  {
    name: RouteName.AlbumDetail,
    component: AlbumDetail,
  },
  {
    name: RouteName.AlbumDetailGuest,
    component: AlbumDetailGuest,
  },
  {
    name: RouteName.Contributors,
    component: Contributors,
  },
  {
    name: RouteName.JoinVideoCallGuest,
    component: JoinVideoCallGuest,
  },
  {
    name: RouteName.ClipDetailGuest,
    component: ClipDetailGuest,
  },
  {
    name: RouteName.SignIn,
    component: SignIn,
  },
  {
    name: RouteName.SignUp,
    component: SignUp,
  },
  {
    name: RouteName.EmailWaitingConfirm,
    component: EmailWaitingConfirm,
  },
  {
    name: RouteName.EmailVerifying,
    component: EmailVerifying,
  },
  {
    name: RouteName.ForgotPassword,
    component: ForgotPassword,
  },
  {
    name: RouteName.ResetPassword,
    component: ResetPassword,
  },
  {
    name: RouteName.AgoraVideoCall,
    component: AgoraVideoCall,
  },
  {
    name: RouteName.Store,
    component: Store,
  },
  {
    name: RouteName.SelfRecordingGuest,
    component: SelfRecordingGuest,
  },
  {
    name: RouteName.ReviewVideosGuest,
    component: ReviewVideosGuest,
  },
  {
    name: RouteName.UnlinkedQrCode,
    component: UnlinkedQrCode,
  },
  {
    name: RouteName.ReviewVideos,
    component: ReviewVideosPage,
  },
  {
    name: RouteName.NotFound,
    component: NotFound,
  },
];

export { routeConfig };
