import { Fragment, useCallback, useContext } from 'react';
import { Menu, PositionType } from 'common/menu';
import { useClickOutside } from 'hooks/click-outside';
import { SensorType } from 'context/sensor-selection-context/context-reducer';
import { SensorSelectionContext } from 'context';
import { SensorSelectionActions } from 'context/sensor-selection-context/action-types';
import styles from './sensor-menu.module.scss';
import { MenuOption } from './menu-option';

type SensorMenuProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  sensorType: SensorType;
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
};

const SensorMenu = ({
  containerRef,
  sensorType,
  menuOpen,
  setMenuOpen,
}: SensorMenuProps) => {
  const { sensorSelection, updateSensorSelection } = useContext(
    SensorSelectionContext,
  );

  const updateSelection = useCallback(
    (option: MediaDeviceInfo) => {
      const actionType =
        sensorType === 'camera'
          ? SensorSelectionActions.CAMERA_SELECTED
          : SensorSelectionActions.MICROPHONE_SELECTED;
      updateSensorSelection({ type: actionType, selectedOption: option });
      setMenuOpen(false);
    },
    [sensorType, setMenuOpen, updateSensorSelection],
  );

  useClickOutside(containerRef, () => {
    setMenuOpen(false);
  });

  return (
    <div>
      {menuOpen && (
        <Menu
          position={PositionType.BottomRightAligned}
          className={styles.sensorMenu}
        >
          {sensorSelection[sensorType].options.map((option, index) => (
            <Fragment key={option.deviceId}>
              {index > 0 && <div className={styles.divider} />}
              <MenuOption
                option={option}
                selected={
                  option.deviceId ===
                  sensorSelection[sensorType].selectedOption?.deviceId
                }
                onClick={() => updateSelection(option)}
              />
            </Fragment>
          ))}
        </Menu>
      )}
    </div>
  );
};

export { SensorMenu };
