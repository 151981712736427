/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Modal } from 'common/modal';
import { ButtonStyle } from 'common/button';
import { Album } from 'models/album';
import { subscriptionSchemeInfo } from 'config/constants';
import { SubscriptionSchemeId } from 'common/enums';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Spinner } from 'common/spinner';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { dayjs } from 'helpers/dayjs';
import {
  classnames,
  getPriceInfoBySubscription,
  getSubscriptionSchemeById,
  isFreeTrial,
} from 'helpers/utils';
import { FormFooter } from 'common/form-footer';
import { SubscriptionSwitch } from 'models/subscription-switch';
import styles from './upgrade-confirm-subscription-modal.module.scss';

type UpgradeConfirmSubscriptionModalProps = {
  footerLoading: boolean;
  subscriptionsScheme: SubscriptionTypeType[];
  album: Album;
  newSubscriptionSelected: SubscriptionTypeType;
  discountedPrices: SubscriptionDiscountedPriceType[];
  closeFn: () => void;
  successFn: () => void;
};

const UpgradeConfirmSubscriptionModal: React.FC<
  UpgradeConfirmSubscriptionModalProps
> = ({
  footerLoading,
  subscriptionsScheme,
  album,
  newSubscriptionSelected,
  discountedPrices,
  closeFn,
  successFn,
}) => {
  const [modalInitialLoading, setModalInitialLoading] = useState(true);

  const subPriceWithDiscount = getPriceInfoBySubscription(
    discountedPrices,
    newSubscriptionSelected,
  ).price;

  const subFullPrice = subscriptionsScheme.find(
    (sub: SubscriptionTypeType) => sub.id === newSubscriptionSelected.id,
  )!.price;
  const actualSubMaxClips = getSubscriptionSchemeById(
    subscriptionsScheme,
    album.subscriptionType as SubscriptionSchemeId,
  )?.permissions?.clipNumbers;

  const currentSubSchemeInfo =
    subscriptionSchemeInfo[album.subscriptionType as SubscriptionSchemeId];
  const newSubSelectedSchemeInfo =
    subscriptionSchemeInfo[newSubscriptionSelected.id as SubscriptionSchemeId];

  const [newTierPrice, setNewTierPrice] = useState<SubscriptionSwitchType>(
    new SubscriptionSwitch(),
  );

  const { rollover, purchaseExtraCharge } = newTierPrice;

  const subFinalPrice = newTierPrice.price;
  const extraClips = actualSubMaxClips ? album.maxClips - actualSubMaxClips : 0;

  useEffect(() => {
    const getPriceForNewTier = async (
      albumId: number,
      subSelectedId: SubscriptionSchemeId,
    ) => {
      const switchPricesList =
        await SubscriptionController.getTierSwitchPrices(albumId);

      const priceForSelectedSub = switchPricesList.prices.find(
        (elem: SubscriptionSwitchType) => elem.id === subSelectedId,
      )!;

      setModalInitialLoading(false);

      setNewTierPrice(priceForSelectedSub);
    };

    getPriceForNewTier(album.id, newSubscriptionSelected.id);
  }, []);

  const showCurrentPrice = () =>
    isFreeTrial(album)
      ? 'Free Trial'
      : `$${album.lastPayment?.amount.toFixed(2)} / ${
          currentSubSchemeInfo.paymentPeriod
        }`;

  const showComparisonSection = () => {
    const titleClass = 'text__body__regular__small__textNeutral40';
    const valueClass = 'text__body__regular__small__textNeutral30';

    const discountValue = subFullPrice - subFinalPrice - rollover;

    return (
      <div className={classnames(styles.subComparisonSection)}>
        <div className={classnames(styles.currentSub)}>
          <div className={styles.item}>
            <span className={titleClass}> Current subscription: </span>
            <span className={valueClass}>{currentSubSchemeInfo.name}</span>
          </div>

          <div className={styles.item}>
            <span className={titleClass}> Started: </span>
            <span className={valueClass}>
              {dayjs(album.lastPayment?.paymentDate).format('MM/DD/YY')}
            </span>
          </div>

          <div className={styles.item}>
            <span className={titleClass}> Pricing: </span>
            <span className={valueClass}>{showCurrentPrice()}</span>
          </div>
          {rollover > 0 && (
            <div className={styles.item}>
              <span className={titleClass}> Roll over payment: </span>
              <span className={valueClass}>${rollover.toFixed(2)}</span>
            </div>
          )}
          {extraClips > 0 && (
            <div className={styles.item}>
              <span className={titleClass}> Extra clips: </span>
              <span className={valueClass}>{extraClips} clips</span>
            </div>
          )}
        </div>

        <div className={classnames(styles.newSub)}>
          <div className={styles.item}>
            <span className={titleClass}> New subscription: </span>
            <span className={valueClass}>{newSubSelectedSchemeInfo.name}</span>
          </div>
          <div className={styles.item}>
            <span className={titleClass}> Starting: </span>
            <span className={valueClass}>
              {dayjs(album.nextPaymentDate).format('MM/DD/YY')}
            </span>
          </div>

          <div className={styles.item}>
            <span className={titleClass}> Pricing: </span>
            <span className={valueClass}>
              {`$${subPriceWithDiscount.toFixed(2)} /
          ${newSubSelectedSchemeInfo.paymentPeriod}`}
            </span>
          </div>
          {/* Only show discountValue if it makes a difference */}
          {discountValue >= 0.01 && (
            <div className={styles.item}>
              <span className={titleClass}> Roll over discount: </span>
              <span className={valueClass}>${discountValue.toFixed(2)}</span>
            </div>
          )}
          {purchaseExtraCharge > 0 && (
            <div className={styles.item}>
              <span className={titleClass}> Extra clips charge: </span>
              <span className={valueClass}>
                ${purchaseExtraCharge.toFixed(2)}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const showFinalPrice = () => (
    <div className={styles.finalPrice}>
      <div className={styles.item}>
        <span className="text__body__regular__large__textNeutral40">
          {' '}
          Upgrade total:{' '}
        </span>
        <span className="text__body__regular__large__primary50">{`$${subFinalPrice.toFixed(
          2,
        )}`}</span>
      </div>
    </div>
  );

  const showUpgradeInfo = () => (
    <div className="text__body__regular__small__textNeutral30">
      {`You will be charged a difference of $${subFinalPrice.toFixed(
        2,
      )} to upgrade this album
      from ${currentSubSchemeInfo.name}
      to ${newSubSelectedSchemeInfo.name}.
      The upgrade will take effect immediately.`}
    </div>
  );

  const showFooterButtons = () => {
    const getFinalPriceButton = () =>
      newTierPrice.price === 0 ? 'Upgrade' : `Pay $${subFinalPrice.toFixed(2)}`;

    return (
      <FormFooter
        includeLine={false}
        readyToSubmit
        successButton={getFinalPriceButton()}
        cancelButtonStyle={ButtonStyle.GreyGhost}
        successButtonStyle={ButtonStyle.PrimaryFilled}
        onClickCancel={closeFn}
        onClickSuccess={() => successFn()}
      />
    );
  };

  const showModalContent = () => (
    <>
      <p className="text__title2__textNeutral40">
        {' '}
        Confirm subscription upgrade{' '}
      </p>
      {showComparisonSection()}
      {showFinalPrice()}
      {showUpgradeInfo()}

      {footerLoading ? (
        <Spinner
          className={classnames(
            globalStyles.smallSpinner,
            styles.footerLoading,
          )}
        />
      ) : (
        showFooterButtons()
      )}
    </>
  );

  return (
    <Modal title="Modify subscription" onClose={closeFn}>
      <div className={classnames(styles.modalContent)}>
        {modalInitialLoading ? (
          <Spinner className={styles.initialLoading} />
        ) : (
          showModalContent()
        )}
      </div>
    </Modal>
  );
};

export { UpgradeConfirmSubscriptionModal };
