import { Modal } from 'common/modal';
import { useEffect } from 'react';
import { msToRecallPaymentEndpoint } from 'config/constants';
import { PaymentInProgress } from 'common/payment-in-progress';
import styles from './modal-processing-payment.module.scss';

type ModalProcessingPaymentProps = {
  title: string;
  pollingRequest?: () => void;
  disableManualClosing?: boolean;
  closeFn?: () => void;
};

const ModalProcessingPayment: React.FC<ModalProcessingPaymentProps> = ({
  title,
  disableManualClosing,
  pollingRequest,
  closeFn,
}) => {
  useEffect(() => {
    const timer = setInterval(() => {
      pollingRequest?.();
    }, msToRecallPaymentEndpoint);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal
      className={styles.processingContent}
      title={title}
      disableManualClosing={disableManualClosing}
      onClose={closeFn}
    >
      <PaymentInProgress />
    </Modal>
  );
};

export { ModalProcessingPayment };
