import { ChevronIcon } from 'assets/icons';
import { Pagination } from 'models/pagination';
import { classnames } from 'helpers/utils';
import styles from './paginator-select.module.scss';

type PaginatorProps = {
  paginationInfo: Pagination;
  notifyGetMoreAlbums: (page: number) => void;
};

const PaginationSelect: React.FC<PaginatorProps> = ({
  paginationInfo,
  notifyGetMoreAlbums,
}) => {
  const range = (from: number, to: number) => {
    let i = from;
    const rangeNumbers = [];

    while (i <= to) {
      rangeNumbers.push(i);
      i += 1;
    }

    return rangeNumbers;
  };
  const generateNumberPagination = () => {
    if (paginationInfo.lastPage <= 5) {
      return range(1, paginationInfo.lastPage);
    }
    if (paginationInfo.currentPage === 1 && paginationInfo.lastPage > 5) {
      return range(1, 5);
    }
    if (paginationInfo.currentPage > paginationInfo.lastPage - 3) {
      return range(paginationInfo.lastPage - 4, paginationInfo.lastPage);
    }
    return range(
      paginationInfo.currentPage - 1,
      paginationInfo.currentPage + 3,
    );
  };
  return (
    <div className={styles.paginationScroll}>
      <button
        type="button"
        onClick={() => notifyGetMoreAlbums(paginationInfo.currentPage - 1)}
        disabled={paginationInfo.currentPage === 1}
      >
        <ChevronIcon className={styles.paginationIconLeft} />
      </button>

      <div className={styles.paginationNumber}>
        {generateNumberPagination().map((number: number) => (
          <button
            type="button"
            key={number}
            onClick={() => notifyGetMoreAlbums(number)}
          >
            <div
              key={number}
              className={classnames(
                'text__body__regular__medium__textNeutral40',
                paginationInfo?.currentPage === number ? styles.active : '',
              )}
            >
              {number}
            </div>
          </button>
        ))}
      </div>
      <button
        type="button"
        onClick={() => notifyGetMoreAlbums(paginationInfo.currentPage + 1)}
        disabled={paginationInfo.currentPage === paginationInfo.lastPage}
      >
        <ChevronIcon
          className={classnames(
            styles.paginationIcon,

            paginationInfo.currentPage !== paginationInfo.lastPage
              ? styles.canShowMore
              : '',
          )}
        />
      </button>
    </div>
  );
};

export { PaginationSelect };
