import { classnames } from 'helpers/utils';
import React, { ReactNode } from 'react';
import { AlertTriangleIcon } from 'assets/icons';
import styles from './album-limited-condition-warning.module.scss';

export enum AlbumLimitedConditionWarningType {
  Info = 'Info',
  Alert = 'Alert',
}

type AlbumLimitedConditionWarningProps = {
  title: string;
  content: ReactNode | string;
  type: AlbumLimitedConditionWarningType;
};

const AlbumLimitedConditionWarning: React.FC<
  AlbumLimitedConditionWarningProps
> = ({ title, content, type }) => (
  <div className={styles.albumWarningContainer}>
    <div className={styles.iconColumn}>
      <AlertTriangleIcon
        className={classnames(styles.iconAlert, {
          [styles.iconInfo]: type === AlbumLimitedConditionWarningType.Info,
        })}
      />
    </div>
    <div className={styles.bodyColumn}>
      <p
        className={classnames(
          'text__body__regular__large__textNeutral50',
          styles.bodyTitle,
        )}
      >
        {title}
      </p>
      {content}
    </div>
  </div>
);

export { AlbumLimitedConditionWarning };
