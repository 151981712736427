import { classnames } from 'helpers/utils';
import styles from './progress-bar.module.scss';

type ProgressBarProps = {
  percentageUsed: number;
  reachingLimit: boolean;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentageUsed,
  reachingLimit,
}) => (
  <div>
    <div className={classnames(styles.outsideBox)}>
      <div
        style={{ width: `${percentageUsed}%` }}
        className={classnames(styles.insideBox, {
          [styles.runningOutTimeColor]: reachingLimit,
        })}
      />
    </div>
  </div>
);

export { ProgressBar };
