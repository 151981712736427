enum SensorSelectionActions {
  SENSOR_SELECTION_LOADED = 'SENSOR_SELECTION_LOADED',
  CAMERA_TOGGLED = 'CAMERA_TOGGLED',
  MICROPHONE_TOGGLED = 'MICROPHONE_TOGGLED',
  CAMERA_SELECTED = 'CAMERA_SELECTED',
  MICROPHONE_SELECTED = 'MICROPHONE_SELECTED',
  CAMERA_ERRORED = 'CAMERA_ERRORED',
  MICROPHONE_ERRORED = 'MICROPHONE_ERRORED',
}

export { SensorSelectionActions };
