import { Item, StoreStateType } from 'pages/store/store-reducer';
import { isEmailValid } from './utils';

export const getMountOfItems = (state: StoreStateType) => {
  let total = 0;
  state.itemsInCart?.forEach((item: Item) => {
    total += item.mountOfItems;
  });
  return total;
};

export const getTotalPrice = (state: StoreStateType) => {
  let total = 0;
  state.itemsInCart?.forEach((item: Item) => {
    total += item.mountOfItems * item.priceOneItem;
  });
  return total;
};

export const getStateId = (state: StoreStateType): string => {
  const stateId = state.shippingCosts?.find(
    (item) => item.state === state.shippingDetails.state,
  )?.id;
  return stateId || '';
};

export const checkAllFieldsAreFilled = (state: StoreStateType) => {
  const {
    addressFirstField,
    zipCode,
    city,
    state: stateField,
    fullName,
    email,
  } = state.shippingDetails;
  return (
    !!addressFirstField &&
    !!zipCode &&
    !!city &&
    !!stateField &&
    !!fullName &&
    isEmailValid(email)
  );
};

export const getShippingCosts = (state: StoreStateType) => {
  const foundCost = state.shippingCosts.find(
    (elem) => elem.state === state.shippingDetails.state,
  );
  if (foundCost) {
    return foundCost.cost;
  }

  return 0;
};
export const getShippingTax = (state: StoreStateType) => {
  const foundTax = state.shippingCosts.find(
    (elem) => elem.state === state.shippingDetails.state,
  );
  if (foundTax) {
    return foundTax.tax;
  }

  return 0;
};
