class SubscriptionDeal {
  id: string;

  name: string;

  description: string;

  expirationDate: Date;

  priceModifier: string;

  appliesTo: string[];

  constructor(params?: SubscriptionDeal | null) {
    this.id = params?.id || '';
    this.name = params?.name || '';
    this.description = params?.description || '';
    this.expirationDate = params?.expirationDate || new Date();
    this.priceModifier = params?.priceModifier || '';
    this.appliesTo = params?.appliesTo || [];
  }
}

export { SubscriptionDeal };
