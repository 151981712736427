import { NotificationType } from 'common/enums';
import { ReactNode } from 'react';

class NotificationObject {
  show: boolean;

  title: string;

  noMessage?: boolean;

  message: string;

  type: NotificationType;

  limitTimeMs?: number;

  footer?: ReactNode;

  constructor(params: NotificationObjectType | null) {
    this.type = params?.type || NotificationType.Error;
    this.show = params?.show || false;
    this.title = params?.title || this.getDefaultTitle();
    this.noMessage = params?.noMessage;
    this.message = params?.message || this.getDefaultMessage();
    this.limitTimeMs = params?.limitTimeMs || undefined;
    this.footer = params?.footer || undefined;
  }

  getDefaultTitle = () => {
    if (this.type === NotificationType.Error) {
      return 'Something went wrong';
    }
    if (this.type === NotificationType.Success) {
      return 'Changes saved';
    }

    return '';
  };

  getDefaultMessage = () => {
    if (this.type === NotificationType.Error) {
      return 'There was an error. Please try again later.';
    }

    if (this.type === NotificationType.Success) {
      return ''; // Note: To be define if neccesary
    }

    return '';
  };
}

export { NotificationObject };
