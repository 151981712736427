const selfRecordingActions = {
  addVideo: 'addVideo',
  setIsRecording: 'setIsRecording',
  setIsUploading: 'setIsUploading',
  setSelectedQuestion: 'setSelectedQuestion',
  deleteAllVideos: 'deleteAllVideos',
  deleteVideo: 'deleteVideo',
  setAlbumId: 'setAlbumId',
  newClip: 'newClip',
  setReadyForNextStep: 'setReadyForNextStep',
  updateInformation: 'updateInformation',
  videoFailed: 'videoFailed',
  chooseMode: 'chooseMode',
  setUploadMode: 'setUploadMode',
  setMediaStream: 'setMediaStream',
  cleanState: 'cleanState',
  updateAudioOnly: 'updateAudioOnly',
  setBatchId: 'setBatchId',
  setPositionInBatchToClip: 'setPositionInBatchToClip',
};

export { selfRecordingActions };
