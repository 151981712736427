import { Dispatch, ReactNode, createContext, useMemo, useReducer } from 'react';
import {
  InitialStateType,
  SelfRecordingReducer,
  initialState,
} from './context-reducer';

type SelfRecordingProviderProps = {
  children: ReactNode;
};

const SelfRecordingContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const SelfRecordingProvider = ({ children }: SelfRecordingProviderProps) => {
  const [state, dispatch] = useReducer(SelfRecordingReducer, initialState);
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <SelfRecordingContext.Provider value={providerValue}>
      {children}
    </SelfRecordingContext.Provider>
  );
};

export { SelfRecordingContext, SelfRecordingProvider };
