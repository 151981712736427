import { Button } from 'common/button';
import { Modal } from 'common/modal/modal';
import React from 'react';
import styles from './modal-info.module.scss';

type ModalInfoProps = {
  onClose: () => void;
  info: string;
  buttonMessage: string;
  title: string;
};

const ModalInfo: React.FC<ModalInfoProps> = ({
  title,
  info,
  onClose,
  buttonMessage,
}) => (
  <Modal title={title} onClose={onClose} disableManualClosing>
    <div className="text__body__regular__medium__textNeutral30">{info}</div>
    <div className={styles.buttonContainer}>
      <Button onClick={onClose}>{buttonMessage}</Button>
    </div>
  </Modal>
);

export { ModalInfo };
