/* eslint-disable max-len */
import { albumTitleMaxLength } from 'config/constants';
import { isEmailValid } from './utils';

const isEmailAlreadyAdded = (
  allContributors: ContributorType[],
  currentContributor: ContributorType,
) => {
  const { idContributorAux, email } = currentContributor;

  const emailAlreadyadded = allContributors.find(
    (contri: ContributorType) =>
      contri.idContributorAux !== idContributorAux && contri.email === email,
  );

  return emailAlreadyadded;
};

const getContributorNameError = (value: string) =>
  value.trim() === '' ? "name shouldn't be empty" : '';

const getContributorEmailError = (
  allContributors: ContributorType[],
  currentContributor: ContributorType,
) => {
  const { email } = currentContributor;
  let emailError = '';

  if (!email) {
    emailError = 'email cannot be empty';
  } else if (!isEmailValid(email)) {
    emailError = 'invalid email';
  } else if (isEmailAlreadyAdded(allContributors, currentContributor)) {
    emailError = 'email already added';
  }

  return emailError;
};

export const removeEmailsAlreadyAddedErrors = (
  allContributors: ContributorType[],
  contributorsErrorsList: ContributorErrorsType[],
) => {
  const newContributorsErrorsList: ContributorErrorsType[] = [];

  contributorsErrorsList.forEach(
    (currentContriErrors: ContributorErrorsType) => {
      let newContriErrorsCopy = { ...currentContriErrors };

      if (currentContriErrors.email === 'email already added') {
        const emailToAnalize = allContributors.find(
          (contr: ContributorType) =>
            contr.idContributorAux === currentContriErrors.idContributorAux,
        )!.email;

        let emailStillExistingFlag = false;

        allContributors.forEach((contri: ContributorType) => {
          if (
            contri.idContributorAux !== currentContriErrors.idContributorAux &&
            contri.email === emailToAnalize
          ) {
            emailStillExistingFlag = true;
          }
        });

        if (!emailStillExistingFlag) {
          newContriErrorsCopy = { ...currentContriErrors, email: '' };
        }
      }

      newContributorsErrorsList.push(newContriErrorsCopy);
    },
  );

  return newContributorsErrorsList;
};

export const updateContributorErrorsByField = (
  errs: NewAlbumErrorsType,
  allContributors: ContributorType[],
  currentContributor: ContributorType,
  field: string,
  newValue: string,
) => {
  let contributorsErrorsList: ContributorErrorsType[] = [];

  errs.contributors.forEach((actualContriErrors: ContributorErrorsType) => {
    const newContriErrorsCopy = { ...actualContriErrors };

    if (
      actualContriErrors.idContributorAux ===
      currentContributor.idContributorAux
    ) {
      if (field === 'name') {
        newContriErrorsCopy.name = getContributorNameError(newValue);
      }

      if (field === 'email') {
        newContriErrorsCopy.email = getContributorEmailError(
          allContributors,
          currentContributor,
        );
      }
    }

    contributorsErrorsList.push(newContriErrorsCopy);
  });

  contributorsErrorsList = removeEmailsAlreadyAddedErrors(
    allContributors,
    contributorsErrorsList,
  );

  return contributorsErrorsList;
};

export const checkTitleAndGetError = (value: string) => {
  let titleError = '';

  if (value.trim() === '') titleError = "title shouldn't be empty";

  if (value.length > albumTitleMaxLength) {
    titleError = `title can't have more than ${albumTitleMaxLength} characters`;
  }

  return titleError;
};

export const checkContributorsErrors = (
  contributors: ContributorErrorsType[],
) =>
  contributors.some(
    (contr: ContributorErrorsType) => contr.name || contr.email,
  );

export const hasErrorsInformationSection = (errors: NewAlbumErrorsType) => {
  if (errors.title) return true;

  const existingContributorsHaveError = checkContributorsErrors(
    errors.contributors,
  );

  if (existingContributorsHaveError) return true;

  return false;
};

const checkAllContributorsAndGetErrors = (contributors: ContributorType[]) => {
  const allContributorsErrors: ContributorErrorsType[] = [];

  contributors.forEach((currentContri: ContributorType) => {
    const contributorErrors = {
      idContributorAux: currentContri.idContributorAux,
      name: '',
      email: '',
    };

    contributorErrors.name = getContributorNameError(currentContri.name);
    contributorErrors.email = getContributorEmailError(
      contributors,
      currentContri,
    );

    allContributorsErrors.push(contributorErrors);
  });

  return allContributorsErrors;
};

export const checkAllFieldsAndGetErrors = (album: AlbumNewRequestType) => {
  const { title, contributors } = album;
  const errs: NewAlbumErrorsType = { title: '', contributors: [] };

  if (!album.title) {
    errs.title = checkTitleAndGetError(title);
  }

  errs.contributors = checkAllContributorsAndGetErrors(contributors);

  return errs;
};
