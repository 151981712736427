import { ExpandIcon } from 'assets/icons';
import { AppContext, appActions } from 'context';
import { useContext } from 'react';
import { AudioPlayer } from 'common/audio-player';
import { classnames } from 'helpers/utils';
import styles from './audio-clip.module.scss';

type AudioClipProps = {
  videoUrl?: string;
  alt: string;
  className?: string;
  image?: string;
  classNameIcon?: string;
};

const AudioClip: React.FC<AudioClipProps> = ({
  videoUrl,
  alt,
  image,
  className,
  classNameIcon,
}) => {
  const { dispatch } = useContext(AppContext);
  return (
    <>
      {image ? (
        <div className={classnames(styles.imageContainer, className || '')}>
          <img src={image} alt={alt} className={styles.image} />
          <button
            type="button"
            className={styles.expandIcon}
            onClick={() =>
              dispatch({
                type: appActions.SET_IMAGE_TO_ENLARGE,
                imageToEnlarge: image,
              })
            }
          >
            <ExpandIcon className={classNameIcon} />
          </button>
        </div>
      ) : null}
      <AudioPlayer videoUrl={videoUrl} />
    </>
  );
};

export { AudioClip };
