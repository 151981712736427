import {
  CAMERA_ENABLED_KEY,
  CAMERA_ID_KEY,
  MICROPHONE_ENABLED_KEY,
  MICROPHONE_ID_KEY,
} from 'config/constants';
import { SensorSelectionActions } from './action-types';

export type SensorType = 'microphone' | 'camera';

type SensorInformation = {
  enabled: boolean;
  error?: any;
  options: MediaDeviceInfo[];
  selectedOption?: MediaDeviceInfo;
};

export type SensorSelection = {
  [key in SensorType]: SensorInformation;
};

const initialState: SensorSelection = {
  camera: {
    enabled: false,
    error: undefined,
    options: [],
    selectedOption: undefined,
  },
  microphone: {
    enabled: false,
    error: undefined,
    options: [],
    selectedOption: undefined,
  },
};

export type Action = {
  type: keyof typeof SensorSelectionActions;
  data?: SensorSelection;
  enabled?: boolean;
  selectedOption?: MediaDeviceInfo;
  error?: any;
};

const SensorSelectionReducer = (
  state: SensorSelection,
  action: Action,
): SensorSelection => {
  switch (action.type) {
    case SensorSelectionActions.SENSOR_SELECTION_LOADED:
      return action.data!;
    case SensorSelectionActions.CAMERA_TOGGLED:
      localStorage.setItem(CAMERA_ENABLED_KEY, action.enabled!.toString());
      return {
        ...state,
        camera: {
          ...state.camera,
          enabled: action.enabled!,
        },
      };
    case SensorSelectionActions.MICROPHONE_TOGGLED:
      localStorage.setItem(MICROPHONE_ENABLED_KEY, action.enabled!.toString());
      return {
        ...state,
        microphone: {
          ...state.microphone,
          enabled: action.enabled!,
        },
      };
    case SensorSelectionActions.CAMERA_SELECTED:
      localStorage.setItem(CAMERA_ID_KEY, action.selectedOption!.deviceId);
      return {
        ...state,
        camera: {
          ...state.camera,
          selectedOption: action.selectedOption,
        },
      };
    case SensorSelectionActions.MICROPHONE_SELECTED:
      localStorage.setItem(MICROPHONE_ID_KEY, action.selectedOption!.deviceId);
      return {
        ...state,
        microphone: {
          ...state.microphone,
          selectedOption: action.selectedOption,
        },
      };
    case SensorSelectionActions.CAMERA_ERRORED:
      return {
        ...state,
        camera: {
          ...state.camera,
          error: action.error,
          enabled: false,
        },
      };
    case SensorSelectionActions.MICROPHONE_ERRORED:
      return {
        ...state,
        microphone: {
          ...state.microphone,
          error: action.error,
          enabled: false,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { initialState, SensorSelectionReducer };
