import { Modal } from 'common/modal/modal';
import { rootUrl } from 'config/constants';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { AppContext } from 'context';
import { useContext } from 'react';
import { canBuyClips, canBuyExtraVideoCallTime } from 'helpers/utils';
import styles from './modal-clips-time-subscription.module.scss';

type ModalBuyTimeSubscriptionProps = {
  onClose: () => void;
  onBuyTime: () => void;
  onUpgradeSubscription: () => void;
  onBuyClips: () => void;
  album: AlbumType;
};

const ModalBuyTimeSubscription = ({
  onClose,
  onBuyTime,
  onUpgradeSubscription,
  onBuyClips,
  album,
}: ModalBuyTimeSubscriptionProps) => {
  const {
    state: { subscriptionsScheme },
  } = useContext(AppContext);
  return (
    <Modal onClose={onClose} contentClassName={styles.modal}>
      <div className={styles.modalContent}>
        <p className="text__body__regular__medium__textNeutral40">
          You can get more clips or video call time by upgrading your
          subscription or purchasing clip packs or extra hours of video call
          time. Click{' '}
          <a
            href={`${rootUrl}/pricing`}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          for more information on our subscription plans.
        </p>
        <div className={styles.buttonsContainer}>
          <Button
            buttonStyle={ButtonStyle.PrimaryStroke}
            buttonSize={ButtonSize.Medium}
            onClick={onUpgradeSubscription}
          >
            Upgrade subscription
          </Button>
          <Button
            buttonStyle={ButtonStyle.PrimaryStroke}
            buttonSize={ButtonSize.Medium}
            onClick={onBuyClips}
            disabled={!canBuyClips(album, subscriptionsScheme)}
          >
            Buy clips
          </Button>
          <Button
            buttonStyle={ButtonStyle.PrimaryStroke}
            buttonSize={ButtonSize.Medium}
            onClick={onBuyTime}
            disabled={!canBuyExtraVideoCallTime(album, subscriptionsScheme)}
          >
            Buy video call time
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ModalBuyTimeSubscription };
