import {
  calculateHoursAndMinutes,
  canBuyClips,
  canBuyExtraVideoCallTime,
  canUpgradeAlbum,
  classnames,
  formatProgressBarTime,
  isExpiredDate,
  isFreeTrial,
  isLegacy,
  isLifeTime,
  isReadOnly,
  isRestrictedAlbum,
} from 'helpers/utils';
import { Dispatch, useContext, useMemo, useState } from 'react';
import { LabeledText } from 'common/labeled-text';
import dayjs from 'dayjs';
import {
  contactLink,
  msMaxPercentageToAlertVideoCallTime,
  subscriptionSchemeInfo,
} from 'config/constants';
import {
  NotificationType,
  PaymentType,
  SubscriptionSchemeId,
  SubscriptionStatus,
} from 'common/enums';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { goToPage, RouteName } from 'routes';
import { Modal } from 'common/modal';
import { LinkButton } from 'common/link-button';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AlertTriangleIcon, InfoIcon } from 'assets/icons';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { AllPaymentProcess } from 'common/all-payment-process';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { HelperText } from 'common/helper-text';
import { ProgressBar } from 'common/progress-bar';
import { DiscountCodeItem } from 'common/discount-code-item';
import { DiscountCodeDetailsModal } from 'common/discount-code-details-modal';
import { BuyClipsProcess } from 'common/buy-clips-process';
import { BuyExtraCallTimeProcess } from 'common/buy-extra-call-time-process/buy-extra-call-time-process';
import { CancelSubscriptionModal } from './cancel-subscription-modal';
import aboutPageStyles from '../../about-album.module.scss';
import styles from './subscription-section.module.scss';

type SubscriptionSectionProps = {
  album: AlbumType;
  isOwner: boolean;
  refetchAlbum: () => Promise<void>;
  generalDispatch: Dispatch<any>;
};

const SubscriptionSection = ({
  album,
  isOwner,
  refetchAlbum,
  generalDispatch,
}: SubscriptionSectionProps) => {
  const { state: user } = useContext(AppContext);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [startPaymentProcess, setStartPaymentProcess] = useState(false);
  const [showModalBuyClips, setShowModalBuyClips] = useState<boolean>(false);
  const [showModalBuyTime, setShowModalBuyExtraTime] = useState<boolean>(false);
  const [showDiscountDetailsModal, setShowDiscountDetailsModal] =
    useState(false);
  const [showModalInformation, setShowModalInformation] =
    useState<boolean>(false);

  const handleShowModifySubscription = () => {
    setStartPaymentProcess(true);
  };

  const modalInformationClips = () => (
    <Modal
      title="Additional clips"
      onClose={() => setShowModalInformation(false)}
      contentClassName={styles.modalInformation}
    >
      <div className="text__body__regular__medium__textNeutral40">
        Extra clips purchased for subscription albums are maintained under the
        set subscription pricing. Pricing for extra clips for Legacy and
        Lifetime albums are higher to reflect the cost of extended storage.
      </div>
    </Modal>
  );

  const infoWarningMessage = useMemo(() => {
    if (album.subscriptionStatus === SubscriptionStatus.subscriptionCanceled) {
      return (
        <div
          className={classnames(
            'text__body__regular__small__textNeutral40',
            styles.subscriptionWarning,
          )}
        >
          <AlertTriangleIcon className={styles.alertIcon} />
          <span>
            The subscription has been cancelled and will run out on{' '}
            {dayjs(album.nextPaymentDate).format('MM/DD/YYYY')}. If you want to
            be reinstated for payment please&nbsp;
            <a href={contactLink} target="_blank" rel="noreferrer">
              contact support
            </a>
            .
          </span>
        </div>
      );
    }

    if (album.scheduledDowngrade) {
      return (
        <div
          className={classnames(
            'text__body__regular__small__textNeutral40',
            styles.subscriptionWarning,
          )}
        >
          <InfoIcon className={styles.alertIcon} />
          <span>
            This album has been scheduled to be downgraded to a Read Only
            subscription on&nbsp;
            {dayjs(album.nextPaymentDate).format('MM/DD/YYYY')}
            {isOwner && '. You can still modify it if you change your mind'}.
          </span>
        </div>
      );
    }

    if (album.subscriptionStatus === SubscriptionStatus.subscriptionOverdue) {
      return (
        <div
          className={classnames(
            'text__body__regular__small__textNeutral40',
            styles.subscriptionWarning,
          )}
        >
          <AlertTriangleIcon className={styles.alertIcon} />
          <span>
            Your card couldn’t be charged for your last bill. Please{' '}
            <Button
              buttonSize={ButtonSize.Small}
              buttonStyle={ButtonStyle.SecondaryGhostLink}
              onClick={() => {
                goToPage(RouteName.Account);
              }}
            >
              review your payment method
            </Button>
            .
          </span>
        </div>
      );
    }

    return null;
  }, [
    album.subscriptionStatus,
    album.scheduledDowngrade,
    album.lastPayment?.paymentDate,
  ]);

  const handleModifyAlbumSubscription = async (
    newTier: SubscriptionSchemeId,
  ) => {
    try {
      const albumResponse = await AlbumsController.modifyAlbumSubscription(
        album.id,
        newTier,
      );
      return albumResponse;
    } catch (err: any) {
      return null;
    }
  };

  const handleError = () => {
    setStartPaymentProcess(false);

    generalDispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Modify Album',
        message: 'Subscription failed.',
        type: NotificationType.Error,
      }),
    });
  };

  const handleSuccessPayment = async () => {
    generalDispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Modify Album',
        message: 'Subscription changed successfully.',
        type: NotificationType.Success,
      }),
    });

    setStartPaymentProcess(false);
    await refetchAlbum();
  };

  const showAllPaymentProcessComponent = () => (
    <AllPaymentProcess
      albumReceived={album}
      paymentType={PaymentType.ModifyAlbumSubscription}
      successPaymentTextBody="Your subscription has been successfully modified."
      failedPaymentTextBody="Your subscription could not be modified. Please review your payment method and try again."
      notifyProcessConfirmation={(newTier?: SubscriptionSchemeId) =>
        handleModifyAlbumSubscription(newTier!)
      }
      cancelPaymentProcess={() => setStartPaymentProcess(false)}
      successPaymentProcess={handleSuccessPayment}
      notifyError={handleError}
      failedPaymentProcess={() => {
        setStartPaymentProcess(false);
      }}
    />
  );

  const showModifySubButton = (disabled: boolean) => (
    <Button
      buttonStyle={ButtonStyle.PrimaryStroke}
      onClick={() => handleShowModifySubscription()}
      disabled={disabled}
    >
      Modify subscription
    </Button>
  );

  const showCancelSubButton = (disabled: boolean) => (
    <Button
      buttonStyle={ButtonStyle.GreyGhost}
      className={styles.cancelButton}
      onClick={() => setShowCancelModal(true)}
      disabled={disabled}
    >
      Cancel subscription
    </Button>
  );

  const mustShowNextBillingAmount = () =>
    isOwner &&
    !isLegacy(album) &&
    !isFreeTrial(album) &&
    album.subscriptionStatus !== SubscriptionStatus.subscriptionCanceled &&
    album.nextPaymentAmount;

  const mustShowRenewalDue = () =>
    isOwner &&
    canUpgradeAlbum(album, user.subscriptionsScheme) &&
    !isFreeTrial(album) &&
    album.nextPaymentDate;

  const renewalMessage = (alb: AlbumType) => {
    if (alb.subscriptionStatus === SubscriptionStatus.subscriptionOverdue) {
      return 'Expired on';
    }
    if (alb.subscriptionStatus === SubscriptionStatus.subscriptionCanceled) {
      return 'Expiration due';
    }
    return 'Renewal due';
  };

  const mustShowCancelSubscriptionButton =
    (album.subscriptionType === SubscriptionSchemeId.standard ||
      album.subscriptionType === SubscriptionSchemeId.readOnly) &&
    (album.subscriptionStatus === SubscriptionStatus.subscriptionActive ||
      album.subscriptionStatus === SubscriptionStatus.subscriptionOverdue);

  const showProgressBar = (
    title: string,
    currentAmount: number,
    maxAmount: number,
    leftLabel: string,
    rightLabel: string,
    leftValue: string,
    rightValue: string,
    reachingLimit: boolean,
  ) => {
    const percentageUsed = (100 * currentAmount!) / maxAmount;
    return (
      <div className={styles.progressBar}>
        <div
          className={classnames(
            styles.progressTitle,
            'text__body__regular__overline__textNeutral30',
          )}
        >
          {title}
        </div>
        <div>
          <ProgressBar
            percentageUsed={percentageUsed}
            reachingLimit={reachingLimit}
          />
        </div>
        <div className={classnames(styles.progressTimes)}>
          <span className={styles.usedTime}>
            <span className="text__body__regular__small__textNeutral30">
              {leftLabel}&nbsp;
            </span>
            <span className="text__body__regular__small__textNeutral40">
              {leftValue}
            </span>
          </span>
          <span className={styles.maxTime}>
            <span className="text__body__regular__small__textNeutral30">
              {rightLabel}&nbsp;
            </span>
            <span className="text__body__regular__small__textNeutral40">
              {rightValue}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const modalBuyClips = () => (
    <BuyClipsProcess
      album={album}
      onFinishProcess={() => {
        setShowModalBuyClips(false);
      }}
      refetchAlbum={refetchAlbum}
    />
  );

  const modalBuyExtraTime = () => (
    <BuyExtraCallTimeProcess
      onFinishProcess={() => {
        setShowModalBuyExtraTime(false);
      }}
      album={album}
      refetchAlbum={refetchAlbum}
    />
  );

  const showDiscountCodesList = (
    subscriptionDeals: SubscriptionDealResponseSerialized[],
  ) => {
    const activeDeals = subscriptionDeals.filter(
      (deal) =>
        deal.appliesTo.includes(album.subscriptionType) &&
        !isExpiredDate(deal.expirationDate),
    );
    const expiredDeals = subscriptionDeals.filter(
      (deal) =>
        deal.appliesTo.includes(album.subscriptionType) &&
        isExpiredDate(deal.expirationDate),
    );
    if (activeDeals.length) {
      return (
        <div className={styles.discountCodesList}>
          {activeDeals.map((deal) => (
            <DiscountCodeItem key={deal.id} deal={deal} />
          ))}
          <Button
            buttonSize={ButtonSize.Small}
            buttonStyle={ButtonStyle.PrimaryGhostLink}
            onClick={() => setShowDiscountDetailsModal(true)}
          >
            Discount details
          </Button>
        </div>
      );
    }
    return (
      <>
        <span className="text__body__regular__medium__textNeutral30">
          No active discount.
        </span>
        {!!expiredDeals.length && (
          <div className={styles.dealExpiredWarning}>
            <AlertTriangleIcon className={styles.alertIcon} />
            <span className="text__body__regular__small__textNeutral40">
              Your last active discount code has expired.
              <br /> Remove it or replace it from your{' '}
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.SecondaryGhostLink}
                onClick={() => goToPage(RouteName.Account)}
              >
                account settings
              </Button>
              .
            </span>
          </div>
        )}
      </>
    );
  };

  const helperTextContent = () => {
    if (isReadOnly(album)) {
      return (
        <span className="text__body__regular__small__textNeutral30">
          This is a read only album. Upgrade to a subscription to add new
          content to your album.
        </span>
      );
    }
    if (isFreeTrial(album)) {
      return (
        <span className="text__body__regular__small__textNeutral30">
          Upgrade your subscription to access more clips and video call time.
        </span>
      );
    }
    return (
      <span className="text__body__regular__small__textNeutral30">
        Additional video call time rolls over to the next year. The purchase of
        additional clips is a one-time charge. Album renewal price will not
        increase. For more information{' '}
        <Button
          buttonSize={ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          onClick={() => {
            setShowModalInformation(true);
          }}
        >
          click here
        </Button>
        .
      </span>
    );
  };

  const showDiscountDetailsModalComponent = (
    discountList: SubscriptionDealResponseType[],
  ) => (
    <DiscountCodeDetailsModal
      discountCodesList={discountList}
      notifyCloseModal={() => setShowDiscountDetailsModal(false)}
    />
  );

  const usedCallTime = Number(album.videoCallProps?.usedCallTime);
  const maxCallTime = Number(album.videoCallProps?.maxCallTime);
  const remainingCallTime = maxCallTime - usedCallTime;
  const reachingCallTimeLimit =
    (100 * usedCallTime) / maxCallTime > msMaxPercentageToAlertVideoCallTime;
  const reachingClipsLimit = album.maxClips - album.clipAmount <= 3;

  const { hours: usedCallHours, minutes: usedCallMinutes } =
    calculateHoursAndMinutes(usedCallTime);
  const { hours: remainingCallHours, minutes: remainingCallMinutes } =
    calculateHoursAndMinutes(remainingCallTime);

  const { subscriptionDeals } = user.data.user;

  return (
    <>
      {showModalBuyClips && modalBuyClips()}
      {showModalBuyTime && modalBuyExtraTime()}
      {showModalInformation && modalInformationClips()}
      {showDiscountDetailsModal &&
        showDiscountDetailsModalComponent(subscriptionDeals)}
      <div
        className={classnames(
          globalStyles.infoCard,
          styles.subscriptionSection,
        )}
      >
        <p className="text__title2__textNeutral40">Subscription</p>
        <div className={styles.infoBar}>
          <div className={styles.albumDateAndRenewalColumn}>
            <LabeledText
              label="Album created"
              text={dayjs(album.createdAt).format('MM/DD/YYYY')}
              vertical
            />

            {mustShowRenewalDue() && (
              <div className={styles.labeledText}>
                <span className="text__body__semi__bold__medium__textNeutral40">
                  {renewalMessage(album)}:
                </span>
                <span
                  className={
                    album.subscriptionStatus ===
                    SubscriptionStatus.subscriptionOverdue
                      ? classnames(
                          'text__body__semi__bold__medium__textNeutral40',
                          styles.orangeText,
                        )
                      : 'text__body__regular__small__textNeutral30'
                  }
                >
                  {dayjs(album.nextPaymentDate).format('MM/DD/YYYY')}
                </span>
              </div>
            )}
          </div>

          <div className={styles.subscriptionAndBillingColumn}>
            {album.subscriptionType && (
              <LabeledText
                label="Subscription"
                // Display the value of the enum, not the key
                text={
                  subscriptionSchemeInfo[
                    album.subscriptionType as SubscriptionSchemeId
                  ].name
                }
                vertical
              />
            )}

            {mustShowNextBillingAmount() && (
              <LabeledText
                label="Next billing"
                text={`$${album.nextPaymentAmount.toFixed(2)}`}
                vertical
              />
            )}
          </div>
        </div>

        {infoWarningMessage}

        {isOwner &&
          album.subscriptionStatus ===
            SubscriptionStatus.subscriptionActive && (
            <div className={styles.buttonColumn}>
              {canUpgradeAlbum(album, user.subscriptionsScheme) &&
                showModifySubButton(
                  album.subscriptionStatus ===
                    SubscriptionStatus.subscriptionOverdue,
                )}
              {mustShowCancelSubscriptionButton &&
                showCancelSubButton(
                  album.subscriptionStatus ===
                    SubscriptionStatus.subscriptionOverdue,
                )}
            </div>
          )}

        <div className={globalStyles.divider} />

        <p className="text__title2__textNeutral40">Usage</p>
        {!isReadOnly(album) && (
          <div className={styles.progressSection}>
            {showProgressBar(
              'VIDEO CALL TIME',
              usedCallTime,
              maxCallTime,
              'Used:',
              'Remaining:',
              formatProgressBarTime(usedCallHours, usedCallMinutes),
              formatProgressBarTime(remainingCallHours, remainingCallMinutes),
              reachingCallTimeLimit,
            )}
            {showProgressBar(
              'ALBUM STORAGE',
              album.clipAmount,
              album.maxClips,
              'Used:',
              'Remaining:',
              `${album.clipAmount} clips`,
              `${album.maxClips - album.clipAmount} clips`,
              reachingClipsLimit,
            )}
          </div>
        )}
        <HelperText
          content={helperTextContent()}
          icon={<InfoIcon className={styles.infoIcon} />}
        />
        {isOwner && (
          <div className={aboutPageStyles.buttonSection}>
            {!isRestrictedAlbum(album) && (
              <>
                {canBuyClips(album, user.subscriptionsScheme) && (
                  <LinkButton
                    text="Buy extra clips"
                    onClick={() => {
                      setShowModalBuyClips(true);
                    }}
                    className={classnames({
                      [styles.orangeLink]: reachingClipsLimit,
                    })}
                  />
                )}
                {canBuyExtraVideoCallTime(album, user.subscriptionsScheme) && (
                  <LinkButton
                    text="Buy video call time"
                    className={classnames({
                      [styles.orangeLink]: reachingCallTimeLimit,
                    })}
                    onClick={() => {
                      setShowModalBuyExtraTime(true);
                    }}
                  />
                )}
              </>
            )}
            {canUpgradeAlbum(album, user.subscriptionsScheme) &&
              !isRestrictedAlbum(album) &&
              !(isLegacy(album) || isLifeTime(album)) && (
                <LinkButton
                  text="Upgrade subscription"
                  className={
                    reachingCallTimeLimit || reachingClipsLimit
                      ? styles.orangeLink
                      : ''
                  }
                  onClick={handleShowModifySubscription}
                />
              )}
          </div>
        )}

        {isOwner && (
          <>
            <div className={globalStyles.divider} />

            <p className="text__title2__textNeutral40">Active discount</p>
            <HelperText
              content={
                <span className="text__body__regular__small__textNeutral30">
                  Only one discount code may be applied to an individual album.
                  We apply the best deal based on the codes you supply.
                </span>
              }
              icon={<InfoIcon className={styles.infoIcon} />}
            />
            {showDiscountCodesList(subscriptionDeals)}
            <div className={aboutPageStyles.buttonSection}>
              <LinkButton
                text="Modify in account settings"
                onClick={() => goToPage(RouteName.Account)}
              />
            </div>

            <div className={globalStyles.divider} />

            <div className={aboutPageStyles.buttonSection}>
              <LinkButton
                text="Payment history"
                onClick={() => goToPage(RouteName.Account)}
              />
              <LinkButton
                text="Review payment method in account settings"
                onClick={() => goToPage(RouteName.Account)}
              />
              <LinkButton
                text="Contact support for account transferring"
                onClick={() => window.open(contactLink, '_blank')}
              />
            </div>
          </>
        )}
        {showCancelModal && (
          <CancelSubscriptionModal
            albumId={album.id}
            refetchAlbum={refetchAlbum}
            onClose={() => setShowCancelModal(false)}
          />
        )}

        {startPaymentProcess && showAllPaymentProcessComponent()}
      </div>
    </>
  );
};

export { SubscriptionSection };
