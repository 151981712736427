import Bowser from 'bowser';
import { MediaPermissionsErrorType } from 'mic-check';
import {
  calculateBrowserSettingsLink,
  calculateMissingSensorsText,
} from './calculate';

const calculatePossibleSolutions = (
  microphoneError?: any,
  cameraError?: any,
): JSX.Element[] => {
  if (!microphoneError && !cameraError) {
    return [];
  }

  const possibleSolutions = [];
  const missingSensorsText = calculateMissingSensorsText(
    microphoneError,
    cameraError,
  );

  if (
    microphoneError?.type === MediaPermissionsErrorType.UserPermissionDenied ||
    cameraError?.type === MediaPermissionsErrorType.UserPermissionDenied
  ) {
    const browserSettingsLink = calculateBrowserSettingsLink();
    possibleSolutions.push(
      <span key="browser settings">
        Please check your browser settings and grant Generational Story access
        to your {missingSensorsText}. See{' '}
        <a href={browserSettingsLink} target="_blank" rel="noreferrer">
          instructions
        </a>
        .
      </span>,
    );
  }

  if (
    microphoneError?.type ===
      MediaPermissionsErrorType.SystemPermissionDenied ||
    cameraError?.type === MediaPermissionsErrorType.SystemPermissionDenied
  ) {
    const { browser } = Bowser.parse(window.navigator.userAgent);

    possibleSolutions.push(
      <span key="device settings">
        {possibleSolutions.length ? ' If the issue persists,' : 'Please'} make
        sure {browser.name} has access to your {missingSensorsText} in your
        device’s settings.
      </span>,
    );
  }

  if (
    microphoneError?.type ===
      MediaPermissionsErrorType.CouldNotStartVideoSource ||
    cameraError?.type === MediaPermissionsErrorType.CouldNotStartVideoSource
  ) {
    possibleSolutions.push(
      <span key="close other applications">
        {possibleSolutions.length ? ' If the issue persists,' : 'Please'}{' '}
        {/* eslint-disable max-len */}
        close any other software accessing your camera or mic (Google Meet,
        Zoom, etc.) and refresh the page.
      </span>,
    );
  }

  return possibleSolutions.length
    ? possibleSolutions
    : [
        <span key="not sure">
          Check your settings and make sure your microphone and camera are set
          up correctly.
        </span>,
      ];
};

export const calculateWaitingRoomPersistentInstructions = (
  microphoneError?: any,
  cameraError?: any,
): JSX.Element => {
  if (!microphoneError && !cameraError && !cameraError) {
    return (
      <span>
        {/* eslint-disable max-len */}
        Check your camera and background before joining the call. This preview
        shows how your camera will be recorded.
      </span>
    );
  }

  return (
    <span>
      Generational Story can’t access your{' '}
      {calculateMissingSensorsText(microphoneError, cameraError)}.{' '}
      {calculatePossibleSolutions(microphoneError, cameraError)}
    </span>
  );
};

export const calculateWaitingRoomModalInstructions = (
  microphoneError?: any,
  cameraError?: any,
): [string, JSX.Element | null] => {
  if (!microphoneError && !cameraError && !cameraError) {
    return ['', null];
  }

  const missingSensorsText = calculateMissingSensorsText(
    microphoneError,
    cameraError,
  );
  const possibleSolutions = calculatePossibleSolutions(
    microphoneError,
    cameraError,
  );

  return [
    `Generational Story can’t access your ${missingSensorsText}.`,
    <span>
      {!!microphoneError && (
        <span>You won’t be able to join the call without a working mic. </span>
      )}

      {possibleSolutions.map((possibleSolution, index) => (
        <span key={possibleSolution.key}>
          {/* Add paragraph breaks for the modal view */}
          {index > 0 && (
            <>
              <br />
              <br />
            </>
          )}
          {possibleSolution}
        </span>
      ))}
    </span>,
  ];
};
