import { Modal } from 'common/modal/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button/button';

import styles from './modal-card-declined.module.scss';

type ModalCardDeclinedType = {
  onClose: () => void;
  openPaymentMethodModal: () => void;
};

const ModalCardDeclined = ({
  onClose,
  openPaymentMethodModal,
}: ModalCardDeclinedType) => (
  <Modal onClose={onClose} contentClassName={styles.modal}>
    <div className={styles.modalContent}>
      <div className={styles.modalInformation}>
        <span className="text__title2__textNeutral40">
          Your card was declined.
        </span>
        <p className="text__body__regular__small__textNeutral30">
          The purchase process failed. Please review your payment method and try
          again.
        </p>
      </div>
      <div className={styles.actionsButtons}>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          onClick={openPaymentMethodModal}
        >
          Review payment method
        </Button>
      </div>
    </div>
  </Modal>
);

export { ModalCardDeclined };
