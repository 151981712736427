/* eslint-disable max-len */
import React, { FunctionComponent, HTMLProps } from 'react';
import { classnames } from 'helpers/utils';
import { CrossIcon, InfoIcon } from 'assets/icons';
import { HelperText } from 'common/helper-text';

import styles from './input.module.scss';

enum InputStyle {
  REGULAR = 'regular',
}

type InputProps = {
  id: string;
  inputStyle?: InputStyle;
  disabled?: boolean;
  secondLabel?: string;
  withIconStart?: any;
  withIconEnd?: any;
  withValidation?: boolean;
  errorMessage?: string;
  showErrorBorder?: boolean;
  ErrorIconComp?: FunctionComponent;
  helperText?: string;
  className?: string;
  innerClassName?: string;
  onSubmit?: () => void;
  dataCy?: string;
};

const Input: React.FC<InputProps & HTMLProps<HTMLInputElement>> = ({
  id,
  label,
  disabled,
  secondLabel,
  withIconStart,
  withIconEnd,
  withValidation = true,
  errorMessage,
  showErrorBorder = false,
  ErrorIconComp = CrossIcon,
  value,
  helperText = '',
  className = '',
  innerClassName = '',
  onSubmit,
  dataCy,
  ...props
}) => {
  const helperClass = classnames(
    'mt-5',
    errorMessage || helperText ? styles.helperBox : 'invisible',
  );

  const showErrorMessage = () => (
    <div className={helperClass}>
      <span
        className={classnames(
          styles.iconCross,
          errorMessage && errorMessage.length > 1 ? '' : 'invisible',
        )}
      >
        <ErrorIconComp className={styles.iconError} />
      </span>
      <span
        className={classnames(
          styles.errorMessage,
          'text__body__regular__overline__warning30',
        )}
      >
        {errorMessage}
      </span>
    </div>
  );

  const showHelperText = () => (
    <HelperText
      content={helperText}
      icon={<InfoIcon className={styles.iconHelper} />}
      className={helperClass}
    />
  );

  return (
    <div className={className}>
      {label && (
        <div className={classnames('mb-4')}>
          <label
            className={classnames(
              disabled
                ? 'text__body__semi__bold__medium__textNeutral20'
                : 'text__body__semi__bold__medium__textNeutral40',
            )}
            htmlFor={id}
          >
            <span>{label}</span>
            <span className="text__body__regular__medium__textNeutral30">
              {secondLabel && secondLabel}
            </span>
          </label>
        </div>
      )}
      <div className={classnames(styles.inputInside)}>
        <input
          autoComplete="new-password"
          disabled={disabled}
          id={id}
          value={value}
          className={classnames(
            styles.regular,
            errorMessage || showErrorBorder ? styles.inputErrorBorder : '',
            withIconStart ? styles.movePlaceholderLeft : '',
            innerClassName,
          )}
          // hit onSubmit when user presses enter
          onKeyDown={(e) => {
            if (e.key === 'Enter' && onSubmit) {
              onSubmit();
            }
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          data-cy={dataCy}
        />
        {withIconStart && !value && (
          <div className={classnames(styles.iconPositionStart)}>
            {withIconStart}
          </div>
        )}
        {withIconEnd && (
          <div className={classnames(styles.iconPositionEnd)}>
            {withIconEnd}
          </div>
        )}
        {helperText && showHelperText()}
        {withValidation && showErrorMessage()}
      </div>
    </div>
  );
};

export { InputStyle, Input };
export type { InputProps };
