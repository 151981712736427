import React from 'react';
import { classnames } from 'helpers/utils';
import qrImage from 'assets/images/qrcode.png';
import { StoreStateType } from 'pages/store/store-reducer';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import styles from './checkout.module.scss';

type ConfirmOrderProps = {
  state: StoreStateType;
};

export const ConfirmOrder: React.FC<ConfirmOrderProps> = ({ state }) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  return (
    <>
      <div
        className={classnames(
          'text__body__regular__overline__textNeutral40',
          styles.itemsTitle,
        )}
      >
        CONFIRM YOUR ORDER
      </div>
      {state.itemsInCart?.map((item) => (
        <div className={styles.cartItem}>
          <img
            src={item.image || qrImage}
            alt="Avatar"
            className={styles.cartItemImage}
          />
          <div>
            <div
              className={
                isMobile
                  ? 'text__body__semi__bold__large__textNeutral40'
                  : 'text__title1__textNeutral40'
              }
            >
              <span
                className={
                  isMobile
                    ? 'text__body__semi__bold__large__primary50'
                    : 'text__title1__primary50'
                }
              >
                {item.mountOfItems}x
              </span>{' '}
              {item.name}
            </div>
            <div
              className={
                isMobile
                  ? 'text__body__semi__bold__large__textNeutral30'
                  : 'text__title2__textNeutral30'
              }
            >
              ${item.priceOneItem.toFixed(2)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
