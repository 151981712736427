import { Modal } from 'common/modal/modal';
import { Select } from 'common/select';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Spinner } from 'common/spinner';
import { formatPrice } from 'helpers/utils';
import styles from './modal-buy-clips.module.scss';

type ModalBuyClipsType = {
  options: SelectType[];
  onClose: () => void;
  optionSelected: SelectType;
  onSelectOption: (option: SelectType) => void;
  clipPrice: number;
  isLoading: boolean;
  buyClipsFn: () => void;
};

const ModalBuyClips: React.FC<ModalBuyClipsType> = ({
  onClose,
  options,
  optionSelected,
  onSelectOption,
  clipPrice,
  isLoading,
  buyClipsFn,
}) => (
  <Modal onClose={onClose} contentClassName={styles.modal}>
    <div className={styles.modalContent}>
      <div className={styles.modalInformation}>
        <span className="text__title2__textNeutral40">Buying clips</span>
        <p className="text__body__regular__small__textNeutral30">
          Increase your album limit buying packs of clips. You can review your
          album limit from the album’s about page.
        </p>
      </div>
      <div className={styles.selectRow}>
        <span className="text__body__semi__bold__medium__textNeutral40">
          I want to purchase
        </span>
        <Select
          id="clipsSelected"
          options={options}
          optionSelected={optionSelected}
          onClickOption={onSelectOption}
          className={styles.clipSelect}
          value={optionSelected.value}
        />
        <span className="text__body__semi__bold__medium__textNeutral40">
          clips
        </span>
      </div>
      <div className={styles.actionsButtons}>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.GreyGhost}
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          buttonSize={ButtonSize.Medium}
          buttonStyle={ButtonStyle.PrimaryFilled}
          className={isLoading ? styles.loading : ''}
          onClick={buyClipsFn}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner color="white" className="spinner-inside-button" />
          ) : (
            <div className={styles.buttonBox}>
              Buy for ${formatPrice(Number(optionSelected.value) * clipPrice)}
            </div>
          )}
        </Button>
      </div>
    </div>
  </Modal>
);
export { ModalBuyClips };
