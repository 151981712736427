import { ModalCardDeclined } from 'common/modal-card-declined';
import { ModalSuccessBuying } from 'common/modal-success-buying';
import { ModalOhNo } from 'common/modal-oh-no';
import { useContext, useEffect, useState } from 'react';
import { AddPaymentMethodModal } from 'common/add-payment-method-modal';
import { errorsCodeInPayments, extraVideoCallPackage } from 'config/constants';
import {
  calculateHoursAndMinutes,
  getExtraCallTimeHourPriceAlbum,
} from 'helpers/utils';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { AppContext } from 'context';
import { ModalProcessingPayment } from 'common/modal-processing-payment/modal-processing-payment';
import { PaymentStatus } from 'common/enums';
import { ModalBuyTime } from './components/modal-buy-call-time';

type BuyExtraCallTimeProcessType = {
  onFinishProcess: () => void;
  album: AlbumType;
  refetchAlbum?: () => void;
};

const BuyExtraCallTimeProcess = ({
  onFinishProcess,
  album,
  refetchAlbum,
}: BuyExtraCallTimeProcessType) => {
  const [buyTimeModal, setBuyTimeModal] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [cardDeclined, setCardDeclined] = useState<boolean>(false);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<number | null>(null);
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [alb, setAlbum] = useState<AlbumType>(album);
  const { hours, minutes } = calculateHoursAndMinutes(
    alb.videoCallProps?.maxCallTime ?? 0,
  );

  const {
    state: { subscriptionsScheme },
  } = useContext(AppContext);
  const hourPrice = subscriptionsScheme
    ? getExtraCallTimeHourPriceAlbum(album, subscriptionsScheme)
    : 0;

  const fetchAlbum = async () => {
    try {
      const response = await AlbumsController.getAlbum(album.id);
      setAlbum(response);
      setPaymentProcessing(false);
      setSuccess(true);
    } catch (err) {
      setError(true);
    }
  };

  const verifyPaymentStatus = async () => {
    try {
      if (paymentId) {
        const response = await AlbumsController.getPayment(paymentId);
        if (response.status === PaymentStatus.paid) {
          setPaymentId(null);
          fetchAlbum();
        }
        if (response.status === PaymentStatus.failed) {
          setPaymentId(null);
          setError(true);
        }
      }
    } catch (err: any) {
      setError(true);
    }
  };

  useEffect(() => {
    if (paymentId) {
      setBuyTimeModal(false);
      setPaymentProcessing(true);
    }
  }, [paymentId]);

  const handleBuyTime = async () => {
    try {
      const response = await AlbumsController.buyExtraCallTime(
        album.id,
        extraVideoCallPackage,
      );
      setPaymentId(response.id);
    } catch (err: any) {
      const isPaymentError = err?.errors?.some(
        (e: any) => e.errorCode === errorsCodeInPayments.cardDeclined,
      );
      if (isPaymentError) {
        setCardDeclined(true);
      } else {
        setError(true);
      }
    }
  };
  const closeSuccess = () => {
    onFinishProcess();
    if (refetchAlbum) {
      refetchAlbum();
    }
  };
  return (
    <>
      {paymentProcessing && (
        <ModalProcessingPayment
          title=""
          pollingRequest={verifyPaymentStatus}
          disableManualClosing
        />
      )}
      {success && (
        <ModalSuccessBuying
          onClose={closeSuccess}
          information={`You have ${hours} hours ${
            minutes > 0 ? `and ${minutes} minutes` : ''
          } of video call time available`}
        />
      )}
      {error && (
        <ModalOhNo onClose={onFinishProcess} retryFunction={handleBuyTime} />
      )}
      {cardDeclined && (
        <ModalCardDeclined
          onClose={() => {
            onFinishProcess();
            setCardDeclined(false);
          }}
          openPaymentMethodModal={() => setOpenPaymentMethodModal(true)}
        />
      )}

      {openPaymentMethodModal && (
        <AddPaymentMethodModal
          notifyClose={() => {
            setOpenPaymentMethodModal(false);
            onFinishProcess();
          }}
          notifySuccess={() => {
            setCardDeclined(false);
            setOpenPaymentMethodModal(false);
            setBuyTimeModal(true);
            setIsLoading(false);
          }}
        />
      )}
      {buyTimeModal && (
        <ModalBuyTime
          onClose={onFinishProcess}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleBuyTime={handleBuyTime}
          hourPrice={hourPrice}
        />
      )}
    </>
  );
};
export { BuyExtraCallTimeProcess };
