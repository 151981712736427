import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Spinner } from 'common/spinner';
import { VideoClip } from 'common/video-clip';
import { appActions, AppContext } from 'context';
import { classnames, isChromeVersionWithIssues } from 'helpers/utils';
import { Album } from 'models/album';
import { Clip } from 'models/clip';
import { MetaTags } from 'models/meta-tags';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { goToPage, RouteName } from 'routes';
import { useHandleApiError } from 'hooks/use-handle-api-error';

import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { AudioClip } from 'common/audio-clip';
import { addLinks } from 'helpers/text-helper';
import { ExpandIcon } from 'assets/icons';
import styles from './clip-detail-guest.module.scss';

type ParamType = {
  albumId: string;
  clipId: string;
};

const ClipDetailGuest: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const [album, setAlbum] = useState<AlbumType>(new Album());
  const [clip, setClip] = useState<ClipType>(new Clip());

  const [fetching, setFetching] = useState(true);

  const { albumId, clipId } = useParams<ParamType>();
  const setApiError = useHandleApiError(() => {
    goToPage(RouteName.NotFoundRedirect);
  }, false);

  const getClipById = (alb: AlbumType, clipIdSelected: number) =>
    alb.clips!.find((cli: ClipType) => cli.id === clipIdSelected);

  const fetchAlbum = async () => {
    try {
      if (albumId) {
        const albumResponse = await AlbumsController.getSharedAlbum(+albumId);
        const { title, coverThumbnailUrl } = albumResponse;
        const description = `Check out this clip by ${title}`;
        dispatch({
          type: appActions.SET_META_TAGS,
          metaTags: new MetaTags({ description, image: coverThumbnailUrl }),
        });

        const clipToShow = getClipById(albumResponse, +clipId);

        if (!clipToShow) {
          throw new Error('Clip does not exist');
        }

        setAlbum(albumResponse);
        setClip(clipToShow);
      }
    } catch (err: any) {
      setApiError(err);
    }
  };

  useEffect(() => {
    fetchAlbum();
  }, []);

  useEffect(() => {
    if (isChromeVersionWithIssues()) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Problems with your browser version',
          message:
            'Your browser might experience issues playing back videos. Consider updating your browser to get the best experience.',
          type: NotificationType.Error,
          limitTimeMs: 15000,
        }),
      });
    }
  }, []);

  useEffect(() => {
    if (clip.videoUrl) {
      setFetching(false);
    }
  }, [clip]);

  const showDefaultPicture = () => (
    <div className={styles.defaultPicture}>
      <Logo className={styles.logo} />
    </div>
  );

  const showHeader = () => (
    <div className={styles.headerRow}>
      <div className={styles.leftSide}>
        <div className={styles.pictureRounded}>
          {album.coverThumbnailUrl ? (
            <img
              src={album.coverThumbnailUrl}
              className={styles.picture}
              alt="logo"
            />
          ) : (
            showDefaultPicture()
          )}
        </div>
        <div className={styles.titles}>
          <div className="text__body__regular__overline__textNeutral30">
            {' '}
            ALBUM{' '}
          </div>
          <span
            className={classnames(
              styles.albumName,
              'text__heading4__textNeutral40',
            )}
          >
            {album.title}
          </span>
        </div>
      </div>
    </div>
  );

  const showVideoAndDescription = () => (
    <div className={styles.videoAndDescription}>
      <div className={styles.video}>
        {clip.isAudioOnly ? (
          <div className={styles.audioOnly}>
            <AudioClip
              alt=""
              videoUrl={clip.videoUrl}
              image={clip.customThumbnail}
            />
          </div>
        ) : (
          <VideoClip clipSelected={clip} />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.clipTitle}>
          <div className="text__title1__textNeutral40">{clip.name}</div>
          <div className="text__body__regular__medium__textNeutral30">
            {addLinks(clip.description)}
          </div>
        </div>
        <div>
          {clip.customThumbnail && !clip.isAudioOnly && (
            <div className={classnames(styles.imageContainer)}>
              <img src={clip.customThumbnail} alt="" className={styles.image} />
              <button
                type="button"
                className={styles.expandIcon}
                onClick={() =>
                  dispatch({
                    type: appActions.SET_IMAGE_TO_ENLARGE,
                    imageToEnlarge: clip.customThumbnail,
                  })
                }
              >
                <ExpandIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const showContentView = () => (
    <div className={styles.clipContainer}>
      {showHeader()}
      {showVideoAndDescription()}
    </div>
  );

  return (
    <div className={globalStyles.loggedMainContainer}>
      {!fetching ? showContentView() : <Spinner />}
    </div>
  );
};

export { ClipDetailGuest };
