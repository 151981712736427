class SubscriptionSwitch {
  id: string;

  price: number;

  rollover: number;

  purchaseExtraCharge: number;

  constructor(params?: SubscriptionSwitchType) {
    this.id = params?.id || '';
    this.price = params?.price || 0;
    this.rollover = params?.rollover || 0;
    this.purchaseExtraCharge = params?.purchaseExtraCharge || 0;
  }
}

export { SubscriptionSwitch };
