import { useContext, useEffect, useReducer, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import qrImage from 'assets/images/qrcode.png';
import { AppContext, appActions } from 'context/app-context';
import { NavSections } from 'common/enums';
import { classnames } from 'helpers/utils';
import { StoreController } from 'networking/controllers/store-controller';
import { CardItem } from './components/list';
import styles from './store.module.scss';
import { StoreInitialState, StorePages, StoreReducer } from './store-reducer';
import { Checkout } from './components/checkout';
import { Cart } from './components/cart';

const Store: React.FC = () => {
  const [storeState, storeDispatch] = useReducer(
    StoreReducer,
    StoreInitialState,
  );
  const { dispatch } = useContext(AppContext);
  const [items, setItems] = useState<StoreItem[]>([]);
  const handleItems = async () => {
    const { data } = await StoreController.getStoreItems();
    setItems(data);
  };
  const showSelectItemsPage = () => (
    <div
      className={classnames(
        globalStyles.loggedMainContainer,
        styles.fullContent,
      )}
    >
      <div className={classnames(styles.storeList)}>
        <div className={classnames(styles.storeTitleAndSubtitle)}>
          <div className={classnames('text__heading4__textNeutral40')}>
            {' '}
            Store
          </div>
          <div
            className={classnames('text__body__regular__medium__textNeutral30')}
          >
            Preserve & share your memories with the world. More items coming
            soon!
          </div>
        </div>
        {items.map((item: StoreItem) => (
          <CardItem
            image={item.image ?? qrImage} // TODO: change when have a default image
            name={item.name}
            description={item.description}
            price={item.price}
            id={item.id}
            state={storeState}
            storeDispatch={storeDispatch}
            key={item.id}
            handleSetImageToShow={() => {
              dispatch({
                type: appActions.SET_IMAGE_TO_ENLARGE,
                imageToEnlarge: item.image ?? qrImage,
              });
            }}
          />
        ))}
      </div>
      <div className={classnames(styles.storeCart)}>
        <Cart state={storeState} dispatch={storeDispatch} />
      </div>
    </div>
  );

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.store,
    });
    handleItems();
  }, []);

  if (storeState.actualPage === StorePages.selectItems) {
    return showSelectItemsPage();
  }
  return <Checkout state={storeState} dispatch={storeDispatch} />;
};

export { Store };
