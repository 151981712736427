import React from 'react';
import { classnames } from 'helpers/utils';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import styles from './store-item.module.scss';

type StoreItemProps = {
  image: string;
  name: string;
  price: number;
  amountOfItems: number;
};

const StoreItem: React.FC<StoreItemProps> = ({
  name,
  price,
  image,
  amountOfItems,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  return (
    <div className={classnames(styles.card)}>
      <div className={classnames(styles.cardContent)}>
        <div className={styles.information}>
          <div className={classnames(styles.cardImage)}>
            <img
              src={image}
              className={classnames(styles.picture)}
              alt="Store Item"
            />
          </div>
          <div className={classnames(styles.nameAndPrice)}>
            <div
              className={
                isMobile
                  ? 'text__body__semi__bold__medium__textNeutral40'
                  : 'text__title1__textNeutral40'
              }
            >
              {amountOfItems}x {name}
            </div>
            <div className={styles.rowAmountPrice}>
              <div
                className={
                  isMobile
                    ? 'text__body__regular__small__textNeutral30'
                    : 'text__body__regular__medium__textNeutral30'
                }
              >
                {amountOfItems}x ${price.toFixed(2)}
              </div>
              <div
                className={
                  isMobile
                    ? 'text__body__semi__bold__small__textNeutral40'
                    : 'text__body__semi__bold__medium__textNeutral40'
                }
              >
                ${(price * amountOfItems).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { StoreItem };
