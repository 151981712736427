class SelectElement {
  id: string;

  value: string;

  constructor(params?: SelectElement | null) {
    this.id = params?.id || '';
    this.value = params?.value || '';
  }
}

export { SelectElement };
