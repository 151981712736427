import React, { ChangeEvent, HTMLProps } from 'react';
import { CheckboxIcon, CheckmarkIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import styles from './checkbox.module.scss';

type CheckboxProps = {
  disabled?: boolean;
  isChecked?: boolean;
  itemKey: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> & HTMLProps<HTMLInputElement> = ({
  isChecked = false,
  itemKey,
  disabled = false,
  onChange,
}) => (
  <div>
    <input
      type="checkbox"
      name={itemKey}
      id={itemKey}
      onChange={(e) => onChange(e)}
      checked={isChecked}
      className={styles.custom}
      disabled={disabled}
    />
    <label htmlFor={itemKey} id={`checkbox-${itemKey}`}>
      <CheckboxIcon className={disabled ? styles.boxDisabled : styles.box} />
      {isChecked === true && (
        <CheckmarkIcon
          className={classnames(
            styles.check,
            disabled ? styles.checkDisabled : styles.checkEnabled,
          )}
        />
      )}
    </label>
  </div>
);
export { Checkbox };
