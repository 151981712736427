import { AlbumTabs } from 'common/enums';
import { classnames } from 'helpers/utils';
import styles from './album-tab.module.scss';

type AlbumTabProps = {
  tab: AlbumTabs;
  selectedTab: AlbumTabs;
  disabled?: boolean;
  handleTabSelected: (queryParams: ParamsType) => void;
};

const AlbumTab = ({
  tab,
  selectedTab,
  disabled = false,
  handleTabSelected,
}: AlbumTabProps) => (
  <div className={styles.albumTab}>
    <button
      type="button"
      disabled={disabled}
      className={classnames(
        {
          [styles.disabledTab]: disabled,
          [styles.tabSelected]: selectedTab === tab,
        },
        styles.tabButton,
      )}
      onClick={() => handleTabSelected({ tab })}
    >
      {tab}
    </button>
  </div>
);

type UpgradeAlbumTabProps = {
  onClick: () => void;
  showSecondaryColor: boolean;
};

const UpgradeAlbumTab = ({
  onClick,
  showSecondaryColor = false,
}: UpgradeAlbumTabProps) => (
  <div className={styles.albumTab}>
    <button
      type="button"
      className={classnames(styles.tabButton, styles.upgradeTab, {
        [styles.upgradeTabWarning]: showSecondaryColor,
      })}
      onClick={onClick}
    >
      Upgrade album
    </button>
  </div>
);

export { AlbumTab, UpgradeAlbumTab };
