class UserSignIn {
  email: string;

  password: string;

  constructor(params?: UserSignIn) {
    this.email = params?.email || '';
    this.password = params?.password || '';
  }
}

export { UserSignIn };
