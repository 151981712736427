import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { capitalizeFirstLetter, classnames } from 'helpers/utils';
import { ChevronIcon } from 'assets/icons';
import { RouteName, goToPage } from 'routes';
import styles from './account-albums-cards-mobile.module.scss';

type AccountAlbumsCardsMobileProps = {
  albums: AlbumType[];
  getRenewalExpiration: Function;
  getAlbumStatusLabel: Function;
};

const AccountAlbumsCardsMobile: React.FC<AccountAlbumsCardsMobileProps> = ({
  albums,
  getRenewalExpiration,
  getAlbumStatusLabel,
}) => {
  const getLastBilling = (album: AlbumType) =>
    album.lastPayment?.amount
      ? `$${album.lastPayment?.amount.toFixed(2)}`
      : '-';

  return (
    <div className={styles.albumsCardsMobile}>
      <div
        className={classnames(
          styles.body,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        {albums.map((album: AlbumType) => (
          <div key={album.id} className={styles.albumCard}>
            <div className={styles.leftSize}>
              <div
                className={classnames(
                  'text__body__semi__bold__medium__textNeutral40',
                  styles.albumName,
                )}
              >
                {album.title}
              </div>
              <div
                className={classnames(
                  'text__body__regular__small__textNeutral30',
                  styles.subscription,
                )}
              >
                {`${capitalizeFirstLetter(
                  album.subscriptionType,
                )} Subscription`}
              </div>
              <div className={classnames(styles.lastBilling)}>
                <span
                  className={classnames(
                    'text__body__semi__bold__small__textNeutral30',
                  )}
                >
                  {' '}
                  Last billing:{' '}
                </span>
                <span
                  className={classnames(
                    'text__body__regular__small__textNeutral30',
                  )}
                >
                  {getLastBilling(album)}
                </span>
              </div>
              <div className={classnames(styles.renewalExpiration)}>
                <span
                  className={classnames(
                    'text__body__semi__bold__small__textNeutral30',
                  )}
                >
                  {' '}
                  Renewal:{' '}
                </span>
                <span
                  className={classnames(
                    'text__body__regular__small__textNeutral30',
                  )}
                >
                  {getRenewalExpiration(album)}
                </span>
              </div>

              {getAlbumStatusLabel(album, styles.status)}
            </div>
            <div className={styles.rightSize}>
              <Button
                className={styles.detailsButton}
                buttonStyle={ButtonStyle.PrimaryGhost}
                buttonSize={ButtonSize.Small}
                onClick={() => {
                  goToPage(RouteName.AlbumDetail, { id: album.id });
                }}
              >
                <ChevronIcon className={styles.chevronIcon} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { AccountAlbumsCardsMobile };
