class Clip {
  id: number;

  createdAt: Date;

  createdBy: number;

  recordedBy: number;

  createdByOwner: boolean;

  recordedByOwner: boolean;

  description: string;

  isPrivate: boolean;

  length: number;

  name: string;

  order: number;

  recordedAt: Date;

  videoUrl?: string;

  thumbnailUrl?: string;

  recordingStatus: string;

  qrCodes: QrCode[];

  isAudioOnly: boolean;

  customThumbnail?: string;

  constructor(params?: Clip | null) {
    this.id = params?.id || -1;
    this.createdAt = params?.createdAt || new Date();
    this.createdBy = params?.createdBy || -1;
    this.createdByOwner = params?.createdByOwner || false;
    this.recordedByOwner = params?.recordedByOwner || false;
    this.recordedBy = params?.recordedBy || -1;
    this.description = params?.description || '';
    this.isPrivate = params?.isPrivate || false;
    this.length = params?.length || -1;
    this.name = params?.name || '';
    this.order = params?.order || -1;
    this.recordedAt = params?.recordedAt || new Date();
    this.videoUrl = params?.videoUrl;
    this.thumbnailUrl = params?.thumbnailUrl;
    this.recordingStatus = params?.recordingStatus || '';
    this.qrCodes = params?.qrCodes || [];
    this.isAudioOnly = params?.isAudioOnly || false;
    this.customThumbnail = params?.customThumbnail || '';
  }
}

export { Clip };
