/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { ChevronIcon, SettingsIcon } from 'assets/icons';
import { classnames, isAnyLoggedUser } from 'helpers/utils';
import { RouteName, goToPage } from 'routes';
import { Menu } from 'common/menu';
import { useClickOutside } from 'hooks/click-outside';
import { NavSections } from 'common/enums';
import CypressIds from 'cypressIds';
import styles from './right-section.module.scss';

type OptionNavbarMenuProps = {
  tab: NavSections;
  handleTabSelected: (queryParams: ParamsType) => void;
};

const OptionNavbarMenu: React.FC<OptionNavbarMenuProps> = ({
  tab,
  handleTabSelected,
}) => (
  <button
    type="button"
    onClick={() => handleTabSelected({ tab })}
    className={classnames(
      styles.actionRepeated,
      'text__body__regular__medium__textNeutral30',
    )}
    data-cy={tab}
  >
    {tab}
  </button>
);

type RightSectionProps = {
  user: UserType;
  logOutUser: () => void;
};

const RightSection: React.FC<RightSectionProps> = ({ user, logOutUser }) => {
  const [showUserOptionsMenu, setShowUserOptionsMenu] = useState(false);
  const containerUserOptionsRef = useRef(null);

  useClickOutside(containerUserOptionsRef, () => {
    setShowUserOptionsMenu(false);
  });

  const showLoginButton = () => (
    <button
      type="button"
      onClick={() => {
        goToPage(RouteName.SignIn);
      }}
    >
      <span className="text__body__semi__bold__medium__primary50"> Login </span>
    </button>
  );

  const showMenu = () => (
    <div ref={containerUserOptionsRef}>
      <Menu width={styles.optionsMenuWidth}>
        <OptionNavbarMenu
          tab={NavSections.albums}
          handleTabSelected={() => goToPage(RouteName.Albums)}
        />
        <OptionNavbarMenu
          tab={NavSections.contributors}
          handleTabSelected={() => goToPage(RouteName.Contributors)}
        />
        <OptionNavbarMenu
          tab={NavSections.templates}
          handleTabSelected={() => goToPage(RouteName.Templates)}
        />
        <OptionNavbarMenu
          tab={NavSections.store}
          handleTabSelected={() => goToPage(RouteName.Store)}
        />

        <hr className={classnames(styles.actionRepeated)} />
        <div
          role="button"
          tabIndex={0}
          onClick={() => goToPage(RouteName.Account)}
          className={styles.settings}
        >
          <button
            type="button"
            className={classnames(
              styles.optionMenu,
              'text__body__regular__medium__textNeutral30',
            )}
          >
            <SettingsIcon />
            Account
          </button>
        </div>
        <div className={classnames(styles.divider)} />
        <button
          type="button"
          onClick={() => logOutUser()}
          className={classnames(
            styles.optionMenu,
            'text__body__regular__medium__textNeutral30',
          )}
        >
          Logout
        </button>
      </Menu>
    </div>
  );

  const showUserMenu = () => (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setShowUserOptionsMenu(true)}
      className={styles.nameAndIcon}
      data-cy={CypressIds.navbarMenu}
    >
      <span>Hi,&nbsp;</span>
      <span className={styles.name}>{user.name}</span>
      <div className={styles.chevronIcon}>
        <ChevronIcon />
        {showUserOptionsMenu && showMenu()}
      </div>
    </div>
  );

  return isAnyLoggedUser(user) ? showUserMenu() : showLoginButton();
};

export { RightSection };
