class PaymentSerializer {
  static deSerializePaymentMethod(
    data: PaymentMethodRaw,
  ): PaymentMethodSerialized {
    return {
      brand: data.brand,
      lastFourDigits: data.last_four_digits,
      expirationMonth: data.expiration_month,
      expirationYear: data.expiration_year,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };
  }

  static deSerializePaymentInfo(data: PaymentInfoRaw): PaymentInfoSerialized {
    return {
      id: data.id,
      amount: parseFloat(data.amount),
      paymentDate: data.payment_date,
      paymentIntentId: data.payment_intent_id,
      status: data.status,
    };
  }
}

export { PaymentSerializer };
