class AgoraTokenChannel {
  token: string;

  channelName: string;

  uid: string;

  constructor(params?: AgoraTokenChannel) {
    this.token = params?.token || '';
    this.channelName = params?.channelName || '';
    this.uid = params?.uid || '';
  }
}

export { AgoraTokenChannel };
