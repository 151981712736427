import { useContext, useEffect, useState } from 'react';

import { ModalWarning } from 'common/modal-warning';
import { Album } from 'models/album';
import { AppContext } from 'context';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { Checkbox } from 'common/checkbox';
import { HelperText } from 'common/helper-text';

import styles from './request-album-download-modal.module.scss';

type RequestAlbumDownloadModalProps = {
  album: Album;
  onClose: () => void;
};

const RequestAlbumDownloadModal = ({
  album,
  onClose,
}: RequestAlbumDownloadModalProps) => {
  const {
    state: {
      data: {
        user: { email },
      },
    },
  } = useContext(AppContext);

  const [includePrivateClips, setIncludePrivateClips] = useState(false);
  const hasPublicRecordings =
    album.clips &&
    !!album.clips.find((clip) => !clip.isPrivate && clip.videoUrl);
  const hasPrivateRecordings =
    album.clips &&
    !!album.clips.find((clip) => clip.isPrivate && clip.videoUrl);

  useEffect(() => {
    // If there are no public recordings, then we should default to including private clips
    setIncludePrivateClips(!hasPublicRecordings);
  }, [hasPublicRecordings]);

  return (
    <ModalWarning
      className={styles.requestAlbumDownloadModal}
      title="Request offline copy"
      content={
        <div>
          <p>
            A zip file containing an offline copy of the <b>{album.title}</b>{' '}
            album will be sent to <b>{email}</b> within 24 hours.
          </p>
          <br />
          {hasPrivateRecordings && (
            <div className={styles.includePrivateClips}>
              <Checkbox
                itemKey="include-private-clips"
                onChange={() => setIncludePrivateClips(!includePrivateClips)}
                isChecked={includePrivateClips}
                disabled={!hasPublicRecordings}
              />
              <HelperText content="Include private clips in the offline copy" />
            </div>
          )}
        </div>
      }
      successButtonText="Submit request"
      successFn={async () => {
        await AlbumsController.requestAlbumDownload({
          id: album.id,
          includePrivateClips,
        });
        onClose();
      }}
      closeFn={onClose}
    />
  );
};

export { RequestAlbumDownloadModal };
