class ChangePassword {
  password: string;

  newPassword: string;

  newPasswordRepeat: string;

  constructor(params?: ChangePassword) {
    this.password = params?.password || '';
    this.newPassword = params?.newPassword || '';
    this.newPasswordRepeat = params?.newPasswordRepeat || '';
  }
}

export { ChangePassword };
