import React, { ChangeEvent, useContext, useEffect } from 'react';
import {
  checkEmailAndGetGenericError,
  checkStateAndGetError,
  checkValueAndGetEmptyError,
  classnames,
} from 'helpers/utils';
import { isEmpty } from 'lodash';
import { AppContext } from 'context';
import { Input } from 'common/input';
import { Select } from 'common/select';
import { usStatesList } from 'config/us-states-list';
import { contactLink } from 'config/constants';
import {
  StoreAction,
  StoreStateType,
  ShippingDetailsErrorsType,
} from 'pages/store/store-reducer';
import { StoreController } from 'networking/controllers/store-controller';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { PaymentMethodCard } from 'pages/account/components/payment-method-card';
import styles from './checkout.module.scss';
import { OrderList } from './OrderList';

type OrderDetailsProps = {
  state: StoreStateType;
  dispatch: React.Dispatch<StoreAction>;
  notifyChangeState: (stateId: string) => void;
};

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  state,
  dispatch,
  notifyChangeState,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { state: generalState } = useContext(AppContext);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'UPDATE_SHIPPING_DETAILS',
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleChangeState = (newState: SelectType) => {
    dispatch({
      type: 'UPDATE_SHIPPING_DETAILS',
      field: 'state',
      value: newState.value,
    });
    notifyChangeState(newState.id as string);
  };

  const isThereAPaymentMethod = () =>
    !isEmpty(generalState.data.user.paymentMethods);

  const fetchShippingCosts = async () => {
    const shippingCosts = await StoreController.getShippingCosts();
    dispatch({ type: 'SET_SHIPPING_COSTS', shippingCosts });
  };

  const validateField = <T extends keyof ShippingDetailsErrorsType>(
    field: T,
  ) => {
    const emptyFieldMessage = checkValueAndGetEmptyError(
      state.shippingDetails[field],
    );
    dispatch({
      type: 'UPDATE_SHIPPING_ERRORS',
      shippingDetailsErrors: {
        ...state.shippingDetailsErrors,
        [field]: emptyFieldMessage.toUpperCase(),
      },
    });

    if (field === 'email' && state.shippingDetails.email) {
      const emailMessage = checkEmailAndGetGenericError(
        state.shippingDetails.email,
      );
      dispatch({
        type: 'UPDATE_SHIPPING_ERRORS',
        shippingDetailsErrors: {
          ...state.shippingDetailsErrors,
          [field]: emailMessage.toUpperCase(),
        },
      });
    }
    if (field === 'state') {
      const stateMessage = checkStateAndGetError(state.shippingDetails.state);
      dispatch({
        type: 'UPDATE_SHIPPING_ERRORS',
        shippingDetailsErrors: {
          ...state.shippingDetailsErrors,
          [field]: stateMessage.toUpperCase(),
        },
      });
    }
  };

  const showPaymentMethodSection = () => (
    <>
      <div
        className={classnames('text__body__regular__overline__textNeutral40')}
      >
        PAYMENT METHOD
      </div>
      <PaymentMethodCard
        generalState={generalState}
        className={classnames(styles.paymentMethodCard)}
        subtitleText={
          isThereAPaymentMethod()
            ? ''
            : 'There’s no payment method associated with your account.'
        }
      />
      {isThereAPaymentMethod() ? (
        <div className="text__body__regular__tiny__textNeutral30">
          *Updating your payment method will affect future transactions in your
          account. Including subscription renewals.{' '}
        </div>
      ) : null}
    </>
  );

  const showShippingDetailsSection = () => (
    <>
      <div
        className={classnames('text__body__regular__overline__textNeutral40')}
      >
        SHIPPING DETAILS
      </div>
      <div>
        <Input
          id="fullName"
          name="fullName"
          label="Full Name"
          placeholder="Full Name"
          value={state.shippingDetails.fullName}
          type="text"
          onChange={handleInputChange}
          withValidation
          errorMessage={state.shippingDetailsErrors.fullName}
          onBlur={() => validateField('fullName')}
        />
        <Input
          id="email"
          name="email"
          label="Email"
          placeholder="Email"
          value={state.shippingDetails.email}
          type="email"
          onChange={handleInputChange}
          withValidation
          errorMessage={state.shippingDetailsErrors.email}
          onBlur={() => validateField('email')}
        />
        <Input
          id="addressFirstField"
          name="addressFirstField"
          label="Street Address"
          placeholder="Street Address 1"
          value={state.shippingDetails.addressFirstField}
          type="text"
          onChange={handleInputChange}
          withValidation
          errorMessage={state.shippingDetailsErrors.addressFirstField}
          onBlur={() => validateField('addressFirstField')}
        />
        <Input
          id="addressSecondField"
          name="addressSecondField"
          label=""
          placeholder="Street Address 2 (optional)"
          value={state.shippingDetails.addressSecondField}
          type="text"
          onChange={handleInputChange}
          className={classnames({
            [styles.addressSecondaryInput]:
              !state.shippingDetailsErrors.addressFirstField,
          })}
        />
        <div className={styles.countryAndZipCode}>
          <div className={styles.country}>
            <span className="text__body__semi__bold__medium__textNeutral40">
              Country
            </span>
            <div className={styles.countryContent}>
              <div className="text__body__regular__medium__textNeutral30">
                United States
              </div>
              <div className="text__body__regular__tiny__textNeutral30">
                For orders outside the US{' '}
                <a
                  className="text__body__regular__tiny__primary50"
                  href={contactLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  contact support.
                </a>
              </div>
            </div>
          </div>
          <Input
            id="zipCode"
            name="zipCode"
            label="ZIP code"
            placeholder="ZIP code"
            value={state.shippingDetails.zipCode}
            type="text"
            onChange={handleInputChange}
            withValidation
            errorMessage={state.shippingDetailsErrors.zipCode}
            onBlur={() => validateField('zipCode')}
          />
        </div>
        <div className={styles.cityAndState}>
          <Input
            id="city"
            name="city"
            label="City"
            placeholder="City"
            value={state.shippingDetails.city}
            type="text"
            onChange={handleInputChange}
            withValidation
            errorMessage={state.shippingDetailsErrors.city}
            onBlur={() => validateField('city')}
          />
          <div>
            <Select
              options={usStatesList}
              optionSelected={{ id: '', value: '' }}
              onClickOption={handleChangeState}
              id="state"
              value={state.shippingDetails.state}
              placeholder="State"
              title="State"
              withValidation
              errorMessage={state.shippingDetailsErrors.state}
            />
          </div>
        </div>
      </div>
    </>
  );
  useEffect(() => {
    fetchShippingCosts();
  }, []);

  return (
    <div className={styles.orderDetailsContent}>
      <div className={styles.leftSection}>
        <div className={styles.paymentMethodSection}>
          {showPaymentMethodSection()}
        </div>
        <div className={styles.shippingDetailsSection}>
          {showShippingDetailsSection()}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.rightSection}>
          <OrderList state={state} />
        </div>
      )}
    </div>
  );
};
