/* eslint-disable max-len */
import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { SubscriptionType } from 'models/subscription-type';
import { SubscriptionTypesSerializer } from 'networking/serializers/subscription-types-serializer';
import { SubscriptionDeal } from 'models/subscription-deal';
import { SubscriptionDealSerializer } from 'networking/serializers/subscription-deal-serializer';
import { SubscriptionDiscountedPrices } from 'models/subscription-discounted-prices';
import { UserSerializer } from 'networking/serializers/user-serializer';
import { SubscriptionSwitchList } from 'models/subscription-switch-list';
import { AllDataUser } from 'models/all-data-user';

class SubscriptionController {
  static async getSubscriptionTypes() {
    const response = await ApiService.get<SubscriptionTypeRaw[]>(
      API_ROUTES.SUBSCRIPTION_TYPES,
    );

    const deSerializedTypes = (response.data || []).map(
      SubscriptionTypesSerializer.deSerializeSubscriptionType,
    );

    return deSerializedTypes.map((type) => new SubscriptionType(type));
  }

  static async getSubscriptionDealByCode(code: string) {
    const response = await ApiService.get<SubscriptionDealResponseRaw>(
      API_ROUTES.GET_SUBSCRIPTION_DEAL_BY_CODE(code),
    );

    const deSerializedSubDeal =
      SubscriptionDealSerializer.deSerializeSubscriptionDealResponse(
        response.data,
      );

    return new SubscriptionDeal(deSerializedSubDeal);
  }

  static async getSubscriptionPrices() {
    const resp = await ApiService.get<SubscriptionDiscountedPriceRaw[]>(
      API_ROUTES.SUBSCRIPTION_PRICES,
    );

    const deSerializedSubDiscountedPrices =
      SubscriptionDealSerializer.deSerializeSubscriptionDiscountedPricesList(
        resp.data,
      );

    return new SubscriptionDiscountedPrices(deSerializedSubDiscountedPrices);
  }

  static async applySubscriptionDealByCode(code: string) {
    const response = await ApiService.post<AllDataUserRaw>(
      API_ROUTES.APPLY_SUBSCRIPTION_DEAL_BY_CODE,
      { id: code },
    );

    const deSerializedUser = UserSerializer.deSerializeAllDataUser(
      response.data,
    );

    return new AllDataUser(deSerializedUser);
  }

  static async getTierSwitchPrices(albumId: number) {
    const response = await ApiService.get<SubscriptionSwitchRaw[]>(
      API_ROUTES.GET_TIER_SWITCH_PRICES(albumId),
    );

    const deSerializedSubSwitchList =
      SubscriptionDealSerializer.deSerializeSubscriptionSwitchPricesList(
        response.data,
      );

    return new SubscriptionSwitchList(deSerializedSubSwitchList);
  }

  static async removeSubscriptionDealCode(code: string) {
    await ApiService.delete<void>(
      API_ROUTES.REMOVE_SUBSCRIPTION_DEL_CODE(code),
    );
  }
}

export { SubscriptionController };
