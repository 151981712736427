export enum SubscriptionSchemeId {
  trial = 'trial',
  readOnly = 'read_only',
  standard = 'standard',
  legacy = 'legacy',
  free = 'free',
  readOnlyFree = 'read_only_free',
  lifeTime = 'lifetime_album',
}

export enum SubscriptionStatus {
  subscriptionActive = 'subscription_active',

  firstPaymentFailed = 'first_payment_failed',

  paymentPending = 'payment_pending',
  paymentFailed = 'payment_failed',

  subscriptionCanceled = 'subscription_canceled',
  subscriptionOverdue = 'subscription_overdue',
  subscriptionEnded = 'subscription_ended',
}

export enum Breakpoints {
  xl = 1400,
  lg = 1200,
  md = 992,
  sm = 768,
  xs = 576,
}

export enum TimeUnit {
  milliseconds = 'milliseconds ',
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
}

export enum BasicOrderByValues {
  alphabeticallyAZ = 'Alphabetically A-Z',
  alphabeticallyZA = 'Alphabetically Z-A',
  lastUpdate = 'Last Update',
  oldestUpdate = 'Oldest Update',
}

export enum Countries {
  UnitedStates = 'United States',
  Other = 'Other',
}

export enum SubModificationType {
  upgrade = 'upgrade',
  downgrade = 'downgrade',
}

export enum InvitationStatusId {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected',
}

export enum AllContributorsFiltersIds {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected',
}

export enum AllAlbumsFiltersIds {
  active = 'active',
  restricted = 'restricted',
  locked = 'locked',
}

export enum NavSections {
  albums = 'Albums',
  contributors = 'Contributors',
  templates = 'My Templates',
  account = 'Account',
  store = 'Store',
  logout = 'Logout',
}

export enum CreateAlbumSteps {
  subscriptionType = 1,
  information = 2,
}

export enum AlbumTabs {
  clips = 'Clips',
  planInterview = 'Plan interview',
  videoCall = 'Video call',
  playlists = 'Playlists',
  upgradeAlbum = 'Upgrade album',
  about = 'About',
  recordUpload = 'Record/Upload',
}

export enum VideoCallMenuQuestionsTabs {
  pending = 'pending',
  recorded = 'recorded',
}

export enum FileExtension {
  Jpg = '.jpg',
  Jpeg = '.jpeg',
  Png = '.png',
  Mp4 = '.mp4',
  Webm = '.webm',
}

export enum NotificationType {
  Error = 'Error',
  Success = 'Success',
  Info = 'Info',
}

export enum PaymentType {
  CreateNewAlbum = 'CreateNewAlbum',
  ModifyAlbumSubscription = 'ModifyAlbumSubscription',
}

export enum QuestionsManagmentViewType {
  editAlbum = 'editAlbum',
  myTemplates = 'myTemplates',
}

export enum RecordingStatus {
  noVideo = 'no_video',
  recorded = 'recorded',
  processing = 'processing',
}

export enum VideoStatus {
  new = 'new',
  inProgress = 'inProgress',
  ready = 'ready',
  failed = 'failed',
}

export enum ClipActions {
  select = 'select',
  delete = 'delete',
  togglePrivate = 'togglePrivate',
  editClip = 'editClip',
  toggleAudioOnly = 'toggleAudioOnly',
}
export enum AlbumOwnershipFiltersIds {
  owner = 'owner',
  contributor = 'contributor',
}

export enum AlbumStatusFiltersIds {
  active = 'active',
  restricted = 'restricted',
  locked = 'locked',
}

export enum QrCodesFiltersIds {
  withQrCodes = 'with_qr_codes',
  withoutQrCodes = 'without_qr_codes',
}

export enum SelfRecordingStatus {
  recorded = 'recorded',
  processing = 'processing',
  error = 'error',
}

export enum PaymentStatus {
  pending = 'pending',
  failed = 'failed',
  paid = 'paid',
}
