import { AccountStateType } from 'pages/account/account-reducer';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import {
  classnames,
  getAlbumStatusLabel,
  isCanceledAlbum,
  isRestrictedAlbum,
} from 'helpers/utils';
import { InfoLabel } from 'common/info-label';
import { Button, ButtonStyle } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { dayjs } from 'helpers/dayjs';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Pagination } from 'models/pagination';
import { PaginationSelect } from 'common/paginator-select';
import { AccountAlbumsRows } from './components/account-albums-rows';
import { AccountAlbumsCardsMobile } from './components/account-albums-cards-mobile';
import styles from './subscriptions-table.module.scss';

type SubscriptionsTableProps = {
  accountState: AccountStateType;
  className?: string;
  fetchingAlbums: boolean;
  notifyGetMoreAlbums: (page: number) => void;
};

const SubscriptionsTable: React.FC<SubscriptionsTableProps> = ({
  accountState,
  className = '',
  fetchingAlbums,
  notifyGetMoreAlbums,
}) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const { albums, pagination } = accountState;

  const isNotLastPage = () => pagination?.lastPage !== 1;

  const showTitle = () => (
    <p className="mb-15 text__title2__textNeutral40"> Subscriptions </p>
  );

  const getAlbumStatus = (alb: AlbumType, positionClass: string) => {
    const { withIcon, statusValue } = getAlbumStatusLabel(alb);

    return (
      statusValue && (
        <InfoLabel
          withIcon={withIcon}
          className={classnames(styles.statusLabel, positionClass)}
          content={
            <p
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
              )}
            >
              {statusValue}
            </p>
          }
        />
      )
    );
  };
  const getRenewalExpiration = (album: AlbumType) => {
    const isRestricted = isRestrictedAlbum(album);

    let postText = '';
    let preText = '';

    if (isRestricted) {
      postText = 'Payment failed.';
    }

    if (isCanceledAlbum(album)) {
      preText = 'Cancelled. Expires on:';
    }

    return (
      <span>
        <span>
          <span>
            {preText}
            &nbsp;
          </span>
          <span className={classnames({ [styles.warningText]: isRestricted })}>
            {album.nextPaymentDate !== null
              ? `${dayjs(album.nextPaymentDate).format('MM/DD/YY')}`
              : '-'}
          </span>
        </span>
        <span>
          &nbsp;
          {postText}
        </span>
      </span>
    );
  };

  const showMoreAlbumsButton = () => (
    <div className={styles.moreAlbumsButton}>
      <PaginationSelect
        paginationInfo={pagination as Pagination}
        notifyGetMoreAlbums={notifyGetMoreAlbums}
      />
    </div>
  );

  const accountRows = () =>
    mobile ? (
      <AccountAlbumsCardsMobile
        albums={albums}
        getRenewalExpiration={getRenewalExpiration}
        getAlbumStatusLabel={getAlbumStatus}
      />
    ) : (
      <AccountAlbumsRows
        albums={albums}
        getRenewalExpiration={getRenewalExpiration}
        getAlbumStatusLabel={getAlbumStatus}
      />
    );

  const showTable = () => (
    <>
      {fetchingAlbums ? (
        <div className={styles.spinnerCenter}>
          <Spinner className={globalStyles.mediumSpinner} />
        </div>
      ) : (
        accountRows()
      )}
      <div className={styles.moreAlbumsButton}>
        {accountState.albums.length && isNotLastPage()
          ? showMoreAlbumsButton()
          : ''}
      </div>
    </>
  );

  const showEmptySubscriptions = () => (
    <div className={styles.emptyPage}>
      <div className={styles.emptyPageTexts}>
        <p
          className={classnames(
            'text__heading5__textNeutral30',
            styles.headingEmpty,
          )}
        >
          You don’t have any active subscriptions
        </p>
        <p className="text__body__regular__medium__textNeutral30">
          Subscriptions belong to albums you own.
          <span>You can&nbsp;</span>
          <Button
            buttonStyle={ButtonStyle.PrimaryGhostLink}
            onClick={() => goToPage(RouteName.AlbumNew)}
          >
            create a new album
          </Button>
        </p>
      </div>
    </div>
  );

  return (
    <div className={className}>
      {showTitle()}
      {albums.length ? showTable() : showEmptySubscriptions()}
    </div>
  );
};

export { SubscriptionsTable };
