import { SubscriptionSchemeId, SubscriptionStatus } from 'common/enums';
import { AgoraVideoCallProps } from './agora-video-call-props';
import { Owner } from './owner';

class Album {
  id: number;

  title: string;

  clipAmount: number;

  createdAt: Date;

  lastUpdate: Date;

  totalTime: number;

  coverThumbnailUrl?: string;

  coverUrl?: string;

  clips?: ClipType[];

  unansweredQuestions?: ClipType[];

  scheduledDowngrade: SubscriptionSchemeId | null;

  subscriptionStatus: SubscriptionStatus | '';

  subscriptionType: SubscriptionSchemeId | '';

  owner: OwnerInfoType;

  contributors: ContributorType[];

  lastPayment: PaymentInfoType | null;

  lastPendingPayment: PaymentInfoType | null;

  nextPaymentDate: Date | null;

  nextPaymentAmount: number;

  videoCallProps?: AgoraVideoCallPropsType;

  qrCodes: QrCode[];

  reminder?: ReminderType | null;

  maxClips: number;

  constructor(params?: Album | null) {
    this.id = params?.id || -1;
    this.title = params?.title || '';
    this.coverThumbnailUrl = params?.coverThumbnailUrl;
    this.coverUrl = params?.coverUrl;
    this.clipAmount = params?.clipAmount || 0;
    this.createdAt = params?.createdAt || new Date();
    this.lastUpdate = params?.lastUpdate || new Date();
    this.totalTime = params?.totalTime || -1;
    this.clips = params?.clips || [];
    this.unansweredQuestions = params?.unansweredQuestions;
    this.scheduledDowngrade = params?.scheduledDowngrade || null;
    this.subscriptionStatus = params?.subscriptionStatus || '';
    this.subscriptionType = params?.subscriptionType || '';
    this.owner = params?.owner || new Owner();
    this.contributors = params?.contributors || [];
    this.lastPayment = params?.lastPayment || null;
    this.lastPendingPayment = params?.lastPendingPayment || null;
    this.nextPaymentDate = params?.nextPaymentDate || null;
    this.nextPaymentAmount = params?.nextPaymentAmount || 0;
    this.videoCallProps = params?.videoCallProps || new AgoraVideoCallProps();
    this.qrCodes = params?.qrCodes || [];
    this.reminder = params?.reminder || null;
    this.maxClips = params?.maxClips || 0;
  }
}

export { Album };
