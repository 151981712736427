import React from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { DeleteIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import styles from './image-preview.module.scss';

type ImagePreviewProps = {
  imageLocation: string;
  removePreview: Function;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({
  imageLocation = '',
  removePreview,
}) => (
  <>
    <div className={styles.imageContainer}>
      <img
        src={imageLocation}
        alt="Uploaded Cover"
        className={styles.preview}
      />
    </div>
    <div className={styles.buttonContainer}>
      <Button
        buttonStyle={ButtonStyle.RedGhost}
        buttonSize={ButtonSize.Tiny}
        className={classnames(styles.buttonIcon, styles.dangerButton)}
        onClick={() => removePreview()}
      >
        <DeleteIcon />
        &nbsp; Delete cover
      </Button>
    </div>
  </>
);

export { ImagePreview };
