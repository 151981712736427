import { InfoModal } from 'common/info-modal';
import styles from './discount-code-details-modal.module.scss';

type DiscountCodeDetailsModalProps = {
  discountCodesList: SubscriptionDealResponseType[];
  notifyCloseModal: () => void;
};

const DiscountCodeDetailsModal: React.FC<DiscountCodeDetailsModalProps> = ({
  discountCodesList,
  notifyCloseModal,
}) => {
  const discountListDetailsContent = (list: SubscriptionDealResponseType[]) => (
    <div className={styles.discountListModal}>
      {list.map((code: SubscriptionDealResponseType) => (
        <div key={code.name} className={styles.discountItem}>
          <span className="text__body__semi__bold__medium__textNeutral40">
            {code.name.toLocaleUpperCase()}
          </span>
          <span>&nbsp;- &nbsp;</span>
          <span className="text__body__regular__medium__textNeutral40">
            {code.description}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <InfoModal
      title="Discount details"
      content={discountListDetailsContent(discountCodesList)}
      onClose={() => notifyCloseModal()}
    />
  );
};

export { DiscountCodeDetailsModal };
