import React from 'react';
import { Line } from 'common/line';
import { Button, ButtonSize, ButtonType } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { classnames } from 'helpers/utils';
import styles from './reset-password-step.module.scss';

const ResetPasswordStep2: React.FC = () => {
  const showContentView = () => (
    <>
      <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
        {' '}
        Your password has been changed.{' '}
      </div>
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral30',
          'mb-12',
        )}
      >
        Go back to the log in page and sign in with your new credentials.
      </div>

      <Line />

      <Button
        className={classnames(styles.backButton)}
        onClick={() => {
          goToPage(RouteName.SignIn);
        }}
        buttonSize={ButtonSize.Medium}
        buttonType={ButtonType.Submit}
      >
        Back to log in
      </Button>
    </>
  );

  return <>{showContentView()}</>;
};

export { ResetPasswordStep2 };
