import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { classnames } from 'helpers/utils';
import { ChevronIcon } from 'assets/icons';
import { RouteName, goToPage } from 'routes';
import styles from './contributor-table-row-mobile.module.scss';

type ContributorRowMobileProps = {
  albums: AlbumType[];
};

const ContributorRowMobile: React.FC<ContributorRowMobileProps> = ({
  albums,
}) => (
  <div className={styles.albumsCardsMobile}>
    <div
      className={classnames(
        styles.body,
        'text__body__regular__medium__textNeutral30',
      )}
    >
      {albums.map((album: AlbumType) => (
        <div key={album.id} className={styles.albumCard}>
          <div className={styles.leftSize}>
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral40',
                styles.albumName,
              )}
            >
              {album.title}
            </div>
            <div className={classnames(styles.owner)}>
              <span
                className={classnames(
                  'text__body__semi__bold__small__textNeutral30',
                )}
              >
                {' '}
                Owner:{' '}
              </span>
              <span
                className={classnames(
                  'text__body__regular__small__textNeutral30',
                )}
              >
                {album.owner.name}
              </span>
            </div>
            <div className={classnames(styles.company)}>
              <span
                className={classnames(
                  'text__body__semi__bold__small__textNeutral30',
                )}
              >
                {' '}
                Company:{' '}
              </span>
              <span
                className={classnames(
                  'text__body__regular__small__textNeutral30',
                )}
              >
                {album.owner.companyName ? album.owner.companyName : '-'}
              </span>
            </div>
          </div>
          <div className={styles.rightSize}>
            <Button
              className={styles.detailsButton}
              buttonStyle={ButtonStyle.PrimaryGhost}
              buttonSize={ButtonSize.Small}
              onClick={() => {
                goToPage(RouteName.AlbumDetail, { id: album.id });
              }}
            >
              <ChevronIcon className={styles.chevronIcon} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export { ContributorRowMobile };
