/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { Button, ButtonStyle } from 'common/button';
import {
  msMaxRecordingPeriodTime,
  msMaxTimeBeforeShowingAlertLongRecording,
  msMaxTimeBeforeShowingTimeRunOutToggle,
} from 'config/constants';
import { PlayIcon, StopIcon } from 'assets/icons';
import { IAgoraRTCRemoteUser } from 'agora-rtc-react';
import { VideoCallMenuQuestionsTabs } from 'common/enums';
import { classnames } from 'helpers/utils';
import startRecordingSound from 'assets/audios/start-recording-sound.mp3';
import endRecordingSound from 'assets/audios/end-recording-sound.mp3';
import { RecordingProgressBar } from '../recording-progress-bar';
import styles from './footer.module.scss';

type FooterProps = {
  isRecording: boolean;
  tabSelected: VideoCallMenuQuestionsTabs;
  keepRecordingToggleValue: boolean;
  noAbleToStartRecording: boolean;
  stoppingRecording: boolean;
  startingRecording: boolean;
  endpointsNotWorking: boolean;
  clipsCountRecorded: number;
  maxNumberRecordingChunks: number;
  istimeToShowAlertToggle: boolean;
  istimeToShowLongVideoAlert: boolean;
  otherUserInCall: boolean;
  otherUserTracks: IAgoraRTCRemoteUser | null;
  otherUserTrackState: TrackStateType;
  notifyChangeIsRecording: (
    newValue: boolean,
    manualStop?: boolean,
  ) => Promise<void>;
  notifyShowAlertTimeRunOutToggle: (show: boolean) => void;
  notifyShowAlertTimeRunOutLongVideo: (show: boolean) => void;
  notifyErrorRecording: (message: string) => void;
  notifyIncrementClipCountRecorded: () => void;
  notifyResetClipCountRecorded: () => void;
};

const Footer: React.FC<FooterProps> = ({
  isRecording,
  tabSelected,
  // keepRecordingToggleValue, // ! Not use for now while we have only one video of 30 min.
  // istimeToShowAlertToggle, // ! Not use for now while we have only one video of 30 min.
  noAbleToStartRecording,
  stoppingRecording,
  startingRecording,
  endpointsNotWorking,
  clipsCountRecorded,
  maxNumberRecordingChunks,
  istimeToShowLongVideoAlert,
  otherUserInCall,
  otherUserTracks,
  otherUserTrackState,
  notifyChangeIsRecording,
  notifyShowAlertTimeRunOutToggle,
  notifyShowAlertTimeRunOutLongVideo,
  notifyErrorRecording,
  notifyIncrementClipCountRecorded,
  notifyResetClipCountRecorded,
}) => {
  const [msTotalTimeRecorded, setMsTotalTimeRecorded] = useState<number>(0);
  const [msChunkTimeRecorded, setMsChunkTimeRecorded] = useState<number>(0);

  const endRecordingSoundRef = useRef<HTMLAudioElement>(null);
  const startRecordingSoundRef = useRef<HTMLAudioElement>(null);

  let timer: NodeJS.Timer;

  const lastRecordingChunk = () =>
    clipsCountRecorded === maxNumberRecordingChunks;

  const handleForceStopRecording = async () => {
    try {
      await notifyChangeIsRecording(false, true);
    } catch (err: any) {
      notifyErrorRecording('There was an error trying to stop recording.');
    }
  };

  const resetAllRecordingValues = () => {
    setMsTotalTimeRecorded(0);
    setMsChunkTimeRecorded(0);
    notifyShowAlertTimeRunOutToggle(false);
    notifyShowAlertTimeRunOutLongVideo(false);
    notifyResetClipCountRecorded();
  };

  const startNewRecordingChunk = () => {
    notifyShowAlertTimeRunOutToggle(false);
    notifyIncrementClipCountRecorded();
    setMsChunkTimeRecorded(0);
  };

  useEffect(() => {
    // ! Not use for now while we have only one video of 30 min.
    // if (msChunkTimeRecorded === msMaxClipChunkDuration) {
    //   if (!keepRecordingToggleValue) {
    //     handleForceStopRecording();
    //   } else {
    //     startNewRecordingChunk();
    //   }
    // }

    // ! Not use for now while we have only one video of 30 min.
    // if ((msChunkTimeRecorded > msMaxTimeBeforeShowingTimeRunOutToggle) && !istimeToShowAlertToggle && !lastRecordingChunk()) {
    //   notifyShowAlertTimeRunOutToggle(true);
    // }

    if (
      msTotalTimeRecorded > msMaxTimeBeforeShowingAlertLongRecording &&
      !istimeToShowLongVideoAlert
    ) {
      notifyShowAlertTimeRunOutLongVideo(true);
    }
  }, [msChunkTimeRecorded]);

  useEffect(() => {
    if (!isRecording) {
      resetAllRecordingValues();
    } else {
      timer = setInterval(() => {
        setMsTotalTimeRecorded((prevState) => prevState + 1000);
        setMsChunkTimeRecorded((prevState) => prevState + 1000);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isRecording]);

  useEffect(() => {
    if (msTotalTimeRecorded === msMaxRecordingPeriodTime + 1000) {
      resetAllRecordingValues();

      handleForceStopRecording();
    }
  }, [msTotalTimeRecorded]);

  const otherUserInCallWithNoAudio = () =>
    otherUserInCall &&
    !(otherUserTracks?.audioTrack && otherUserTrackState.audio);

  const handleButtonStatus = () => {
    if (tabSelected === VideoCallMenuQuestionsTabs.recorded && !isRecording)
      return true;

    if (noAbleToStartRecording || endpointsNotWorking) return true;

    if (!isRecording && otherUserInCallWithNoAudio()) return true;

    return false;
  };

  const recordingButton = () => (
    <Button
      buttonStyle={
        isRecording ? ButtonStyle.SecondaryFilled : ButtonStyle.PrimaryFilled
      }
      onClick={() => {
        notifyChangeIsRecording(!isRecording, true);
        if (isRecording) {
          endRecordingSoundRef.current?.play();
        } else {
          startRecordingSoundRef.current?.play();
        }
      }}
      disabled={handleButtonStatus()}
      className={styles.recordingButton}
    >
      <div className={styles.recordingButtonInside}>
        {isRecording ? <StopIcon /> : <PlayIcon />}
        {isRecording ? 'Stop recording' : 'Start recording'}
      </div>
    </Button>
  );

  const recordingText = () => {
    let text = '';

    if (!isRecording && otherUserInCallWithNoAudio()) {
      text = 'Respondent’s mic is off';
    } else if (isRecording) {
      text = 'Recording';
    } else {
      text = 'Recording stopped';
    }

    return (
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral10',
          styles.recordingText,
        )}
      >
        {text}
      </div>
    );
  };

  const stoppingRecordingText = () => (
    <div className="text__body__regular__medium__textNeutral10">
      {' '}
      Processing video...{' '}
    </div>
  );

  const startingRecordingText = () => (
    <div className="text__body__regular__medium__textNeutral10">
      {' '}
      Starting recording, please wait...{' '}
    </div>
  );

  const showFooter = () => (
    <>
      <audio ref={startRecordingSoundRef} hidden>
        <source src={startRecordingSound} type="audio/mp3" />
        <track kind="captions" />
      </audio>
      <audio ref={endRecordingSoundRef} hidden>
        <source src={endRecordingSound} type="audio/mp3" />
        <track kind="captions" />
      </audio>
      <RecordingProgressBar
        chuckTimeRecorded={msChunkTimeRecorded}
        maxTimeRecording={msMaxRecordingPeriodTime}
        // maxTimeRecording={msMaxClipChunkDuration}  // ! Use this when we use 5 minutes video again.
      />
      <div className={styles.buttonSection}>
        {!stoppingRecording && !startingRecording && recordingButton()}
        {stoppingRecording && stoppingRecordingText()}
        {startingRecording && startingRecordingText()}

        {!stoppingRecording && !startingRecording && recordingText()}
      </div>
    </>
  );

  return showFooter();
};

export { Footer };
