import { VideoCallRole } from 'hooks/use-videocall-websocket';

const getUserRole = (
  callInformation: VideoCallInformationType,
  userId: number,
): VideoCallRole =>
  callInformation.videocallInterviewerId === userId
    ? VideoCallRole.INTERVIEWER
    : VideoCallRole.RESPONDENT;

const getOtherRole = (actualRole: VideoCallRole): VideoCallRole =>
  actualRole === VideoCallRole.INTERVIEWER
    ? VideoCallRole.RESPONDENT
    : VideoCallRole.INTERVIEWER;

const isRoleChanged = (
  currentRole: VideoCallRole,
  userId: number,
  callInformation: VideoCallInformationType,
): boolean => {
  if (currentRole === VideoCallRole.INTERVIEWER) {
    return callInformation.videocallInterviewerId !== userId;
  }
  if (currentRole === VideoCallRole.RESPONDENT) {
    // Guest user
    if (userId <= 0) {
      return !callInformation.videocallRespondentIsGuest;
    }
    return callInformation.videocallRespondentId !== userId;
  }
  return false;
};

const userHasRole = (
  callInformation: VideoCallInformationType,
  userId: number,
): boolean => {
  if (userId <= 0) return callInformation.videocallRespondentIsGuest;
  return [
    callInformation.videocallInterviewerId,
    callInformation.videocallRespondentId,
  ].includes(userId);
};

const videocallIsFull = (callInformation: VideoCallInformationType) =>
  (!!callInformation.videocallInterviewerId ||
    callInformation.videocallInterviewerIsGuest) &&
  (!!callInformation.videocallRespondentId ||
    callInformation.videocallRespondentIsGuest);

export {
  getUserRole,
  isRoleChanged,
  userHasRole,
  getOtherRole,
  videocallIsFull,
};
