import { SelfRecordingContext } from 'context/self-recording-context';
import { useContext } from 'react';

const selfRecordingClipName = (index: number) => {
  const {
    state: { selectedQuestion, videosRecorded },
  } = useContext(SelfRecordingContext);
  if (videosRecorded.length > 1) {
    return selectedQuestion
      ? `${selectedQuestion.name}(${index + 1})`
      : `Custom recording (${index + 1})`;
  }
  return selectedQuestion ? selectedQuestion.name : 'Custom recording';
};

export { selfRecordingClipName };
