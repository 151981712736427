import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { ContributorsStateType } from 'pages/contributors/contributors-reducer';
import { useClickOutside } from 'hooks/click-outside';
import { Input } from 'common/input';
import { ChevronIcon, FilterIcon, SearchIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import { Menu, PositionType } from 'common/menu';
import { Select } from 'common/select';
import { contributorsOrderByOptions } from 'config/constants';
import { useMediaQuery } from 'hooks/use-media-query';
import { ThreeStatesCheckbox } from 'common/checkbox-three-states';
import { HelperText } from 'common/helper-text';
import { Checkbox } from 'common/checkbox';
import { cloneDeep } from 'lodash';
import {
  AllAlbumsFiltersIds,
  AllContributorsFiltersIds,
  Breakpoints,
} from 'common/enums';
import styles from './contributors-filters.module.scss';

const contributorsFiltersArray: CheckboxOptionSimpleType[] = [
  {
    id: AllContributorsFiltersIds.accepted,
    name: 'Accepted invites',
    isSelected: true,
  },
  {
    id: AllContributorsFiltersIds.pending,
    name: 'Pending invites',
    isSelected: true,
  },
  {
    id: AllContributorsFiltersIds.rejected,
    name: 'Rejected invites',
    isSelected: true,
  },
];

const albumsFiltersArray: CheckboxOptionSimpleType[] = [
  { id: AllAlbumsFiltersIds.active, name: 'Active albums', isSelected: true },
  {
    id: AllAlbumsFiltersIds.restricted,
    name: 'Restricted albums',
    isSelected: true,
  },
  { id: AllAlbumsFiltersIds.locked, name: 'Locked albums', isSelected: true },
];

const allContributorsGroupFilter: CheckboxGroupType = {
  id: 'allContributorsFilter',
  name: 'All Contributors',
  checkValue: true,
  options: contributorsFiltersArray,
};

const allAlbumsGroupFilter: CheckboxGroupType = {
  id: 'allAlbumsFilter',
  name: 'All Albums',
  checkValue: true,
  options: albumsFiltersArray,
};

type ContributorsFiltersProps = {
  contributorsState: ContributorsStateType;
  className?: string;
  notifyChangeSearch: (newValue: string) => void;
  notifyClickSortBy: (option: SelectType) => void;
  notifyAllNewFiltersSelected: (
    queryParams: ContributorsQueryParamsType,
  ) => void;
};

const ContributorsFilters: React.FC<ContributorsFiltersProps> = ({
  contributorsState,
  notifyChangeSearch,
  notifyClickSortBy,
  notifyAllNewFiltersSelected,
  className,
}) => {
  const [showFilterContributorsOptions, setShowFilterContributorsOptions] =
    useState<boolean>(false);

  const [checkBoxFilters, setCheckBoxFilters] = useState([
    allContributorsGroupFilter,
    allAlbumsGroupFilter,
  ]);

  const { queryParams, searchValue } = contributorsState;

  const containerContriButorsFilterOptionsRef = useRef(null);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  useClickOutside(containerContriButorsFilterOptionsRef, () => {
    setShowFilterContributorsOptions(false);
  });

  const selectAllOptionsFromCheckBoxGroup = (
    options: CheckboxOptionSimpleType[],
  ) =>
    options.map((option: CheckboxOptionSimpleType) => ({
      ...option,
      isSelected: true,
    }));

  const deSelectAllOptionsFromCheckBoxGroup = (
    options: CheckboxOptionSimpleType[],
  ) =>
    options.map((option: CheckboxOptionSimpleType) => ({
      ...option,
      isSelected: false,
    }));

  const getNewQueryParams = (checkBoxGroupEdited: CheckboxGroupType) => {
    const queryParamsUpdatedCopy = { ...queryParams };

    checkBoxGroupEdited.options.forEach((opt: CheckboxOptionSimpleType) => {
      queryParamsUpdatedCopy[
        opt.id as AllContributorsFiltersIds | AllAlbumsFiltersIds
      ] = opt.isSelected;
    });

    return queryParamsUpdatedCopy;
  };

  const allOptionsSelected = (checkBoxGroupChanged: CheckboxGroupType) =>
    checkBoxGroupChanged.options.every(
      (opt: CheckboxOptionSimpleType) => opt.isSelected === true,
    );

  const noOptionsSelected = (checkBoxGroupChanged: CheckboxGroupType) =>
    checkBoxGroupChanged.options.every(
      (opt: CheckboxOptionSimpleType) => opt.isSelected === false,
    );

  const handleChangeAllFilterCheckbox = (checkBoxGroupSelectedId: string) => {
    const checkBoxFiltersCopy = cloneDeep(checkBoxFilters);

    const checkBoxGroupSelected = checkBoxFiltersCopy.find(
      (group) => group.id === checkBoxGroupSelectedId,
    )!;

    if (checkBoxGroupSelected.checkValue === false) {
      checkBoxGroupSelected.checkValue = true;
      checkBoxGroupSelected.options = selectAllOptionsFromCheckBoxGroup(
        checkBoxGroupSelected.options,
      );
    } else if (checkBoxGroupSelected.checkValue === true) {
      checkBoxGroupSelected.checkValue = false;
      checkBoxGroupSelected.options = deSelectAllOptionsFromCheckBoxGroup(
        checkBoxGroupSelected.options,
      );
    } else if (checkBoxGroupSelected.checkValue === null) {
      checkBoxGroupSelected.checkValue = true;
      checkBoxGroupSelected.options = selectAllOptionsFromCheckBoxGroup(
        checkBoxGroupSelected.options,
      );
    }

    setCheckBoxFilters(checkBoxFiltersCopy);

    const newQueryParams = getNewQueryParams(checkBoxGroupSelected);

    notifyAllNewFiltersSelected(newQueryParams);
  };

  const selectUniqueFilter = (
    checkBoxGroupSelectedId: string,
    filterSelected: string,
  ) => {
    const checkBoxFiltersCopy = cloneDeep(checkBoxFilters);

    const checkBoxGroupSelected = checkBoxFiltersCopy.find(
      (group) => group.id === checkBoxGroupSelectedId,
    )!;

    const option = checkBoxGroupSelected.options.find(
      (opt) => opt.id === filterSelected,
    )!;
    option.isSelected = !option.isSelected;

    if (allOptionsSelected(checkBoxGroupSelected)) {
      checkBoxGroupSelected.checkValue = true;
    } else if (noOptionsSelected(checkBoxGroupSelected)) {
      checkBoxGroupSelected.checkValue = false;
    } else {
      checkBoxGroupSelected.checkValue = null;
    }

    setCheckBoxFilters(checkBoxFiltersCopy);

    const newQueryParams = getNewQueryParams(checkBoxGroupSelected);

    notifyAllNewFiltersSelected(newQueryParams);
  };

  const allFilterSelected = useCallback(() => {
    let allFiltersSelectedFlag = true;

    checkBoxFilters.forEach((group: CheckboxGroupType) => {
      if (
        group.options.some(
          (option: CheckboxOptionSimpleType) => !option.isSelected,
        )
      ) {
        allFiltersSelectedFlag = false;
      }
    });

    return allFiltersSelectedFlag;
  }, [checkBoxFilters]);

  const showContributorsFilterMenu = () => (
    <div className={styles.contributorsFilterMenu}>
      <Menu
        className={styles.filtersSection}
        position={mobile ? PositionType.BottomRight : PositionType.BottomLeft}
        width={styles.menuWith}
      >
        {checkBoxFilters.map((checkBoxGroup) => (
          <div className={styles.filterGroup} key={checkBoxGroup.id}>
            <div className={styles.title}>
              <ThreeStatesCheckbox
                itemKey={checkBoxGroup.id}
                onChange={() => handleChangeAllFilterCheckbox(checkBoxGroup.id)}
                checkValue={checkBoxGroup.checkValue}
              />
              <HelperText content={checkBoxGroup.name} />
            </div>
            <div className={styles.options}>
              {checkBoxGroup.options!.map((option) => (
                <div key={option.id} className={styles.option}>
                  <Checkbox
                    itemKey={option.id}
                    onChange={() =>
                      selectUniqueFilter(checkBoxGroup.id, option.id)
                    }
                    isChecked={option.isSelected}
                  />
                  <HelperText content={option.name} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Menu>
    </div>
  );

  return (
    <div className={className}>
      <div className={styles.inputSearch}>
        <Input
          id="search"
          name="search"
          placeholder="Search by contributor or album"
          value={searchValue}
          withValidation={false}
          withIconEnd={<SearchIcon className={classnames(styles.searchIcon)} />}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            notifyChangeSearch(e.target.value)
          }
        />
      </div>

      <div
        className={styles.AllcontributorsFilterContainer}
        ref={containerContriButorsFilterOptionsRef}
      >
        <button
          type="button"
          className={styles.filterIcons}
          onClick={() =>
            setShowFilterContributorsOptions(!showFilterContributorsOptions)
          }
        >
          <FilterIcon
            className={classnames(
              styles.filterIcon,
              { [styles.menuOpen]: showFilterContributorsOptions },
              { [styles.notSelectedAll]: !allFilterSelected() },
            )}
          />
          <div
            className={classnames({
              [styles.someFilterNotSelected]: !allFilterSelected(),
            })}
          />
          <ChevronIcon
            className={classnames(styles.chevronIcon, {
              [styles.menuOpen]: showFilterContributorsOptions,
            })}
          />
        </button>

        {showFilterContributorsOptions && showContributorsFilterMenu()}
      </div>
      <div className={styles.orderByDropdown}>
        <span className="text__body__regular__small__textNeutral30">
          {' '}
          Sort by:{' '}
        </span>
        <Select
          options={contributorsOrderByOptions}
          optionSelected={queryParams.sortBy!}
          onClickOption={(option) => notifyClickSortBy(option)}
          id="orderByOption"
          value={queryParams.sortBy!.value}
          placeholder="Select option"
        />
      </div>
    </div>
  );
};

export { ContributorsFilters };
