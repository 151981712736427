import { ButtonStyle } from 'common/button';
import { ModalWarning } from 'common/modal-warning/modal-warning';

type WarningModalProps = {
  deal: SubscriptionDealResponseType;
  onClose: () => void;
  onConfirm: () => void;
};

const ModalWarningDeleteDeal = ({
  deal,
  onClose,
  onConfirm,
}: WarningModalProps) => (
  <ModalWarning
    title="Delete discount code"
    content={`Are you sure you want to delete the code ‘${deal.name.toUpperCase()}’?`}
    successButtonText="Delete discount code"
    successStyleButton={ButtonStyle.RedFilled}
    closeFn={onClose}
    successFn={onConfirm}
  />
);

export { ModalWarningDeleteDeal };
