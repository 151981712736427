/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { AudioVisualiser } from './components/audio-visualiser';

type AudioAnalyserProps = {
  mediaStream: MediaStream;
  className?: string;
};

const AudioAnalyser: React.FC<AudioAnalyserProps> = ({
  mediaStream,
  className = '',
}) => {
  const [audioData, setAudioData] = useState<Uint8Array>(new Uint8Array(0));

  useEffect(() => {
    const tick = () => {
      analyser.getByteTimeDomainData(dataArray);
      setAudioData(cloneDeep(dataArray));
      rafId = requestAnimationFrame(tick);
    };

    const audioContext = new window.AudioContext();

    let analyser: AnalyserNode = audioContext.createAnalyser();
    const dataArray: Uint8Array = new Uint8Array(analyser.frequencyBinCount);

    const source: MediaStreamAudioSourceNode =
      audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);

    let rafId: number = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(rafId);
      analyser.disconnect();
      source.disconnect();
    };
  }, [mediaStream.id]);

  return <AudioVisualiser audioData={audioData} className={className} />;
};

export { AudioAnalyser };
