import React from 'react';
import { AlertTriangleIcon } from 'assets/icons';
import { HelperText } from 'common/helper-text';
import styles from './file-uploader-error-message.module.scss';

type FileUploaderErrorMessageProps = {
  errorMessage: string | React.ReactNode;
  actions?: React.ReactNode;
};

const FileUploaderErrorMessage: React.FC<FileUploaderErrorMessageProps> = ({
  errorMessage = 'Oh no, something went wrong.',
  actions = [],
}) => (
  <>
    <AlertTriangleIcon className={styles.errorIcon} />
    <HelperText content={errorMessage} className={styles.errorMessage} />
    {actions && <div>{actions}</div>}
  </>
);

export { FileUploaderErrorMessage };
