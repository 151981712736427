import { AccountStateType } from 'pages/account/account-reducer';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { classnames } from 'helpers/utils';
import { Pagination } from 'models/pagination';
import { PaginationSelect } from 'common/paginator-select';
import { Spinner } from 'common/spinner';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { ContributorAlbumsRows } from './components/contributor-table-row';
import { ContributorRowMobile } from './components/contributor-table-row-mobile';
import styles from './contributor-table.module.scss';

type ContributorTableProps = {
  accountState: AccountStateType;
  className?: string;
  fetchingAlbums: boolean;
  notifyGetMoreAlbums: (page: number) => void;
};

const ContributorTable: React.FC<ContributorTableProps> = ({
  fetchingAlbums,
  accountState,
  className = '',
  notifyGetMoreAlbums,
}) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const { albumsContributor, paginationContributor } = accountState;

  const thereAreMorePages = paginationContributor?.lastPage !== 1;

  const showTitle = () => (
    <p className="mb-15 text__title2__textNeutral40">Contributor albums </p>
  );

  const showMoreAlbumsButton = () => (
    <div className={styles.moreAlbumsButton}>
      <PaginationSelect
        paginationInfo={paginationContributor as Pagination}
        notifyGetMoreAlbums={notifyGetMoreAlbums}
      />
    </div>
  );
  const contributorsRows = () =>
    mobile ? (
      <ContributorRowMobile albums={albumsContributor} />
    ) : (
      <ContributorAlbumsRows albums={albumsContributor} />
    );

  const showTable = () => (
    <div className={styles.moreAlbumsButton}>
      {fetchingAlbums ? (
        <div className={styles.spinnerCenter}>
          <Spinner className={globalStyles.mediumSpinner} />
        </div>
      ) : (
        contributorsRows()
      )}
      {thereAreMorePages && showMoreAlbumsButton()}
    </div>
  );

  const showEmptyAlbums = () => (
    <div className={styles.emptyPage}>
      <div className={styles.emptyPageTexts}>
        <p
          className={classnames(
            'text__heading5__textNeutral30',
            styles.headingEmpty,
          )}
        >
          You are not a contributor in any albums
        </p>
        <p className="text__body__regular__medium__textNeutral30">
          Contributor albums are albums you’ve been invited to that are owned by
          another user.
        </p>
      </div>
    </div>
  );

  return (
    <div className={className}>
      {showTitle()}
      {albumsContributor.length ? showTable() : showEmptyAlbums()}
    </div>
  );
};

export { ContributorTable };
