class ApiErrorSerializer {
  static deSerialize(data: RawApiError) {
    return {
      errorCode: data.error_code || null,
      description: data.description || null,
      details: data.details || null,
    };
  }
}

export { ApiErrorSerializer };
