import { rootUrl } from 'config/constants';
import { classnames } from 'helpers/utils';
import styles from './footer.module.scss';

enum FooterType {
  SignedUser = 'signedUser',
  NoSignedUser = 'noSignedUser',
}

type FooterProps = {
  footerType?: FooterType;
};

function handleType(footerType: FooterType) {
  const classes = [styles.container];

  switch (footerType) {
    case FooterType.NoSignedUser:
      classes.push(styles.noSignedUser);
      break;
    default:
      break;
  }

  return classnames(...classes);
}

const Footer: React.FC<FooterProps> = ({
  footerType = FooterType.SignedUser,
}) => {
  const links = [
    { url: '', label: 'What is Generational Story?' },
    { url: 'privacy-policy', label: 'Privacy policy' },
    { url: 'pricing', label: 'Subscription plans' },
    { url: 'faq', label: 'FAQ' },
    { url: 'contact-us', label: 'Contact us' },
  ];

  return (
    <div className={handleType(footerType)}>
      {links.map(({ url, label }) => (
        <a
          className={classnames(
            styles.link,
            'text__body__regular__tiny__textNeutral30',
          )}
          href={`${rootUrl}/${url}`}
          target="_blank"
          rel="noopener noreferrer"
          key={label}
        >
          {label}
        </a>
      ))}
    </div>
  );
};

export { Footer, FooterType };
