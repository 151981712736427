/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState } from 'react';
import { Modal } from 'common/modal';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Spinner } from 'common/spinner';
import { Input } from 'common/input';
import { EyeIcon } from 'assets/icons';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { UserController } from 'networking/controllers/user-controller';
import { ChangePassword } from 'models/change-password';
import { classnames, isPasswordValid, noEmptyFields } from 'helpers/utils';
import styles from './change-password-modal.module.scss';

type ErrorFieldType = {
  label: string;
  value: string;
};

type MandatoryFieldsType = {
  password: ErrorFieldType;
  newPassword: ErrorFieldType;
  newPasswordRepeat: ErrorFieldType;
};

const mandatoryFieldsErrors: MandatoryFieldsType = {
  password: { value: '', label: 'Password' },
  newPassword: { value: '', label: 'New password' },
  newPasswordRepeat: { value: '', label: 'Repeat new Password' },
};

const textFieldsVisibility = {
  password: false,
  newPassword: false,
  newPasswordRepeat: false,
};

type ModalEditAlbumProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const ChangePasswordModal: React.FC<ModalEditAlbumProps> = ({
  onClose,
  onSuccess,
}) => {
  const [formState, setFormState] = useState<ChangePassword>(
    new ChangePassword(),
  );
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState<MandatoryFieldsType>(
    mandatoryFieldsErrors,
  );
  const [backError, setBackError] = useState(false);
  const [showPassword, setShowPassword] = useState(textFieldsVisibility);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevUserState) => ({ ...prevUserState, [name]: value }));
  };

  const validateField = (field: keyof typeof mandatoryFieldsErrors) => {
    let emptyFieldMessage = '';

    emptyFieldMessage =
      formState[field].trim() === ''
        ? `${mandatoryFieldsErrors[field].label} shouldn't be empty`
        : '';

    setErrors((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], value: emptyFieldMessage },
    }));

    if (emptyFieldMessage) return;

    if (field === 'newPassword') {
      const passwordMessage = !isPasswordValid(formState[field])
        ? 'Invalid password. It must have at least 8 characters, one letter and one number.'
        : '';

      setErrors((prevState) => ({
        ...prevState,
        [field]: { ...prevState[field], value: passwordMessage },
      }));

      if (passwordMessage) return;
    }

    if (field === 'newPasswordRepeat') {
      const repeatMessage =
        formState.newPassword !== formState.newPasswordRepeat
          ? 'passwords do not match'
          : '';

      setErrors((prevState) => ({
        ...prevState,
        [field]: { ...prevState[field], value: repeatMessage },
      }));
    }
  };

  const existingErrors = (errs: MandatoryFieldsType) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const field in errs) {
      if (errs[field as keyof typeof mandatoryFieldsErrors].value !== '')
        return true;
    }

    return false;
  };

  const submitModal = async () => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });

    if (existingErrors(errors)) {
      return;
    }

    setFetching(true);

    const requestData: ChangePasswordRequestType = { ...formState };

    try {
      setFetching(true);
      await UserController.changePassword(requestData);
      onSuccess!();
    } catch (err: any) {
      setFetching(false);
      setBackError(true);
    }
  };

  const handlePasswordVisibility = (field: string) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field as keyof typeof textFieldsVisibility],
    }));
  };

  const showModalContentView = () => {
    const { password, newPassword, newPasswordRepeat } = formState;

    return (
      <Modal title="Change password" onClose={onClose}>
        <div>
          <Input
            className="mb-10"
            id="password"
            name="password"
            label="Current password"
            placeholder="Current password"
            value={password}
            type={showPassword.password ? 'text' : 'password'}
            errorMessage={errors.password.value}
            withIconEnd={
              <EyeIcon onClick={() => handlePasswordVisibility('password')} />
            }
            onChange={handleChange}
            onBlur={() => validateField('password')}
          />
          <Input
            className="mb-10"
            id="newPassword"
            name="newPassword"
            label="New password"
            placeholder="New password"
            value={newPassword}
            type={showPassword.newPassword ? 'text' : 'password'}
            errorMessage={errors.newPassword.value}
            withIconEnd={
              <EyeIcon
                onClick={() => handlePasswordVisibility('newPassword')}
              />
            }
            onChange={handleChange}
            onBlur={() => validateField('newPassword')}
          />
          <Input
            className="mb-10"
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            label="Repeat new Password"
            placeholder="new password"
            value={newPasswordRepeat}
            type={showPassword.newPasswordRepeat ? 'text' : 'password'}
            errorMessage={errors.newPasswordRepeat.value}
            withIconEnd={
              <EyeIcon
                onClick={() => handlePasswordVisibility('newPasswordRepeat')}
              />
            }
            onChange={handleChange}
            onBlur={() => validateField('newPasswordRepeat')}
          />
        </div>

        <div className={styles.footer}>
          <div
            className={classnames(styles.error, {
              [styles.showBackendError]: backError,
            })}
          >
            <p className="text__body__semi__bold__medium__warning30">
              Something went wrong.
            </p>
            <p className="text__body__regular__tiny__textNeutral30">
              Please try again
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.cancelButton}
              onClick={onClose}
              buttonStyle={ButtonStyle.GreyGhost}
              buttonSize={ButtonSize.Small}
            >
              Cancel
            </Button>
            {!fetching ? (
              <Button
                buttonSize={ButtonSize.Small}
                disabled={
                  !noEmptyFields({ password, newPassword, newPasswordRepeat })
                }
                className={styles.successButton}
                onClick={() => submitModal()}
              >
                Update Password
              </Button>
            ) : (
              <div className={styles.spinnerContainer}>
                <Spinner className={globalStyles.smallSpinner} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  return showModalContentView();
};

export { ChangePasswordModal };
