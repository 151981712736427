import { User } from './user';

class AllDataUser {
  user: User;

  constructor(data?: AllDataUserType) {
    this.user = data?.user || new User();
  }
}

export { AllDataUser };
