class SubscriptionType {
  id: string;

  name: string;

  price: number;

  onlyFirstAlbum: boolean;

  paymentDescription: string;

  features: string[];

  paymentPeriod: string;

  extraClipPrice: number;

  extraVideoCallTimePrice: number;

  permissions?: SubscriptionPermissionsType;

  constructor(params?: SubscriptionTypeSerialized | null) {
    this.id = params?.id || '';
    this.name = params?.name || '';
    this.onlyFirstAlbum = params?.onlyFirstAlbum || false;
    this.paymentDescription = params?.paymentDescription || '';
    this.features = params?.features || [];
    this.paymentPeriod = params?.paymentPeriod || '';
    this.price = params?.price || 0;
    this.extraClipPrice = params?.extraClipPrice || 0;
    this.extraVideoCallTimePrice = params?.extraVideoCallTimePrice || 0;
    this.permissions = params?.permissions;
  }
}

export { SubscriptionType };
