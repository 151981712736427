/* eslint-disable max-len */
import { ChangeEvent, useEffect, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Button, ButtonStyle } from 'common/button';
import { Input } from 'common/input';
import { Spinner } from 'common/spinner';
import { AllDataUser } from 'models/all-data-user';
import { classnames } from 'helpers/utils';
import styles from './discount-code.module.scss';

type DiscountCodeProps = {
  className?: string;
  label?: string;
  couponCode?: string;
  canAddMore?: boolean;
  onSuccess: (data: SubscriptionDealResponseType | AllDataUser) => void;
  notifyApplyNewCode: () => Promise<SubscriptionDealResponseType | AllDataUser>;
  notifyCodeInputIsValid?: (isValid: boolean) => void;
  notifyChangeCoupon: (newValue: string) => void;
};

const DiscountCode: React.FC<DiscountCodeProps> = ({
  className = '',
  label = '',
  couponCode = '',
  canAddMore = true,
  notifyApplyNewCode,
  notifyCodeInputIsValid = () => {},
  notifyChangeCoupon,
  onSuccess,
}) => {
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Transform to uppercase
    const el = e.target as HTMLInputElement;
    const start = el.selectionStart;
    const end = el.selectionEnd;
    el.value = el.value.toUpperCase();
    el.setSelectionRange(start, end);

    if (el.value === '') {
      notifyCodeInputIsValid(true);
      setCodeErrorMessage('');
    }
    notifyChangeCoupon(el.value);
  };

  const handleApplyCode = async () => {
    setIsLoading(true);

    try {
      const data = await notifyApplyNewCode();
      notifyCodeInputIsValid(true);
      setCodeErrorMessage('');
      onSuccess(data);
    } catch (err) {
      setCodeErrorMessage(
        'this code does not exist or has expired'.toUpperCase(),
      );
      notifyCodeInputIsValid(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (couponCode) {
      handleApplyCode();
    }
  }, []);
  const showInputLayout = () => (
    <Input
      className={styles.field}
      innerClassName={styles.input}
      id="couponCode"
      name="couponCode"
      placeholder="Discount code"
      value={couponCode}
      type="text"
      onChange={handleChange}
      errorMessage={codeErrorMessage}
      disabled={isLoading}
      onSubmit={handleApplyCode}
    />
  );

  const showApplyButton = () => (
    <Button
      className={classnames(styles.applyButton)}
      buttonStyle={ButtonStyle.PrimaryFilled}
      onClick={() => {
        handleApplyCode();
      }}
      disabled={!canAddMore || !couponCode}
    >
      Apply
    </Button>
  );

  const showSpinner = () => (
    <div className={styles.spinner}>
      <Spinner className={classnames(globalStyles.smallSpinner)} />
    </div>
  );

  return (
    <div className={className}>
      <div className={styles.discountCodeContainer}>
        <div className="text__body__semi__bold__medium__textNeutral40">
          {label}
        </div>

        <div className={styles.fieldWithButton}>
          {showInputLayout()}
          {isLoading ? showSpinner() : showApplyButton()}
        </div>
      </div>
    </div>
  );
};

export { DiscountCode };
