import { useContext, useEffect, useState } from 'react';
import { AppContext, appActions } from 'context';
import { SignHeader } from 'common/sign-header';
import { classnames } from 'helpers/utils';
import { Line } from 'common/line';
import { Button, ButtonType } from 'common/button';
import { UserController } from 'networking/controllers/user-controller';
import { Notification } from 'common/notification';
import { CornerLogoutButton } from 'common/corner-logout-button';
import { Footer, FooterType } from 'common/footer';
import { errorsCode, msToCheckIfUserAlreadyVerified } from 'config/constants';
import { RouteName, goToPage } from 'routes';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { NotificationObject } from 'models/notificationObject';
import styles from './email-waiting-confirm.module.scss';

const EmailWaitingConfirm = () => {
  const { state, dispatch } = useContext(AppContext);
  const [resendActivated, setResendActivated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'There was an error. Please try again later.',
  );

  const showResentText = () => (
    <span className={classnames('text__body__regular__medium__textNeutral30')}>
      <span
        className={classnames('text__body__semi__bold__medium__textNeutral30')}
      >
        {' '}
        Resent!{' '}
      </span>
      <span> Please check your email. </span>
    </span>
  );

  const showFirstText = () => (
    <span className={classnames('text__body__regular__medium__textNeutral30')}>
      <span
        className={classnames('text__body__semi__bold__medium__textNeutral30')}
      >
        Didn’t get an email?{' '}
      </span>
      <span> Check your spam folder or resend it below. </span>
    </span>
  );

  const handleResend = async () => {
    try {
      await UserController.resendUserEmail();
      setResendActivated(true);
    } catch (err: any) {
      if (err.errors) {
        const resentEmailError = err.errors.find(
          (e: any) => e.errorCode === errorsCode.resentEmail,
        );

        if (resentEmailError) {
          setErrorMessage(
            'Too many requests, please wait a moment before trying again.',
          );
        }
      } else {
        setErrorMessage('There was an error. Please try again later.');
      }

      setShowError(true);
    }
  };

  const showErrorNotification = () => (
    <Notification
      message={errorMessage}
      handleClose={() => setShowError(false)}
    />
  );

  const checkIfUserAlreadyVerified = async () => {
    try {
      const data = await UserController.me();

      if (data.user.verified) {
        dispatch({ type: appActions.USER_LOGGED, data });
        goToPage(RouteName.Home);
      }
    } catch (err: any) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'User Information',
          message: 'Error getting user information',
        }),
      });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      checkIfUserAlreadyVerified();
    }, msToCheckIfUserAlreadyVerified);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const showContent = () => (
    <div className={globalStyles.signMainContainer}>
      <CornerLogoutButton />
      <div className={globalStyles.signBoxContent}>
        <div className={styles.elements}>
          <SignHeader />
          <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
            For your security, please verify your email.
          </div>
          <div
            className={classnames(
              'text__body__regular__medium__textNeutral30',
              'my-7',
            )}
          >
            <span>
              An email has been sent to
              <b>{` ${state.data.user.email}`}</b>. For your security please
              verify your email address before continuing with the sign up
              process.
            </span>
          </div>

          <Line className={styles.lineStyles} />

          <div className={styles.helpTextEmail}>
            {resendActivated ? showResentText() : showFirstText()}
          </div>
          <Button onClick={() => handleResend()} buttonType={ButtonType.Submit}>
            Resend email
          </Button>
        </div>
      </div>
      <Footer footerType={FooterType.NoSignedUser} />
    </div>
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showContent()}
    </>
  );
};

export { EmailWaitingConfirm };
