import React, { RefObject, useEffect, useRef } from 'react';
import { CheckboxIcon, CheckmarkIcon, CheckDashIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import styles from './checkbox-three-states.module.scss';

type ThreeStatesBoxValue = boolean | null;

type ThreeStateCheckboxProps = {
  disabled?: boolean;
  itemKey: string;
  checkValue?: ThreeStatesBoxValue;
  onChange: (value: ThreeStatesBoxValue) => void;
};

const ThreeStatesCheckbox: React.FC<ThreeStateCheckboxProps> = ({
  disabled = false,
  itemKey,
  checkValue = false,
  onChange,
}) => {
  const inputRef = useRef(null);
  const checkedRef = useRef(checkValue);

  const updateInput = (ref: RefObject<any>, newValue: ThreeStatesBoxValue) => {
    const input = ref.current;

    if (input) {
      input.checked = newValue;
      input.indeterminate = newValue == null;
    }
  };

  useEffect(() => {
    checkedRef.current = checkValue;
    updateInput(inputRef, checkValue!);
  }, [checkValue]);

  const handleClick = () => {
    switch (checkedRef.current) {
      case true:
        checkedRef.current = false;
        break;
      case false:
        checkedRef.current = null;
        break;
      default: // null
        checkedRef.current = true;
        break;
    }

    updateInput(inputRef, checkedRef.current);

    if (onChange) {
      onChange(checkedRef.current);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        name={itemKey}
        id={itemKey}
        disabled={disabled}
        type="checkbox"
        className={styles.custom}
        onClick={handleClick}
      />
      <label htmlFor={itemKey} id={`checkbox-${itemKey}`}>
        <CheckboxIcon className={disabled ? styles.boxDisabled : styles.box} />

        {checkValue === true && (
          <CheckmarkIcon
            className={classnames(
              disabled ? styles.checkDisabled : styles.check,
            )}
          />
        )}

        {checkValue === null && (
          <CheckDashIcon
            className={classnames(
              disabled ? styles.checkDisabled : styles.dash,
            )}
          />
        )}
      </label>
    </>
  );
};

export { ThreeStatesCheckbox };
