/* eslint-disable max-len */
import { Spinner } from 'common/spinner';
import { classnames } from 'helpers/utils';
import { AlertTriangleIcon, CheckIcon } from 'assets/icons';
import { VideoStatus } from 'common/enums';
import { VideoRow } from '../video-row';
import styles from './videos-queue.module.scss';

type VideosQueueProps = {
  videos: AllVideoProps[];
  albumId: number;
  notifyVideoStatusChange: (videoId: number, newStatus: string) => void;
  notifyDeleteVideo: (videoId: number) => void;
  videosInProgress: boolean;
};

const VideosQueue: React.FC<VideosQueueProps> = ({
  videos,
  albumId,
  notifyVideoStatusChange,
  notifyDeleteVideo,
  videosInProgress,
}) => {
  const showUploadingMessage = () => (
    <div
      className={classnames(
        'text__body__regular__small__textNeutral30',
        styles.uploadingStatusTitle,
      )}
    >
      <Spinner className={styles.uploadingIcon} />
      <span>
        {' '}
        Uploading videos. Don’t leave this page or your progress will be lost.{' '}
      </span>
    </div>
  );

  const showUploadedMessage = () => (
    <div
      className={classnames(
        'text__body__regular__small__textNeutral30',
        styles.uploadingStatusTitle,
      )}
    >
      <CheckIcon className={styles.uploadingIcon} />
      <span>
        {' '}
        Your videos have been uploaded. Click on save clips to add them to your
        album.
      </span>
    </div>
  );

  const showFailedMessage = () => (
    <div
      className={classnames(
        'text__body__regular__small__textNeutral30',
        styles.uploadingStatusTitle,
      )}
    >
      <AlertTriangleIcon className={classnames(styles.alertTriangleIcon)} />
      <span> File is too big. Maximum file size accepted is 500MB.</span>
    </div>
  );

  const showVideosList = () => (
    <div className={styles.videosRowsContainer}>
      {videos.map((video: AllVideoProps) => (
        <VideoRow
          key={video.id}
          albumId={albumId}
          video={video}
          notifyVideoStatusChange={(id: number, newStatus: string) =>
            notifyVideoStatusChange(id, newStatus)
          }
          notifyDeleteVideo={(id: number) => notifyDeleteVideo(id)}
        />
      ))}
    </div>
  );

  const showMessage = () => {
    if (videosInProgress) return showUploadingMessage();
    if (videos.some((video) => video.status === VideoStatus.failed))
      return showFailedMessage();

    return showUploadedMessage();
  };

  return (
    <div className={styles.queueContainer}>
      {showMessage()}
      {showVideosList()}
    </div>
  );
};

export { VideosQueue };
