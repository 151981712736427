import React from 'react';
import { FlagsmithProvider } from 'flagsmith/react';
import { flagsmithEnvironmentId } from 'config/constants';
import flagsmith from 'flagsmith';

type FeatureFlagProviderProps = {
  children: React.ReactElement;
};

const FeatureFlagsProvider = ({ children }: FeatureFlagProviderProps) => (
  <FlagsmithProvider
    options={{
      environmentID: flagsmithEnvironmentId,
    }}
    // @ts-ignore
    flagsmith={flagsmith}
  >
    {children}
  </FlagsmithProvider>
);
export { FeatureFlagsProvider };
