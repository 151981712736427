import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Prompt } from 'react-router-dom';

import { classnames } from 'helpers/utils';
import {
  videoSupportedExtensions,
  mbVideoMaxSize,
  confirmationMsgLetUploadVideo,
  sFileMaxDuration,
} from 'config/constants';
import { NotificationType, VideoStatus } from 'common/enums';
import { FileUploaderVideo } from 'common/file-uploader-video';

import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { ChevronIcon, PlusIcon } from 'assets/icons';
import { RouteName, goToPage } from 'routes';
import { formatFileExtensions, isValidFile } from 'helpers/file-utils';
import { LocalStorageApi } from 'helpers/local-storage';
import { SelfRecordingContext } from 'context/self-recording-context';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { AppContext, appActions } from 'context/app-context';
import { NotificationObject } from 'models/notificationObject';
import { VideosRecordedType } from 'context/self-recording-context/context-reducer';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { VideosQueue } from '../videos-queue';
import styles from './upload-videos.module.scss';

type UploadVideosProps = {
  album: AlbumType;
  clipsExistingAmount: number;
  className?: string;
  refetchAlbum?: () => void;
};

const UploadVideos: React.FC<UploadVideosProps> = ({
  album,
  className = '',
  clipsExistingAmount,
  refetchAlbum,
}) => {
  const {
    state: { selectedQuestion, videosRecorded, batchId },
    dispatch,
  } = useContext(SelfRecordingContext);
  const { dispatch: generalDispatch } = useContext(AppContext);
  const [videos, setVideos] = useState<AllVideoProps[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(
    '',
  );
  const [showNextQuestionButton, setShowNextQuestionButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoId = useRef<number>(0);
  const guestToken = LocalStorageApi.get('guestToken');
  const inputRef = useRef<HTMLInputElement>(null);
  const [nextQuestion, setNextQuestion] = useState(false);
  const currentQuestionIndex = useRef<number>(0);

  const maxQuestionsLimit = album.maxClips;

  const uploadErrors = {
    generic: 'Oh no, something went wrong.',
    size: `File is too big. Maximum file size accepted is ${mbVideoMaxSize}MB.`,
    format: `File format not supported. Please upload only ${formatFileExtensions(
      videoSupportedExtensions,
    ).toUpperCase()} files.`,
    duration: `File is too long. Maximum file length is ${
      sFileMaxDuration / 60
    } minutes.`,
  };

  const onClickAddMoreVideos = () => inputRef.current?.click();

  const addNewVideoToList = (fileReceived: File) => {
    videoId.current += 1;
    const newFile: AllVideoProps = {
      file: fileReceived,
      id: videoId.current,
      status: VideoStatus.new,
      uploadStartTime: new Date(),
    };
    setVideos((prevVideos) => [...prevVideos, newFile]);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    setErrorMessage(
      await isValidFile(
        file!,
        uploadErrors,
        videoSupportedExtensions,
        mbVideoMaxSize,
        sFileMaxDuration,
      ),
    );

    if (!errorMessage) {
      addNewVideoToList(file!);
      e.target.value = '';
    }
  };

  const totalVideosInProgress = () =>
    videos.filter(
      (video: AllVideoProps) => video.status === VideoStatus.inProgress,
    ).length;

  const totalVideos = () =>
    videos.filter(({ status }: AllVideoProps) =>
      [VideoStatus.new, VideoStatus.inProgress, VideoStatus.ready].includes(
        status as VideoStatus,
      ),
    ).length;

  const clipsAmount = clipsExistingAmount + totalVideos();

  const handleChangeVideoStatus = (id: number, newStatus: string) => {
    setVideos(
      videos.map((video: AllVideoProps) => {
        if (video.id === id) {
          return { ...video, status: newStatus };
        }
        return video;
      }),
    );
  };

  useEffect(() => {
    if (videosRecorded.length > 1 && !batchId) {
      dispatch({
        type: selfRecordingActions.setBatchId,
      });
    }
    if (videosRecorded.length === 0) {
      setVideos([]);
      if (refetchAlbum) {
        refetchAlbum();
        if (nextQuestion) {
          setNextQuestion(false);
          if (
            album.unansweredQuestions &&
            album.unansweredQuestions?.length >= currentQuestionIndex.current
          ) {
            const questionSelected =
              album.unansweredQuestions[currentQuestionIndex.current + 1];

            if (questionSelected) {
              dispatch({
                type: selfRecordingActions.setSelectedQuestion,
                selectedQuestion: questionSelected,
              });
            } else {
              dispatch({
                type: selfRecordingActions.setSelectedQuestion,
                selectedQuestion: album.unansweredQuestions[0],
              });
            }
          }
          return;
        }
        if (selectedQuestion) {
          const questionSelected = album.unansweredQuestions?.find(
            (item) => item.id === selectedQuestion?.id,
          );
          dispatch({
            type: selfRecordingActions.setSelectedQuestion,
            selectedQuestion: questionSelected,
          });
        }
      }
    }
  }, [videosRecorded]);

  const handleDeleteVideo = (id: number) => {
    setVideos(videos.filter((video: AllVideoProps) => video.id !== id)!);
  };

  const createClip = async (
    video: VideosRecordedType,
    albumId: number,
    name: string,
    positionInBatch: number,
  ) => {
    try {
      const createClipRequest: CreateClipRequestType = {
        clip: {
          videoSignedId: video.videoSignedId,
          name,
          recordedAt: video.uploadStartTime ?? new Date(Date.now()),
          batchId,
          positionInBatch,
        },
      };
      await AlbumsController.createClip(albumId, createClipRequest);
    } catch (error) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
    }
  };

  const editClip = async (video: VideosRecordedType, clip: ClipType) => {
    try {
      const requestData: ClipEditRequestType = {
        name: selectedQuestion?.name,
        videoSignedId: video.videoSignedId,
        recodedAt: video.uploadStartTime,
      };
      await AlbumsController.editClip(clip.id, requestData);
    } catch (error) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
    }
  };

  const handleSaveClips = async () => {
    try {
      setIsLoading(true);
      if (selectedQuestion) {
        Promise.all(
          videosRecorded.map(async (video) =>
            editClip(video, selectedQuestion),
          ),
        ).then();
      } else {
        Promise.all(
          videosRecorded.map((video, index) =>
            createClip(video, album.id, video.fileName ?? '', index + 1),
          ),
        ).then(() =>
          dispatch({
            type: selfRecordingActions.setBatchId,
          }),
        );
      }
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Your clip(s) have been saved.',
          message: '',
          type: NotificationType.Success,
        }),
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      setIsLoading(false);
      return false;
    }
  };

  const saveVideosCustomRecording = async () => {
    const response = await handleSaveClips();
    if (response) {
      setVideos([]);
      dispatch({
        type: selfRecordingActions.deleteAllVideos,
      });
    }
  };

  const showAddMoreButton = () => (
    <div className={styles.addMoreAndSaveButtons}>
      <Button
        className={styles.addMoreVideosButton}
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={onClickAddMoreVideos}
        disabled={clipsAmount >= maxQuestionsLimit || isLoading}
      >
        <PlusIcon className={styles.plusIcon} /> Add more videos
      </Button>
      <input
        type="file"
        ref={inputRef}
        accept={formatFileExtensions(videoSupportedExtensions)}
        onChange={handleInputChange}
        hidden
        multiple={!selectedQuestion}
      />
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryFilled}
        onClick={saveVideosCustomRecording}
      >
        Save clips
      </Button>
    </div>
  );

  const selectNextQuestion = () => {
    if (selectedQuestion) {
      currentQuestionIndex.current =
        album.unansweredQuestions?.findIndex(
          (item) => item.id === selectedQuestion.id,
        ) ?? 0;
    }
    setNextQuestion(true);
    setVideos([]);
    setShowNextQuestionButton(false);
    dispatch({
      type: selfRecordingActions.deleteAllVideos,
    });
  };

  const nextQuestionButton = () => (
    <div className={styles.addMoreAndSaveButtons}>
      <Button
        className={styles.addMoreVideosButton}
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={selectNextQuestion}
      >
        Next Question <ChevronIcon className={styles.chevronIcon} />
      </Button>
    </div>
  );

  useEffect(
    () => () => {
      setVideos([]);
      if (!guestToken) {
        dispatch({ type: selfRecordingActions.deleteAllVideos });
      }
    },
    [],
  );

  const saveVideosSelectedQuestion = async () => {
    const readyToNexQuestion = await handleSaveClips();
    if (readyToNexQuestion) {
      setShowNextQuestionButton(true);
    }
  };

  useEffect(() => {
    if (
      videos.length &&
      videos.every((video) => video.status === VideoStatus.ready)
    ) {
      if (guestToken) {
        goToPage(RouteName.ReviewVideosGuest, { guestToken });
      }
      if (selectedQuestion && !guestToken) {
        saveVideosSelectedQuestion();
      }
    }
    if (!videos.length) {
      dispatch({
        type: selfRecordingActions.setIsUploading,
        isUploading: false,
      });
    }
  }, [videos]);

  useEffect(() => {
    const videosInProgress = videos.some(
      (video) => video.status === VideoStatus.inProgress,
    );
    dispatch({
      type: selfRecordingActions.setIsUploading,
      isUploading: videosInProgress,
    });
  }, [videos]);

  return (
    <>
      <Prompt
        when={!!totalVideosInProgress()}
        message={confirmationMsgLetUploadVideo}
      />
      <div className={className}>
        {!videos.length ? (
          <div className={styles.uploadVideosColumn}>
            <FileUploaderVideo
              clipsAmount={clipsAmount}
              className={styles.fileUploader}
              supportedExtensions={videoSupportedExtensions}
              maxFileSizeInMb={mbVideoMaxSize}
              notifyNewFile={addNewVideoToList}
              maxQuestionsLimit={maxQuestionsLimit}
              isGuest={guestToken}
              canUploadMultipleFiles={!selectedQuestion}
            />
            <div
              className={classnames(
                styles.uploadFooter,
                'text__body__regular__tiny__textNeutral30',
              )}
            >
              {`Videos have to be less than 5 minutes long and under ${mbVideoMaxSize}MB.`}
            </div>
          </div>
        ) : (
          <div className={styles.uploadQueueColumn}>
            <VideosQueue
              albumId={album.id}
              videos={videos}
              notifyVideoStatusChange={handleChangeVideoStatus}
              notifyDeleteVideo={handleDeleteVideo}
              videosInProgress={!!totalVideosInProgress()}
            />
            {!selectedQuestion && showAddMoreButton()}
            {showNextQuestionButton && nextQuestionButton()}
          </div>
        )}
      </div>
    </>
  );
};

export { UploadVideos };
