import React, { useContext } from 'react';
import { ExpandIcon } from 'assets/icons';
import { AppContext, appActions } from 'context';
import styles from './small-picture.module.scss';

type SmallPictureProps = {
  picture: string;
};

const SmallPicture: React.FC<SmallPictureProps> = ({ picture }) => {
  const { dispatch } = useContext(AppContext);
  return (
    <div className={styles.clipImageContainer}>
      <img src={picture} alt="" className={styles.clipImage} />
      <button
        type="button"
        onClick={() =>
          dispatch({
            type: appActions.SET_IMAGE_TO_ENLARGE,
            imageToEnlarge: picture,
          })
        }
      >
        <ExpandIcon className={styles.expandIcon} />
      </button>
    </div>
  );
};

export { SmallPicture };
