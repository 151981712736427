import React, { useState, ChangeEvent } from 'react';

import { MailIcon } from 'assets/icons';
import { Button, ButtonStyle, ButtonSize } from 'common/button';
import { Modal } from 'common/modal';
import { Input } from 'common/input';
import { Spinner } from 'common/spinner';
import { classnames, isEmailValid } from 'helpers/utils';
import { useFetch } from 'hooks/use-fetch';
import { AlbumsController } from 'networking/controllers/albums-controller';

import styles from './share-by-email.module.scss';

type ShareByEmailProps = {
  className?: string;
  clip: ClipType;
};

const ShareByEmail: React.FC<ShareByEmailProps> = ({
  className = '',
  clip,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const [inputError, setInputError] = useState('');
  const [error, setError] = useState(null);
  const [successfulShared, setSuccessfulShared] = useState(false);
  const { 0: loading, 3: setExecution } = useFetch({
    action: AlbumsController.shareClipByEmail,
    param: { clipId: clip.id, email },
    exec: false,
    onError: setError,
    onSuccess: () => setSuccessfulShared(true),
  });

  const handleCancel = () => {
    setOpenModal(false);
    setEmail('');
    setSuccessfulShared(false);
    setError(null);
  };
  const handleClick = () => {
    setError(null);
    if (isEmailValid(email)) {
      setExecution(true);
      return;
    }
    setInputError('invalid email');
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    setInputError('');
    setSuccessfulShared(false);
  };
  const modalFooterClass = classnames(styles.modalFooter, {
    [styles.rowReverse]: !loading && !error && !successfulShared,
  });

  return (
    <>
      {openModal && (
        <Modal
          title="Share clip via email"
          onClose={handleCancel}
          className={styles.modalContainer}
          contentClassName={styles.modalContent}
        >
          <p className="text__body__semi__bold__medium__textNeutral40">
            Who do you want to share this with?
          </p>
          <Input
            id="share-email"
            value={email}
            onChange={handleChange}
            placeholder="Type email"
            errorMessage={inputError}
          />
          <div className={modalFooterClass}>
            {loading && <Spinner className={styles.spinner} />}
            {error && (
              <div className={styles.errorContainer}>
                <span className="text__body__regular__large__warning30">
                  Something went wrong.
                </span>
                <span className="text__body__regular__small__textNeutral30">
                  We couldn’t share the clip. Please try again.
                </span>
              </div>
            )}
            {successfulShared && (
              <p className="text__body__semi__bold__small__primary50">
                Clip sent!
              </p>
            )}
            <div className={styles.buttonsfooter}>
              <Button
                buttonStyle={ButtonStyle.GreyGhost}
                buttonSize={ButtonSize.Small}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button disabled={!email || loading} onClick={handleClick}>
                Send
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        className={classnames(styles.container, className)}
        onClick={() => setOpenModal(true)}
      >
        <MailIcon />
        <span> Email </span>
      </Button>
    </>
  );
};

export { ShareByEmail };
