import React from 'react';

import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { QrIcon } from 'assets/icons';
import { Button } from 'common/button';
import { RouteName, goToPage } from 'routes';

import { classnames } from 'helpers/utils';
import styles from './unlinked-qr-code.module.scss';

const UnlinkedQrCode: React.FC = () => (
  <div
    className={classnames(
      globalStyles.loggedMainContainer,
      styles.fullPageContent,
    )}
  >
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.icon}>
          <QrIcon />
        </div>
        <div
          className={classnames('text__heading4__textNeutral40', styles.title)}
        >
          This QR code is not linked to an album.
        </div>
      </div>
      <p className="text__body__regular__medium__textNeutral30">
        If you’re the owner, scan it with the Generational Story app to connect
        it to an album or clip.
      </p>
      <Button
        className={styles.button}
        onClick={() => goToPage(RouteName.Home)}
      >
        Go back home
      </Button>
    </div>
  </div>
);

export { UnlinkedQrCode };
