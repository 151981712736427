/* eslint-disable max-len */
import { classnames } from 'helpers/utils';
import { useContext, useEffect } from 'react';
import { SelfRecordingStatus, VideoStatus } from 'common/enums';
import { FilmIcon, CrossIcon, AlertTriangleIcon } from 'assets/icons';
import { useFileUpload } from 'hooks/use-file-upload';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { bytesToMegabytes } from 'helpers/file-utils';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import styles from './video-row.module.scss';

type VideoRowProps = {
  albumId: number;
  questionSelected?: ClipType;
  video: AllVideoProps;
  notifyVideoStatusChange: (id: number, newStatus: string) => void;
  notifyDeleteVideo: (id: number) => void;
};

const VideoRow: React.FC<VideoRowProps> = ({
  video,
  notifyVideoStatusChange,
  notifyDeleteVideo,
}) => {
  const {
    state: { selectedQuestion },
    dispatch,
  } = useContext(SelfRecordingContext);
  const { uploadS3File, cancelRequest, uploadPercentage, response, error } =
    useFileUpload();

  const handleCancelVideo = () => {
    cancelRequest();
    notifyDeleteVideo(video.id);
  };

  const showVideoStatusMessage = () => {
    if (video.status === VideoStatus.inProgress) {
      return (
        <div className={styles.sizeInBytes}>
          <span>{`${bytesToMegabytes(
            video.file.size * (uploadPercentage / 100),
          ).toFixed(2)} MB `}</span>
          <span
            className={classnames({
              [styles.videoInProgress]: video.status === VideoStatus.inProgress,
            })}
          >{` / ${bytesToMegabytes(video.file.size).toFixed(2)} MB `}</span>
        </div>
      );
    }

    if (video.status === VideoStatus.ready) {
      return 'Uploaded';
    }

    if (video.status === VideoStatus.failed) {
      return 'Upload failed';
    }

    return '';
  };

  const showActions = () => {
    if (video.status === VideoStatus.inProgress) {
      return (
        <Button
          buttonSize={ButtonSize.Small}
          onClick={() => handleCancelVideo()}
          buttonStyle={ButtonStyle.PrimaryGhost}
        >
          {' '}
          Cancel{' '}
        </Button>
      );
    }

    if (video.status === VideoStatus.failed) {
      return (
        <Button
          buttonSize={ButtonSize.Small}
          onClick={() => notifyDeleteVideo(video.id)}
          buttonStyle={ButtonStyle.PrimaryGhost}
        >
          <CrossIcon className={styles.crossIcon} />
        </Button>
      );
    }

    return '';
  };

  const showFilmIcon = () => (
    <FilmIcon
      className={classnames(styles.filmIcon, {
        [styles.videoInProgress]: video.status === VideoStatus.inProgress,
      })}
    />
  );

  const showFailIcon = () => (
    <AlertTriangleIcon className={classnames(styles.alertTriangleIcon)} />
  );

  const showIcon = () => {
    if (
      video.status === VideoStatus.inProgress ||
      video.status === VideoStatus.ready
    ) {
      return showFilmIcon();
    }

    if (video.status === VideoStatus.failed) {
      return showFailIcon();
    }

    return null;
  };

  const showProgressBar = () => (
    <div className={styles.loadingBar}>
      <div
        className={classnames(styles.progress, {
          [styles.progressFailed]: video.status === VideoStatus.failed,
        })}
        style={{ width: `${uploadPercentage}%` }}
      />
    </div>
  );

  const showFileName = () => (
    <span
      className={classnames(
        'text__body__regular__small__textNeutral40',
        styles.videoName,
        {
          [styles.videoNameInProgress]: video.status === VideoStatus.inProgress,
        },
      )}
    >
      {video.file.name}
    </span>
  );

  const showContent = () => (
    <div key={video.id} className={styles.videoRow}>
      {showIcon()}

      {showFileName()}

      {showProgressBar()}

      <div
        className={classnames(
          'text__body__regular__small__textNeutral30',
          styles.videoStatus,
        )}
      >
        {showVideoStatusMessage()}
      </div>

      <div className={styles.actions}>{showActions()}</div>
    </div>
  );

  useEffect(() => {
    if (video.status === VideoStatus.new) {
      notifyVideoStatusChange(video.id, VideoStatus.inProgress);
      uploadS3File(video.file);
    }
  }, [video]);

  useEffect(() => {
    if (response) {
      notifyVideoStatusChange(video.id, VideoStatus.ready);
      dispatch({
        type: selfRecordingActions.newClip,
        videoToAppend: {
          requestId: video.id,
          clip: selectedQuestion,
          videoId: response.id,
          videoUrl: response.url,
          status: SelfRecordingStatus.recorded,
          fileName: !selectedQuestion ? video.file.name : undefined,
          uploadStartTime: video.uploadStartTime,
          videoSignedId: response.signedId,
        },
      });

      dispatch({ type: selfRecordingActions.setReadyForNextStep });
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      notifyVideoStatusChange(video.id, VideoStatus.failed);
    }
  }, [error]);

  return showContent();
};

export { VideoRow };
