import { classnames } from 'helpers/utils';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Spinner } from 'common/spinner';
import styles from './payment-in-progress.module.scss';

const PaymentInProgress = () => (
  <>
    <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
      Please wait. We´re processing your transaction.
    </div>
    <div
      className={classnames(
        'text__body__regular__medium__textNeutral30',
        'my-7',
      )}
    >
      Payment in progress do not close your window.
    </div>

    <div className={styles.spinnerContent}>
      <Spinner className={globalStyles.mediumSpinner} />
    </div>
  </>
);

export { PaymentInProgress };
