import { classnames } from 'helpers/utils';
import { InfoIcon } from 'assets/icons';
import styles from './clip-recorded-window.module.scss';

type ClipRecordedWindowProps = {
  className?: string;
  notifyClose: () => void;
  notifyDeleteClip: () => void;
};

const ClipRecordedWindow: React.FC<ClipRecordedWindowProps> = ({
  className = '',
  notifyClose,
  notifyDeleteClip,
}) => (
  <div className={classnames(styles.clipRecordedWindowContainer, className)}>
    <div className={styles.title}>
      <div className={styles.infoIcon}>
        <InfoIcon />
      </div>
      <div className="text__body__regular__large__textNeutral10">
        Clip recorded!
      </div>
    </div>
    <div className={classnames('text__body__regular__small__textNeutral20')}>
      You will be able to review it after finishing the call.
    </div>
    <div className={styles.buttons}>
      <button
        type="button"
        className={classnames(
          'text__body__regular__small__success20',
          styles.okButton,
        )}
        onClick={notifyClose}
      >
        Ok
      </button>

      <button
        type="button"
        className={classnames(
          'text__body__semi__bold__small__textNeutral20',
          styles.deleteButton,
        )}
        onClick={notifyDeleteClip}
      >
        Delete clip
      </button>
    </div>
  </div>
);

export { ClipRecordedWindow };
