import { classnames } from 'helpers/utils';
import styles from './labeled-text.module.scss';

type LabeledTextProps = {
  label: string;
  text: string;
  vertical?: boolean;
  className?: string;
};

const LabeledText = ({
  label,
  text,
  vertical = false,
  className = '',
}: LabeledTextProps) => (
  <div
    className={classnames(className, styles.labeledText, {
      [styles.vertical]: vertical,
    })}
  >
    <span className="text__body__semi__bold__medium__textNeutral40">
      {label}:
    </span>
    <span className="text__body__regular__small__textNeutral30">{text}</span>
  </div>
);

export { LabeledText };
