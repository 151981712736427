import { AllDataUser } from 'models/all-data-user';
import { NotificationObject } from 'models/notificationObject';
import { MetaTags } from 'models/meta-tags';
import { SubscriptionType } from 'models/subscription-type';
import { identifyUser } from 'posthog';
import { appActions } from './action-types';

export type InitialStateType = {
  data: AllDataUserType;
  logged: boolean;
  actualSection?: string;
  notification: NotificationObjectType;
  showBetaMsg: boolean;
  metaTags: MetaTagsType;
  subscriptionsScheme?: SubscriptionTypeType[];
  imageToEnlarge?: string;
};

const initialState: InitialStateType = {
  data: new AllDataUser(),
  logged: false,
  actualSection: '',
  notification: new NotificationObject(null),
  showBetaMsg: false,
  metaTags: new MetaTags(null),
  subscriptionsScheme: [],
};

type Action = {
  type: string;
  data: AllDataUserType;
  actualSection?: string;
  notification: NotificationObjectType;
  showBetaMsg: boolean;
  metaTags: MetaTagsType;
  subscriptionsScheme: SubscriptionType[];
  imageToEnlarge?: string;
};

const AppReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case appActions.USER_LOGGED:
      // Identify user in posthog
      identifyUser(action.data.user.id);
      return {
        ...state,
        data: action.data,
        logged: true,
      };
    case appActions.SESSION_EXPIRED:
      return {
        ...state,
        data: new AllDataUser(),
        logged: false,
        metaTags: new MetaTags(null),
        showBetaMsg: false,
      };
    case appActions.ACTUAL_SECTION:
      return {
        ...state,
        actualSection: action.actualSection,
      };
    case appActions.NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case appActions.BETA_MSG:
      return {
        ...state,
        showBetaMsg: action.showBetaMsg,
      };
    case appActions.SET_META_TAGS:
      return {
        ...state,
        metaTags: action.metaTags,
      };
    case appActions.SUBSCRIPTIONS_SCHEME:
      return {
        ...state,
        subscriptionsScheme: action.subscriptionsScheme,
      };
    case appActions.SET_IMAGE_TO_ENLARGE:
      return {
        ...state,
        imageToEnlarge: action.imageToEnlarge,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { initialState, AppReducer };
