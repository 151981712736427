import { AlbumsQueryParamsType } from 'networking/types/albums-query-params';

class AlbumsQueryParams {
  search?: string;

  pageSize: number;

  pageNum: number;

  sortBy: string;

  role?: AlbumsQueryParamsType['role'];

  active?: boolean;

  restricted?: boolean;

  locked?: boolean;

  hasQr?: boolean;

  constructor(params?: AlbumsQueryParamsType | null) {
    this.search = params?.search || '';
    this.pageNum = params?.pageNum || 1;
    this.pageSize = params?.pageSize || -1;
    this.sortBy = params?.sortBy || '';
    this.role = params?.role;
    this.restricted = params?.restricted || false;
    this.active = params?.active || false;
    this.locked = params?.locked || false;
    this.hasQr = params?.hasQr || false;
  }
}

export { AlbumsQueryParams };
