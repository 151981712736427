import { classnames } from 'helpers/utils';
import { AlertTriangleIcon } from 'assets/icons';
import styles from './warning-reaching-clips-limit.module.scss';

type WarningReachingClipsLimitProps = {
  maxQuestionsPerAlbum: number;
  allClipsCount: number;
};

const WarningReachingClipsLimit: React.FC<WarningReachingClipsLimitProps> = ({
  maxQuestionsPerAlbum,
  allClipsCount,
}) => {
  const limitReached = allClipsCount === maxQuestionsPerAlbum;

  return (
    <div className={classnames(styles.warningClipLimitContainer)}>
      <div className={styles.leftSection}>
        <div
          className={classnames(styles.warningIcon, {
            [styles.redColor]: limitReached,
          })}
        >
          <AlertTriangleIcon />
        </div>
        <div className="text__body__regular__small__textNeutral00">
          {limitReached
            ? 'Album’s clip limit reached'
            : 'Reaching album’s clip limit'}
        </div>
      </div>
      <div
        className={classnames(
          styles.rightSection,
          { [styles.redBackground]: limitReached },
          'text__body__regular__small__textNeutral00',
        )}
      >
        <span>
          {allClipsCount}/{maxQuestionsPerAlbum}
        </span>
        <span>recorded</span>
      </div>
    </div>
  );
};

export { WarningReachingClipsLimit };
