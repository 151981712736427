import React, { useState, useEffect, useReducer } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Notification } from 'common/notification';
import { Footer, FooterType } from 'common/footer';
import { goToPage, RouteName } from 'routes';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { utmProperties } from 'config/constants';
import { UserController } from 'networking/controllers/user-controller';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { ReactComponent as LogoIntervivos } from 'assets/images/intervivosLogo.svg';
import { capitalizeFirstLetter, classnames } from 'helpers/utils';
import { SignUpReducer, signUpinitialState } from './sign-up-reducer';
import { SignHeader } from '../../common/sign-header';
import { SignUpStep1, SignUpStep2 } from './sections';
import styles from './sign-up.module.scss';

const SignUp: React.FC = () => {
  const getQueryParam = (param: string) => {
    const contributorProp = new URLSearchParams(window.location.search).get(
      param,
    );

    return contributorProp || null;
  };

  const [state, dispatch] = useReducer(SignUpReducer, signUpinitialState);
  const [showError, setShowError] = useState(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);

  const addCouponCode = async (couponCode: string) => {
    try {
      const response =
        await SubscriptionController.getSubscriptionDealByCode(couponCode);
      dispatch({
        type: 'UPDATE_SUBSCRIPTION_DEALS',
        subscriptionDeals: [response],
      });
      const couponCodeId = { id: response.id, code: couponCode };
      dispatch({ type: 'COUPON_CODE_APPLIED', couponCodeId: [couponCodeId] });
    } catch (error) {
      dispatch({ type: 'COUPON_INPUT', couponCode });
    }
  };

  useEffect(() => {
    ['name', 'email'].forEach((queryParam: string) => {
      const contributorValue = getQueryParam(queryParam);

      if (contributorValue) {
        dispatch({
          type: 'CHANGE_INPUT',
          object: 'user',
          field: queryParam,
          value: contributorValue || '',
        });
      }
    });

    const companySingUp = getQueryParam('company');
    if (companySingUp) {
      dispatch({
        type: 'CHANGE_INPUT',
        object: 'user',
        field: 'companyName',
        value: capitalizeFirstLetter(companySingUp),
      });
      setIsCompany(true);
    }

    const token = getQueryParam('token');

    if (token) {
      dispatch({ type: 'ADD_TOKEN', token });
    }
    utmProperties.forEach((utmProperty: UtmProperties) => {
      const utmValue = getQueryParam(utmProperty);
      if (utmValue) {
        dispatch({
          type: 'SET_UTM_PROPERTY',
          utmProperty: UserController.deSerializeUtmProperty(
            utmProperty,
          ) as string,
          utmValue,
        });
      }
    });

    const couponCode = getQueryParam('couponCode');
    if (couponCode) {
      addCouponCode(couponCode);
    }

    return () => {
      dispatch({ type: 'REGISTER_SUCCESS' });
    };
  }, []);

  const showSignUpStep1View = () => (
    <SignUpStep1
      step={state.step}
      user={state.user}
      sectionAlreadyVisited={state.alreadyVisited.step1}
      dispatch={dispatch}
      isCompany={isCompany}
    />
  );

  const showSignUpStep2View = () => (
    <SignUpStep2
      step={state.step}
      sectionAlreadyVisited={state.alreadyVisited.step2}
      user={state.user}
      subscriptionDealList={state.subscriptionDeal}
      couponCode={state.user.couponCode}
      dispatch={dispatch}
      subscriptionDealsApplied={state.couponCodeApplied}
      isCompany={isCompany}
    />
  );

  const showCompanyLogo = (company: string) => {
    if (company.toLowerCase() === 'intervivos') {
      return <LogoIntervivos />;
    }
    return null;
  };
  const showContentView = () => (
    <div className={styles.fullContent}>
      <div className={styles.loginText}>
        <span className="text__body__regular__small__textNeutral30">
          {' '}
          Already registered?{' '}
        </span>
        <span>
          <Button
            buttonSize={ButtonSize.Small}
            buttonStyle={ButtonStyle.PrimaryGhostLink}
            onClick={() => goToPage(RouteName.SignIn)}
          >
            Log in
          </Button>
        </span>
      </div>
      <div
        className={classnames(styles.marginTop, globalStyles.signBoxContent)}
      >
        <div className={styles.elements}>
          <SignHeader />
          {isCompany && (
            <>
              <p className="text__heading6__surfaceLight50"> & </p>
              {showCompanyLogo(state.user.companyName)}
            </>
          )}
        </div>
        {state.step === 1 && showSignUpStep1View()}
        {state.step === 2 && showSignUpStep2View()}
      </div>
    </div>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <div className={globalStyles.signMainContainer}>
      {showError && showErrorNotification()}
      {showContentView()}
      <Footer footerType={FooterType.NoSignedUser} />
    </div>
  );
};

export { SignUp };
