import { Dispatch } from 'react';
import {
  TemplateStateType,
  TemplatesAction,
} from 'pages/templates/templates-reducer';
import { classnames } from 'helpers/utils';
import { TemplateCard } from '../template-card/template-card';
import styles from './templates-list-section.module.scss';

type TemplatesListSectionProps = {
  templatesState: TemplateStateType;
  templatesDispatch: Dispatch<TemplatesAction>;
  className?: string;
};

const TemplatesListSection: React.FC<TemplatesListSectionProps> = ({
  templatesState,
  templatesDispatch,
  className = '',
}) => {
  const showTemplatesList = () => (
    <>
      {templatesState.templatesList.map((template: PreBuiltQuestionsType) => (
        <TemplateCard
          key={template.id}
          templatesState={templatesState}
          template={template}
          templatesDispatch={templatesDispatch}
        />
      ))}
    </>
  );

  const showTemplatesEmpty = () => (
    <div
      className={classnames('text__title2__textNeutral20', styles.noTemplates)}
    >
      No templates.
    </div>
  );

  return (
    <div className={className}>
      {templatesState.templatesList.length
        ? showTemplatesList()
        : showTemplatesEmpty()}
    </div>
  );
};

export { TemplatesListSection };
