import { InfoIcon } from 'assets/icons';
import { VideoCallRole } from 'hooks/use-videocall-websocket';
import { useEffect } from 'react';
import styles from './warning-role-updated.module.scss';

type WarningRoleUpdatedProps = {
  newRole: VideoCallRole;
  notifyClose: () => void;
};

const WarningRoleUpdated: React.FC<WarningRoleUpdatedProps> = ({
  newRole,
  notifyClose,
}) => {
  const previousRole =
    newRole === VideoCallRole.INTERVIEWER
      ? VideoCallRole.RESPONDENT
      : VideoCallRole.INTERVIEWER;

  useEffect(() => {
    setTimeout(() => notifyClose(), 3000);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.infoIcon}>
          <InfoIcon />
        </div>
        <div className="text__body__regular__large__textNeutral10">
          Your role has been changed to {newRole}
        </div>
      </div>
      <div className="text__body__regular__small__textNeutral20">
        Another user has taken the {previousRole} role.
      </div>
    </div>
  );
};

export { WarningRoleUpdated };
