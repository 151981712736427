import React from 'react';

import { Modal } from 'common/modal';
import { classnames } from 'helpers/utils';
import { Button, ButtonStyle } from 'common/button';

import styles from './info-modal.module.scss';

type InfoModalProps = {
  className?: string;
  title: string;
  content: JSX.Element | string;
  onClose: () => void;
};

const InfoModal: React.FC<InfoModalProps> = ({
  className = '',
  title,
  content,
  onClose,
}) => (
  <Modal contentClassName={className} title={title} onClose={onClose}>
    <div className={classnames('text__body__regular__medium__textNeutral40')}>
      {content}
    </div>
    <div className={styles.buttonsfooter}>
      <Button buttonStyle={ButtonStyle.PrimaryFilled} onClick={onClose}>
        Close
      </Button>
    </div>
  </Modal>
);

export { InfoModal };
