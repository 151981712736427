import React, { useContext, useEffect } from 'react';

import { Router } from 'routes';
import { routeConfig } from 'route-components';
import { Helmet } from 'react-helmet';
import { appActions, AppContext } from 'context';
import { Notification } from 'common/notification';
import { ErrorBoundary } from 'common/error-boundary';
import { FeatureFlagsProvider } from 'hooks/use-feature-flags';
import { LocalStorageApi } from 'helpers/local-storage';
import { NotificationObject } from 'models/notificationObject';
import { ApiService } from 'networking/api-service';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import 'index.scss';
import { ImageFullScreen } from 'common/image-full-screen';

const App = () => {
  const { dispatch, state } = useContext(AppContext);
  const { notification, metaTags, imageToEnlarge } = state;
  const { type, show, title, noMessage, message, limitTimeMs, footer } =
    notification;
  const { description, image } = metaTags;

  const handleClose = () => {
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject(null),
    });
  };
  const getAndStoreSubscriptionScheme = async () => {
    const response = await SubscriptionController.getSubscriptionTypes();
    dispatch({
      type: appActions.SUBSCRIPTIONS_SCHEME,
      subscriptionsScheme: response,
    });
  };

  useEffect(() => {
    getAndStoreSubscriptionScheme();

    const csrfToken = LocalStorageApi.get('csrfToken');
    if (csrfToken) {
      ApiService.setHeaders({ 'X-CSRF-Token': csrfToken });
    }
  }, []);

  return (
    <FeatureFlagsProvider>
      <ErrorBoundary>
        <Helmet>
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="436" />
          <meta property="og:image:height" content="228" />
        </Helmet>
        {imageToEnlarge && <ImageFullScreen />}
        {show && (
          <Notification
            handleClose={handleClose}
            noMessage={noMessage}
            message={message}
            title={title}
            type={type}
            limitTimeMs={limitTimeMs}
            footer={footer}
          />
        )}
        <Router routeConfig={routeConfig} />
      </ErrorBoundary>
    </FeatureFlagsProvider>
  );
};

export { App };
