/* eslint-disable max-len */
import React from 'react';
import { Checkbox } from 'common/checkbox';
import { InfoLabel } from 'common/info-label';
import { Accordion } from 'common/accordion';
import { QuestionsManagmentViewType } from 'common/enums';
import { classnames, isEditingAlbum } from 'helpers/utils';
import { ThreeStatesCheckbox } from 'common/checkbox-three-states';
import { HelperText } from 'common/helper-text';
import styles from './pre-built-item.module.scss';

type PreBuiltItemProps = {
  item: PreBuiltQuestionsType;
  remainingQuestionsCount: number;
  questionsManagmentView: QuestionsManagmentViewType;
  selectPreBuiltItem: (PreBuiltId: string) => void;
  selectQuestion: (PreBuiltId: string, questionName: string) => void;
};

const PreBuiltItem: React.FC<PreBuiltItemProps> = ({
  item,
  remainingQuestionsCount,
  questionsManagmentView,
  selectPreBuiltItem,
  selectQuestion,
}) => {
  const getNoSelectedQuestionsCount = (questions: QuestionType[]) => {
    let counter = 0;

    questions.forEach((question: QuestionType) => {
      if (!question.isSelected) {
        counter += 1;
      }
    });

    return counter;
  };

  const canAddPreBuiltItem = (templ: PreBuiltQuestionsType, limit: number) => {
    const noSelectedCount = getNoSelectedQuestionsCount(templ.questions);
    return noSelectedCount <= limit;
  };

  const canAddQuestion = (limit: number) => limit > 0;

  const preBuiltItemTitle = () => (
    <div className={styles.title}>
      <ThreeStatesCheckbox
        itemKey={item?.id!}
        onChange={() => selectPreBuiltItem(item!.id)}
        checkValue={item?.checkValue}
        disabled={
          !isEditingAlbum(questionsManagmentView) &&
          !canAddPreBuiltItem(item!, remainingQuestionsCount)
        }
      />
      <HelperText content={item?.name!} />
    </div>
  );

  const preBuiltItemContent = () => (
    <div className={styles.content}>
      {item.questions!.map((question) => (
        <div key={question.name} className={styles.question}>
          <Checkbox
            itemKey={question.name}
            onChange={() => selectQuestion(item.id, question.name)}
            isChecked={question.isSelected}
            disabled={
              !isEditingAlbum(questionsManagmentView) &&
              !question.isSelected &&
              !canAddQuestion(remainingQuestionsCount)
            }
          />
          <HelperText content={question?.name!} />
          {question.alreadyAdded && (
            <InfoLabel
              withIcon={false}
              content="Added"
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
                styles.questionAdded,
              )}
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Accordion
      className={styles.preBuiltItemContainer}
      title={preBuiltItemTitle()}
      content={preBuiltItemContent()}
    />
  );
};

export { PreBuiltItem };
