import { AlbumsSerializer } from './albums-serializer';

class TemplateAndQuestionsSerializer {
  static deserializeDefaultQuestions(
    data: DefaultQuestionsRaw,
  ): DefaultQuestionsType {
    return {
      suggestedQuestions: data.suggested_questions.map((sq) =>
        this.deserializeSuggestedQuestions(sq),
      ),
    };
  }

  private static deserializeQuestion(data: QuestionRaw): QuestionType {
    return {
      id: data.id,
      name: data.name,
      createdBy: data.created_by,
      recordedBy: data.recorded_by,
      createdByOwner: data.created_by_owner,
      recordedByOwner: data.recorded_by_owner,
      isSelected: false,
    };
  }

  private static deserializeSuggestedQuestions(
    data: PreBuiltQuestionsRaw,
  ): PreBuiltQuestionsType {
    return {
      id: data.id,
      name: data.name,
      questions: data.questions.map((q) => this.deserializeQuestion(q)),
      checkValue: false,
    };
  }

  static serializeTemplate(data: PreBuiltQuestionsType): PreBuiltQuestionsRaw {
    return {
      id: data.id,
      name: data.name,
      questions: data.questions.map((question: QuestionType) =>
        AlbumsSerializer.serializeEditQuestion(question),
      ),
    };
  }

  static deserializeTemplate(
    data: PreBuiltQuestionsRaw,
  ): PreBuiltQuestionsSerialized {
    return {
      id: data.id,
      name: data.name,
      questions: data.questions.map((q) => this.deserializeQuestion(q)),
      checkValue: false,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };
  }

  static deSerializeTemplates(
    data: PreBuiltQuestionsRaw[],
  ): PreBuiltQuestionsSerialized[] {
    return data.map((template: PreBuiltQuestionsRaw) =>
      this.deserializeTemplate(template),
    );
  }

  static serializeEditTemplate(
    data: EditTemplateRequestType,
  ): EditTemplateRequestRaw {
    return {
      template: this.serializeTemplate(data.template),
    };
  }
}

export { TemplateAndQuestionsSerializer };
