import { Menu, PositionType } from 'common/menu';
import { Fragment, useCallback, useRef, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { HelperText } from 'common/helper-text';
import { Checkbox } from 'common/checkbox';
import { ChevronIcon, FilterIcon } from 'assets/icons';
import {
  AlbumOwnershipFiltersIds,
  AlbumStatusFiltersIds,
  Breakpoints,
  QrCodesFiltersIds,
} from 'common/enums';
import { useClickOutside } from 'hooks/click-outside';
import { classnames } from 'helpers/utils';
import {
  allOptionsSelected,
  noOptionsSelected,
  selectAllOptionsFromCheckBoxGroup,
} from 'helpers/filters-helper';
import { cloneDeep } from 'lodash';
import { useMediaQuery } from 'hooks/use-media-query';
import { AlbumsQueryParamsType } from 'networking/types/albums-query-params';
import styles from './albums-filters.module.scss';

export const getNewQueryParams = (
  checkBoxGroups: CheckboxGroupType[],
  oldQueryParams: AlbumsQueryParamsType,
) => {
  const queryParamsUpdatedCopy: AlbumsQueryParamsType = {
    pageNum: oldQueryParams.pageNum,
    pageSize: oldQueryParams.pageSize,
    sortBy: oldQueryParams.sortBy,
  };

  checkBoxGroups.forEach((checkBoxGroupEdited) =>
    checkBoxGroupEdited.options.forEach((opt: CheckboxOptionSimpleType) => {
      // INFO: Adds album ownership
      if (opt.isSelected && opt.id === AlbumOwnershipFiltersIds.owner) {
        // INFO: If the user wants to filter collaborators albums too,
        //       we delete the role filter because it's the way the backend handles it
        if (
          queryParamsUpdatedCopy.role !== AlbumOwnershipFiltersIds.contributor
        ) {
          queryParamsUpdatedCopy.role = AlbumOwnershipFiltersIds.owner;
        } else {
          queryParamsUpdatedCopy.role = undefined;
        }
      }

      if (opt.isSelected && opt.id === AlbumOwnershipFiltersIds.contributor) {
        if (queryParamsUpdatedCopy.role !== AlbumOwnershipFiltersIds.owner) {
          queryParamsUpdatedCopy.role = AlbumOwnershipFiltersIds.contributor;
        } else {
          queryParamsUpdatedCopy.role = undefined;
        }
      }

      // INFO: Adds albums with QR codes
      if (opt.isSelected && opt.id === QrCodesFiltersIds.withQrCodes) {
        if (queryParamsUpdatedCopy.hasQr === undefined) {
          queryParamsUpdatedCopy.hasQr = true;
        } else {
          queryParamsUpdatedCopy.hasQr = undefined;
        }
      }

      if (opt.isSelected && opt.id === QrCodesFiltersIds.withoutQrCodes) {
        if (queryParamsUpdatedCopy.hasQr === undefined) {
          queryParamsUpdatedCopy.hasQr = false;
        } else {
          queryParamsUpdatedCopy.hasQr = undefined;
        }
      }

      if (
        opt.id === AlbumStatusFiltersIds.active ||
        opt.id === AlbumStatusFiltersIds.restricted ||
        opt.id === AlbumStatusFiltersIds.locked
      ) {
        queryParamsUpdatedCopy[opt.id] = opt.isSelected;
      }
    }),
  );

  return queryParamsUpdatedCopy;
};

export const albumOwnershipFiltersArray: CheckboxOptionSimpleType[] = [
  {
    id: AlbumOwnershipFiltersIds.owner,
    name: 'Owned albums',
    isSelected: true,
  },
  {
    id: AlbumOwnershipFiltersIds.contributor,
    name: 'Contributor albums',
    isSelected: true,
  },
];

export const albumStatusFiltersArray: CheckboxOptionSimpleType[] = [
  { id: AlbumStatusFiltersIds.active, name: 'Active albums', isSelected: true },
  {
    id: AlbumStatusFiltersIds.restricted,
    name: 'Restricted albums',
    isSelected: true,
  },
  { id: AlbumStatusFiltersIds.locked, name: 'Locked albums', isSelected: true },
];

export const qrCodesFiltersArray: CheckboxOptionSimpleType[] = [
  {
    id: QrCodesFiltersIds.withQrCodes,
    name: 'With QR code(s)',
    isSelected: true,
  },
  {
    id: QrCodesFiltersIds.withoutQrCodes,
    name: 'Without QR code(s)',
    isSelected: true,
  },
];

export const albumOwnershipGroupFilter: CheckboxGroupType = {
  id: 'albumOwnershipGroupFilter',
  name: 'Album ownership',
  options: albumOwnershipFiltersArray,
};

export const albumStatusGroupFilter: CheckboxGroupType = {
  id: 'albumStatusGroupFilter',
  name: 'Album status',
  options: albumStatusFiltersArray,
};

export const qrCodesGroupFilter: CheckboxGroupType = {
  id: 'qrCodesGroupFilter',
  name: 'QR code(s)',
  options: qrCodesFiltersArray,
};

interface AlbumsFiltersProps {
  queryParams: AlbumsQueryParamsType;
  setQueryParams: (queryParams: AlbumsQueryParamsType) => void;
}

const AlbumsFilters: React.FC<AlbumsFiltersProps> = ({
  queryParams,
  setQueryParams,
}) => {
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);
  const [checkBoxFilters, setCheckBoxFilters] = useState([
    albumOwnershipGroupFilter,
    albumStatusGroupFilter,
    qrCodesGroupFilter,
  ]);

  const [visibleFilters, setVisibleFilters] = useState([false, false, false]);

  const containerFilterOptionsRef = useRef(null);
  useClickOutside(containerFilterOptionsRef, () => {
    setShowFilterOptions(false);
  });

  const applyFilters = () => {
    const newQueryParams = getNewQueryParams(checkBoxFilters, queryParams);

    setQueryParams(newQueryParams);
  };

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  const handleChangeFilter = (
    checkBoxGroupSelectedId: string,
    filterSelected: string,
  ) => {
    const checkBoxFiltersCopy = cloneDeep(checkBoxFilters);

    const checkBoxGroupSelected = checkBoxFiltersCopy.find(
      (group) => group.id === checkBoxGroupSelectedId,
    )!;

    const option = checkBoxGroupSelected.options.find(
      (opt) => opt.id === filterSelected,
    )!;
    option.isSelected = !option.isSelected;

    if (allOptionsSelected(checkBoxGroupSelected)) {
      checkBoxGroupSelected.checkValue = true;
    } else if (noOptionsSelected(checkBoxGroupSelected)) {
      checkBoxGroupSelected.checkValue = false;
    } else {
      checkBoxGroupSelected.checkValue = null;
    }

    setCheckBoxFilters(checkBoxFiltersCopy);
  };

  const handleClearFilters = () => {
    const checkBoxFiltersCopy = checkBoxFilters.map((filterGroup) => {
      if (!filterGroup.checkValue) {
        return {
          ...filterGroup,
          checkValue: true,
          options: selectAllOptionsFromCheckBoxGroup(filterGroup.options),
        };
      }
      return { ...filterGroup };
    });

    setCheckBoxFilters(checkBoxFiltersCopy);

    const newQueryParams = getNewQueryParams(checkBoxFiltersCopy, queryParams);
    setQueryParams(newQueryParams);
  };

  const allFilterSelected = useCallback(() => {
    let allFiltersSelectedFlag = true;

    checkBoxFilters.forEach((group: CheckboxGroupType) => {
      if (
        group.options.some(
          (option: CheckboxOptionSimpleType) => !option.isSelected,
        )
      ) {
        allFiltersSelectedFlag = false;
      }
    });

    return allFiltersSelectedFlag;
  }, [checkBoxFilters]);

  const someFilterSelected = useCallback(
    () =>
      checkBoxFilters.some((group: CheckboxGroupType) =>
        group.options.some(
          (option: CheckboxOptionSimpleType) => option.isSelected,
        ),
      ),
    [checkBoxFilters],
  );

  const showFiltersMenu = () => (
    <div className={styles.filterMenu}>
      <Menu
        className={styles.filtersSection}
        position={mobile ? PositionType.BottomRight : PositionType.BottomLeft}
        width={styles.menuWith}
      >
        {checkBoxFilters.map((checkBoxGroup, index) => (
          <Fragment key={checkBoxGroup.id}>
            <button
              type="button"
              onClick={() => {
                const newVisibleFilters = visibleFilters.slice();
                newVisibleFilters[index] = !newVisibleFilters[index];
                setVisibleFilters(newVisibleFilters);
              }}
              className={styles.filterType}
            >
              <div className={styles.filterGroupTitle}>
                <span className="text__body__semi__bold__medium__textNeutral40">
                  {checkBoxGroup.name}
                </span>
                <div
                  className={classnames({
                    [styles.badge]: !noOptionsSelected(checkBoxGroup),
                  })}
                />
              </div>
              <ChevronIcon
                className={classnames(styles.chevron, {
                  [styles.menuOpen]: visibleFilters[index],
                })}
              />
            </button>
            {visibleFilters[index] && (
              <div className={styles.filterGroup} key={checkBoxGroup.id}>
                <div className={styles.options}>
                  {checkBoxGroup.options!.map((option) => (
                    <div key={option.id} className={styles.option}>
                      <Checkbox
                        itemKey={option.id}
                        onChange={() =>
                          handleChangeFilter(checkBoxGroup.id, option.id)
                        }
                        isChecked={option.isSelected}
                      />
                      <HelperText
                        className="text__body__regular__medium__textNeutral30"
                        content={option.name}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Fragment>
        ))}
        <div className={styles.filterButtons}>
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.GreyGhost}
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.PrimaryGhost}
            onClick={applyFilters}
          >
            Apply filters
          </Button>
        </div>
      </Menu>
    </div>
  );

  return (
    <div className={styles.filterDropdown} ref={containerFilterOptionsRef}>
      <button
        type="button"
        className={styles.filterIcons}
        onClick={() => setShowFilterOptions(!showFilterOptions)}
      >
        <FilterIcon
          className={classnames(
            styles.filterIcon,
            { [styles.menuOpen]: showFilterOptions },
            { [styles.notSelectedAll]: !allFilterSelected() },
          )}
        />
        <div
          className={classnames({
            [styles.someFilterNotSelected]: someFilterSelected(),
          })}
        />
        <ChevronIcon
          className={classnames(styles.chevronIcon, {
            [styles.menuOpen]: showFilterOptions,
          })}
        />
      </button>
      {showFilterOptions && showFiltersMenu()}
    </div>
  );
};

export { AlbumsFilters };
