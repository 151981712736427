import React, { useContext, useState } from 'react';
import { classnames } from 'helpers/utils';
import { Notification } from 'common/notification';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { goToPage, RouteName } from 'routes';
import { UserController } from 'networking/controllers/user-controller';
import { appActions, AppContext } from 'context';
import styles from './corner-logout-button.module.scss';

type CornerLogoutButtonProps = {
  className?: string;
};

const CornerLogoutButton: React.FC<CornerLogoutButtonProps> = ({
  className = '',
}) => {
  const { state, dispatch } = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  const classes = [className, styles.logoutButton];

  const handleLogout = async () => {
    try {
      await UserController.signOutUser();
      dispatch({ type: appActions.SESSION_EXPIRED });
      goToPage(RouteName.SignIn);
    } catch (e) {
      setShowError(true);
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const showContent = () => (
    <div
      className={classnames({ invisible: !state.data.user.name }, ...classes)}
    >
      <span className="text__body__regular__small__textNeutral30">{`Welcome ${state.data.user.name}.  `}</span>
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </div>
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showContent()}
    </>
  );
};

export { CornerLogoutButton };
