class AlbumEditModal {
  id: number;

  title: string;

  imageUrl: string;

  constructor(params?: AlbumEditModalType) {
    this.id = params?.id || -1;
    this.title = params?.title || '';
    this.imageUrl = params?.coverUrl || '';
  }
}

export { AlbumEditModal };
