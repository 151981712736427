import { ReactNode, useEffect } from 'react';

import { Modal } from 'common/modal/modal';
import { classnames } from 'helpers/utils';
import { Button, ButtonStyle } from 'common/button';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Spinner } from 'common/spinner';
import { captureEvent } from 'posthog';
import CypressIds from 'cypressIds';
import styles from './modal-warning.module.scss';

type WarningModalProps = {
  title?: string;
  content?: ReactNode;
  className?: string;
  contentClassName?: string;
  showCancelButton?: boolean;
  disableManualClosing?: boolean;
  closeButtonText?: string;
  closeStyleButton?: ButtonStyle;
  successButtonText?: string;
  successStyleButton?: ButtonStyle;
  loading?: boolean;
  closeFn?: () => void;
  successFn?: () => void;
  disableSuccessButton?: boolean;
};

const ModalWarning = ({
  title = '',
  content,
  className = '',
  contentClassName = '',
  showCancelButton = true,
  disableManualClosing = false,
  closeButtonText = 'Cancel',
  successButtonText = 'Accept',
  closeStyleButton = ButtonStyle.GreyGhost,
  successStyleButton = ButtonStyle.SecondaryFilled,
  loading = false,
  closeFn,
  successFn = () => {},
  disableSuccessButton = false,
}: WarningModalProps) => {
  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      closeFn!();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  const cancelButton = () => (
    <Button
      buttonStyle={closeStyleButton}
      className={styles.cancelButton}
      onClick={closeFn}
    >
      {closeButtonText}
    </Button>
  );

  const successButton = () => (
    <Button
      dataCy={CypressIds.confirmModalButton}
      buttonStyle={successStyleButton}
      className={styles.successButton}
      onClick={() => {
        captureEvent('modalConfirmButtonClicked', {
          modalTitle: title,
          buttonText: successButtonText,
        });
        successFn();
      }}
      disabled={disableSuccessButton}
    >
      {successButtonText}
    </Button>
  );

  const footerButtons = () => (
    <>
      {showCancelButton && cancelButton()}
      {successButton()}
    </>
  );

  const showModal = () => (
    <Modal
      title={title}
      onClose={closeFn}
      disableManualClosing={disableManualClosing}
      className={classnames(styles.modal, className)}
      contentClassName={classnames(styles.contentModal, contentClassName)}
    >
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral40',
          styles.content,
        )}
      >
        {content}
      </div>
      <div className={styles.buttonsfooter}>
        {loading ? (
          <Spinner
            className={classnames(
              globalStyles.smallSpinner,
              styles.spinnerButtons,
            )}
          />
        ) : (
          footerButtons()
        )}
      </div>
    </Modal>
  );

  return showModal();
};

export { ModalWarning };
