import { getStateId } from 'helpers/store-helper';
import { Item, StoreStateType } from 'pages/store/store-reducer';

class StoreSerializer {
  static deSerializeItems(data: StoreItemRaw[]): StoreItem[] {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      price: item.price,
      image: item.image_url,
    }));
  }

  static deSerializeShippingCosts(data: ShippingCostRaw[]): ShippingCost[] {
    return data.map((item) => ({
      id: item.id,
      state: item.value,
      cost: item.cost,
      tax: item.tax,
    }));
  }

  static serilizeProducts(products: Item) {
    return {
      id: products.id,
      quantity: products.mountOfItems,
    };
  }

  static serializeOrder(order: StoreStateType) {
    return {
      order: {
        address: {
          street_address_1: order.shippingDetails.addressFirstField,
          street_address_2: order.shippingDetails.addressSecondField,
          country: 'US',
          zip_code: order.shippingDetails.zipCode,
          state: getStateId(order),
          city: order.shippingDetails.city,
        },
        email: order.shippingDetails.email,
        products: order.itemsInCart?.map((item) => this.serilizeProducts(item)),
      },
    };
  }
}
export { StoreSerializer };
