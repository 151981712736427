class Contributor {
  idContributorAux: number;

  name: string;

  email: string;

  invitationId?: number;

  invitationStatus?: string;

  constructor(data?: ContributorType) {
    this.idContributorAux = data?.idContributorAux || -1;
    this.name = data?.name || '';
    this.email = data?.email || '';
    this.invitationId = data?.invitationId || -1;
    this.invitationStatus = data?.invitationStatus || '';
  }
}

export { Contributor };
