class ClipEditModal {
  id: number;

  name: string;

  description: string;

  videoUrl?: string;

  constructor(params?: ClipType) {
    this.id = params?.id || -1;
    this.name = params?.name || '';
    this.description = params?.description || '';
    this.videoUrl = params?.videoUrl;
  }
}

export { ClipEditModal };
