import { AppContext, SensorSelectionContext, appActions } from 'context';
import { useFileUpload } from 'hooks/use-file-upload';
import { useContext, useEffect, useRef, useState } from 'react';
import ysFixWebmDuration from 'fix-webm-duration';
import { SelfRecordingContext } from 'context/self-recording-context';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType, SelfRecordingStatus } from 'common/enums';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { captureException } from 'sentry';

export const useSelfRecording = () => {
  const { sensorSelection, updateSensorSelection } = useContext(
    SensorSelectionContext,
  );
  const [mediaRecorderState, setMediaRecorder] =
    useState<MediaRecorder | null>();
  const { state: selfRecordingState, dispatch: selfRecordingDispatch } =
    useContext(SelfRecordingContext);
  const chunks = useRef([] as any[]);
  const startedTimes = useRef<[number]>([0]);
  const endedTimes = useRef<[number]>([0]);
  const [countingTime, setCountingTime] = useState(false);
  const { uploadS3File, response, errorWithId } = useFileUpload();
  const { dispatch } = useContext(AppContext);
  const amountOfClips = useRef(0);
  const { selectedQuestion, videosRecorded } = selfRecordingState;
  const videosRecordedRef = useRef(videosRecorded);

  useEffect(() => {
    videosRecordedRef.current = videosRecorded;
  }, [videosRecorded]);

  const uploadVideo = async (requestId: string) => {
    let typeForFile = 'video/webm';

    if (!MediaRecorder.isTypeSupported('video/webm')) {
      typeForFile = 'video/mp4';
    }

    const recordedBlob = new Blob(chunks.current, {
      type: typeForFile,
    });

    const fixedBlob = await ysFixWebmDuration(
      recordedBlob,
      endedTimes.current[endedTimes.current.length - 1]! -
        startedTimes.current[startedTimes.current.length - 1]!,
      { logger: false },
    );

    const fileToUpload = new File(
      [fixedBlob],
      selectedQuestion ? selectedQuestion?.name : 'Custom recording',
      {
        type: typeForFile,
      },
    );
    selfRecordingDispatch({
      type: selfRecordingActions.updateInformation,
      updateInformation: {
        requestId,
        file: fileToUpload,
      },
    });
    uploadS3File(fileToUpload, requestId);
  };

  const closeAllTracks = () => {
    selfRecordingState.mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const retryFailedRequests = () => {
    selfRecordingState.videosRecorded.forEach((video) => {
      if (video.status === SelfRecordingStatus.error) {
        if (video.file) {
          selfRecordingDispatch({
            type: selfRecordingActions.updateInformation,
            updateInformation: {
              requestId: video.requestId,
              status: SelfRecordingStatus.processing,
            },
          });
          uploadS3File(video.file, video.requestId);
        } else {
          dispatch({
            type: appActions.NOTIFICATION,
            notification: new NotificationObject({
              show: true,
              title: 'Something went wrong',
              message: 'Please try again',
              type: NotificationType.Error,
            }),
          });
        }
      }
    });
  };

  useEffect(() => {
    if (errorWithId && errorWithId.requestId) {
      selfRecordingDispatch({
        type: selfRecordingActions.videoFailed,
        idWhoseVideoFailed: errorWithId.requestId,
      });
    }
  }, [errorWithId]);

  useEffect(() => {
    if (response && response.requestId) {
      selfRecordingDispatch({
        type: selfRecordingActions.addVideo,
        videoToAppend: {
          requestId: response.requestId,
          videoId: response.id,
          videoUrl: response.url,
          videoSignedId: response.signedId,
        },
      });
      selfRecordingDispatch({ type: selfRecordingActions.setReadyForNextStep });
    }
  }, [response]);

  const handleDataAvailable = (event: { data: Blob }) => {
    if (event.data.size > 0) {
      chunks.current.push(event.data);
    }
  };

  const handleStartRecording = () => {
    amountOfClips.current += 1;
    startedTimes.current.push(Date.now());
    selfRecordingDispatch({
      type: selfRecordingActions.newClip,
      videoToAppend: {
        requestId: amountOfClips.current.toString(),
        clip: selectedQuestion,
        videoId: 0,
        videoUrl: '',
        status: SelfRecordingStatus.processing,
      },
    });
    selfRecordingDispatch({
      type: selfRecordingActions.setIsRecording,
      isRecording: true,
    });
    setCountingTime(true);
  };

  const handleStopRecording = () => {
    endedTimes.current.push(Date.now());
    selfRecordingDispatch({
      type: selfRecordingActions.setIsRecording,
      isRecording: false,
    });
    setCountingTime(false);
    uploadVideo(
      videosRecordedRef.current[videosRecordedRef.current.length - 1]
        ?.requestId,
    );
    chunks.current = []; // we need to clear the chunks so that the next recording is not appended to the previous one
  };

  const optionsMediaRecorder = () => {
    let options = {};
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      options = { mimeType: 'video/webm; codecs=vp9' };
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      options = { mimeType: 'video/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
    } else {
      captureException('No suitable mimetype found for this device');
    }
    return options;
  };

  const onSuccess = (stream: MediaStream) => {
    const options = optionsMediaRecorder();
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.onstop = handleStopRecording;
    mediaRecorder.onstart = handleStartRecording;
    setMediaRecorder(mediaRecorder);
    selfRecordingDispatch({
      type: selfRecordingActions.setMediaStream,
      mediaStream: stream,
    });
    return mediaRecorder;
  };

  const createMediaRecorder = async () => {
    const constraints = {
      audio: sensorSelection.microphone.enabled,
      video: sensorSelection.camera.enabled,
    };
    const promises = await navigator.mediaDevices.getUserMedia(constraints);

    if (promises) {
      const mediaRecorder = onSuccess(promises);
      return mediaRecorder;
    }
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Something went wrong with the camera or microphone',
        message: 'Please try again',
        type: NotificationType.Error,
      }),
    });
    return null;
  };

  useEffect(() => {
    updateSensorSelection({ type: 'CAMERA_TOGGLED', enabled: true });
    updateSensorSelection({ type: 'MICROPHONE_TOGGLED', enabled: true });
  }, []);

  return {
    closeAllTracks,
    mediaRecorderState,
    countingTime,
    retryFailedRequests,
    createMediaRecorder,
  };
};
