class AlbumsNew {
  title: string;

  coverSignedId?: string;

  contributors: ContributorType[];

  subscriptionType: string;

  constructor(params?: AlbumNewRequestType) {
    this.title = params?.title || '';
    this.subscriptionType = params?.subscriptionType || '';
    this.coverSignedId = params?.coverSignedId;
    this.contributors = params?.contributors || [];
  }
}

export { AlbumsNew };
