class UserUtmProperties {
  utmSource: string | null;

  utmMedium: string | null;

  utmCampaign: string | null;

  utmTerm: string | null;

  utmContent: string | null;

  constructor(params?: UserUtmProperties | null) {
    this.utmSource = params?.utmSource || null;
    this.utmMedium = params?.utmMedium || null;
    this.utmCampaign = params?.utmCampaign || null;
    this.utmTerm = params?.utmTerm || null;
    this.utmContent = params?.utmContent || null;
  }
}

export { UserUtmProperties };
