/* eslint-disable no-restricted-syntax */
import { classnames } from 'helpers/utils';
import React, { useEffect, useRef } from 'react';

type AudioVisualiserProps = {
  audioData: Uint8Array;
  className?: string;
};

const AudioVisualiser: React.FC<AudioVisualiserProps> = ({
  audioData,
  className = '',
}) => {
  const canvas = useRef(null);

  const draw = () => {
    const canvass: any = canvas.current;
    const { height, width } = canvass;
    const context = canvass.getContext('2d');
    let x = 0;
    const sliceWidth = (width * 1.0) / audioData.length;

    context.lineWidth = 2;
    context.strokeStyle = '#ffffff';
    context.clearRect(0, 0, width, height);

    context.beginPath();
    context.moveTo(0, height / 2);

    for (const item of audioData) {
      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;
    }
    context.lineTo(x, height / 2);
    context.stroke();
  };

  useEffect(() => {
    draw();
  }, [audioData]);

  return (
    <div className={classnames(className)}>
      <canvas width="100" height="100" ref={canvas} />
    </div>
  );
};

export { AudioVisualiser };
