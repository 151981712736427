enum CypressIds {
  emailSignup = 'signupEmail',
  emailSignin = 'signinEmail',
  passwordSignin = 'signinPassword',
  loginButton = 'loginButton',
  nameSignup = 'signupName',
  passwordSignup = 'signupPassword',
  companyNameSignup = 'signupCompanyName',
  repeatPasswordSignup = 'signupRepeatPassword',
  signupButton = 'signupButton',
  nameBackup = 'backupName',
  emailBackup = 'backupEmail',
  selectState = 'selectState',
  newAlbumButton = 'newAlbumButton',
  albumName = 'albumName',
  createAlbumButton = 'createAlbumButton',
  successCreateAlbum = 'successCreateAlbum',
  addTemplateButton = 'addTemplateButton',
  albumCard = 'albumCard',
  confirmModalButton = 'confirmModalButton',
  navbarMenu = 'navbarMenu',
  goToSignUp = 'goToSignUp',
  templatesAddButton = 'TemplatesAddButton',
}

export default CypressIds;
