import React, { useContext, useEffect, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import {
  classnames,
  getAllMediaQueries,
  showTextEllipsis,
} from 'helpers/utils';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { useParams } from 'react-router-dom';
import { Album } from 'models/album';
import { Spinner } from 'common/spinner';
import { maxCharactersAlbumTitleByResolution } from 'config/constants';
import { QuestionsSelector } from 'common/questions-selector';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { CameraUpload } from 'common/camera-upload';
import { InvalidGuestLink } from 'pages/invalid-guest-link';
import styles from './self-recording-guest.module.scss';

const SelfRecordingGuest: React.FC = () => {
  const { albumId, guestToken } = useParams<ParamsType>();
  const { state, dispatch } = useContext(SelfRecordingContext);
  const [isCustomRecording, setIsCustomRecording] = useState<boolean>(
    !state.selectedQuestion,
  );
  const [album, setAlbum] = useState<AlbumType>(new Album());
  const [fetching, setFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  const mediaQueries = getAllMediaQueries();
  const getMaxCharactersTitleByResolution = () => {
    const actualMedia = Object.keys(mediaQueries).find(
      (media) => mediaQueries[media as keyof typeof mediaQueries],
    );

    if (actualMedia) {
      return maxCharactersAlbumTitleByResolution[
        actualMedia as keyof typeof maxCharactersAlbumTitleByResolution
      ];
    }

    return maxCharactersAlbumTitleByResolution.xxl;
  };

  const showHeaderRow = () => (
    <div className={styles.headerRow}>
      <div className={styles.pictureRounded}>
        {album.coverThumbnailUrl ? (
          <img
            src={album.coverThumbnailUrl}
            className={styles.picture}
            alt="logo"
          />
        ) : (
          <div className={styles.defaultPicture}>
            <Logo className={styles.logo} />
          </div>
        )}
      </div>
      <div className={styles.titlesAndPencil}>
        <div className="text__body__regular__overline__textNeutral30">
          ALBUM
        </div>
        <div className={styles.title}>
          <span
            className={classnames(
              styles.albumName,
              'text__heading4__textNeutral40',
            )}
          >
            {showTextEllipsis(album.title, getMaxCharactersTitleByResolution())}
          </span>
        </div>
      </div>
    </div>
  );

  const onSelectCustomRecording = () => {
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: undefined,
    });
    setIsCustomRecording(true);
  };
  const onSelectQuestion = (id?: string) => {
    setIsCustomRecording(false);
    const question = album.unansweredQuestions?.find((item) => item.id === id);
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: question,
    });
  };

  const fetchAlbum = async () => {
    try {
      const response = await AlbumsController.getAlbumForGuest(albumId);
      setAlbum(response);
    } catch (_error) {
      setIsError(true);
    } finally {
      setFetching(false);
    }
  };

  const showContent = () => (
    <>
      <div className={styles.leftSide}>
        <p className="text__title2__textNeutral40">Select a question</p>
        <div className={styles.lineSeparator} />
        <QuestionsSelector
          album={album}
          selectedQuestion={state.selectedQuestion}
          onSelectQuestion={onSelectQuestion}
          isCustomRecording={isCustomRecording}
          onSelectCustomRecording={onSelectCustomRecording}
          isGuest
        />
      </div>
      <div className={styles.rightSide}>
        <CameraUpload
          album={album}
          selectedQuestion={state.selectedQuestion}
          notifySelectQuestion={onSelectQuestion}
          notifySelectCustomRecording={onSelectCustomRecording}
          refetchAlbum={fetchAlbum}
        />
      </div>
    </>
  );

  useEffect(() => {
    fetchAlbum();
  }, [guestToken]);
  useEffect(() => {
    dispatch({ type: selfRecordingActions.setAlbumId, albumId });
  }, []);

  if (isError) return <InvalidGuestLink />;
  return (
    <div className={globalStyles.loggedMainContainer}>
      <div className={styles.headerContent}>{showHeaderRow()}</div>
      <div className={classnames(styles.bodyContent)}>
        {!fetching ? showContent() : <Spinner />}
      </div>
    </div>
  );
};

export { SelfRecordingGuest };
