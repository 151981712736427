/* eslint-disable max-len */
import { ChevronIcon, InfoIcon } from 'assets/icons';
import {
  NotificationType,
  SubModificationType,
  SubscriptionSchemeId,
} from 'common/enums';
import { HelperText } from 'common/helper-text';
import { Modal } from 'common/modal';
import { SubscriptionCard } from 'common/subscription-card';
import { maxNumberDiscountCodes, rootUrl } from 'config/constants';
import { AppContext, appActions } from 'context';
import { getDiscountedPriceIfBetter } from 'helpers/subscription-utils';
import { classnames } from 'helpers/utils';
import { FormFooter } from 'common/form-footer';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { DiscountCode } from 'common/discount_code';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { useContext, useEffect, useState } from 'react';
import { DiscountCodeItem } from 'common/discount-code-item';
import { NotificationObject } from 'models/notificationObject';
import { SubscriptionType } from 'models/subscription-type';
import styles from './modify-subscription-modal.module.scss';

type ModifySubscriptionModalProps = {
  album: AlbumType;
  discountedPrices: SubscriptionDiscountedPriceType[];
  isInsideVideoCall: boolean;
  onClose: () => void;
  onSuccess: (
    subSelected: SubscriptionTypeType,
    subModificationType: SubModificationType,
    newTier: SubscriptionSchemeId,
  ) => void;
  notifyUpdateUserData: () => void;
};

const ModifySubscriptionModal = ({
  album,
  discountedPrices,
  isInsideVideoCall,
  onClose,
  onSuccess,
  notifyUpdateUserData,
}: ModifySubscriptionModalProps) => {
  const {
    state: { data, subscriptionsScheme },
    dispatch,
  } = useContext(AppContext);

  const getSubsAllowedForAlbum = (
    currentSubscriptionTypeId: SubscriptionSchemeId,
    allSubsList: SubscriptionTypeType[] = [],
  ) => {
    const currentSubType = allSubsList.find(
      (sub: SubscriptionTypeType) => sub.id === currentSubscriptionTypeId,
    );

    const subsAllowedForAlbum: SubscriptionTypeType[] = [currentSubType!];

    allSubsList.forEach((subScheme: SubscriptionTypeType) => {
      if (subScheme.id === SubscriptionSchemeId.readOnly) {
        return;
      }

      if (currentSubType?.permissions?.canSwitchTo.includes(subScheme.id)) {
        subsAllowedForAlbum.push(subScheme);
      }
    });

    const readOnlySub = allSubsList.find(
      (sub) =>
        sub.id === SubscriptionSchemeId.readOnly &&
        currentSubType?.permissions?.canSwitchTo.includes(sub.id) &&
        !isInsideVideoCall,
    );
    if (readOnlySub) {
      subsAllowedForAlbum.push(readOnlySub);
    }

    return subsAllowedForAlbum;
  };

  const availableSubs = getSubsAllowedForAlbum(
    album.subscriptionType,
    subscriptionsScheme,
  );

  const [subscriptionSelected, setSubscriptionSelected] =
    useState<SubscriptionTypeType>(new SubscriptionType());

  const [couponCode, setCouponCode] = useState<string>('');
  const [showDiscountDetails, setShowDiscountDetails] = useState(false);

  const getSubscriptionSelected = () => {
    const subSelected = subscriptionsScheme!.find(
      (sub: SubscriptionTypeType) => sub.id === album.subscriptionType,
    );

    setSubscriptionSelected(subSelected!);
  };

  useEffect(() => {
    getSubscriptionSelected();
  }, []);

  const handleApplyCode = async () =>
    SubscriptionController.applySubscriptionDealByCode(couponCode!);

  const showDiscountDetailsButton = () => (
    <div className={styles.discountDetailsButton}>
      <InfoIcon className={classnames(styles.discountDetailsIcon)} />
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        onClick={() => setShowDiscountDetails((value) => !value)}
        className={styles.discountButton}
      >
        Discount details
        <ChevronIcon
          className={classnames(styles.chevronIcon, {
            [styles.detailsOpen]: showDiscountDetails,
          })}
        />
      </Button>
    </div>
  );

  const deleteCouponCode = async (deal: SubscriptionDealResponseType) => {
    try {
      await SubscriptionController.removeSubscriptionDealCode(deal.id);
      notifyUpdateUserData();
    } catch (err) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Discount code',
          message: "Discount code couldn't be deleted",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const showDiscountList = (codesList: SubscriptionDealResponseType[]) => (
    <div className={styles.discountCodesList}>
      {codesList.map((deal: SubscriptionDealResponseType) => (
        <DiscountCodeItem
          key={deal.id}
          deal={deal}
          deleteCouponCode={deleteCouponCode}
        />
      ))}
    </div>
  );
  const showDiscountDetailsComponent = (
    discountList: SubscriptionDealResponseType[],
  ) =>
    discountList.map((discount) => (
      <div key={discount.id} className={styles.discountDetails}>
        <p className="text__body__regular__medium__textNeutral30">
          <span className="text__body__semi__bold__medium__textNeutral30">
            {discount.name.toUpperCase()}
          </span>{' '}
          - {discount.description}
        </p>
      </div>
    ));

  const showLearnMoreText = () => {
    const subTitleCardText = () => (
      <>
        <span className="text__body__regular__small__textNeutral30">
          {' '}
          Not Sure?{' '}
        </span>
        <a
          className={classnames('text__body__regular__small__primary50')}
          href={`${rootUrl}/pricing`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span> Learn more about our subscriptions. </span>
        </a>
      </>
    );

    return (
      <span>
        <HelperText
          content={subTitleCardText()}
          icon={<InfoIcon className={classnames(styles.infoIcon)} />}
          className={styles.helperText}
        />
      </span>
    );
  };

  const currentSubBasePriceIsLessThanNewSubBasePrice = (
    alb: AlbumType,
    subsScheme: SubscriptionTypeType[],
    subSelected: SubscriptionTypeType,
  ) => {
    const currentSubBasePrice = subsScheme.find(
      (sub: SubscriptionTypeType) => sub.id === alb.subscriptionType,
    )?.price!;

    const newSubBasePrice = subsScheme.find(
      (sub: SubscriptionTypeType) => sub.id === subSelected.id,
    )?.price!;

    return currentSubBasePrice < newSubBasePrice;
  };

  const handleConfirmSubModification = () => {
    let subModificationType;

    if (
      currentSubBasePriceIsLessThanNewSubBasePrice(
        album,
        subscriptionsScheme!,
        subscriptionSelected,
      )
    ) {
      subModificationType = SubModificationType.upgrade;
    } else {
      subModificationType = SubModificationType.downgrade;
    }

    onSuccess(
      subscriptionSelected,
      subModificationType,
      subscriptionSelected.id,
    );
  };

  const footerButtons = () => (
    <FormFooter
      includeLine={false}
      successButton="Next"
      readyToSubmit={subscriptionSelected.id !== album.subscriptionType}
      cancelButtonStyle={ButtonStyle.GreyGhost}
      successButtonStyle={ButtonStyle.PrimaryFilled}
      onClickCancel={onClose}
      onClickSuccess={() => handleConfirmSubModification()}
    />
  );

  return (
    <Modal
      title="Modify subscription"
      onClose={onClose}
      className={styles.modifySubscriptionModal}
      contentClassName={styles.content}
    >
      <>
        <div className={styles.textBody}>
          <div className="text__title2__textNeutral40">Modify subscription</div>
          <div className="text__body__regular__small__textNeutral30">
            <p>
              Subscription upgrades are prorated based on the remaining credit
              from your current subscription.
            </p>
            <p>
              Subscription downgrades requests are applied in the next annual
              billing cycle.
            </p>
          </div>
        </div>
        {showLearnMoreText()}

        <div className={styles.subscriptionOptions}>
          {availableSubs.map((subscription: SubscriptionTypeType) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              subscriptionActive={album.subscriptionType === subscription.id}
              cardSelected={subscriptionSelected.id === subscription.id}
              betterPrice={getDiscountedPriceIfBetter(
                subscription,
                discountedPrices,
              )}
              notifyClickCard={() => setSubscriptionSelected(subscription)}
              className={styles.subscriptionOption}
            />
          ))}
        </div>
        <div className={styles.discountCodeTitles}>
          <div className="text__title2__textNeutral40">Discounts</div>
          <div>
            <HelperText
              content="Your discount code will apply to all elegible albums."
              icon={<InfoIcon className={classnames(styles.infoIcon)} />}
              className={styles.helperText}
            />
          </div>
        </div>

        <div className={styles.discountCodeBody}>
          <DiscountCode
            className={styles.discountCodeInput}
            couponCode={couponCode}
            canAddMore={
              data.user.subscriptionDeals.length < maxNumberDiscountCodes
            }
            notifyApplyNewCode={() => handleApplyCode()}
            notifyChangeCoupon={(newValue: string) => setCouponCode(newValue)}
            onSuccess={() => {
              setCouponCode('');
              notifyUpdateUserData();
            }}
          />

          {data.user.subscriptionDeals.length ? (
            <div className={styles.discountCodeListAndDetails}>
              {showDiscountList(data.user.subscriptionDeals)}
              {showDiscountDetailsButton()}
            </div>
          ) : null}
        </div>

        {showDiscountDetails &&
          showDiscountDetailsComponent(data.user.subscriptionDeals)}
        <div className={styles.buttonsfooter}>{footerButtons()}</div>
      </>
    </Modal>
  );
};

export { ModifySubscriptionModal };
