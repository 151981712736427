import dayjs from 'dayjs';
import 'dayjs/plugin/duration';
import 'dayjs/plugin/relativeTime';
import 'dayjs/plugin/advancedFormat';

dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/relativeTime'));
dayjs.extend(require('dayjs/plugin/advancedFormat'));

export { dayjs };
